import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class SubcategoryService {

  uri = URI;

	constructor(private http: HttpClient) { }

  addSubcategory(subcategory){
		return this.http.post(`${this.uri}`+"subcategories/", subcategory);
  }

  editSubcategory(subcategory, id){
    return this.http.put(`${this.uri}`+"subcategories/"+id+"/", subcategory);
  }

  deleteSubcategory(id){
    return this.http.delete(`${this.uri}`+"subcategories/"+id+"/");
  }

}
