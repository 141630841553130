export const _commun = {
    required: true,
    label: false,
    classname: "",
    translatable: false,
    description: "",
    placeholder: "",
    active: true,
    width: "col-md-12"
}

export const _multiple = {
    values: [{
        label: "Label 1",
        value: "value-1",
        selected: true,
        conditionStep: ""

    }],
}


export const _btn = {
    ..._commun,
    "type_name":"Button",
    "type": "button",
    "url":"",
    "target":"_blank",
    "default":"",
}

export const _title = {
    ..._commun,
    "type_name":"Title",
    "type": "title",
    "default":"",
}



