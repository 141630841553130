import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubcategoryService } from 'src/app/shared/services/subcategory.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-subcategory',
  templateUrl: './dialog-subcategory.component.html',
  styleUrls: ['./dialog-subcategory.component.scss']
})
export class DialogSubcategoryComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSubcategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public subcat: SubcategoryService,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    public dialog: MatDialog

  )
  {
    this.notifier = notifierService;
  }


  private readonly notifier: NotifierService;



  subcategory: any;
  hotel:any;
  attrs:any;
  superFields:any;
  languages :any;
  category :any;
  errors = null;
  empty : any;

  ngOnInit() {
      this.languages = this.data.languages;
      this.subcategory = this.data.subcategory;
      this.empty = {...this.data.subcategory}
      this.hotel = this.data.hotel;
      this.attrs = this.data.attrs;
      this.superFields = this.data.superFields.super_subcat_attr;
      this.category = this.data.category;//need only for add action
  }

  /**
   * EDIT SUBCATEGORY
   * @param subcategory
   */
  editCategory(subcategory) {

    this.spinner.show('global');
    this.subcat.editSubcategory(subcategory, subcategory.id).subscribe(
      res => {
        subcategory = res;
        this.spinner.hide('global');
        this.translate.get('Subcategory successfully updated').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
        //close modal
        this.dialogRef.close(true);
      },
      err => {
        this.spinner.hide('global');
        this.errors = err.error;
        this.translate.get('An error has occurred').subscribe(
          value => {
            this.notifier.notify('error', value);
          }
        );
      }
    )
  }

  /**
   * CREATE SUBCATEGORY
   * @param subcategory
   */
  createCategory(subcategory, addNew =false){
      this.spinner.show('global');

      this.subcat.addSubcategory(subcategory).subscribe(res => {
          this.spinner.hide('global');
          this.translate.get('Category successfully created').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
          this.category.subcategories.push(res)
          //close modal
          this.dialogRef.close(true);

          //add another
          if(addNew){
            this.dialog.open(DialogSubcategoryComponent, {data:{...this.data, subcategory:this.empty}})
          }
      }, err=>{
        this.spinner.hide('global');
          this.errors = err.error;
          this.translate.get('An error has occurred').subscribe(
            value => {
              this.notifier.notify('error', value);
            }
          );
      })
  }

  /**
   * Create Or Update
   * @param subcategory
   * @param fb
   */
  createOrUpdate(subcategory, addNew=false){
    if(subcategory.id){
      this.editCategory(subcategory);
    }else{
      this.createCategory(subcategory, addNew);
    }
  }

}
