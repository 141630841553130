import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogMessageComponent } from 'src/app/components/modals/dialog-message/dialog-message.component';
import { DeeplTransService } from 'src/app/shared/services/deepl-trans.service';
import { WorkflowService } from 'src/app/shared/services/workflow.service';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DialogPreviewListComponent } from '../../dialogs/dialog-preview-list/dialog-preview-list.component';
import { convertAfter } from '../steps-trans/utilities';

@Component({
  selector: 'workflow-tab-content',
  templateUrl: './workflow-tab-content.component.html',
  styleUrls: ['./workflow-tab-content.component.scss']
})
export class WorkflowTabContentComponent implements OnInit {

  constructor(
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private workflowService: WorkflowService,
    private translate: TranslateService,
  ) {
    this.notifier = notifierService;
  }


  @Input() data_id : any
  @Input() hotel: any
  @Input() slug: any
  @Input() defaultLang: any
  @Input() languages: any
  @Input() workflow: any
  @Input() group: any
  IndexTranslatedField : any;

  private readonly notifier: NotifierService;

  workflowContent = { user: [], admin: [] };
  @Input() automaticLang = localStorage['hotel_language'];
  _breadCrumbs = []


  ngOnInit() {
  }





  /**
  * SAVE CONTENT
  */
 saveContent(type) {

  this.spinner.show('global');

  let saveContent = convertAfter(this.workflowContent[this.group]);
  let fb = new FormData();
  fb.append('format', 'v2');
  fb.append('translation', JSON.stringify(saveContent));
  fb.append('customer', this.slug);

  this.workflowService.setWorkflowTranslation(fb, type).subscribe(res => {
    this.spinner.hide('global');
    if (res['success']) {
      this.translate.get('Texts are saved').subscribe(
        value => {
          this.notifier.notify('success', value);
        }
      );
    } else {
      this.translate.get(res['message']).subscribe(
        value => {
          this.notifier.notify('error', value);
        }
      );
    }
  })
}


  /**
   *
   * @param attr
   */
  dialogPreview(attr) {
    console.log(this.defaultLang, "------");
    this.dialog.open(DialogPreviewListComponent, {
      data: {
        workflows: this.workflowContent[attr],
        languages: this.languages,
        hotel: this.hotel,
        defaultLang: this.defaultLang
      },
      width: '1354px'
    })
  }

  /**
   *
   * @param event
   * @param attr
   */
  updateContent(event, attr) {
    this.workflowContent[attr] = event.data
  }

  /**
   *
   * @param event
   */
  updateDefaultLang(event){
    this.automaticLang = event;
  }

  /**
   *
   * @param type
   */
  autoTranslate(type) {
    let templates = this.workflowContent[type];
    let i = 0;
    this.IndexTranslatedField = [];
    let notTranslatable = [];
    this.spinner.show('global');
    for (let temp of templates) {
      let j = 0;
      for (let text of temp.translations) {
        if (text.content[this.defaultLang]  && text.content[this.defaultLang].length < 500 && (typeof(text.content[this.automaticLang]) == undefined || !text.content[this.automaticLang])) {
          // this.updateText(type, text, i , j);
          this.indexingTranslatedInput(type, text, i , j)
        }else if(text.content[this.defaultLang]  && text.content[this.defaultLang].length > 500 && (typeof(text.content[this.automaticLang]) == undefined || !text.content[this.automaticLang])){
          notTranslatable.push("Template : "+temp.template_name + ", Variable :" + temp.translations[j].variable_name)
        }
        j++
      }
      i++
    }
    setTimeout(()=>{this.displayMaxLengthVariables(notTranslatable)},100);
    this.updateText()
  }


  /**
   *
   * @param message
   */
  displayMaxLengthVariables(message){
    (message.length) && this.dialog.open(DialogMessageComponent, {
      data:{title:"There are untranslatable variables since the number of characters exceeds 500", message:message.join('<br>')}, width:"700px"
    })
  }


  /**
   *
   * @param type
   * @param text
   * @param i
   * @param j
   */
  indexingTranslatedInput(type, text, i , j){
    this.IndexTranslatedField.push({
      type, text :text.content[this.defaultLang] , i, j, translated :''
    })
    //
  }

  /**
   *
   * @param type
   * @param text
   * @param i
   * @param j
   */
  updateText(){
    let textTosend = [];
    for(let text of this.IndexTranslatedField){
      textTosend.push(text.text);
    }
    let obj ={
      texts : textTosend,
      lang :this.automaticLang.toLowerCase(),
      lang_src: this.defaultLang
    }
    if(!obj.texts.length){
      alert('Nothing to translate');
      this.spinner.hide('global');

      return false;
    }

    this.workflowService.translate(obj).subscribe(res => {
      let i = 0;
      this.spinner.hide('global');
      if(res['translations']){
        for(let translated of res['translations']){
          let index = this.IndexTranslatedField[i];
          let type = index.type;
          let k = index.i;
          let j = index.j;
          this.workflowContent[type][k].translations[j].content[this.automaticLang]=translated.translation
          i++
        }
      }
      else {
        alert('An error was accured');

      }

    }, ()=>{
      alert('An error was accured');
      this.spinner.hide('global');

    })

  }





}
