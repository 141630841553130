import { ActiveSelectLanguageService } from 'src/app/shared/services/active-select-language.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-preview',
  templateUrl: './dialog-preview.component.html',
  styleUrls: ['./dialog-preview.component.scss']
})
export class DialogPreviewComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private selectLang : ActiveSelectLanguageService,

  ) { }

  url = {};
  languages = this.data.languages;
  hotel = this.data.hotel;
  lang ;
  init = false;

  ngOnInit() {
    this.init = true;

    this.lang = this.data.currentLang;
    this.selectLang.currentLanguage.subscribe(res=>{
      this.lang = res;
      console.log(res, '----------')
    })
  }




  getSafeUrl(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url.replace('http','https') + this.lang)
  }

  updateLang(val){
    this.init = false;
    this.lang = val
    this.init = true;
  }

}
