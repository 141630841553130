<h2 class="mat-dialog-title with-trans">
{{(trigger.id ? 'Edit Trigger' : 'Add Trigger') | translate }}
    <app-select-translate [hotel]="data.hotel"></app-select-translate>
  </h2>

<mat-dialog-content class="mat-typography">
  <div class="row">
  <div class="col-lg-6">
    <div class="form-group">
      <label>{{ "Name" | translate }}</label>
      <input type="text" [(ngModel)]="trigger.name" class="form-control">
      <app-error-input *ngIf="errors" [errors]="errors" name="trigger.name"></app-error-input>

    </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group">
      <mat-form-field>
        <mat-label>{{ "Category" | translate }}</mat-label>
        <mat-select  [(ngModel)]="trigger.category" name="category">
          <ng-container *ngFor="let category of data.categories" >
          <mat-option *ngIf="category.is_maintenance" [value]="category.key">
            {{category.name}}
          </mat-option>
        </ng-container>
        </mat-select >
      </mat-form-field>
      <app-error-input *ngIf="errors" [errors]="errors" name="trigger.category"></app-error-input>

    </div>
  </div>
 
  <!-- trame  -->
    <div class="col-lg-4">
      <mat-form-field class="full-width">
        <mat-label>{{ "Trame name" | translate }}</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="trame.name">
      </mat-form-field>
      <app-error-input *ngIf="errors" [errors]="errors" name="trame.name"></app-error-input>
    </div>

    <div class="col-lg-4">
      <div class="form-group">

        <mat-form-field>
          <mat-label>{{ "Send To" | translate }}</mat-label>
          <mat-select [(ngModel)]="trame.send_to" multiple>
            <mat-option *ngFor="let group of data.groups" [value]="group.id">{{group.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <app-error-input *ngIf="errors" [errors]="errors" name="trame.send_to"></app-error-input>

      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "Export type" | translate }}</mat-label>
          <mat-select matNativeControl [(ngModel)]="trame.export_type" name="category" multiple>
            <mat-option *ngFor="let export_type of export_types" [value]="export_type.value">
              {{export_type.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-error-input *ngIf="errors" [errors]="errors" name="trame.export_type"></app-error-input>
      </div>
    </div>


    <div class="col-lg-12">

      <div class="form-group">
        <label>{{ "Tags" | translate }} : <span class="tags"> @borne</span></label>
        <!--translated input-->
        <translated-input [model]="trame" attr="tags" [hotel]="data.hotel" [type]="'input'" label="Notification text">
        </translated-input>
        <!--translated input-->
        <app-error-input *ngIf="errors" [errors]="errors" name="trame.tags"></app-error-input>

      </div>
    </div>
  </div>
  <!-- trame  -->



</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{ "Cancel" | translate }}</button>
  <button mat-button (click)="create()"  class="btn btn-success">{{ "Save" | translate }}</button>
</mat-dialog-actions>