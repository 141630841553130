<div class="white-box content nopadd {{template?.color}}">
  <i class="{{template?.icon}}"></i>
  <span class="title">{{tile?.title ? tile?.title : template?.name}} {{getPeriod()}}</span>
  <span class="number" *ngIf="!template?.configs.operation">{{template?.configs?.target ? data[template?.configs.target]  : data?.count ? data?.count : 0}} 
    <ng-container *ngIf="!template?.subtitle">€</ng-container>
    <small *ngIf="template?.subtitle">{{template?.subtitle}}</small><br>
    <small class="sm">{{template?.text}}</small>
  </span>
  <span class="number" *ngIf="template?.configs.operation == 'length'">
    {{getCount()}}
    <small *ngIf="template?.subtitle">{{template?.subtitle}}</small><br>
    <small class="sm">{{template?.text}}</small>
  </span>

</div>