  <div *ngIf="data">

    <div class="row">
      <!--config-->
      <div class="col-6">
        <ng-container *ngIf="configs?.config_filter">


        <strong>Search</strong>
        <select class="form-control inline" [(ngModel)]="searchFilter.key"> 
          <option [value]="option.key" *ngFor="let option of configs?.config_filter"> {{option.value}}</option>
        </select>

        <input type="text" placeholder="Search" class="form-control inline" [(ngModel)]="searchFilter.value"> 

        <button class="btn btn-info" (click)="getData()">Search</button>
      </ng-container>
      </div>
      
      <div class="text-right col-6">
        <button class="btn btn-success" (click)="exportexcel()"><i class="fa fa-cloud-download" aria-hidden="true"></i>         Excel Export</button>
        <select (change)="getData({page_number:1,number_per_page:number_per_page})" [(ngModel)]="currentPeriod" class="inline form-control">
          <option disabled>{{'Select a period' | translate}}</option>
          <option *ngFor="let period of periods" value="{{period.value}}">{{period.name | lowercase | translate}}
          </option>
        </select>
        <button class="btn btn-success" (click)="configDisplay()">
          <i class="fa fa-cog" aria-hidden="true"></i>
        </button>

        <button class="btn btn-default" (click)="getData()">
          <i class="fa fa-refresh" aria-hidden="true"></i>
        </button>
      </div>
      <!-- <div class="col-3 vertical-align text-right">
        <input class="form-control" [(ngModel)]="searchValue" placeholder="{{'Search' | translate}}" type="search">
      </div> -->

   
      <!-- <ng-container *ngFor="let filter of filters">

        <div class="col-lg-2 col-md-4">
          <label for="">{{filter.name}}</label>
          <select *ngIf="inList(headers,filter.name)" (change)="filterTable($event)"
            [(ngModel)]="filterModel[filter.name]" class="form-control">
            <option value="" selected>{{'All' | translate}}</option>
            <option *ngFor="let value of filter.values" value="{{value}}">{{value}}</option>
          </select>
        </div>
      </ng-container> -->


    </div>
    <br>
    <div class="table-responsive" *ngIf="data">
      <app-table-supervison (OnPagination)="getData($event)" [totalpages]="totalpages" [count]="count" [page_number]="page_number" [configs]="configs" [isLoading]="isLoading" [data]="data" [hotel]="hotel" [displayedColumns]="displayedColumns"
        [searchValue]="searchValue">
      </app-table-supervison>

      
    </div>
  </div>

 