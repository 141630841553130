<div class="text-right">

  <select [(ngModel)]="type" class="btn btn-xs btn-default" (change)="actions('change-type')" *ngIf="change_type">
    <option value="column">{{'Column' | translate}}</option>
    <option value="area">{{'Area' | translate}}</option>
    <option value="spline">{{'Spline' | translate}}</option>
  </select>

  <select class="btn btn-xs btn-default" [(ngModel)]="period" (change)="actions('change-period')">
    <option value="{{period.value}}" *ngFor="let period of periods">{{period.name | translate}}</option>
  </select>
  <button class="btn  btn-xs btn-default btn-refresh" (click)="actions('reload')"> 
    <i class="fa fa-refresh" aria-hidden="true"></i>
  </button>
</div>