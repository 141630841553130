import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class HotelService {

	uri = URI;

	constructor(private http: HttpClient) {
	}

	/**
	 * GET LIST HOTELS SERVICE
	 */
	getHotels() {
		return this.http.get(`${this.uri}`+"hotels/");
	}

	/**
	 * GET HOTEL SERVICE BY ID
	 * @param id
	 */
	getHotel(id){
		return this.http.get(`${this.uri}`+"hotels/"+id);
	}

	/**
	 * GET ALL LANGUAGES
	 */
	getLanguages(){
		return this.http.get(`${this.uri}`+"language-hotels/");
	}

	/**
	 * UPDATE HOTEL SERVICE
	 */
	updateHotel(id, hotel){
		return this.http.patch(URI + 'hotels/'+id+'/', hotel)
	}

	/**
	 * UPDATE IMAGE HOTEL SERVICE
	 * @param id
	 * @param hotel
	 */
	updateHotelImage(id, hotel){
		return this.http.put<any>(URI + 'hotel/'+id+'/update-image', hotel)
	}

	/**
	 * UPDATE HOTEL LANG
	 * @param id
	 * @param hotel
	 */
	updateHotelLang(id, hotel){
		return this.http.put<any>(URI + 'hotel/'+id+'/update-lang', hotel)
  	}


}
