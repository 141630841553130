<!-- Page Content -->
<div class="container-fluid">
  <app-fixed-title *ngIf="!onglet" [title]='title' [hotel]='hotel'></app-fixed-title>

  <h4  class="mt-3" *ngIf="onglet">{{title}}</h4>

  <div class="white-box">


  <mat-tab-group *ngIf="devices">
    <ng-container *ngFor="let type of paramTypes" >


    <mat-tab [label]="type.name" *ngIf="getParamsByType(type.key)">
      <div class="row">
        <ng-container  *ngFor="let device of devices">

        <div class="col-md-4" *ngIf="device.NAME_SCREEN_FORMAT == type.key">
          <div class="block">
            <h3>{{device?.NAME}}</h3>
            <p>
              <ng-container *appHasRole="'super-user'">UID :  {{device?.UUID_DEVICE}} <br> </ng-container>
              Form : {{device.FORM_JSON ? 'Created' : 'Not created'}} <br>
              Last modification : {{device.DATE_MODIF | date : 'dd/mm/yyyy'}}
            </p>
            <div class="actions text-right">
              <a class="btn btn-info btn-sm" [routerLink]="['/device/form', slug, device.ID_DEVICE]" *appHasRole="'super-user'"><i class="fa fa-bookmark" aria-hidden="true"></i> {{'Edit form'| translate}}</a>
              <a [routerLink]="['/device/fill-form', slug, device.ID_DEVICE]"  class="btn btn-info btn-sm" ><i class="fa fa-check-square-o" aria-hidden="true"></i> {{'Fill the form'| translate}}</a>
          
            </div>
          </div>
        </div>
      </ng-container>

      </div>
    </mat-tab>
  </ng-container>
  </mat-tab-group>
</div>
</div>