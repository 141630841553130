<div class="lang-config-select" [ngClass]="{'fixed-lang-config':fixed}" *ngIf="hotel?.languages.length">

     <img src="assets/img/icons/svg/{{hotel_lang}}.svg" class="flag">
     <mat-select class="form-control"  *ngIf="hotel" [(ngModel)]="hotel_lang" (selectionChange)="updateModel($event.value)">
      <mat-option *ngFor="let lang of hotel.languages"
      [value]="lang.lang_code">
      <img src="assets/img/icons/svg/{{lang.lang_code}}.svg"> {{lang.lang_name | translate}}
      </mat-option>
    </mat-select>
</div>

 