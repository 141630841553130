<h2 class="mat-dialog-title with-trans">
  {{'Preview Template' | translate}}
  <button class="btn" [ngClass]="{'btn-success': grid == 4, 'btn-default' : grid == 6}" (click)="updateGrid(4)">
    <i class="fa fa-th" aria-hidden="true"></i>
  </button>
  <button class="btn"  [ngClass]="{'btn-success': grid == 6, 'btn-default' : grid == 4}"  (click)="updateGrid(6)">
    <i class="fa fa-th-large" aria-hidden="true"></i>
  </button>
  <app-select-translate [hotel]="hotel" [default]="lang" (changeEvent)="updateLang($event)" [model]="lang" [languages]="languages">
  </app-select-translate>
</h2>
<mat-dialog-content class="mat-typography">
  
  <div class="row">
    <div class="col-md-{{grid}}" *ngFor="let url of urls">
     <h4>{{url.i}} - {{formatText(url.name)}}</h4> 
      <div class="content-iframe" >
        <iframe [src]="url.url" frameborder="0" *ngIf="init"></iframe>
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
 
</mat-dialog-content>
<mat-dialog-actions align="end">

  <button mat-button mat-dialog-close [mat-dialog-close]="" class="btn btn-default">{{'Close' | translate}}</button>
</mat-dialog-actions>