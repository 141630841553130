import { NgxSpinnerService } from 'ngx-spinner';
import { RoomCategoryService } from 'src/app/shared/services/room-category.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-room-category',
  templateUrl: './dialog-room-category.component.html',
  styleUrls: ['./dialog-room-category.component.scss']
})
export class DialogRoomCategoryComponent implements OnInit {

  errors : any;
  hotel = this.data.hotel;
  constructor(
    public dialogRef: MatDialogRef<DialogRoomCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private roomCategoryService : RoomCategoryService,
    private spinner: NgxSpinnerService,
  ) {

   }

  ngOnInit() {
  }


  /**
   *
   * @param data
   */
  create(data){
    this.roomCategoryService.create({...data.room, customer: this.data.slug}).subscribe(res => {
      this.spinner.hide('global');
      this.dialogRef.close(res);
    }, err=>{
      this.errors = err.error
      this.spinner.hide('global');

    });
  }
  /**
   *
   * @param data
   */
  update(data){
    this.roomCategoryService.update({...data.room}).subscribe(res => {
      this.spinner.hide('global');
      this.dialogRef.close(res);
    }, err=>{
      this.errors = err.error
      this.spinner.hide('global');

    });
  }

  createOrUpdate(data){
    this.spinner.show('global');
    if (!data['id']) {
      this.create(this.data);
    } else {
      this.update(this.data);
    }
  }
}
