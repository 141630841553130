import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class TabpayService {

  uri = URI;

  constructor(private http: HttpClient) { }

    getTransactionList(id, link="", status='success', period = '', amount){
    	if (link){
    		return this.http.get(link);
    	}else {
			let params = "?"
			if(period)
				params += "period="+period;
			if(amount)
				params += "&operator="+amount.operator+"&amount="+amount.amount;

			return this.http.get(`${this.uri}`+"transaction-list-by-status/"+id+'/'+status+params);
    	}
	}


	directPayment(trans){
		return this.http.post(`${this.uri}`+"transaction-direct-payment-with-token",trans);
	}


	creditPayment(trans){
		return this.http.post(`${this.uri}`+"transaction-credit-with-token",trans);
	}


	getListByHotel(params){
		return this.http.get(`${this.uri}`+"transactions/"+params);
	}

	getById(id){
		return this.http.get(`${this.uri}`+"transactions/"+id+"/");
	}

	/**
	 *
	 * @param customer_id
	 * @param action
	 * @param reference
	 * @param reservation_code
	 * @param amount
	 */
	debitOrCancel(obj){
		return this.http.post(this.uri+"payment/",obj);
	}



}
