<div *ngIf="module.configs">

  <ng-container *ngFor="let btn of module.configs.go_to_btns; let i = index">

    <div class="row">
      <!-- field -->
      <div class="form-group  col">
        <label>Type</label>
        <select class="form-control" [(ngModel)]="btn.type">
          <option value="module">Link to module</option>
          <option value="inten_link">Internal link</option>
          <option value="extern_link">External link</option>
        </select>
      </div>
      <!-- field -->

      <!-- field -->
      <div class="form-group  col">
        <label>Text</label>
        <input type="text" class="form-control" [(ngModel)]="btn.button_text">
      </div>
      <!-- field -->


      <!-- field -->
      <div class="form-group  col">
        <label>Parameters</label>
        <input type="text" class="form-control" placeholder="WORFKLOW={ { id } }" [(ngModel)]="btn.params">
      </div>
      <!-- field -->

      <!-- field -->
      <div class="form-group  col"  *ngIf="btn.type != 'module'">
        <label>link</label>
        <input type="text" class="form-control" placeholder="Link" [(ngModel)]="btn.link">
      </div>
      <!-- field -->

      <!-- field -->
      <div class="form-group  col" *ngIf="btn.type == 'module'">
        <label>Module</label>
        <select [(ngModel)]="btn.module" class="form-control">
          <option [value]="module.id" *ngFor="let module of all_modules">
            {{module.name}}
          </option>
        </select>
      </div>
      <!-- field -->

      <div class="col-1">
        <button (click)="delete(i)" class="btn btn-danger btn-xs"><i class="fa fa-trash"></i></button>
      </div>
    </div>
  </ng-container>

</div>

<button class="btn btn-xs btn-success" (click)="addBtn()">Add button</button>