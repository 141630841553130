<h2 class="mat-dialog-title">
   {{(data['type'] == "file")? 'Rename File' : 'Folder' | translate}}
</h2>
<mat-dialog-content class="mat-typography">
  <div class="form-group">
    <input type="text" *ngIf='data["type"] == "folder"' class="form-control" placeholder='{{"Name of folder" | translate}} ' [(ngModel)]="data['name']">
    <input type="text" *ngIf='data["type"] == "file"' class="form-control" placeholder='{{"Name of file" | translate}} ' [(ngModel)]="file">
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{'Cancel' | translate}}</button>
  <button mat-button [mat-dialog-close]="getNameAfterEdit()"  class="btn btn-success">{{'Confirm'| translate}}</button>
</mat-dialog-actions>