import { MEDIA } from 'src/app/shared/settings/global';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'form-list-inputs',
  templateUrl: './list-inputs.component.html',
  styleUrls: ['./list-inputs.component.scss']
})
export class ListInputsComponent implements OnInit {

  constructor() { }

  @Input() attrs      :any;
  @Input() hotel      :any;
  @Input() model      :any;
  @Input() languages  :any;
  @Input() fb         :any;
  @Input() type       :any;
  @Input() group      :any;
  @Input() superFields:any;
  @Input() extraObject:any;
  @Input() errors     :any;


  MEDIA = MEDIA;

  ngOnInit() {
    if(!this.fb){
      this.fb = new FormData();
    }

  }

  /**
   *
   * @param role
   */
  getPermission(role){
    return typeof role == 'undefined' ?  'concierge' : role
  }


  getVal(name, value){
    this.model[name] = value;
  }


}
