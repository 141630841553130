<div class="box-scroll minth bold-scroll">

  <table mat-table id="excel-table" [dataSource]="dataSource" class="table-bordered supervision" matSort [matSortActive]="orderField"
    [matSortDirection]="sortDirection" (matSortChange)="sortData($event)">

    <ng-container *ngFor="let btn of configs.customBtnsParams">
      <ng-container [matColumnDef]="btn.text" *appHasRole="btn.role">
        <th mat-header-cell *matHeaderCellDef> {{btn.text}} </th>
        <td mat-cell *matCellDef="let element">
          <button class="btn btn-xs btn-success" (click)="openDetailAPI(element, btn)">
            <i [ngClass]="btn.icon"></i>
          </button>
        </td>
      </ng-container> 
    </ng-container>
     


       <ng-container matColumnDef="Detail">
        <th mat-header-cell *matHeaderCellDef> {{'Detail' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <button class="btn btn-xs btn-success" (click)="openDetailClient(element)">
            <i class="fa fa-address-card-o" ></i>
          </button>
        </td>
      </ng-container>  
 

    <!-- Position Column -->
    <ng-container *ngFor="let column of displayedColumns" matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef > {{getName(column) | titlecase | translate }} </th>
      <td mat-cell *matCellDef="let element" [ngClass]="getClassTd(element[column])">
        <app-table-td [value]="element[column]" [attr]="column" *ngIf="element !== null && element[column] !== undefined">
        </app-table-td>
        <div *ngIf="element && element[column] === ''" class="text-center">---</div>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="customDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: customDisplayedColumns;"></tr>
  </table>

  <div class="loader" *ngIf="isLoading">
    <div></div>
    <div></div>
  </div>

  <div class="alert alert-info text-center" *ngIf="!isLoading && !data.length">
    There's no data in this period
  </div>




</div>
<nav aria-label="Page navigation " class="pagination" *ngIf="page_number">

  
  <ul class="pagination justify-content-end">
      <li>Page {{page_number}} of {{totalpages}}</li>
      <li>
          <select class="form-control" [(ngModel)]="number_per_page" (change)="changePageSize()">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
          </select>
      </li>
    <li class="page-item"><a class="page-link" (click)="paginate(1, true)" [ngClass]="{'disabled':count <= 10}">
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
    </a></li>
    <li class="page-item"><a class="page-link" (click)="paginate(page_number-1, page_number>1)" [ngClass]="{'disabled':page_number==1}">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </a></li>
    <li class="page-item"><a class="page-link" (click)="paginate(page_number+1, page_number <= totalpages)" [ngClass]="{'disabled':page_number < page_number}">
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
    </a></li>
    <li class="page-item"><a class="page-link" (click)="paginate(totalpages, true)" [ngClass]="{'disabled':count <= 10}"> 
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
    </a></li>
  </ul>
</nav>
