<div class="container-fluid">
    <div class="white-box">


        <mat-tab-group>
            <mat-tab label="Hotel Information">
                <app-settings [onglet]="true"></app-settings>
            </mat-tab>
            <mat-tab [label]="module_journey.name" *ngIf="module_journey">
                <app-module-worker [module]="module_journey"></app-module-worker>
            </mat-tab>
            <!-- <mat-tab label="Journeys"> Content 2 </mat-tab> -->
            <mat-tab label="Offers">
                <app-upsell-list [onglet]="true"></app-upsell-list>
             </mat-tab>
            <mat-tab label="Product exclusion">
                <app-item-link-pms></app-item-link-pms>
            </mat-tab>
            <mat-tab label="Notifications"> 
                <app-manage-triggers></app-manage-triggers>    
            </mat-tab>
            <mat-tab label="Accounts">
                <app-users [onglet]="true"></app-users>
            </mat-tab>
            <mat-tab label="Access rights">
                <app-manage-roles [onglet]="true"></app-manage-roles>
            </mat-tab>
            <mat-tab [label]="module.name" *ngFor="let module of modules">
                <app-module-worker [module]="module"></app-module-worker>
            </mat-tab>
            

        </mat-tab-group>

    </div>