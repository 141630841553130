
export const super_category_attr = [

	{
		label: "Informations",
		fields: [
			{
				name: "hotel",
				label: "Hotel",
				input: false,
				translatable: false
			},
			{
				name: "name",
				label: "Name",
				input: 'input',
				translatable: true,
				width: "col-lg-6"
			},
			{
				name: "visible",
				label: "Visible",
				input: 'checkbox',
				translatable: false,
				width: 'col-lg-2'
            },
      
            {
                name: "use_for_upsell",
                label: "Use for upsell",
                input: 'hidden',
                translatable: false,
                value: true
            },
			{
				name: "short_description",
				label: "Short description",
				input: 'htmleditor',
				translatable: true,
				width: 'col-lg-12'
			},
			{
				name: "long_description",
				label: "Long description",
				input: 'htmleditor',
				translatable: true,
				width: 'col-lg-12'
			},
            {
                name:"image",
                label:"Image",
                input: "media",
                translatable:false,
                width: 'col-lg-12'
            }
		]
	}
];
export const super_subcat_attr = false;
export const super_item_attr = [
    {
        label: "Informations",
        name : "information_tab",
        fields: [
            {
                name: "name",
                label: "Name",
                input: "input",
                translatable: true,
                width: 'col-lg-6'
            },
            {
                name: "item_pms_id",
                label: "ID PMS",
                input: "input",
                translatable: false,
                permission: 'administrator',
                width: 'col-lg-6'
            },
            {
                name: "initial_price",
                label: "Unit price",
                input: "input",
                type: "number",
                translatable: false,
                width: 'col-lg-6'
            },
            {
                name: "price_text",
                label: "Text for unit price",
                input: "input",
                translatable: true,
                width: 'col-lg-6'

            },
            {
                name: "qte_type",
                label: "Quantity type",
                input: "select",
                translatable: false,
                width: 'col-lg-6',
                values: [
                    ["MAN", "Unit"],
                    ["NGT", "Nb night"],
                    ["NOG", "Nb Guest"],
                    ["NGT-1", "Nb night-1"],
                    ["NAG", "Nb guest x Nb night"],
                    ["NAGA", "Nb guest adluls x Nb night"],
                    ["NAGC", "Nb guest children x Nb night"],
                    ["NAR","Nb room"]
                ]
            },
            {
                name: "short_description",
                label: "Short description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "long_description",
                label: "Long description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
				name: "dont_add_if_in_basket",
				label: "Dont add if in basket",
				input: 'checkbox',
				translatable: false,
				width: 'col-lg-2'
            },
            {
				name: "show_stay_quantity",
				label: "Show stay quantity",
				input: 'checkbox',
				translatable: false,
				width: 'col-lg-2'
            },
            {
				name: "has_calendar_stock",
				label: "Calendar Stock",
				input: 'checkbox',
				translatable: false,
				width: 'col-lg-2'
            },
            {
				name: "manual_stock",
				label: "Manual Stock",
				input: 'checkbox',
				translatable: false,
				width: 'col-lg-2'
            }
            
        ]
    },
    {
        label:"Images",
        name:"images_tab",
        fields:[
            {
                name: "image_square",
                label: "First Image",
                input: "media",
                translatable: false,
                width: 'col-lg-6',
                maintainRatio : true,
                aspectRatio : 4/2
            },
            {
                name: "image_rectangular",
                label: "Second image",
                input: "media",
                translatable: false,
                width: 'col-lg-6',
                aspectRatio : 1/2,
                maintainRatio : true,
            }
        ]
    },



]

export const presentation = "These contents are deployed on the Concierge product pages and on the Product Upsell module";
