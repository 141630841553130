import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	uri = URI

	constructor(private http: HttpClient) { }

	/**
	 *
	 * @param id
	 */
	getAll(id){//id hotel
		return this.http.get(`${this.uri}` +"users-th/?hotels="+id);
	}

	get(id){
		return this.http.get(`${this.uri}` +"users-th/"+id);
	}
	/**
	 *
	 * @param user
	 * @param id
	 */
	update(user, id){
		return this.http.patch(`${this.uri}` +"users-th/"+id+'/', user)
	}

	/**
	 * create Admin Or Concierge
	 * @param user
	 */
	create(user){
		return this.http.post(`${this.uri}` +"rest-auth/register/", user);
	}

	/**
	 *
	 * @param inv
	 */
	sendInvitation(inv){
		return this.http.post(`${this.uri}` +"invitation/", inv);
	}

	/**
	 * GET ALL GROUPS
	 */
	getGroups(){
		return this.http.get(this.uri +"groups");
	}

	forgotPassword(email){
		return this.http.post<any>(this.uri +"reset-password", {email});
	}
	resetPassword(obj){
		return this.http.post<any>(this.uri +"reset-password-confirm", obj);
	}

	deleteUser(id){
		return this.http.delete(this.uri +"users-th/"+id+"/");
	}

}
