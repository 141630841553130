
export const super_category_attr = [

    {
        label: "Informations",
        fields: [
            {
                name: "hotel",
                label: "Hotel",
                input: false,
                translatable: false
            },
            {
                name: "name",
                label: "Name",
                input: 'input',
                translatable: true,
                width: "col-lg-6"
            },
            {
                name: "visible",
                label: "Visible",
                input: 'checkbox',
                translatable: false,
                width: 'col-lg-3'
            },
            {
                name: "short_description",
                label: "Short description",
                input: 'htmleditor',
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "long_description",
                label: "Long description",
                input: 'htmleditor',
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name:"image",
                label:"Image",
                input: "media",
                translatable:false,
                width: 'col-lg-6'
            },
            {
                name: "color",
                label: "Color",
                input: 'input',
                type : 'color',
                translatable: false,
                width: "col-lg-4"
            },
        ]
    }
];
export const super_subcat_attr = [
    "_all"
]
export const super_item_attr = [
    {
        label: "Informations",
        name : "information_tab",
        fields: [
            {
                name: "name",
                label: "Name",
                input: "input",
                translatable: true
            },
            {
                name: "icon",
                label: "Icon",
                input: "select-icon",
                translatable: false
            },
             {
                name: "short_description",
                label: "Short description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "long_description",
                label: "Long description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
           
            {
                name: "initial_price",
                label: "Initial price",
                input: "input",
                type: "number",
                translatable: false
            },
            {
                name: "extern_url",
                label: "Extern Url",
                input: "input",
                type: "url",
                translatable: false
            },
           
             {
                name: "optional_title",
                label: "Optional title",
                input: "input",
                translatable: true
            },
            {
                name: "optional_description",
                label: "Optional description",
                input: "input",
                translatable: true
            },

        ]
    },
    {
        label:"Images",
        name:"images_tab",
        fields:[
            {
                name: "image_square",
                label: "First Image",
                input: "media",
                translatable: false,
                width: 'col-lg-6'
            },
            {
                name: "image_rectangular",
                label: "Second image",
                input: "media",
                translatable: false,
                width: 'col-lg-6'
            }
        ]
    },
    {
        label: "Options",
        name : "option_tab",
        fields: [
            {
                name: "visible",
                label: "Visible",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },
            {
                name: "more_details",
                label: "More details",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },
            {
                name: "home_page",
                label: "Home page",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            }
        ]
    }
]

export const presentation = "These contents are deployed on the Concierge activities page";