import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ActiveSelectLanguageService {

  currentLanguage = new EventEmitter();


  constructor() { }


  /**
   *
   * @param lang
   */
  setLanguage(lang){
    this.currentLanguage.emit(lang)
  }
}
