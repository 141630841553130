import { NotifierService } from 'angular-notifier';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { TransportService } from 'src/app/shared/services/transport.service';

import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DialogTransportComponent } from '../../modals/transport/transport.component';

@Component({
  selector: 'hotel-transport-arround',
  templateUrl: './transport-arround.component.html',
  styleUrls: ['./transport-arround.component.scss']
})
export class TransportArroundComponent {

  constructor(
    private trans: TransportService,
    public dialog: MatDialog,
    private translate: TranslateService,
    notifierService: NotifierService
    ) {
      this.notifier = notifierService;
    }

  private readonly notifier: NotifierService;
  @Input() transports = [];
  @Input() hotel:any;
  @Input() languages:any;
  transport  = {description:"",category:""};



  /**
   * DIALOG TRANSPORT
   * @param transport
   */
  openDialogTransport(transport = this.transport) {
    let data = {
      transport ,
      hotel     : this.hotel,
      languages : this.languages,
      transports : this.transports
    };
    const dialogRefTrs = this.dialog.open(DialogTransportComponent, { data: data , width:'670px' });

    dialogRefTrs.afterClosed().subscribe(result => {
      if(result.action == "add"){
        this.transports.push(result.response);
      }else if(result.action == "update"){
        transport = result.response;
      }
    });
  }


   /**
    * DELETE Transport MODAL
    * @param object
    * @param name
    * @param type
    * @param index
    */
   deleteConfirm(object, name, type, index) {

    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name, type }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'delete') {
        //Request Transport
        this.trans.deletTransport(object.id).subscribe(() => {
          this.transports.splice(index, 1);
          this.translate.get('Transport successfully deleted').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
        },
        err => console.log(err))
      }
    });

  }



}
