import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NavPaginatorComponent } from '../components/paginator/nav-paginator/nav-paginator.component';
import { DebugComponent } from './components/debug/debug.component';
import { FixedTitleComponent } from './components/fixed-title/fixed-title.component';
import { CustomerTypeDirective } from './directives/customer-type.directive';
import { HasRoleDirective } from './directives/has-role.directive';
import { HasntRoleDirective } from './directives/hasnt-role.directive';
import { IsLinkDirective } from './directives/is-link.directive';
import { IsNotLinkDirective } from './directives/is-not-link.directive';
import { PlaceholderPipe } from './pipes/placeholder.pipe';
import { ReplaceNullWithTextPipe } from './pipes/remplacenulltext.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { RouterModule } from '@angular/router';
import { ChartSupervisionComponent } from './components/chart-supervision/chart-supervision.component';
import { ChartModule } from 'angular-highcharts';
import { DateAgoPipe } from './pipes/date-ago.pipe';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    SafePipe,
    PlaceholderPipe,
    ReplaceNullWithTextPipe,
    CustomerTypeDirective,
    HasRoleDirective,
    IsLinkDirective,
    IsNotLinkDirective,
    HasntRoleDirective,
    TruncatePipe,
    FixedTitleComponent,
    DebugComponent,
    NavPaginatorComponent,
    ChartSupervisionComponent,
    DateAgoPipe,
  ],
  imports: [
    CommonModule,
    NgxJsonViewerModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatChipsModule,
    DragDropModule,
    LayoutModule,
    MatMenuModule,
    MatInputModule,
    ChartModule,
    MatIconModule,
    NgxJsonViewerModule,
    MatFormFieldModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    SafePipe,
    DateAgoPipe,
    PlaceholderPipe,
    ReplaceNullWithTextPipe,
    CustomerTypeDirective,
    HasRoleDirective,
    IsLinkDirective,
    IsNotLinkDirective,
    HasntRoleDirective,
    TruncatePipe,
    TranslateModule,
    FixedTitleComponent,
    DebugComponent,
    NavPaginatorComponent,
    ChartSupervisionComponent
  ],
})
export class SharedModule {}
