import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { ModuleManagerService } from 'src/app/shared/services/module-manager.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-module-manager-edit',
  templateUrl: './module-manager-edit.component.html',
  styleUrls: ['./module-manager-edit.component.scss']
})
export class ModuleManagerEditComponent implements OnInit {



  constructor(
    private hs: HotelService,
    notifierService: NotifierService,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private moduleService: ModuleManagerService,
    private _router: Router,
    public dialog: MatDialog) {
    this.notifier = notifierService;

  }

  private readonly notifier: NotifierService;


  title = "Module Manager : ";
  user = JSON.parse(localStorage.getItem('user_obj'));
  slug: any;
  hotel: any;
  module = {
    id: null,
    name: "New Module",
    endpoint: "test",
    title: "New Module",
    image: "",
    createdby: this.user.first_name + this.user.last_name,
    form: { params: { autoTranslate: false }, sections: [{ sectionName: "Section 1", fields: [] }] },
    configs: {
      api_create:"",
      api_get:"",
      api_update:"",
      api_list:"",
      api_delete:"",
      newObject:"",
      default_value:"",
      single_name:"",
      plural_name:"",
      menu_name: "",
      icon: "",
      one_row:false,//use for module without datatable only one row
      api_form:"",
      active: true,
      router_link: "",
      open_in_dialog: false,
      can_delete: true,
      access_to_data: "",
      pagination_clientside: false,
      pagination_serverside: false,
      permission: 'concierge', //or administrator or super-user
      dispay_form_sections: 'tabs', //or steps,
      header_table: [],
      variables : [],
      go_to:{
        button_text : "",
        params : "",
        module : "",

      }
    },
    hotel: ""
  };
  module_id: any;

  ngOnInit() {
    this.module.createdby = this.user.first_name + this.user.last_name,
    this.spinner.show('global');

    this.router.params.subscribe(params => {

      this.slug = params.slug;
      this.module_id = params.module_id;
      this.module.hotel = this.slug;

      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
        this.spinner.hide('global');
      });


      if (this.module_id != "new") {
        this.moduleService.get(this.module_id).subscribe((res: any) => {
          this.module = res;

          //ADD ROLE FOR OLD SECTIONS
          this.module.form.sections.map(y=>{
            y["role"] = y["role"] == undefined ?  "concierge" : y["role"]
          })
        });
      }

    });
  }



  /**
   *
   * @param obj
   */
  onAddFieldEvent(obj) {
    this.module.configs.header_table.push({
      name: obj.name,
      label: obj.label,
      active: true,
      id: obj.id
    });
  }


  /**
   * UPDATE HEADER TABLE WITH FORM INPUTS
   * @param event
   */
  changeFieldEvent(event) {
    console.log(event)
    if (['label', 'name'].indexOf(event.fieldname) != -1) {
      let index = this.module.configs.header_table.findIndex(x => x.id == event.field.id);
      this.module.configs.header_table[index][event.fieldname] = event.field[event.fieldname]
    }
  }

  /**
   *
   */
  saveModule(quit=true) {
    this.spinner.show();
    if (this.module.id) {
      this.moduleService.update(this.module).subscribe(() => {
        this.spinner.hide();
        quit && this._router.navigate(['/module-manager', this.slug]);
        this.notifier.notify('success', "Module saved successfully")
      });
    } else {
      this.moduleService.create(this.module).subscribe((res:any) => {
        this.spinner.hide();
        this.module.id = res.id;
        quit && this._router.navigate(['/module-manager', this.slug]);
        this.notifier.notify('success', "Module saved successfully")

      });
    }
  }

  /**
   *
   * @param event
   */
  onDeleteFieldEvent(event) {
    let index = this.module.configs.header_table.findIndex(x => x.id == event.id);
    this.module.configs.header_table.splice(index, 1);
  }







}
