<div class="row">
  <div class="col">{{'Name' | translate}}</div>
  <div class="col">{{'Chart Type' | translate}}</div>
  <div class="col">{{'Categories' | translate}}</div>
  <div class="col">{{'Width' | translate}}</div>
  <div class="col-1"></div>
</div>
<br>
<ng-container *ngFor="let chart of chartsConfig; let i = index">

  <div class="row">
    <div class="col">
      <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="chart.name">
      </div>
    </div>
    <input type="hidden" value="_Date_modification" [(ngModel)]="chart.axeX">
    <!-- <div class="col">
      <div class="form-group">
        <select class="form-control" [(ngModel)]="chart.axeX">
          <ng-container *ngFor="let attr of headers">
            <option value="{{attr}}" *ngIf="isDate(attr)">{{attr}}</option>
          </ng-container>
        </select>
      </div>
    </div> -->
    <div class="col">
      <div class="form-group">
        <select class="form-control" [(ngModel)]="chart.type">
          <option value="area">{{'Area' | translate}}</option>
          <option value="spline">{{'Spline' | translate}}</option>
          <option value="column">{{'Column' | translate}}</option>
          <!-- <option value="columnpercent">Column Percent by type</option> -->
        </select>
      </div>
    </div>
    <div class="col">
      <div class="form-group ">
        <select class="form-control" [(ngModel)]="chart.series">
          <option value="">{{'All' | translate}}</option>
          <ng-container *ngFor="let attr of headers">
            <option value="{{attr}}" *ngIf="isDate(attr, false)">{{attr}}</option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col">
      <select class="form-control"[(ngModel)]="chart.classname" >
        <option value="col-md-3">25%</option>
        <option value="col-md-4">33%</option>
        <option value="col-md-6">50%</option>
        <option value="col-md-8">66%</option>
        <option value="col-md-9">75%</option>
        <option value="col-md-12">100%</option>
      </select>
    </div>
    <div class="col-1 text-right">
      <button class="btn btn-xs btn-danger" (click)="deleteChart(i)"><i class="fa fa-times" aria-hidden="true"></i></button>
    </div>
  </div>
  
</ng-container>

<button class="btn btn-default" (click)="addChart()">{{'Add Chart' | translate}} </button>