<div class="container-fluid">
  <app-fixed-title [title]='"Migrations"'></app-fixed-title>
  <div class="row">
    <div class="col-12">
      <div class="white-box">
        <mat-form-field style="width: 500px;">
          <mat-label>{{'Choose a Customer' | translate}}</mat-label>
          <mat-select (selectionChange)="changeHotel($event)" [(value)]="hotel_id">
            <mat-option *ngFor="let hotel of hotels" [value]="hotel.id">
              ID :{{hotel.id}} - {{'Name'| translate}} : {{hotel.name_hotel}} - {{'Slug'| translate}} {{hotel.name_hotel_slug}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

  </div>
  <button class="btn btn-success" (click)="migration_users()">{{'Migration users' | translate}}</button>

  <div class="row" *ngIf="categories">
    <div class="col-md-6">
      <div class="white-box">
        <div class="content-json">
          <ngx-json-viewer [json]="categories" [expanded]="false">
          </ngx-json-viewer>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="white-box">
        <div class="content-json">
          <ngx-json-viewer [json]="newCategories" *ngIf="newCategories" [expanded]="false">
          </ngx-json-viewer>
        </div>
        <button class="btn btn-danger" (click)="dropTable()">{{'Drop'| translate}}</button>
        <button class="btn btn-success" (click)="migrateCategories(hotel_id)"> {{'Migrate categories'| translate}}</button>
      </div>
    </div>
  </div>
</div>