import { Injectable } from '@angular/core';


export const super_category_attr = [

	{
		label: "Informations",
		fields: [
			{
				name: "hotel",
				label: "Hotel",
				input: false,
				translatable: false
			},
			{
				name: "name",
				label: "Name",
				input: 'input',
				translatable: true,
				width: "col-lg-6"
            },	
            {
                name: "icon",
                label: "Icon",
                input: "select-icon",
                translatable: false,
                width: 'col-lg-3'
            },
            {
				name: "visible",
				label: "Visible",
				input: 'checkbox',
				translatable: false,
				width: 'col-lg-3'
            },
            {
                name: "long_description",
                label: "Description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name:"image",
                label:"Image",
                input: "media",
                translatable:false,
                width: 'col-lg-6'
            },
           
		]
	}
];
export const super_subcat_attr = [
    {
        label: "Informations",
        fields: [
            {
                name: "name",
                label: "Name",
                input: "input",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "short_description",
                label: "Short description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "image",
                label: "Image",
                input: "media",
                translatable : false
            }
        ]
    }
]
export const super_item_attr = [
    {
        label: "Informations",
        name : "information_tab",
        fields: [
            {
                name: "name",
                label: "Name",
                input: "input",
                translatable: true
            },
            {
                name: "icon",
                label: "Icon",
                input: "select-icon",
                translatable: false
            },
            {
                name: "short_description",
                label: "Short description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "long_description",
                label: "Long description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "visible",
                label: "Visible",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },
        ]
    },
    {
        label:"Images",
        name:"images_tab",
        fields:[
            {
                name: "image_square",
                label: "First Image",
                input: "media",
                translatable: false,
                width: 'col-lg-6'
            },
            {
                name: "image_rectangular",
                label: "Second image",
                input: "media",
                translatable: false,
                width: 'col-lg-6'
            }
        ]
    },

]


export const presentation = "These contents are deployed on the Concierge transport page";