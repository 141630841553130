export const MENU_ITEMS = [

  {
    name: "Supervision",
    link: "/supervision",
    param: true,
    icon: 'fa fa-binoculars',
    role: 'concierge',
    active: true,
    id : 'supervison',
  },
  {
    name: "Statistics",
    link: "/statistics",
    param: true,
    icon: 'fa fa-bar-chart',
    role: 'concierge',
    active: true,
    id: 'dashboard',
  },
  {
    name: "Content",
    link: "/content",
    param: true,
    icon: 'fa fa-file-text-o',
    role: 'concierge',
    active: true,
    id: 'content',
  },
  {
    name: "TabPay +",
    link: "/payment/tabpay",
    param: true,
    icon: 'fa fa-eur',
    role: 'concierge',
    active: true,
    id : 'tabpay'
  },
  {

    name: "TabPay V2",
    link: "/transactions-v2",
    param: true,
    icon: 'fa fa-eur',
    role: 'concierge',
    active: true,
    id : 'tabpay-v2'
  },
  {
    name: "Hardwares",
    link: "/dashboard/hardware",
    param: true,
    icon: 'fa fa-wifi',
    role: 'concierge',
    active: true,
    id : 'hardware'
  },
  {
    name: "Digital signage",
    link: "/digital-signage",
    param: true,
    icon: 'fa fa-desktop',
    role: 'concierge',
    active: true,
    id : 'digital-signage',
    submenu: [
      {
        name: 'Devices',
        link: "/digital-signage/devices",
        param: true,
        icon: 'fa fa-calendar-check-o',
        role: 'concierge',
        active: true,
        id : 'devices'
      },
      {
        name: 'Params',
        link: "/digital-signage/params",
        param: true,
        icon: 'fa fa-calendar-check-o',
        role: 'concierge',
        active: true,
        id : 'params'
      },
      {
        name: 'Displays',
        link: "/digital-signage/displays",
        param: true,
        icon: 'fa fa-text-width',
        role: 'administrator',
        active: true,
        id: 'displays'
      }
    ]
  },
  {
    name: "Offers",
    link: "/",
    param: true,
    icon: 'fa fa-shopping-cart',
    role: 'concierge',
    active: true,
    id:'offers-module',
    submenu:[
      {
        name: "Offers",
        link: "/offers-list",
        param: true,
        icon: 'fa fa-television',
        role: 'concierge',
        active: true,
        id : 'offer list'
      },
      {
        name: "Products",
        link: "/upsell-services",
        param: true,
        icon: 'fa fa-shopping-cart',
        role: 'concierge',
        active: true,
        id : 'products'
      },
      {
        name: "Rooms Categories",
        link: "/rooms-categories-list",
        param: true,
        icon: 'fa fa-bed',
        role: 'concierge',
        active: true,
        id : 'rooms-categories'
      },
      {
        name: "Item links PMS",
        link: "/item-link-pms",
        param: true,
        icon: 'fa fa-bed',
        role: 'concierge',
        active: true,
        id : 'item-link-pms'
      }
    ]
  },
  {
    name: "Workflow Translate",
    link: "/workflow-list",
    param: true,
    icon: 'fa fa-text-width',
    role: 'concierge',
    active: true,
    id: 'workflow-list',
  },
  {
    name: "Contents",
    link: "/",
    param: true,
    icon: 'fa fa-tags',
    role: 'concierge',
    active: true,
    id : 'cms',
    submenu: [
      {
        name: "Services",
        link: "/room-services",
        param: true,
        icon: 'fa fa-bed',
        role: 'concierge',
        active: true,
        id : 'room-service'
      },
      {
        name: "Area map",
        link: "/areamap",
        param: true,
        icon: 'fa fa-globe',
        role: 'concierge',
        active: true,
        id : 'areamap'
      },
      {
        name: "Activities",
        link: "/activities",
        param: true,
        icon: 'fa fa-star',
        role: 'concierge',
        active: true,
        id : 'activities'
      },
      {
        name: "Transport",
        link: "/transport",
        param: true,
        icon: 'fa fa-cogs',
        role: 'concierge',
        active: true,
        id : 'transport'
      },
    ]
  },
  {
    name: "Group contents",
    link: "/onglet-contents",
    param: true,
    icon: 'fa fa-file-text-o',
    role: 'concierge',
    active: true,
    id : 'onglet-contents'
  },
  {
    name: "Group settings",
    link: "/onglet-settings",
    param: true,
    icon: 'fa fa-sliders',
    role: 'concierge',
    active: true,
    id : 'onglet-settings'
  },
  {
    name: "Media Manager",
    link: "/media-manager",
    param: true,
    icon: 'fa fa-picture-o',
    role: 'concierge',
    active: true,
    id : 'media-manager'
  },

  {
    name: "Notifications",
    link: "/receptionist",
    param: true,
    icon: 'fa fa-bell',
    role: 'administrator',
    active: true,
    id:'notification'
  },

  {
    name: "Settings",
    link: "/settings",
    param: true,
    icon: 'fa fa-sliders',
    role: 'concierge',
    active: true,
    id : 'settings'
  },
  {
    name: "Users",
    link: "/users",
    param: true,
    icon: 'fa fa-user',
    role: 'administrator',
    active: true,
    id : 'users'
  },

  {
    name: "FAQ",
    link: "/faq/groups",
    param: true,
    icon: 'fa fa-question-circle',
    role: 'concierge',
    active: true,
    id : 'faq'
  },
  {
    name: 'Hotels',
    link: '/hotels',
    param: false,
    icon: 'fa fa-home',
    role: 'concierge',
    active: true,
    id: 'list-hotels'
  },
  {
    name: 'Reservations',
    link: '/reservations',
    param: true,
    icon: 'fa fa-bed',
    role: 'concierge',
    active: true,
    id: 'list-reservations'
  },
  {
    name: 'Tickets',
    link: '/ticketing',
    param: true,
    icon: 'fa fa-comments',
    role: 'concierge',
    active: true,
    id: 'ticketing'
    
  },
  {
    name: "Technical",
    link: "/",
    param: true,
    icon: 'fa fa-coffee',
    role: 'super-user',
    active: true,
    id : 'it-modules',
    submenu: [
    
      {
        name: "Global configuration",
        link: "/configuration-global",
        param: true,
        icon: 'fa fa-suitcase',
        role: 'super-user',
        active: true,
        id : 'module-manager'
      },
      {
        name: "Module Manager",
        link: "/module-manager",
        param: true,
        icon: 'fa fa-suitcase',
        role: 'super-user',
        active: true,
        id : 'module-manager'
      },
      {
        name: "Test API",
        link: "/test-api",
        param: true,
        icon: 'fa fa-plug',
        role: 'super-user',
        active: true,
        id : 'test-api'
      },
      {
        name: "Translate",
        link: "/translate-json",
        param: true,
        icon: 'fa fa-font',
        role: 'super-user',
        active: true,
        id : 'translate-json'
      },
      {
        name: "Add customer",
        link: "/add-customer",
        param: true,
        icon: 'fa fa-address-book',
        role: 'super-user',
        active: true,
        id : 'add-customer'
      },

      {
        name: "Migrations",
        link: "/migrations",
        param: true,
        icon: 'fa fa-database',
        role: 'super-user',
        active: true,
        id : 'migrations'
      },
      {
        name: "Configuration ENV",
        link: "/configuration",
        param: true,
        icon: 'fa fa fa-cog',
        role: 'super-user',
        active: true,
        id: 'config-env'
      },
      {
        name: "Request History",
        link: "/request-history",
        param: true,
        icon: 'fa fa fa-history',
        role: 'super-user',
        active: true,
        id: 'request-history'
      },
    ],

  },
  {
    name: "Roles manager",
    link: "/modules",
    param: true,
    icon: 'fa fa-cubes',
    role: 'administrator',
    active: true,
    id: 'config-roles'
  },
  {
    name: "Monitoring manager",
    link: "/monitoring-manager",
    param: true,
    icon: 'fa fa-cubes',
    role: 'administrator',
    active: true,
    id: 'config-roles'
  },


]
