 
<textarea id="text" [(ngModel)]="text" style="display: none;">
</textarea>
<input type="hidden" id="language-notif" [ngModel]="lang">
<button  id="play-sound" type='button' style="display: none;">Play</button>
<audio controls id="runnotif" style="display: none;">
  <source src="/assets/media/slow-spring-board.mp3" type="audio/mpeg">
</audio>

<span (click)="openDialogNotif()" class="notif">
  {{'Notifications' | translate}} ({{notifications.length}}) 
</span>

