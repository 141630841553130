import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-folder',
  templateUrl: './dialog-folder.component.html',
  styleUrls: ['./dialog-folder.component.scss']
})
export class DialogFolderComponent  implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<DialogFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }


  object : any;
  file : any;
  extension :any;
  idFile : any;

  ngOnInit() {
    console.log(this.data)
    if(this.data.type == 'file'){
      // this.file = this.data.file;
      this.getName(this.data.name)
    }
  }


  getName(name){
    let tempName = name;
    if(name.indexOf('---') != -1){
       tempName = name.split('---')[1];
       this.idFile = name.split('---')[0]
    }
    let object = tempName.split(/\.(?=[^\.]+$)/);
    this.extension = object[1];
    this.file = object[0];
  }

  getNameAfterEdit(){
    if(this.data.type == 'folder'){
      return this.data.name;
    }else {
      return this.idFile + '---' + this.file + '.' + this.extension;
    }
  }

}
