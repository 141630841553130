<h2 class="mat-dialog-title with-trans">
  {{'Add or edit chart' | translate}}
</h2>

<mat-dialog-content class="mat-typography">
  <div class="form-group">

    <label for="">{{'Template' | translate}}</label>
    <mat-select [(ngModel)]="data.item.template" class="form-control" (selectionChange)="updateHeight(true)">
      <ng-container *ngFor="let type of types">
        <mat-optgroup label="{{type.name}}">
          <ng-container *ngFor="let temp of data.templates">
            <mat-option [value]="temp.id" *ngIf="temp.display_type == type.value">
              <ng-container *appHasRole="'super-user'">{{temp.id}}</ng-container>
              {{temp.name}}
            </mat-option>
          </ng-container>
        </mat-optgroup>
      </ng-container>
        <mat-optgroup label="Charts">
          <ng-container *ngFor="let temp of data.templates">
            <mat-option [value]="temp.id" *ngIf="temp.display_type == 'charts' || temp.display_type == 'supervision'">
              <ng-container *appHasRole="'super-user'">{{temp.id}}</ng-container>
              {{temp.name}}
            </mat-option>
          </ng-container>
        </mat-optgroup>
    </mat-select>
  </div>

  <div class="form-group" *ngIf="data.item.template">
    <label for="">{{'Width' | translate}}</label>
    <select [(ngModel)]="data.item.cols" class="form-control"  *ngIf="display_type == 'highlights' || display_type == 'pms'" (change)="updateHeight()">
      <option value="3">25%</option>
      <option value="4">30%</option>
      <option value="6">50%</option>
    </select>

    <select [(ngModel)]="data.item.cols" class="form-control" *ngIf="display_type == 'donuts'" (change)="updateHeight()">
      <option value="3">25%</option>
      <option value="4">30%</option>
    </select>

    <select [(ngModel)]="data.item.cols" class="form-control" *ngIf="display_type == 'charts' || display_type == 'supervision'" (change)="updateHeight()">
      <option value="6">50%</option>
      <option value="8">70%</option>
      <option value="9">75%</option>
      <option value="12">100%</option>
    </select>

    
  </div>


  <div class="form-group">
    <label for="">{{'Title' | translate}}</label>
    <input type="text" class="form-control" [(ngModel)]="data.item.title">
  </div>

  <div class="form-group" *ngIf="data.create">
    <label for="">Put after </label>
    <select [(ngModel)]="after" class="form-control">
      <option [value]="i" *ngFor="let block of data?.dashboardConfig?.configs; let i = index">{{block.title}}</option>
    </select>
  </div>

  <div class="form-group" *ngIf="!data.create">
    <label for="">Put in index of </label>
    <select (change)="updatePosition($event.target.value)" class="form-control">
      <option value="">Choose</option>
      <ng-container *ngFor="let block of data?.dashboardConfig?.configs; let i = index">
        <option [value]="i" [attr.disabled]="i == data.index? 'true': null">{{i+1}} - {{block.title}}</option>
      </ng-container>
    </select>
  </div>

 

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{'Cancel' | translate}}</button>
  <button mat-button [mat-dialog-close]="{response : true, after:after}" class="btn btn-success">{{'Save' | translate}}</button>

</mat-dialog-actions>