<ng-container *ngIf="field.translatable">
  <div class="group-images form-group form-translate-{{currentLang}} translated">

 
 
    <label>{{field.label}} ({{currentLang}})</label>
    <p *ngIf="field.description"><small>{{field.description}}</small></p>
    
    <button class="btn btn-primary" (click)="openMediaManager(fieldsTrans[currentLang])"
      *ngIf="!field.multiple && !response[fieldsTrans[currentLang].name] || fieldsTrans[currentLang].multiple">
      {{'Add Image from media' | translate}}
    </button>
 

    <div class="list-images">
      <ng-container *ngIf="fieldsTrans[currentLang].multiple">
        <div class="img-container" *ngFor="let img of response[fieldsTrans[currentLang].name]; let i = index">
          <i class="fa fa-times-circle" aria-hidden="true" (click)="removeImg(fieldsTrans[currentLang], i)"></i>
          <img src="{{serverApi}}{{img}}" alt="" (click)="openMediaManager(fieldsTrans[currentLang])">
        </div>
      </ng-container>

      <ng-container *ngIf="!fieldsTrans[currentLang].multiple && response[fieldsTrans[currentLang].name]">
        <div class="img-container">
          <i class="fa fa-times-circle" aria-hidden="true" (click)="removeImg(fieldsTrans[currentLang])"></i>
          <img src="{{serverApi}}{{response[fieldsTrans[currentLang].name]}}" alt="" (click)="openMediaManager(fieldsTrans[currentLang])">
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="!field.translatable">
  <div class="group-images">
    <label>{{field.label}}</label>
    <p *ngIf="field.description"><small>{{field.description}}</small></p>
    <!-- add btn -->
    <button class="btn btn-primary" (click)="openMediaManager(field)"
      *ngIf="!field.multiple && !response[field.name] || field.multiple">
      {{'Add Image from media' | translate}}
    </button>
    <!-- add btn -->

    <div class="list-images">
      <ng-container *ngIf="field.multiple">
        <div class="img-container" *ngFor="let img of response[field.name]; let i = index">
          <i class="fa fa-times-circle" aria-hidden="true" (click)="removeImg(field, i)"></i>
          <img src="{{serverApi}}{{img}}" alt="" (click)="openMediaManager(field)">
        </div>
      </ng-container>

      <ng-container *ngIf="!field.multiple && response[field.name]">
        <div class="img-container">
          <i class="fa fa-times-circle" aria-hidden="true" (click)="removeImg(field)"></i>
          <img src="{{serverApi}}{{response[field.name]}}" alt="" (click)="openMediaManager(field)">
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
