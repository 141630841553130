<div class="container-fluid">
  <app-fixed-title [title]='title' [hotel]='hotel' [customAction]="true" customActionText="Refresh" (onCustomAction)="ngOnInit()"></app-fixed-title>

  <div class="white-box">
    <input-validator [errors]="errors" *ngIf="errors"></input-validator>

    <app-form-stepper-or-tabs *ngIf="module && data && hotel" [errors]="errors" [variablesAPI]="module.configs.variables" [form]="module.form" [type]="module.configs.dispay_form_sections" [response]="data" [hotel]="hotel"></app-form-stepper-or-tabs>
    
    <div class="form-group text-right">
      <button (click)="saveData()" class="btn btn-success">Save</button>
    </div>
  </div>
</div>