import { Injectable } from '@angular/core';


export const subcat_attr = [
    {
        label: "Informations",
        fields: [
            {
                name: "name",
                label: "Name",
                input: "input",
                translatable: true,
                width: 'col-lg-4'
            },
            {
                name: "image",
                label: "Image",
                input: "media",
                translatable : false,
                width: 'col-lg-4'

            },
            {
                name: "icon",
                label: "Icon",
                input: "select-icon",
                translatable : false,
                width: 'col-lg-4'

            },
            {
                name: "short_description",
                label: "Short description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
           
        ]
    }
]