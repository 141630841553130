import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { CategoryService } from '../../../shared/services/category.service';
import { HotelService } from '../../../shared/services/hotel.service';
import { ItemService } from '../../../shared/services/item.service';
import { SubcategoryService } from '../../../shared/services/subcategory.service';
import { presentation, super_category_attr, super_item_attr, super_subcat_attr } from './fields';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
  constructor(
    private hs: HotelService,
    public cats: CategoryService,
    public dialog: MatDialog,
    public item: ItemService,
    public subcat: SubcategoryService,
    private route: ActivatedRoute,
  ) { }

  slug: any;
  title = "Activities";
  label = "Activity";
  hotel: any;
  id: any;
  categories: any;
  copy: any;
  searchResults = [];
  filteredCategories = [];
  see_details = {};
  superFields = {super_category_attr, super_item_attr,super_subcat_attr};
  presentation  = presentation;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.slug = params.slug
      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
        this.id = this.hotel.id;
      });

      this.cats.getCategoriesByType(this.slug, 1).subscribe((data) => {
        this.categories = data;
        this.filteredCategories = this.categories;
        for (let v in this.categories) {
          this.see_details[this.categories.id] = true;
        }
      })
    });
  }

}
