import { DialogMediaComponent } from 'src/app/pages/media-manager/modals/dialog-media/dialog-media.component';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DefaultValueService } from '../../../shared/services/default-value.service';
import { server } from '../../../shared/settings/global';
import { getNestedObject, setNestedKey } from '../../module-worker/_nestedProp';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'form-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private dv: DefaultValueService,
    private apiservice:ApiService,

  ) { }

  @Input() form = [];
  @Input() languages;
  @Input() hotel;
  @Input() response ;
  @Input() defaultValues: any;
  @Input() title = 'Edit content';
  @Input() customSelect = [];
  @Input() useTitle = false;
  @Output() reaction = new EventEmitter();
  @Input() variablesAPI = [];
  @Input() dataForSelect = [];
  @Input() variablesAPILIST = [];
  @Input() customVars: any;
  @Input() section;
  tags = {};
  code = {};

  serverApi = server;
  translatable = false;

  images = [];
  checkboxes = [];

  ngOnInit() {

    this.languages = this.hotel.languages;
    for(let field of this.form){
    console.log(field)

      if(field.apiendpoint){
        this.getData(field);
      }
    }

  }


  /**
   * UPDATE RESPONSE
   * @param model
   * @param value
   */
  updateResponse(field, model) {
    //IF FIELD MULTIPLE PARSE ALL TRANSLATABLE FIELD AND CHANGE THEM FROM STRING TO ARRAY
    if (field.multiple) {
      if (field.translatable) {
        for (let lang of this.hotel.languages) {
          let code = lang.lang_code;
          model[field.name + '_' + code] = model[field.name + '_' + code] ? [model[field.name + '_' + code]] : []
        }
      } else {
        //CHANGE FROM STRING TO ARRAY
        model[field.name] = model[field.name] ? [model[field.name]] : []
      }
    } else {
      //ELSE IF FIELD NOT MULTIPLE PARSE ALL TRANSLATABLE FIELD AND CHANGE THEM FROM ARRAY TO STRING
      if (field.translatable) {
        for (let lang of this.hotel.languages) {
          let code = lang.lang_code;
          model[field.name + '_' + code] = (model[field.name + '_' + code] && Array.isArray(model[field.name])) ? model[field.name + '_' + code][0] : ""
        }
      } else {
        //CHANGE THEM FROM ARRAY TO STRING
        model[field.name] = (model[field.name] && Array.isArray(model[field.name])) ? model[field.name][0] : ""
      }
    }

    return model;
  }

  getData(field){
    let attrs = field.apiattributs.trim().split(',');
    let endpoint = field.apiendpoint;
    this.apiservice.getByUrl(endpoint).subscribe((res:string)=>{
      this.dataForSelect[field.name] = []
      for(let line of res){
      this.dataForSelect[field.name].push({'value':line[attrs[0]], 'label':line[attrs[1]] })

      }
    })
  }
  /**
   *
   * @param attr
   *
   * */
  getField(field, subAttr = "") {
    return getNestedObject(this.response, (field.name+subAttr).split('.'))
  }

  /**
   *
   * @param event
   * @param attr
   */
  updateField(value, field) {
    setNestedKey(this.response, field.name.split('.'), value);
    //IF FORM HAS CONDITION TO MOVE TO ANOTHER SECTION
    (field.type == 'select' && !field.multiple) && this.getReaction(value, field)
    return
  }

  /**
   *
   * @param val
   * @param field
   */
  updateFieldAfter(val,field){
   setTimeout(()=>{this.updateField(this.tags[val], field)},200)
  }



  /**
   *
   * @param url
   */
  getRelativePath(url) {
    return url.replace(this.serverApi, '');
  }
  /**
   * CREATE DATA FORM SELECT FIELDS
   * @param values
   */
  getDataforSelect(values) {
    let data = [];
    for (let v of values) {
      data.push({ id: v.value, text: v.label })
    }
    return data;
  }

  /**
 * GET CONSTANT DATA FORM
 * @param values
 */
  getDatafromConstant(values) {
    let data = [];
    for (let v of values) {
      data.push({ id: v.value, text: v.label })
    }
    return data;
  }

  /**
   * OPEN MEDIA MANAGER IN DIALOG
   * @param field
   */
  openMediaManager(field) {
    let data = {
      hotel: this.hotel,
      multiple: field.multiple,
      field: field
    };
    const dialogRef = this.dialog.open(DialogMediaComponent, { data, width: '1350px' });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (field.multiple) {
          res.forEach((v) => {
            this.response[field.name].push(this.getRelativePath(v));
          })
        } else {
          this.response[field.name] = this.getRelativePath(res);
        }
      }
    })
  }

  /**
  * OPEN MEDIA MANAGER FOR SLIDER FIELD
  * @param field
  */
  openMediaManagerSlider(field) {
    let data = { hotel: this.hotel, multiple: field.multiple };
    const dialogRef = this.dialog.open(DialogMediaComponent, { data, width: '1350px' });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        //IF RESPONSE CONVERT CONTENT TO STRING WITH ";"
        res.forEach((v) => {
          let list = this.splitList(this.response[field.name]);
          list.push(this.getRelativePath(v));
          this.response[field.name] = this.joinList(list);
        })
      }
    })
  }

  /**
   * remove image by index
   * @param field
   * @param i
   */
  removeImg(field, i = false) {
    if (field.multiple) {
      this.response[field.name].splice(i, 1);
    } else {
      this.response[field.name] = "";
    }
  }

  /**
   * remove image by index
   * @param field
   * @param i
   */
  removeImgSlider(field, i) {

    if (this.response[field.name]) {
      let temp = this.response[field.name].split(';');
      temp.splice(i, 1);
      this.response[field.name] = this.joinList(temp);
    }

  }

  /**
   * CONVERT LIST TO ARRAY FOR SLIDER FIELD
   * @param list
   */
  splitList(list) {
    if (list == "") {
      return [];
    } else {
      return list.split(';')
    }
  }

  /**
   * CONVERT STRING TO ARRAY TO PARSE SLIDER FIELD
   * @param list : STRING
   */
  extractListSlider(list) {
    if (typeof (list) == 'string') {
      return list.split(';');
    }
  }

  /**
   * CONVERT ARRAY TO STRIG FOR SLIDER FIELD BEFORE SAVE
   * @param list
   */
  joinList(list) {
    if (list.length > 1) {
      return list.join(";")
    } else if (list.length == 0 || (list.length == 1 && list[0] == "")) {
      return "";
    } else if (list.length == 1 && list[0] != "") {
      return list[0];
    }
  }



  /**
   * FIND DEFAULT VALUES
   */
  findDefaultValues() {

    //if there is not default values
    if (!this.defaultValues && this.hotel) {
      //GET DEFAULT VALUE
      this.dv.getDefaultValue(this.hotel.id, 'default-value').subscribe(res => {
        if (res) {
          this.defaultValues = res;
          this.defaultValues['values'] = JSON.parse(this.defaultValues['values']);

          for (let index in this.defaultValues['values']) {
            //if index exists and input is empty
            if (this.form.find(x => x.name == index) !== -1 && (!this.response[index] || !this.response[index].length)) {
              this.response[index] = this.defaultValues['values'][index];
            }
          }

        }
      }, () => {
        this.defaultValues = null;
      });
    }
  }

  /**
   * CONVERT CHECKBOX TO ARRAY
   * @param event
   * @param name
   * @param value
   */
  updateCheckbox(event, name, value) {
    console.log(event,name, value)
    // this.response[name][value] = event.checked
  }

  /**
   *
   * @param val
   * @param field
   */
  getReaction(val, field) {
    let fieldVal = field.values.find(x => x.value == val);
    this.reaction.emit(fieldVal.condition);
  }


  /**
   *
   * @param field
   * @param response
   */
  getParentField(name, response){
    if(name.indexOf('.') == -1){
      return response
    }else {
      let path = name.split('.');
      if(path.length == 2){
        return response[path[0]];
      }else {
        let move = response[path[0]]
        path.splice(0,1)
        return this.getParentField(path.join('.'), move) ;
      }
    }
  }

  getPath(name){
    let arr = name.split('.');
    return arr[arr.length-1]

  }


  addCustomVal = (term) => ({id: term, name: term});


  /**
   * 
   * @param classname 
   * @returns 
   */
  getClassName(classname){
    if(classname!= undefined && classname.indexOf('btn')){
      return classname
    }
  }



}
