
<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)" *ngIf="options.drag">
  <div class="example-box" *ngFor="let item of displayedColumns ; let i = index" cdkDrag>
    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
   {{i+1}} - {{item  | lowercase | translate}} 
    <i class="fa fa-arrows-v" aria-hidden="true" ></i>
  </div>
</div>


<div cdkDropList class="example-list"   *ngIf="options.filter">
  <div class="example-box" *ngFor="let item of displayedColumns ; let i = index" >
    <div class="form-group">
      <label class="container-checkbox"> {{i+1}} - {{item  | lowercase | translate}}
        <input type="checkbox" [(ngModel)]="displayedFilter[item]">
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</div>