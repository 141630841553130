<ng-container *ngIf="form.sections.length>1">
  <div class="text-right mrgb-20">
    <!-- automatic trans -->
    <button class="btn btn-default trans" (click)="autoTranslate()" *ngIf="form.params.autoTranslate">
      <i class="fa fa-flag"></i> {{'Automatic translate' | translate}}
    </button>
    <!-- automatic trans -->

    <app-select-translate [default]="currentLang" (changeEvent)="setCurrentLang($event)" [hotel]="hotel"
      [data_id]="'form-view-built'" [fixed]="false" *ngIf="hotel && translatable"></app-select-translate>
  </div>


  <!-- mat group -->
  <mat-tab-group>
    <ng-container *ngFor="let section of form.sections; let j = index ">
      <mat-tab [label]="section.sectionName" *appHasRole="section.role ? section.role : 'concierge'">

        <!-- IF SECTION IS ARRAY  -->
        <ng-container *ngIf="section.is_array && section.path">
          <ng-container *ngFor="let subResponse of getResponse(response, section, null)">
            <form-view
            class="multiple-view"
              [form]="section.fields"
              [section]="section"
              [hotel]="hotel"
              [variablesAPILIST]="variablesAPILIST"
              [customVars]="customVars"
              [hotel]='hotel'
              [defaultValues]="''"
              [response]="subResponse"> </form-view>
          </ng-container>
         
         
        </ng-container> 
        <!-- IF SECTION IS ARRAY  -->

        <!-- IF SECTION IS NOT ARRAY  -->

        <ng-container *ngIf="!section.is_array">
          <form-view  [form]="section.fields" [section]="section" [variablesAPILIST]="variablesAPILIST"
            [customVars]="customVars" (reaction)="getReaction($event)" [hotel]='hotel' [defaultValues]="''"
            [response]="getResponse(response, section,0)">
          </form-view>
        </ng-container>
        <!-- IF SECTION IS NOT ARRAY  -->


        <button class="btn btn-sm btn-default" *ngIf="section.is_array" (click)="addObjectToSection(response,section)">Add object in
          {{section.sectionName}}</button>
      </mat-tab>

    </ng-container>

  </mat-tab-group>
</ng-container>



<!-- IF IS NOT FORM STEPS -->
<ng-container *ngIf="form.sections.length == 1">
  <div class="row">
    <div class="col-6">
    </div>
    <div class="col-6 text-right">
      <!-- automatic trans -->
      <button class="btn btn-default trans" (click)="autoTranslate(group)" *ngIf="form.params.autoTranslate">
        <i class="fa fa-flag"></i> {{'Automatic translate' | translate}}
      </button>
      <!-- automatic trans -->
      <app-select-translate (changeEvent)="setCurrentLang($event)" [hotel]="hotel" [data_id]="'form-view-built'"
        [fixed]="false" *ngIf="hotel"></app-select-translate>
    </div>
  </div>


  <form-view id="form-view-built" [section]="form.sections[0]" [variablesAPILIST]="variablesAPILIST"
    [form]="form.sections[0].fields" [hotel]='hotel' [defaultValues]="''" [languages]="languages"
    [response]="getResponse(response, form.sections[0])"></form-view>

</ng-container>

<!-- END SECTOON IF IS NOT FORM STEPS -->
<br>
<debug [obj]="[response]" *appHasRole="'super-user'"></debug>