import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'field-values',
  templateUrl: './field-values.component.html',
  styleUrls: ['./field-values.component.scss']
})
export class FieldValuesComponent implements OnInit {

  constructor() { }

  @Input() values: any;
  @Input() form: any;
  @Input() fieldObject: any;
  @Input() otherModel : any;
  @Input() currentSection;


  @Input() options = {
    checkbox: true,
    displayheader: true,
    placeholder: [
      'Label',
      'Value'
    ],
    header: [
      'Selected',
      'Label',
      'Value',
      'Actions'
    ],
    add: 'Add option',
    class: '',
    selected: false,
    other: false,
    otherText : 'Form data',
    attr : 'formdata'
  }
  @Output() clickEvent = new EventEmitter();


  _multiplefields = [
    'select',
    'select-multiple',
    'checkbox',
    'radio'
  ]

  ngOnInit() {
  }

  /**
   *
   */
  addField() {
    let object = {
      label: 'New label ' + (this.values.length + 1),
      value: 'value-' + (this.values.length + 1),
      selected: this.options.selected,
      condition : ""
    }
    this.clickEvent.emit(object)
    this.values.push(object)
  }

  /**
   * DELETE VALUE BY INDEX
   * @param i
   */
  deleteField(i) {
    this.values.splice(i, 1);
  }

  /**
   * DISPLAY VALUES TABLE
   */
  needValues() {
    return this._multiplefields.indexOf(this.fieldObject.type) != -1 && Array.isArray(this.values);
  }

  /**
   * DRAGE AND DROP EVENT
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.values, event.previousIndex, event.currentIndex);

  }
}
