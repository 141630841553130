<h2 class="mat-dialog-title with-trans">
  {{(trame.id ? 'Edit' : 'Add') | translate}} Trame
  <app-select-translate [hotel]="hotel"></app-select-translate>

</h2>

<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="full-width">
        <mat-label>{{ "Name" | translate }}</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="trame.name">
      </mat-form-field>
      <app-error-input *ngIf="errors" [errors]="errors" name="name"></app-error-input>
    </div>

    <div class="col-lg-4">
      <div class="form-group">

        <mat-form-field>
          <mat-label>{{ "Send To" | translate }}</mat-label>
          <mat-select [(ngModel)]="trame.send_to" multiple>
            <mat-option *ngFor="let group of data.groups" [value]="group.id">{{group.name}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "Export type" | translate }}</mat-label>
          <mat-select matNativeControl [(ngModel)]="data.data.export_type" name="category" multiple>
            <mat-option *ngFor="let export_type of export_types" [value]="export_type.value">
              {{export_type.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-error-input *ngIf="errors" [errors]="errors" name="export_type"></app-error-input>
      </div>
    </div>


    <div class="col-lg-12">

      <div class="form-group">
        <label>{{ "Tags" | translate }} : </label>
        <br>
        <ng-container   *ngIf="!trame.is_maintenance">
          <span *ngFor="let notif of data['notifications_keys']" class="tags"> @{{notif.key}}</span>
        </ng-container>

        <br>
        <br>

        <!--translated input-->
        <translated-input [model]="trame" attr="tags" [hotel]="hotel" [type]="'input'" label="Notification text">
        </translated-input>
        <!--translated input-->
        <app-error-input *ngIf="errors" [errors]="errors" name="tags"></app-error-input>

      </div>
    </div>


    <div class="col-lg-12">
      <div class="form-group">
        <mat-checkbox [(ngModel)]="trame.active">{{ "Active" | translate }}</mat-checkbox>
      </div>
    </div>
    <div class="col-lg-12">
      <label for="">{{ "Condition Builder" | translate }}</label>
      <query-builder [(ngModel)]='trame.conditions' *ngIf="config" [config]='config'></query-builder>

    </div>

  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{ "Cancel" | translate }}</button>
  <button mat-button (click)="createOrUpdate(trame)" class="btn btn-success">{{ "Save" | translate }}</button>
</mat-dialog-actions>