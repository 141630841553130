<h2 class="mat-dialog-title">
  {{"Invite user" | translate}}
</h2>

<mat-dialog-content class="mat-typography">

  <div class="row">

    <!-- field -->
    <div class="form-group col-md-12">
      <label>{{ 'Email' | translate }}</label>
      <input type="email" class="form-control" [(ngModel)]="invitation.email">
      <app-error-input *ngIf="errors" [errors]="errors" name="email"></app-error-input>
    </div>
    <!-- field -->

    <!-- field -->
    <div class="form-group col-md-12">
      <mat-label>{{ 'Propertie(s)  to manage' | translate }}</mat-label>
      <mat-select class="form-control" multiple (selectionChange)="updateRoles($event)" [(ngModel)]="invitation.hotels">
        <mat-option *ngFor="let hotel of data.hotelsToManage" [value]="hotel.id">{{hotel.text}}</mat-option>
      </mat-select>

      <app-error-input *ngIf="errors" [errors]="errors" name="hotels"></app-error-input>
    </div>
    <!-- field -->


    <div class="col-md-12">
      <table class="table" *appHasRole="'super-user'">
        <thead>
          <tr>
            <th>{{ 'Client' | translate }}</th>
            <th>{{ 'Role' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let role of invitation['roles']; let i = index">
            <td style="width: 50%;">
              {{getHotel(role['id'])?.text}}
            </td>
            <td style="width: 40%;">
              <select [(ngModel)]="role['role']" class="form-control">
                <option [value]="'concierge'">Concierge</option>
                <option [value]="'administrator'">Administrator</option>
                <option [value]="'super-user'">Super user</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>

    </div>



  </div>





</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'Cancel' | translate }}</button>
  <button mat-button (click)="sendInvitation()" class="btn btn-success">{{"Confirm" | translate}}</button>
</mat-dialog-actions>