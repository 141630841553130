import { NotifierService } from 'angular-notifier';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmDeleteComponent } from '../../components/modals/confirm-delete/confirm-delete.component';
import { FaqService } from '../../shared/services/faq.service';
import { HotelService } from '../../shared/services/hotel.service';
import { BASE_URL } from '../../shared/settings/global';
import { DialogFaqCategoryComponent } from './dialogs/dialog-faq-category/dialog-faq-category.component';
import { DialogFaqQuestionComponent } from './dialogs/dialog-faq-question/dialog-faq-question.component';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(
    private hs: HotelService,
    notifierService: NotifierService,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _router: Router,
    public dialog: MatDialog,
    public FaqService: FaqService,
    private _lightbox: Lightbox

  ) { }


  slug: any;
  hotel: any;
  languages: any;
  faqList: any;
  faqCloned :any;
  filtered: any;
  searchValue = "";
  private _album = [];
  parent = false;
  groupId = 0;
  currentCat : any;
  searchLoading = false


  ngOnInit() {
    this.spinner.show('global');

    this.hs.getLanguages().subscribe((data) => {
      this.languages = data;
    });

    /**
     * router params
     */
    this.router.params.subscribe(params => {
      //IF ROUTE ALL CATEGORIES
      if (typeof(params.group) == "undefined") {
        this.FaqService.getFaqList().subscribe(res => {
          this.spinner.hide('global');
          this.faqList = res;
          this.faqCloned = res;
        });
      }else {
        //IF ROUTE CATEGORY DETAIL
        this.parent = true;
        this.groupId = params.group;

        this.FaqService.getFaqDetailGroup(params.group).subscribe(res => {
          this.spinner.hide('global');
          this.faqList = [res];
          this.faqCloned =[res]
          this.currentCat = res;
        });
      }

      //TEST
      if (params.slug == "undefined") {
        this._router.navigate(['/hotels'])
      }
      this.slug = params.slug;

      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
      });

    });

  }

  /**
 * SEARCH IN DEVICES
 * @param searchValue
 */
  searchFaq(searchValue: string) {
    this.searchValue = searchValue;
    if (!searchValue) {
      this.filtered = Object.assign([], this.faqList);
    } else {
     // this.filtered = this.search(searchValue)
    }
  }

  /**
   *
   * @param index
   */
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  /**
   *
   * @param img
   * @param name
   */
  openImage(img, name){
    this._lightbox.open([
      {
        src  : img,
        thumb: img,
        caption : name,
        downloadUrl:img
      }
    ]);
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  /**
   *
   * @param text
   * @param searchValue
   */
  filter(obj, searchValue) {
    return (obj.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
      obj.description.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
  }


  /**
   *
   * @param text
   */
  findTextSearched(text) {
    if (this.searchValue) {
      return text.toLowerCase().replace(this.searchValue.toLowerCase(), '<span>' + this.searchValue + '</span>');
    }
    return text;
  }

  /**
   *
   */
  getListOfQuestions(){
    if(this.parent && this.faqCloned.subcategory.length == 0){
      return this.faqCloned[0].itemsfaq;
    }
  }

  /**
   *
   */
  getListOfCategory(){
    //if category has parent and has subcategory return list of subcategory
    if(this.parent && this.faqCloned[0].subcategory.length>0 ){
      return this.faqCloned[0].subcategory;
    }else if(this.faqCloned.length > 1){
      return this.faqCloned;
    }else if(this.parent && (this.faqCloned[0].subcategory.length == 0 || this.faqCloned[0].itemsfaq.length>0 )){
      return [];
    }
  }

  /**
   *
   * @param url
   */
  getUrl(url){
    if(url){
      return (url.indexOf('http') != -1) ? url : BASE_URL + url;
    }
  }

  /**
   *
   * @param quest
   */
  openDialogQuestion(quest = null) {
    const dialogRef = this.dialog.open(DialogFaqQuestionComponent, {
      data : {quest: quest ? quest : { category: this.groupId }},
      width: '800px'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.action == 'add') {
        this.faqCloned[0].itemsfaq.push(res.res)
      }
    });
  }


  /**
   * EDIT OR ADD CATEGORY DIALOG
   * @param category
   */
  openDialogCategory(category = null) {
    //new object format
    let newCategory = {title:'', description:'', image:null};
    //if is update category add category to dialog, else new (parent if has parent)

    let data = { cat: category ? category : ( this.parent ? {...newCategory, parent:  this.groupId }  : newCategory)};

    const dialogRef = this.dialog.open(DialogFaqCategoryComponent, {
      data ,
      width: '800px'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.action == 'add') {
        if(this.currentCat){
          this.faqCloned[0].subcategory.push(res.res);
        }else {
         this.faqCloned.push(res.res);
        }
      }
    });
  }

  /**
   * DELETE CATEGORY CONFIRM
   * @param cat
   * @param index
   */
  deleteCategoryConfirm(cat, index) {
    let type = "category";
    let name = cat.title
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name, type }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.FaqService.deleteFaqCategory(cat.id).subscribe(res => {
          //this.notifier.notify('success', 'category successfully deleted');
          this.faqCloned.splice(index, 1);

        },
       err => {})
      }
    });
  }

  /**
   *
   * @param quest
   * @param index
   */
  deleteQuestionConfirm(quest, index){
    let type = "Question";
    let name = quest.title
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name, type }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.FaqService.deleteFaqItem(quest.id).subscribe(res => {
          //this.notifier.notify('success', 'category successfully deleted');
          this.faqCloned[0].itemsfaq.splice(index, 1);

        },
       err => console.log(err))
      }
    });
  }

  /**
   *
   */
  permissionQuestion(){
    //IF WE ARE IN SUBCATEGORY
    if(this.parent && this.faqCloned[0].subcategory.length){
      return false;
    }
    else if(this.parent && this.faqCloned[0].subcategory.length == 0){
      return true;
    }
    else if(!this.parent){
      return false;
    }
  }

  permissionCategory(){
    if(this.parent && this.faqCloned[0].itemsfaq.length){
      return false;
    }
    else if(this.parent && this.faqCloned[0].itemsfaq.length == 0){

      return true;
    }
    else if(!this.parent){

      return true;
    }

  }

  /**
   *
   * @param text
   */
  search(text){

    if(text.length>2){
      this.searchLoading = true;
      this.FaqService.searchFaq({word:text}).subscribe(res=>{
        this.searchLoading = false;
      })
    }else {
       this.searchLoading = false;
    }
  }
}
