import { LANGUAGES } from 'src/app/shared/settings/_const';

import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogLanguagesComponent } from '../../modals/dialoglanguages/dialog-languages.component';

@Component({
  selector: 'hotel-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent{

  constructor(
    public dialog: MatDialog  ) {
  }


  @Input() hotel: any;
  @Input() languages = [];
  langs = LANGUAGES;


  isActive(lang) {
    if (this.hotel) {
      return this.hotel.languages_param.indexOf(lang.id) !== -1;
    }
  }

  getLang(id) {
    return this.languages.find(x => x.id == id);
  }

  openDialogLanguages() {
    let data = { hotel: this.hotel, languages: this.languages };
    this.dialog.open(DialogLanguagesComponent, { data: data,  width:"600px" });

  }
}
