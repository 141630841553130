<h1 class="mat-dialog-title">{{'Amount' | translate}}</h1>
<div mat-dialog-content>
	<div class="alert alert-info">
		
	</div>
	<div class="form-group">
		<input type="number" [(ngModel)]="amount" class="form-control">  
	</div>
</div>
<div mat-dialog-action  align="end">
  <button class="btn btn-default" [mat-dialog-close]="" >{{'No Thanks' | translate}}</button>
  <button class="btn btn-danger" [mat-dialog-close]="amount"  class="btn btn-success">{{'Confirm' | translate}}</button>
</div>