<nav
  aria-label="Page navigation"
  class="pagination"
  *ngIf="current_page">
  <ul class="pagination">
    <li class="indicator-page">
      Page {{ current_page }} de {{ total_pages }}
    </li>
    <li class="page-size">
      <select
        class="form-control"
        #pageSize
        (change)="changePageSize($event.target.value)">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </li>
    <li class="page-item">
      <a
        class="page-link"
        (click)="paginate(1, previous)"
        [ngClass]="!previous ? 'inactive' : ''">
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
      </a>
    </li>
    <li class="page-item">
      <a
        class="page-link"
        (click)="paginate(current_page - 1, previous)"
        [ngClass]="!previous ? 'inactive' : ''">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </a>
    </li>
    <li class="page-item">
      <a
        class="page-link"
        (click)="paginate(current_page + 1, next)"
        [ngClass]="!next ? 'inactive' : ''">
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </a>
    </li>
    <li class="page-item">
      <a
        class="page-link"
        (click)="paginate(total_pages, next)"
        [ngClass]="!next ? 'inactive' : ''">
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
</nav>
