<div class="container-fluid">
  <app-fixed-title [title]='title' *ngIf="!onglet" [hotel]='hotel' [action]="true" (clickEvent)="reloadData(true)"></app-fixed-title>

  <div class="row" *ngIf="onglet">
    <div class="col-md-6">
      <h4>{{title}}</h4>
    </div>
    <div class="col-md-6">
      <button (click)="reloadData(true)" class="btn btn-success ng-star-inserted"><i  class="fa fa-refresh"></i> Refresh </button>
    </div>
  </div>

  <div class="white-box content" *ngIf="hardwares">

    <div class="row">
      <div class="col-md-4 col-12 flex" *ngFor="let hardware of hardwares ; let i = index">

        <!--hardware DISPLAY-->
        <div class="item-display" (click)="openDialogDetail(hardware)">
          <button class="btn btn-default btn-xs btn-refresh" *ngIf="hardware.IS_SCREEN" (click)="reboot(hardware.id)"
            placement="right" show-delay="500">
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </button>
          <!--media-->
          <div class="media">
            <img src="assets/img/icons/svg/monitor.svg" class="mr-3" alt="..."
              *ngIf="!hardware.OUT_OF_SERVICES && hardware.HARDWARE_STATUS && hardware.IS_SCREEN">
            <img src="assets/img/icons/svg/kiosk-hard.svg" class="mr-3" alt="..."
              *ngIf="!hardware.OUT_OF_SERVICES && hardware.HARDWARE_STATUS && !hardware.IS_SCREEN">
            <img src="assets/img/icons/svg/no-stopping.svg" class="mr-3" alt="..." *ngIf="hardware.OUT_OF_SERVICES">
            <img src="assets/img/icons/svg/no-wifi.svg" class="mr-3"
              *ngIf="!hardware.HARDWARE_STATUS && !hardware.OUT_OF_SERVICES">
            <div class="media-body">
              <h5 class="mt-0">
                <span [ngClass]="getStatus(hardware)" class="status-m"></span>
                {{hardware.Name}} <br><small *appHasRole="'super-user'">UUID : {{hardware.UUID}}</small>
              </h5>
            </div>



          </div>

          <div class="content">
            <h3 class="error" *ngIf="hardware.OUT_OF_SERVICES">{{'Hardware out of service' | translate }}</h3>
            <h3 class="error" *ngIf="!hardware.HARDWARE_STATUS && !hardware.OUT_OF_SERVICES">{{'Hardware Not connected'
              | translate }}</h3>
            <div class="status" *ngIf="hardware.HARDWARE_STATUS && !hardware.OUT_OF_SERVICES">

              <div *ngIf="hardware.Status.length" class="text-center">

                <strong *ngIf="hardware.Status.length>1"> {{'Periphirals' | translate }}</strong>
                <ul class="hardwares">
                  <ng-container *ngFor="let st of hardware.Status">
                    <li *ngIf="st.name_en && st.module != 'System'">
                      <span [class]="getClass(st)" placement="top" show-delay="500">
                        <i [class]="st.icon"></i>
                      </span>
                    </li>

                  </ng-container>
                </ul>
                <strong>Last Update : {{getLastUpdate(hardware)}}</strong>
              </div>

            </div>
            <div *ngIf="!hardware.Status.length && !hardware.IS_SCREEN && !hardware.OUT_OF_SERVICES">
              <h3 class="warning">{{'Status not found' | translate }}</h3>
            </div>

          </div>



        </div>
        <!--./hardware DISPLAY-->
      </div>
    </div>
  </div>

  <div class="white-box content table-responsive">
    <h2 class="title">Hardware logs (available only for 2 weeks)</h2>
    <mat-spinner *ngIf="is_loading"></mat-spinner>

    <table class="table table-bordered" *ngIf="!is_loading">
      <thead>
        <tr>
          <th>Date</th>
          <th>Status </th>
          <th>Details</th>
          <th *appHasRole="'super-user'">Technique Details</th>
        </tr>
      </thead>
      <tr *ngFor="let log of logs.results">
        <td>{{log.CREATED_AT | date:'medium'}}</td>
        <td class="text-center">
          <i class="fa fa-check-circle" *ngIf="log.HARDWARE_STATUS_PERIPHIRALS" aria-hidden="true"></i>
          <i class="fa fa-times-circle" *ngIf="!log.HARDWARE_STATUS_PERIPHIRALS" aria-hidden="true"></i>
        </td>
        <td>
          <strong *ngIf="log.STATUS"> {{'Periphirals' | translate }}</strong>
          <ul class="hardwares-logs">
            <ng-container *ngFor="let st of log.STATUS | keyvalue">
              <li *ngIf="st.value.Module != 'System'">
                <span class="good" *ngIf="!st.value.ClientErrorMessage"><i class="fa fa-check-circle" aria-hidden="true"></i></span>
                <span class="error" *ngIf="st.value.ClientErrorMessage"><i class="fa fa-times-circle" aria-hidden="true"></i></span>

                <strong [ngClass]="{'error':st.value.ClientErrorMessage, 'good':!st.value.ClientErrorMessage}"> {{st.key}} </strong> 
                <span class="error"> {{st.value.ClientErrorMessage}} </span>
              </li>
            </ng-container>
          </ul>
        </td>
        <td  class="details-tech" *appHasRole="'super-user'">
          <debug [obj]="log.STATUS" ></debug>
        </td>
      </tr>
    </table>

    <mat-paginator *ngIf="logs" (page)="onChangePage($event)" [length]="logs.count" [pageSize]="filter.page_size"
      [pageIndex]="logs?.current_page-1" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
    </mat-paginator>
  </div>

</div>