import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { copyObject } from 'src/app/helpers/commun';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { ModuleManagerService } from 'src/app/shared/services/module-manager.service';
import { ModuleWorkerService } from 'src/app/shared/services/module-worker.service';
import { URI } from 'src/app/shared/settings/global';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { getNestedObject } from './_nestedProp';

@Component({
  selector: 'app-module-worker',
  templateUrl: './module-worker.component.html',
  styleUrls: ['./module-worker.component.scss']
})
export class ModuleWorkerComponent implements OnInit {


  constructor(
		private hs: HotelService,
    notifierService: NotifierService,
    private moduleWorkerService : ModuleWorkerService,
		private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private moduleService : ModuleManagerService,
		private _router: Router,
		public dialog: MatDialog) {
  		this.notifier = notifierService;

    }

  private readonly notifier: NotifierService;


  //GLOBAL VARS
  slug      :any;
  module_id :any;
  @Input() module    :any;
  hotel     :any;
  data      :any;
  title     :any;
  search    = "";
  tempData  :any;
  module_inherited_id;
  module_inherited;
  form_parent;
  btn_id;
  paramsPagination = {
    next:"",
    previous:"",
    count : 0,
    current:"1",
    total_pages:"1"
  }
  @Input() is_child = false;

  ngOnInit() {
		this.spinner.show('global');

  		this.router.params.subscribe(params => {
      this.slug = params.slug;
      this.module_id = params.module_id;
      this.btn_id = params.btn_id;

      if (!this.module){
        if(params.module_inherited_id){
          this.module_inherited_id = params.module_inherited_id;
          this.form_parent = params.form_parent;
  
          this.moduleService.get(this.module_inherited_id).subscribe((res:any)=>{
            this.module_inherited = res;
            this.getModuleById(this.module_id);
  
          });
  
  
        }else {
           this.getModuleById(this.module_id);
        }
      }else {
        this.setModule()
      }

			this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
      });

     




		});
  }


  /**
   * GET MODULE BY ID
   * @param id 
   */
  getModuleById(id){
    this.moduleService.get(id).subscribe((res:any)=>{
      this.module = res;
      this.title = this.module.title;

      if(this.module.configs.one_row){
        this._router.navigate(["/module-worker-detail",this.slug, id,"update"])
      }else {
        this.getDataFromEndPoint();
      }    
    });
  }

  setModule(){
    this.is_child = true;
    this.getDataFromEndPoint();
  }


  /**
   *
   * @param module
   */
  getDataFromEndPoint(url=null){
    let params = "";
    let endpoint  = this.module.endpoint.replace('{{hotel}}', this.slug);
    if(this.module_inherited  && this.module_inherited.configs.go_to_btns != undefined){
      let btnComeFrom = this.module_inherited.configs.go_to_btns.find(x=>x.id == this.btn_id);
      console.log(btnComeFrom,this.module_inherited.configs.go_to_btns , this.btn_id)
      params = btnComeFrom.params.replace('{{id}}', this.form_parent)
      endpoint = endpoint.indexOf('?') != -1 ? endpoint + "&" + params :  endpoint + "?" + params
    }
    url && this.spinner.show('global');
    endpoint = url ? url :endpoint;
    this.moduleWorkerService.list(endpoint).subscribe((res:any)=>{
      this.spinner.hide('global');
      //IF PAGINATION SERVER SIDE
      if(this.module.configs.pagination_serverside){
        let urlParams = new URL(endpoint.indexOf('http') != -1? endpoint: URI+endpoint);
         this.paramsPagination = {
          total_pages :  res['total_pages'],
          current : urlParams.searchParams.get('page') ? urlParams.searchParams.get('page') : "1",
          next : res['next'],
          previous:res['previous'],
          count:res['count']
        }
      }

      //GET DATA FROM ENDPOINT
      this.data = this.module.configs.access_to_data ?  getNestedObject(res,this.module.configs.access_to_data.split(".")) : res ;
      this.tempData = copyObject(this.data);
    })
  }


  searchItem(){

    if(this.search.length){
      this.data = this.data.filter((obj)=>{
        return JSON.stringify(obj).toLowerCase().indexOf(this.search.toLowerCase()) != -1
      });
    }else {
      this.data = this.tempData
    }
  }


  deleteItem(row, i){
    const dialogRef=this.dialog.open(ConfirmDeleteComponent, {
      data : {}, width:'700px'
    })
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        let endpoint  = this.module.endpoint.replace('{{id}}', row.id);
        this.data.splice(i, 1);

        this.moduleWorkerService.delete(endpoint).subscribe(res=>{
          this.notifier.notify('success', 'Item deleted successfully')
        })
      }
    })
  }

  /**
   *
   * @param row
   * @param path
   */
  getDataValue(row, path){
    let attr = path.split('.')[0];

    try {
      let obj = row;
      if(typeof row[attr] == "string" && row[attr] && path.indexOf('.') != -1) {
        obj[attr] = JSON.parse(row[attr])
      }
      return getNestedObject(row, path.split('.'));
    } catch (error) {
      return row[attr]
    }
  }

  /**
   *
   * @param row
   */
  getLink(btn, row){
    if(btn.type == "module"){
      return ['/module-worker', this.slug ,btn.module, this.module.id, row.id, btn.id]
    }
    else{
      return this.changeParamByValues(btn.link, row);
    }
  }


  /**
   *
   * @param text
   * @param row
   */
  changeParamByValues(text, row){
    console.log(text, "----link")
    if(text != undefined && text.length){
      for(let attr in row){
        text = text.replace('{{'+attr+'}}', row['attr']);
      }
    }

    return text;
  }

}
