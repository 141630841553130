import { NgxSpinnerService } from 'ngx-spinner';
import { FaqService } from 'src/app/shared/services/faq.service';
import { MEDIA } from 'src/app/shared/settings/global';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-faq-category',
  templateUrl: './dialog-faq-category.component.html',
  styleUrls: ['./dialog-faq-category.component.scss']
})
export class DialogFaqCategoryComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogFaqCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public ct: FaqService,
    private spinner: NgxSpinnerService,

  ) { }

  fb = new FormData();
  cat: any;
  MEDIA = MEDIA;
  errors = null;

  ngOnInit() {
    this.cat = this.data.cat;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  changed(event) {
    let target = event.target.value;
    target = event.target.innerHTML;
  }

  /**
   *
   * @param cat
   */
  addCategory(cat) {
    this.spinner.show('global');
    for (let v in cat) {
      if (v != "image") {
        this.fb.append(v, cat[v] ? cat[v] : '')
      }
    }
    if (cat.id) {
      this.ct.editFaqCategory(this.fb, cat.id).subscribe(res => {
        this.spinner.hide('global');
        for(let attr in res){
          this.cat[attr] = res[attr]
        }
        this.dialogRef.close({res, action:'edit'});
      })
    } else {
      this.ct.addFaqCategory(this.fb).subscribe(res => {
        this.spinner.hide('global');
        this.cat = res;
        this.dialogRef.close({res, action:'add'});
      })
    }
  }
}
