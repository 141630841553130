import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { compareValues } from 'src/app/helpers/commun'
// import ReconnectingWebSocket from 'reconnecting-websocket';
import * as $ from "jquery";
import { DialogListNotificationsComponent } from '../../dialogs/dialog-list-notifications/dialog-list-notifications.component';
import { NotifierService } from 'angular-notifier';
import { SocketsService } from 'src/app/shared/services/sockets.service';
import { APIWS } from 'src/app/shared/settings/global';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-manage-notifications',
  templateUrl: './manage-notifications.component.html',
  styleUrls: ['./manage-notifications.component.scss']
})
export class ManageNotificationsComponent implements OnInit, OnDestroy {
  @Input() slug: any;
  admin = localStorage.getItem('id');
  sound = true;
  played: any;
  pile: any = [];
  last_id: any;
  text: any;
  i = 0;
  notifications = [];
  playing = false;
  interval : any;
  lang : 'fr';
  channel = '';
  socket;
  user = localStorage.getItem('user_id')
  constructor(
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    notifierService: NotifierService,
    private SocketsService:SocketsService

    ) { 
      this.notifier = notifierService;
    }
  
    private readonly notifier: NotifierService;

  ngOnInit() {

    if(localStorage.getItem('notif_sound')){
      this.sound = (localStorage.getItem('notif_sound') == 'true')
    }else {
      localStorage.setItem('notif_sound', 'true')
    }
 
    setTimeout(()=>{

    this.onMessage();
    },100)
    this.channel = this.admin  + '_' + this.slug;
    this.setupSocketConnection(this.channel)
 

  
  }

  /**
   * 
   * @param channel 
   */
  setupSocketConnection(channel = this.channel) {
    
    this.socket =  new WebSocket(APIWS+'/ws/notif/'+channel+'/');

    this.socket.onopen = () => {
      console.log('WebSockets connection created.');
    };
    this.socket.onmessage = (event) => {
      // this.num = event.data;
      console.log(event.data, 'here we are')
      this.readMessage(JSON.parse(event.data).message);
    };

    this.socket.onclose = (event)=>{
      setTimeout(()=>{
        this.setupSocketConnection()
      },5000)
    }
 
  }
  ngOnDestroy(){
    this.socket.close()
    this.pile = [];
  }

  /**
   * 
   * @param data 
   */
  readMessage(id){
    
    this.notificationService.getNotifById(id).subscribe(res=> {
      this.pile.push(res);
      this.notifications.unshift(res);
      this.pile.sort(compareValues('created_at', 'asc', true)).sort(compareValues('priority', 'desc'));
      if (!this.playing) {
        this.playing = true
        setTimeout(() => this.playMessages(), 10);
      }
    })
    
  }

  
  onMessage() {
      //  if module is active
      this.notificationService.getNotification(this.slug, this.admin, this.last_id).subscribe((res: string[]) => {
        if (res.length) {
          this.last_id = Math.max.apply(Math, res.map(function (x) { return x['id']; }))
          for (let element of res) {
              this.pile.push(element);
              this.notifications.unshift(element)
          }
          this.pile.sort(compareValues('created_at', 'asc', true)).sort(compareValues('priority', 'desc'));

          if (!this.playing) {
            this.playing = true
            // if is not playing
            setTimeout(() => this.playMessages(), 10);
          }
        }
      })
  }


  playMessages() {
    
    if(this.pile.length) {
      this.text = this.pile[0].text;
      this.lang = this.pile[0].lang;
      let id = this.pile[0].id

      this.notifier.notify(this.pile[0].status,this.text);
      if(Boolean(localStorage.getItem('notif_sound') == 'true')){
        this.speak();
      }
      if (this.pile.length>1) {
        setTimeout(() => {
          this.playMessages();
        }, this.text.length * 120 + 1000)
      } else {
        setTimeout(() => {
          this.playing = false
        }, this.text.length * 120 + 1000)
      }

      this.pile.splice(0, 1);
      //DELETE NOTIFICATION 
      this.deleteNotification(id);
    }
  }

  /**
   * 
   * @param id 
   */
  deleteNotification(id){
    setTimeout(()=>this.notificationService.delete(id).subscribe((response) => {}),1000 * 2);

  }



  openDialogNotif(){
    let dialogRef = this.dialog.open(DialogListNotificationsComponent, {
      width: '650px',
      data: this.notifications,

    });

    dialogRef.afterClosed().subscribe(res => {
      if(res['close']){

        for(let notif of this.pile){
          this.deleteNotification(notif.id);
        }
        this.playing = false
        this.pile = [];
        this.notifications = [];
      }
    });
  }



  speak() {
    setTimeout(() => eventRead(), 10);
  }

}

function eventRead() {
  $('#play-sound').trigger('click');
}