<div class="row">

  <!-- debug -->
  <div class="col-md-3" *ngIf="debug">
    <app-debug-form [form]="form" (formUpdate)="updateForm($event)"></app-debug-form>
  </div>
  <!-- debug -->
  <div [ngClass]="{'col-md-9':debug, 'col-md-12':!debug}">

    <div class="form-builder">

      <!-- preview -->
      <div class="form-preview white-box" *ngIf="form && activePreview">
        <app-form-stepper-or-tabs [response]="response" [variablesAPI]="variablesAPI" [form]="form" [hotel]="hotel"></app-form-stepper-or-tabs>
      </div>
      <!-- preview -->

      <!-- accordion -->
      <div class="accordion" id="accordion" *ngIf="form && !activePreview" cdkDropListGroup>
        <ng-container *ngFor="let section of form.sections; let j = index ">
           <div class="section" [ngClass]="{'active-section':activeSection == j, 'array':section.is_array}" (click)="setActiveSection(j)">
           
            <!-- section name -->
          
             
            <input type="text" class="form-control transparent" [(ngModel)]="section.sectionName">
            <input type="text" class="form-control path" placeholder="Path to array" [(ngModel)]="section.path" *ngIf="section.is_array">
            <!--label Input role-->
              <select  [(ngModel)]="section.role" class="form-control">
                  <option value="" disabled>Role</option>
                  <option value="concierge">Concierge</option>
                  <option value="administrator">Administrator</option>
                  <option value="super-user">Super user</option>
              </select>
          
            <!--label-->

            <!-- clone section -->
            <button class="btn btn-sm array"  matTooltip="Use as array" (click)="useSectionAsArray(section, j)">
              <i class="fa fa-columns" aria-hidden="true"></i>
            </button>
            <!-- ./clone section -->

            <!-- clone section -->
            <button class="btn btn-sm clone"  matTooltip="Clone section" (click)="cloneSelction(section, j)">
              <i class="fa fa-clone" aria-hidden="true"></i>
            </button>
            <!-- ./clone section -->

            <!-- delete section  -->
            <button class="btn btn-sm delete"  matTooltip="Delete section" (click)="deleteSection(section, j)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
            <!-- delete section  -->

          </div>

          <div class="section-fileds" cdkDropList [cdkDropListData]="form.sections[j].fields"
            (cdkDropListDropped)="drop($event)">
            <!--card-->
            <ng-container *ngFor="let field of section.fields; let i = index">

              <div class="card" cdkDrag>

                <ng-container *ngIf="field">
                  <!-- card header -->
                  <div class="card-header" [ngClass]="field.type" id="heading-{{trim(field.name)}}}"
                    (click)="headerEvent(field.name)">

                    <div class="row flex-vertical field">
                      <!-- name -->
                      <div class="col-md-8 collapsed action-collapse" id="action-collapse-{{trim(field.name)}}"
                        [attr.data-toggle]="'collapse'" [attr.data-target]="'#collapse-'+trim(field.name)"
                        [attr.aria-expanded]="'false'" [attr.aria-controls]="'collapse-'+trim(field.name)">
                        <h3 [ngClass]="{'disable-field':!field.active}">
                          {{(field.label ? field.label : field.type_name)}}
                        </h3>
                        <small *ngIf="field.type != 'separator'">{{field.type_name}} - ({{field.name }})</small>
                      </div>
                      <!-- name -->

                      <!-- field actions -->
                      <div class="col text-right">
                        <button class="btn" (click)="deleteField(field, i, j)">
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                        <!-- <button class="btn" (click)="addToDefaultValues(field, i)">
                          <i class="fa fa-bookmark" aria-hidden="true"></i>
                        </button> -->
                        <button class="btn" (click)="cloneField(field, j, i)">
                          <i class="fa fa-clone" aria-hidden="true"></i>
                        </button>
                        <button cdkDragHandle class="btn">
                          <i class="fa fa-arrows" aria-hidden="true"></i>
                        </button>
                      </div>
                      <!-- end field actions -->

                    </div><!-- row -->
                  </div>

                  <!-- card header -->

                  <!-- collapsed -->
                  <div id="collapse-{{trim(field.name)}}" class="collapse"
                    [attr.aria-labelledby]="'heading-'+trim(field.name)" data-parent="#accordion">
                    <div class="card-body">
                      <field-constructor (changeFieldEvent)="onChangeFieldEvent($event)" [currentSection]="j"
                        [customVars]="customVars" [variablesAPI]="variablesAPI" [form]="form" [field]="field" [languages]="languages" [hotel]="hotel">
                      </field-constructor>
                    </div>
                  </div>
                  <!-- collapsed -->
                </ng-container>
              </div>
            </ng-container>

            <!--card-->
          </div>
        </ng-container>
      </div>
      <!-- accordion -->

      <!--actions-->


      <div class="form-actions" [ngStyle]="{'top':topPosition}">
        <button class="btn btn-preview" (click)="activePreviewForm()" [ngClass]="{'active': activePreview}"
          matTooltip="Info about the action">
          <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
        <button class="btn btn-trans" (click)="setAutomaticTrans()" [ngClass]="{'active': form?.params?.autoTranslate}"
          matTooltip="Auto translate">
          <i class="fa fa-flag" aria-hidden="true"></i>
        </button>

        <button class="btn btn-save" (click)="debugForm()" matTooltip="Debug" [ngClass]="{'active': debug}">
          <i class="fa fa-code" aria-hidden="true"></i>
        </button>
        <a class="btn" matTooltip="Go to device form" [routerLink]="['/device/fill-form', slug, deviceParam.id]"
          *ngIf="deviceParam"><i class="fa fa-paper-plane" aria-hidden="true"></i></a>

        <!-- <button class="btn btn-save" (click)="convertJson()">
          <i class="fa fa-bookmark" aria-hidden="true"></i>
        </button> -->

        <button class="btn btn-save" (click)="saveForm()" matTooltip="Save the form">
          <i class="fa fa-floppy-o" aria-hidden="true"></i>
        </button>
        <button class="btn btn-section" matTooltip="Add new section" (click)="addNewSection()">
          <i class="fa fa-film" aria-hidden="true"></i>
        </button>
        <button [matMenuTriggerFor]="menu" aria-label="Menu" matTooltip="Add field"
          class="mat-icon-button btn btn-add-field">
          <mat-icon>more_vert</mat-icon>
        </button>



        <mat-menu #menu="matMenu" yPosition="above" xPosition="before">
          <button mat-menu-item (click)="addNewField(attr.type)" *ngFor="let attr of fields">
            <i class="{{attr.icon}}" aria-hidden="true"></i>
            <span>{{attr.name | translate}}</span>
          </button>
        </mat-menu>
      </div>
      <!--actions-->

    </div>
    <!--form preview-->
  </div>
</div>