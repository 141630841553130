import { _commun, _multiple } from './_commun';

/**
 * RADIO BOX
 */
export const _radio = {
     ..._commun,
     ..._multiple,
     "type": "radio",
     "type_name":"Unique Choice",
     "inline":false	,
     "other": true,
}

/*
* RADIO BOX
*/
export const _boolean = {
    ..._commun,
    type: "boolean",
    type_name:"Boolean value",
    inline:false	,
    other: true,
    values: [{
          label: "True",
          value:  true,
          selected: true,
          conditionStep:""
     },
     {
          label: "False",
          value:  false,
          selected: false,
          conditionStep:""
     }]
}
