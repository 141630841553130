import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-add-edit-supervision',
  templateUrl: './dialog-add-edit-supervision.component.html',
  styleUrls: ['./dialog-add-edit-supervision.component.scss']
})
export class DialogAddEditSupervisionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogAddEditSupervisionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog : MatDialog

  ) {

  }

  defaultBtn = {
    text :"text",
    icon : "icon",
    endpoint: "stat",
    role : "concierge",
    apiParams : []
  }

  ngOnInit() {
  }

  /**
   *
   * @param tab
   */
  addParam(tab){
    tab.paramsUrl.push({key:"key", value:"value"})
  }

  addParamApi(tab){
    tab.push({key:"key", value:"value"})
  }

  /**
   *
   * @param tab
   */
  addBtns(tab){
    tab.customBtns = (tab.customBtns == undefined) ? []:tab.customBtns;
    tab.customBtns.push({});
  }

  addParamsBtns(tab){
    tab.customBtnsParams = (tab.customBtnsParams == undefined) ? []:tab.customBtnsParams;
    tab.customBtnsParams.push({...this.defaultBtn});
    console.log(tab)
  }

  /**
   *
   * @param tab
   * @param key
   */
  delete(tab, index, attr = "paramsUrl"){
    tab[attr].splice(index, 1)
  }


  /**
   *
   */
  addSection(){
    this.data.tabs.configs.push({
      title: "Supervision ",
      detail:"",
      active:true,
      paramsUrl : [
        {key:"id", value:""},
        {key:"customer", value:"{{hotel}}"},
        {key:"template", value:"PROCESS_HISTORY"},
        {key:"init_stats", value:"true"},
        {key:"period", value:"7_DAYS"},
      ]
    })
  }




  /**
   *
   * @param section
   */
  duplicate(section){
    this.data.tabs.configs.push({...section})
  }


  /**
   *
   * @param event
   */
  drop(event){
    moveItemInArray(this.data.tabs.configs, event.previousIndex, event.currentIndex);
  }


  /**
   *
   * @param tab
   * @param index
   */
  deleteSection(tab, index){
    const dialoRef = this.dialog.open(ConfirmDeleteComponent, {
      data:{type : "Section", name : tab.title}, width:"700px"
    });

    dialoRef.afterClosed().subscribe(res=>{
      res && this.data.tabs.configs.splice(index,1)
    })

  }

  addFilter(index){
    if(!this.data.tabs.configs[index].config_filter){
      this.data.tabs.configs[index].config_filter = [];
    }
    this.data.tabs.configs[index].config_filter.push({
      key:"key",
      value : "value"
    })

  }

}
