import { DialogTimelineComponent } from 'src/app/components/modals/dialog-timeline/dialog-timeline.component';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-detail-supervision',
  templateUrl: './dialog-detail-supervision.component.html',
  styleUrls: ['./dialog-detail-supervision.component.scss']
})
export class DialogDetailSupervisionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDetailSupervisionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog)
  {}

  ngOnInit() {
  }

    /**
   *
   * @param value
   */
  openTimeLine(value){
    this.dialog.open(DialogTimelineComponent, {
      data: value, width:'700px'
    });

  }

  getNumber(int){
    return parseInt(int);
  }



}
