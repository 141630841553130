import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HardwareService } from 'src/app/shared/services/hardware.service';
import { HotelService } from 'src/app/shared/services/hotel.service';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DialogStatusDetailsComponent } from './dialogs/dialog-status-details/dialog-status-details.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-dashboard-hardware',
  templateUrl: './dashboard-hardware.component.html',
  styleUrls: ['./dashboard-hardware.component.scss']
})
export class DashboardHardwareComponent implements OnInit {

  constructor(
  	private HrdS : HardwareService,
    private apiservice:ApiService,
    private hs: HotelService,
    notifierService: NotifierService,
    private router : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _router : Router,
    public dialog: MatDialog,
    private translate: TranslateService,
    ) {
      this.notifier = notifierService;
    }

  private readonly notifier: NotifierService;

  hardwares : any;
  title = "List of Hardwares"
  slug : any;
  hotel : any;
  languages : any;
  is_loading = true;
  filter = {
    page : 1,
    page_size:10,
    CUSTOMER:"",
  }
  logs ;
  @Input() onglet;

  ngOnInit() {
    this.spinner.show('global');
    this.hs.getLanguages().subscribe((data) => {
      this.languages = data
    });
    this.router.params.subscribe(params => {
      if(params.slug =="undefined"){
          this._router.navigate(['/hotels'])
      }
      this.slug = params.slug;
      this.filter.CUSTOMER = this.slug; 
      this.hs.getHotel(this.slug).subscribe((data) => {
          this.hotel = data;
      });

      this.getLogs()
      this.reloadData();
    });

  }

  getLogs(){
    this.is_loading = true;
    let params = new HttpParams();
    for (let i in this.filter){
      params = params.set(i, this.filter[i])
    }
    this.apiservice.getWithParam('hardwares-logs/', params).subscribe(res=>{
      this.logs = res;
      this.is_loading = false
    })
  }

  /**
   * 
   * @param $event 
   */
  onChangePage($event){
    this.filter.page_size = $event.pageSize;
    this.filter.page = $event.pageIndex+1;
    this.getLogs();
  }

  /**
   *
   * @param reload
   */
  reloadData(reload=false){
    if(reload){
      this.spinner.show('global');
    }
    this.HrdS.getHardwares(this.slug).subscribe(res=>{
      this.hardwares = res;
      this.spinner.hide('global');
    }, err=>{
      this.spinner.hide('global');
    })
  }

  /**
   *
   * @param hardware
   */
  openDialogDetail(hardware){
    if(hardware.HARDWARE_STATUS && !hardware.OUT_OF_SERVICES && hardware.Status.length){
      const dialogRef = this.dialog.open(DialogStatusDetailsComponent, { data: hardware, width:"700px" });
    }
  }

  /**
   *
   * @param id
   */
  reboot(id){
    this.spinner.show('global');

    this.HrdS.rebootHardware(id).subscribe(res=>{
      this.spinner.hide('global');

      if(res['success']){
        this.translate.get('Hardware was rebooted successfully').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
      }else {
        this.translate.get('An error has occurred').subscribe(
          value => {
            this.notifier.notify('error', value);
          }
        );
      }
    })
  }

  /**
   *
   * @param hardware
   */
  getStatus(hardware){
    if(hardware.OUT_OF_SERVICES  || !hardware.HARDWARE_STATUS){
      return 'danger'
    }
    if(hardware.IS_SCREEN && hardware.HARDWARE_STATUS){
      return 'success'
    }
    if(hardware.Status.length) {
      let system = hardware.Status.find(x=>x.Module== 'System');

       if(typeof(system) != "undefined"){
        if(!system.Ok){
          return 'danger'
        }else if( system.Code == 2 && system.Ok ) {
          return 'warning'
        }else if (system.Ok && system.Code != 2){
          return 'success'
        }
      }

    }else if(hardware.HARDWARE_STATUS && !hardware.Status.length) {
      return 'warning'
    }
  }

  getLastUpdate(hardware){
    if(hardware.Status.length) {
      let system = hardware.Status.find(x=>x.Module== 'System');
       if(typeof(system) != "undefined"){
        return system.Details.Time;
      }
    }
    return ''
  }

  /**
   *
   * @param st
   */
  getClass(st){
    if(st.Ok && st.Code != 2){
      return 'success';
    }else if(!st.Ok && st.Code == 2){
      return 'warning'
    }else if(!st.Ok  && st.Code == 1){
      return 'danger'
    }
    else {
      return 'info'
    }
  }
}
