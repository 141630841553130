import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  uri = URI + 'push-notifications/';

  constructor(private http: HttpClient) { }


  getNotification(slug, admin, last_id = ''){
    let pile_timing = localStorage['notif_pile_timing'] ?  parseInt(localStorage['notif_pile_timing']) : 5;

    let date = new Date();
    date.setMinutes(date.getMinutes()- pile_timing );

    let date_time = date.getFullYear()+'-'+ ('0' +(date.getMonth()+1)).slice(-2)+'-'+('0'+(date.getDate())).slice(-2)+'T'
    + ('0' + date.getHours()).slice(-2) + ":" + ('0'+ date.getMinutes()).slice(-2);

    return this.http.get(`${this.uri}`+ '?created_at__gte='+date_time+'&user='+admin+'&customer='+slug+'&id__gt='+last_id);
  }

  getNotifById(id){
    return this.http.get(this.uri+ id);

  }
  	delete(id){
		  return this.http.delete(this.uri+id+'/');
	  }
}
