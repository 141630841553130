<h2 class="mat-dialog-title">
    Edit Item PMS Link
</h2>
  
  <mat-dialog-content class="mat-typography">
    <div class="form-group">
        <label for="">Product Item to link</label>
        <select class="form-control" [(ngModel)]="itemlinkpms.item_local_id">
            <optgroup [label]="cat.name"  *ngFor="let cat of data.products">
                <option [value]="product.id"  *ngFor="let product of cat.items">{{product.name}}</option>
            </optgroup>
        </select>
    </div>


    <div class="form-group">
        <label for="">Items IDs to link with</label>
        <textarea class="form-control" [(ngModel)]="itemlinkpms.item_pms_id" rows="5"></textarea>
    </div>
  
    </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="btn btn-warning">{{'Cancel' | translate }}</button>
    <button mat-button (click)="saveOrEdit()"  class="btn btn-success">Save</button>
  </mat-dialog-actions>