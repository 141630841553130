export class Customer{
    clone:Object
    templates_correspondances:Object
    variables_id:Object
    data_to_update:Object
    Get:Object
    Duplicate:Object
    Module:Object

    constructor(){
      
        this.clone = {
          "number_devices_to_create": 1,
          "device_model_to_clone": "kiosk",
          "number_hardwares_to_create":1,
          "create_dns_ovh": true,
          "id_customer_source": 42,
          "clone_concierge":false,
          "clone_offers":false,
          "clone_notification":false,
          "new_customer": {
                "name_hotel_slug": "",
                "name_hotel": "",
                "languages_param": "Get.LanguageHotel",
                "pms": "Duplicate.Pms"
          }
        }
        this.templates_correspondances = {
               "choices": "pages"
        }
        this.variables_id= {
          "pages": [
               "walkin_scenario_id",
               "checkin_scenario_id",
               "checkout_scenario_id"
          ]
        }
        this.data_to_update= {
          "DEVICES": {
            "params_generaux": {
              "INPUTS_VALUES": {
                "logo": "/static/images/accueilLogo2.png",
              }
            },
            "params_techniques": {
              "INPUTS_VALUES": {
                "main_color_1": "#fff",
                "main_color_2": "#fff",
                "main_color_3": "#fff",
                "background_image": "",
                "keyboard_plugin": "MyWorkflow/name_hotel_slug/_workflow_keyboard_script.html",
                "workflow_message": "MyWorkflow/name_hotel_slug/_workflow_modal.html"
              }
            }
          }
      
        }
        this.Get= {
          "LanguageHotel$1": [],
          "LanguageHotel$2":{}
        }
        this.Duplicate= {
          "Pms": {
            "id": "2"
          }
        }
        this.Module= {
          "Pms": "th_pms.models"
        }
      
    } 
      
}