import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { WorkflowService } from 'src/app/shared/services/workflow.service';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-workflow-list',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.scss']
})
export class WorkflowListComponent implements OnInit {

  constructor(
    private hs: HotelService,
    notifierService: NotifierService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _router : Router,
    public dialog: MatDialog,
    private workflowService : WorkflowService
  ) { }

  title="Translate texts"
  displays: any;
  languages : any;
  slug : any;
  hotel:any;
  workflows : any;
  tabs = [];
  groupedWorflows= {};
  @Input() onglet = false;

  //log
  ngOnInit() {
    this.spinner.show('global');

    this.hs.getLanguages().subscribe((data) => {
      this.languages = data
    })

    this.route.params.subscribe(params => {
      this.slug = params.slug

      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
      });

      this.workflowService.getWorkflowsByCustomer(this.slug).subscribe(res=>{
        this.workflows = res;
        this.groupByType(this.workflows)
        this.spinner.hide('global');
      })
    });
  }


  /**
   *
   * @param workflows
   */
  groupByType(workflows){
    for(let workflow of workflows){
      this.tabs.indexOf(workflow.GROUP) == -1 && this.tabs.push(workflow.GROUP)
    }
    for(let tab of this.tabs){
      for(let workflow of this.workflows){
        if(typeof(this.groupedWorflows[tab]) == "undefined"){
          this.groupedWorflows[tab] = [];
        }
        (tab == workflow.GROUP) && this.groupedWorflows[tab].push(workflow)
      }
    }
    console.log(this.groupedWorflows)
  }

  getName(tab){
    if (!tab){
      return "Default"
    }
    return tab.split('_').join(' ')
  }

}
