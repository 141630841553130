<!-- Page Content -->
<div class="container-fluid">
  <app-fixed-title [title]='title' [hotel]='hotel'></app-fixed-title>
  <div class="row">

    <div class="col-md-12">
      <div class="white-box content">


        <div class="text-right" *appHasRole="'super-user'">
            <button class="btn btn-success" (click)="openDialogDisplay()">{{'Add new display' | translate}}</button>
          <br>
        </div>


        <table mat-table [dataSource]="sortedData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">

          <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="ID_DISPLAY">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="ID_DISPLAY"> ID. </th>
            <td mat-cell *matCellDef="let element"> {{element.ID_DISPLAY ? element.ID_DISPLAY : element.id}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="NAME">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header="NAME"> {{'Name' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.NAME}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="FORM_JSON">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header="FORM_JSON"> {{'Form'| lowercase | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{!!element.FORM_JSON}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="FOR_WORKFLOW">
            <th class="text-center mat-header-cell" *matHeaderCellDef mat-sort-header="FOR_WORKFLOW"> {{'For Workflow' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{!!element.FOR_WORKFLOW}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> {{'Actions' | translate}} </th>
            <td  *matCellDef="let element" class="mat-cell text-right">
              <a class="btn btn-info btn-sm" [routerLink]="['/form-constructor', slug, element.ID_DISPLAY ? element.ID_DISPLAY : element.id]">{{'Edit form' | translate}}</a>
              <button class="btn btn-success btn-sm" (click)="setDefaultValues(element)" *ngIf="element.NAME == 'default-values'">{{'Default values' | translate}}</button> 
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
         
    

      </div>
    </div>
  </div>
</div>