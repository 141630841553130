<h2 class="mat-dialog-title with-trans">
  {{'Edit property Languages' | translate }}
</h2>

<mat-dialog-content class="mat-typography">
  
  <input-validator  *ngIf="errors" [errors]="errors"></input-validator>
  
  <div class="container">
    <br>
    <div class="form-group">
      <label for="">{{'Default Language' | translate }}</label>
        <select [(ngModel)]="hotel.default_lang" class="form-control" name="default_lang" (change)="onChange($event)">
          <option *ngFor="let lang of languages" [value]="lang.id">
            {{lang.lang_name | translate}}
          </option>
        </select>

    </div>


    <br>
    <div class="form-group">
      <mat-form-field>
        <mat-label>{{ 'Active Languages' | translate }}</mat-label>
        <mat-select [(ngModel)]="hotel.languages_param" multiple (selectionChange)="onChange($event)">
          <mat-option *ngFor="let lang of langselect" [value]="lang.id">{{lang.text | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

 
    </div>

    

  </div>



</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{'Cancel' | translate }}</button>
  <button mat-button (click)="updateOrCreate()"  class="btn btn-success">{{'Update Languages' | translate }}</button>
</mat-dialog-actions>