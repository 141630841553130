import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-font-icons',
  templateUrl: './font-icons.component.html',
  styleUrls: ['./font-icons.component.scss']
})
export class FontIconsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
