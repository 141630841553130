import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'upsell-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class UpsellPromotionsComponent implements OnInit {

  constructor() { }

  @Input() offer: any;
  @Input() items: any;
  @Input() labels : any;
  @Input() features=[];
  @Input() rooms = [];
  @Input() devices = [];
  @Input() scenarios = [];
  @Input() priceType = [];
  @Input() guest = [];
  @Input() categories = {};

    /**
   * SET DATA IN ARRAY
   * @param path
   * @param obj
   * @param value
   */
  resolve(path, obj, value) {
    let l = path.split('.').length;
    let i = 0;
    return path.split('.').reduce(function(prev, curr) {
        i++;
        if(i == l){
          prev[curr] = value
        }
        return prev ? prev[curr] : null
    }, obj || self)
  }

  /**
   * CHANGED SELECT2 EVENT
   * @param event
   * @param model
   */
  changed(event, model) {
      this.resolve(model, this.offer, event.value)
  }

  ngOnInit() {
  }

  /**
   *
   * @param model
   * @param enter
   * @param ret
   */
  getValue(model, enter){
      return model == enter;
  }

  /**
   *
   * @param event
   */
  updateModel(event, prop, value){


    if(event.target.checked){
      this.setValueObject(this.offer, prop, value)
    }else {
      this.setValueObject(this.offer, prop, "")
    }

  }


  /**
   *
   * @param schema
   * @param path
   * @param value
   */
  setValueObject(schema, path, value){

    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }

    schema[pList[len-1]] = value;
  }


  /**
   *
   * @param obj
   * @param prop
   * @param defval
   */
  findObject(obj, prop, defval = null){

    prop = prop.split('.');
    for (var i = 0; i < prop.length; i++) {
        if(typeof obj[prop[i]] == 'undefined')
            return defval;
        obj = obj[prop[i]];
    }
    return obj;
  }

}
