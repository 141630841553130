import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { SubcategoryService } from 'src/app/shared/services/subcategory.service';
import { EXTRA_OBJECT } from 'src/app/shared/settings/_const';
import { MEDIA } from 'src/app/shared/settings/global';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { ItemService } from '../../../shared/services/item.service';
import { item_attr } from '../fields/item-fields';
import { subcat_attr } from '../fields/subcategory-fields';
import { SlidesComponent } from '../slides/slides.component';
import { BuildCategoriesComponent } from '../this/build-categories.component';
import { DialogItemComponent } from '../modals/dialog-item/dialog-item.component';

@Component({
  selector: 'app-item',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemComponent implements OnInit {

  private readonly notifier: NotifierService;

  constructor(
    public dialog: MatDialog,
    public item: ItemService,
    public subcat: SubcategoryService,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    public BuildCategoriesComponent : BuildCategoriesComponent,


  ) {
    this.notifier = notifierService;
  }

  //VARS
  @Input() subcategory: any;
  @Input() category: any;
  @Input() type: any;
  @Input() hotel: any;
  @Input() categoryParent = false;
  @Input() category_type: any;
  @Input() superFields : any;
  @Input() btnNew : any;
  @Input() btnDelete :any;
  @Input() btnNewItem:any;
  @Input() languages:any;
  @Input() hideprice : any;

  MEDIA = MEDIA;
  see_details = {};
  item_attr = item_attr;
  subcat_attr = subcat_attr;
  EXTRA_OBJECT = EXTRA_OBJECT;

  ITEM_IMGS = [
    'image_square',
    'image_rectangular',
  ]



  /**
   * BUILD STYLE FOR PREVIEW IMAGE
   * @param url
   */
  getStyle(url){
   return  {'background-image':'url('+url+')'};
  }

  /**
   * CONFIRM DELETE ITEM
   * @param item
   * @param index
   */
  deleteItem(item, index): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '520px',
      data: {type :'item', name : item.name}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'delete') {
        this.item.deleteItem(item.id).subscribe(
          () => {
            this.translate.get('Item successfully deleted').subscribe(
              value => {
                this.notifier.notify('success', value);
              }
            );

            this.subcategory.items.splice(index, 1);
          },
          () => {
            this.translate.get('An error has occurred.').subscribe(
              value => {
                this.notifier.notify('error', value);
              }
            );
          }
        )
      }
    });
  }

  /**
   * OPEN EXTRA DIALOG
   * @param item
   */
  openExtraDialog(){
  }

  /**
   * SEE ITEM DETAILS
   * @param id : ITEM ID
   */
  getDetails(id) {
    this.see_details[id] = !this.see_details[id];
  }

  /**
   * OPEN MODAL ADD ITEM
   * @param subcategory
   * @param type
   */
  addItem(subcategory, type = 'cat') {
    let data = {
      hotel: this.hotel,
      attrs: this.item_attr,
      category_type: this.category_type,
      superFields: this.superFields,
      languages:this.languages,
      parent: subcategory,
      cropperMaxWidth : 500,
      maintainRatio : true,
      aspectRatio:4/2,
      item : {
        ranked : 0,
        category_type : this.category_type,
        visible : true,
      }
    };
    if (type != 'cat') {
      data.item['sub_category'] =   subcategory.id;
     } else {
      data.item['category'] =   subcategory.id;
    }

    const dialogRef = this.dialog.open(DialogItemComponent, { data });

  }

  /**
   * UPDATE SUBCATEGORY
   * @param subcategory
   */
  updateSubcategory() {

  }

  /**
   * CONFIRM DELETE ITEM
   * @param object : ITEM
   */
  deleteConfirm(object) {

    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name: object.name, type: 'Subcategory', customtext: object.items.length ? 'All items within this sub-category will be deleted' :'' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show('global');
        this.subcat.deleteSubcategory(object.id).subscribe(() => {

          let index = this.category.subcategories.findIndex(x => x.id == object.id);
          this.category.subcategories.splice(index, 1);
          this.translate.get('Subcategory successfully deleted').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );

          this.spinner.hide('global');
        }, () => {
          this.translate.get('An error has occurred').subscribe(
            value => {
              this.notifier.notify('error', value);
            }
          );
          this.spinner.hide('global');
        })
      }
    });

  }

  /**
   * UPDATE ITEM DIALOG
   * @param item
   */
  updateItem(item, subcategory) {
    const dialogRef = this.dialog.open(DialogItemComponent, {
      data: {
        item:item,
        hotel: this.hotel,
        attrs: this.item_attr,
        category_type: this.category_type,
        superFields: this.superFields,
        languages:this.languages, 
        parent : subcategory,
        cropperMaxWidth : 500,
        maintainRatio : true,
        aspectRatio:1/2
      }
    });

  }

  /**
   * BUILD URL
   * @param src
   */
  buildUrl(src){
    if(src && src.search('http') === -1){
      return this.MEDIA + src;
    }else {
      return src;
    }
  }



  /**
   * DUPLICATE CATEGORY
   * @param category
   */
    duplicateItem(item, subcategory){
        let copy = {};
        for(let v in item){
            if(item[v] && v != 'id'){
                copy[v] = item[v]
            }
        }
        for(let lang of this.languages){
            if(copy['name_'+lang.lang_code]){
                 copy['name_'+lang.lang_code] = "copy "+copy['name_'+lang.lang_code];
            }
        }
        this.updateItem(copy, subcategory);
    }

    duplicateSubcat(subcategory){
      let copy = {...subcategory, id:null }
      for(let lang of this.languages){
        if(copy['name_'+lang.lang_code]){
              copy['name_'+lang.lang_code] = "copy "+copy['name_'+lang.lang_code];
          }
      }

      this.BuildCategoriesComponent.addSubCategory(this.category, copy)

    }

  /**
   * CLASS CONDITION
   * @param cat
   */
  displayNewItem(cat){
    if(cat.category_type == "MapPosition" && cat.subcategories.length>0){
      return true;
    }else if(cat.category_type != "MapPosition") {
      return true;
    }else {
      return false;
    }
  }

  /**
   * OPEN SLIDE
   * @param item
   * @param index
   */
  openSlide(item){
    this.dialog.open(SlidesComponent, {data:{item, languages : this.languages, hotel:this.hotel}, width:'900px'});
  }

  /**
   * ng
   */
  ngOnInit() {
    for (let v in this.subcategory.items) {
      this.see_details[this.subcategory.items[v].id] = false;
    }

  }

  /**
   * DRAG AND DROP EVENT
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.subcategory.items, event.previousIndex, event.currentIndex);
    let obj = {
      items: this.subcategory.items.map(a => a.id),
    }
    console.log(obj, "uuuuu")
    this.item.sortItems(obj).subscribe(
      () => {
        this.translate.get('Item order successfully updated').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
      },
      () => {
        this.translate.get('An error has occurred').subscribe(
          value => {
            this.notifier.notify('error', value);
          }
        );
      }
    )
  }


}

