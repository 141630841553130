import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DefaultValueService } from 'src/app/shared/services/default-value.service';
import { ExcelService } from 'src/app/shared/services/excel.service';

import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { objectToArray } from '../../helpers/commun';
import { PERIODS } from '../../shared/settings/_const';
import { chartConfig } from './components/chart-supervision/chart-supervision.component';
import { DialogChartConfComponent } from './dialogs/dialog-chart-conf/dialog-chart-conf.component';
import { DialogConfigSupervisionComponent } from './dialogs/dialog-config-supervision/dialog-config-supervision.component';
import { DialogExcelChargingComponent } from './dialogs/dialog-excel-charging/dialog-excel-charging.component';

export interface vars {
  displayedColumns: string[],
  chartConfig: chartConfig
}
@Component({
  selector: 'app-supervision',
  templateUrl: './supervision.component.html',
  styleUrls: ['./supervision.component.scss']
})
export class SupervisionComponent implements OnInit , OnDestroy{

  constructor(
    private router: ActivatedRoute,
    public dialog: MatDialog,
    private dv: DefaultValueService,
    private dash: DashboardService,
    private excel : ExcelService,

  ) { }


  slug: any;
  @Input() hotel: any;
  title = "Supervision "
  defaultCustomer = 29;
  data = [];
  inherit = false;
  isLoading = false;
  headers = [];
  defaultValue = {};
  displayedFilter = {};
  filters = [];
  filterModel = [];
  stockedData = [];
  displayedColumns = [];
  displayedColumnsData = [];
  chartsConfig = [

  ]

  currentPeriod = "7_DAYS";
  fileName = 'monitoring-export';
  refresh = true;
  periods = PERIODS;
  subscription;
  page_number = 1;
  number_per_page = 10;
  count=10;
  totalpages = 1;
  searchFilter = {
    key:"",
    value:""
  }


  @Output() onDataReady = new EventEmitter();
  @Input() configs = {
    title : "Supervision ",
    detail : true,
    active:true,
    config_filter:[],
    customBtns:[],
    paramsUrl : [
      {key:"id", value:""},
      {key:"customer", value:"{{hotel}}"},
      {key:"template", value:"PROCESS_HISTORY"},
      {key:"init_stats", value:"true"},
      {key:"period", value:"7_DAYS"},
    ]
  }


  ngOnInit() {

    try {
      this.currentPeriod = this.getConfig("period", "7_DAYS");
    } catch (error) {

    }
    this.configs.config_filter && (this.searchFilter.key = this.configs.config_filter[0].key);
    //display spinner
    this.router.params.subscribe(params => {
      this.slug = params.slug;
      this.getDefaultValue(this.slug)
      this.getData()
    });
  }


  //CANCEL ALL REQUESTS ON QUIT
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }


  /**
   *
   * @param param
   * @param defaultVal
   */
  getConfig(param, defaultVal = ""){
    try {
      return this.configs.paramsUrl.find(x=>x.key == param).value
    } catch (error) {
      return defaultVal;
    }
  }


  exportexcel(): void {
    const dialogRef = this.dialog.open(DialogExcelChargingComponent, {
      data : {
        total : this.totalpages,
        current : this.page_number,
        configs : this.configs,
        slug : this.slug,
        period : this.currentPeriod
      }, 
      width:'600px'
    })

    dialogRef.afterClosed().subscribe(res=>{

      this.excel.exportExcel(this.fileName, res);

    })

  }

  /**
   * GET DATA FROM SERVER
   * @param customer
   * @param period
   */
  getData(event=null) {
    this.data = [];
    this.isLoading = true;
    let params = new HttpParams();
    this.subscription && this.subscription.unsubscribe();
    for(let conf of this.configs.paramsUrl){
      let value = conf.value.replace("{{hotel}}",this.slug);
      params = params.set(conf.key, value);
    }

    if(event){
      this.page_number = event['page_number'];
      this.number_per_page = event['number_per_page'];
    }

    if(this.searchFilter.key && this.searchFilter.value){
      params = params.set(this.searchFilter.key,this.searchFilter.value.trim())
    }
    params = params.set('period', this.currentPeriod)
    params = params.set('page_number', this.page_number.toString());
    params = params.set('number_per_page', this.number_per_page.toString());

    this.subscription = this.dash.getDataWithQuery(params).subscribe(res=>{
      this.isLoading = false;
      this.data = objectToArray(res['data']);
      this.count = res['count'];
      this.totalpages = res["num_pages"];
      this.onDataReady.emit(this.count)
      if (this.data.length) {
        this.stockedData = this.data;
        this.getHeaderAttr(this.data[0]);
        this.getDefaultValue();
      }
    })
  }

  /**
   * GET DEFAULT VALUES OF CONFIGURATION
   */
  getDefaultValue(slug = this.slug) {
    this.dv.getDefaultValue(slug, this.getConfig("id")).subscribe((res: object) => {
      if (res) {
        this.defaultValue = res;
        let json = JSON.parse(this.defaultValue['values'])
        this.displayedColumns = this.compareToHeader(json['displayedColumns']);
        this.chartsConfig = json['chartsConfig'] ? json['chartsConfig'] : [];
        this.displayedFilter = json['displayedFilter'] ? json['displayedFilter'] : {};
        // this.buildFilters();
        if (this.inherit) {
          delete this.defaultValue['id'];
        }
      }
    }, err => {
      this.displayedColumns = this.compareToHeader();
      if (err.status == 404 && slug != this.defaultCustomer) {
        this.getDefaultValue(this.defaultCustomer);
        this.inherit = true;
      }
    })
  }



  /**
   * UPDATE DEFAULT VALUES OF CONFIGURATION
   */
  updateDefaultValue() {

    let defaultValues = {
      displayedFilter: this.displayedFilter,
      chartsConfig: this.chartsConfig,
      displayedColumns: this.displayedColumns
    }

    this.defaultValue['values'] = JSON.stringify(defaultValues);

    //BUILD FILTER
    this.buildFilters();
    setTimeout(() => {
      if (typeof this.defaultValue['id'] != "undefined") {
        this.dv.updateDefaultValue({ ...this.defaultValue, hotel: this.slug }).subscribe(() => {
        })
      } else {
        this.defaultValue['name'] = this.getConfig("id");
        this.defaultValue['hotel'] = this.slug;
        this.dv.createDefaultValue(this.defaultValue).subscribe(res => {
          if (res) {
            this.defaultValue['id'] = res['id']
          }
        })
      }
    }, 100)
  }


  /**
   * BUILD DISPLAYED && HEADERS COLUMNS
   * @param obj
   */
  getHeaderAttr(obj) {
    for (let i in obj) {
      if (this.headers.indexOf(i) == -1 && i[0] != '_') {
        this.headers.push(i);
      }
    }
  }


  /**
   *
   * @param array
   */
  compareToHeader(array = []) {
    let fetchedDisplayedColumns = [];
    if (array.length) {
      for (let column of array) {
        if (this.headers.includes(column)) {
          fetchedDisplayedColumns.push(column)
        }
      }
      return fetchedDisplayedColumns;
    } else {
      for (let column of this.headers) {
        fetchedDisplayedColumns.push(column)
      }
    }
    return fetchedDisplayedColumns;
  }



  restOfHeader() {
    let rest = [];
    for (let item of this.headers) {
      if (!this.displayedColumns.includes(item)) {
        rest.push(item)
      }
    }
    return rest;
  }


  /**
   * SORT ARRAY
   * @param headers
   */
  sortByAlph(headers) {
    return headers.sort()
  }

  /**
   *
   * @param array
   * @param value
   */
  inList(array, value) {
    return array.includes(value)
  }


  configChartDisplay(config, index) {
    const dialogRef = this.dialog.open(DialogChartConfComponent, {
      data: { chart: config, headers: this.headers }, width: '600px'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.refresh = false;
        this.chartsConfig[index] = res;
        this.updateDefaultValue();
        setTimeout(() => { this.refresh = true }, 100);
      }
    })
  }

  /**
   * OPEN DIALOG CONFIGURATION
   */
  configDisplay() {
    const dialogRef = this.dialog.open(DialogConfigSupervisionComponent, {
      data: {
        model: this.displayedColumns,
        headers: this.headers,
        displayedColumns: this.displayedColumns,
        chartsConfig: this.chartsConfig,
        displayedFilter: this.displayedFilter,
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.refresh = false;
        this.displayedColumns = res['headers'];
        this.chartsConfig = res['charts'];
        this.updateDefaultValue();
        setTimeout(() => { this.refresh = true }, 100);
      }
    })
  }

  /**
   * BUILD FILTER EVENT
   */
  buildFilters() {
    this.filters = [];
    if (this.data.length) {
      for (let i in this.displayedFilter) {
        if (this.displayedFilter[i]) {
          let values = [];
          let number = !!parseInt(this.data[0][i]);
          for (let val of this.data) {
            if (values.indexOf(val[i]) == -1 && values.indexOf(parseInt(val[i])) == -1) {
              values.push(number ? parseInt(val[i]) : val[i]);
            }
          }
          this.filterModel[i] = "";
          if (!number) {
            this.filters.push({ name: i, values: values.sort() })
          } else {
            this.filters.push({ name: i, values: values.sort(function (a, b) { return a - b; }) })
          }
        }
      }
    }

  }

  /**
   * GET PLURAL OF STRING
   * @param string
   */
  getPlural(string) {
    if (string[string.length - 1] == 'y')
      return string.substr(0, string.length - 1) + 'ies'
    else if (string[string.length - 1] == 's')
      return string;
    else
      return string + 's'
  }

  /**
   * FILTER TABLE EVENT
   * @param event
   * @param name
   */
  filterTable(event) {
    let value = event.target.value;
    if (value != "") {
      this.data = this.stockedData.filter(x => this.buildConditions(x));
    } else {
      this.data = this.stockedData;
    }
  }

  buildConditions(x) {

    var response = true;
    for (let cond in this.filterModel) {
      if (this.filterModel[cond] != "") {
        response = (x[cond]).toString() === this.filterModel[cond] && response;
      }
    }
    return response;
  }
}


