<button class="btn btn-xs btn-success" *ngIf="!actions" (click)="addMaintenanceTrigger()">{{'Add maintenance trigger' | translate }}</button>


<table class="table table-striped">
  <thead *ngIf="header">
    <tr>
      <th>{{'Name' | translate}}</th>
      <th *ngIf="actions">{{'Condition' | translate}}</th>
      <th>{{'State' | translate}}</th>
      <th>{{'Trame' | translate}}</th>
      <th>{{'Export type' | translate}}</th>
      <th>{{'Export to' | translate}}</th>
      <th>{{'Actions' | translate}}</th>
    </tr>
  </thead>
  <tbody cdkDropList (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let obj of trames ; let i=index">



      <tr cdkDrag>
        <td>
          <div *cdkDragPreview class="preview"> {{obj.name}}</div>
          {{obj.name}}
        </td>
        <td *ngIf="actions">{{ obj.conditions_exists }}</td>
        <td>
          <mat-slide-toggle [(ngModel)]="obj.active" (change)="updateState(obj)">
            {{obj.active ? 'Active' : 'Inactive'}}
          </mat-slide-toggle>
        </td>
        <td style="white-space: initial;">{{ obj.tags}}</td>
        <td class="icons">
          <ng-container *ngFor="let type of obj.export_type">
            <img src="/assets/img/icons/svg/gmail.svg" alt="" *ngIf="type == 'mail'">
            <img src="/assets/img/icons/svg/headphones.svg" alt="" *ngIf="type == 'audio'">
            <img src="/assets/img/icons/svg/list.svg" alt="" *ngIf="type == 'text'">
          </ng-container>

        </td>
        <td>{{ obj.groups}}</td>
        <td>
          <button (click)="duplicate(obj)" class="btn btn-success btn-xs" *ngIf="actions">
            <i class="fa fa-clone" aria-hidden="true"></i>
          </button>

          <!--delete btn-->
          <button (click)="edit(obj, i)" class="btn btn-success btn-xs">
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </button>
          <!--delete btn-->
          <button class="btn btn-xs btn-danger" (click)="deleteConfirm(obj, i)" *ngIf="actions">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
