import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { WorkflowService } from 'src/app/shared/services/workflow.service';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-workflow-content',
  templateUrl: './workflow-content.component.html',
  styleUrls: ['./workflow-content.component.scss']
})
export class WorkflowContentComponent implements OnInit {

  /**
   * CONSTRUCTEUR
   */
  constructor(
    private hs: HotelService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private workflowService: WorkflowService,


  ) {

  }


  title = "Workflow: "
  languages: any;
  @Input() slug: any;
  @Input() hotel: any;
  @Input() workflow_id: any;
  workflow: any;
  workflowContent = { user: [], admin: [] };
  defaultLang :any;
  automaticLang = localStorage['hotel_language'];
  _breadCrumbs = []


  ngOnInit() {
    //DISPLAY SPINNER
    this.spinner.show('global');

    this.route.params.subscribe(params => {
      //GET SLUG
      this.slug = params.slug

      //GET ALL LANG
      this.hs.getLanguages().subscribe((data) => {
        this.languages = data;

        //GET HOTEL
        this.hs.getHotel(this.slug).subscribe((data) => {
          this.hotel = data;
          this.spinner.hide('global')
          this.defaultLang = this.languages.find(x => x.id == this.hotel.default_lang).lang_code;
        });

        //GET WORKFLOW DETAIIL
        this.workflowService.getWorkflowDetail(this.workflow_id ? this.workflow_id : params.id).subscribe(res => {
          this.workflow = res;
          this.title = "Workflow: " + this.workflow.WORKFLOW_NAME;
          //bread crumbs : links to display on header
          this._breadCrumbs = [
            { href: '/workflow-list', label: 'Workflows list' }, { label: this.title }
          ]
        })
      })

    });
  }






}
