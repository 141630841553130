import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { ModuleManagerService } from 'src/app/shared/services/module-manager.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modules-manager',
  templateUrl: './modules-manager.component.html',
  styleUrls: ['./modules-manager.component.scss']
})
export class ModulesManagerComponent implements OnInit {

  constructor(
		private hs: HotelService,
		notifierService: NotifierService,
		private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private moduleService : ModuleManagerService,
		private _router: Router,
		public dialog: MatDialog) {
  		this.notifier = notifierService;

    }

  private readonly notifier: NotifierService;


	title = "Module Manager";
	slug: any;
  hotel: any;
  modules : any;

  ngOnInit() {
		this.spinner.show('global');

  		this.router.params.subscribe(params => {
			if (params.slug == "undefined") {
				this._router.navigate(['/hotels'])
			}
			this.slug = params.slug;

			this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
		    this.spinner.hide('global');
      });
      this.moduleService.list("").subscribe(res=>{
        this.modules = res;
      })
		});
  }

  addNew(){
    this._router.navigate(['/module-manager-detail/', this.slug, "new"])
  }

  /**
   *
   * @param module
   * @param index
   */
  deleteModule(module, index){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent,{
      width:'700px',
      data : {
        type : "Module",
        name : module.name
      }
    });

    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.modules.splice(index, 1);
        this.moduleService.delete(module.id).subscribe(()=>{
          this.notifier.notify('success', "Module deleted successfully")
        })
      }
    });
  }

  save(){

  }

}
