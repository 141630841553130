import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { server } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class ModuleWorkerService {



  constructor(private http: HttpClient) { }

  uri = server+'/api/v1/' ;


  /**
   *
   * @param params
   */
  list(path = "") {
    //IF PATH ALLREADY A FULL URL
    return (path.indexOf('http') != -1)?   this.http.get(this.uri+(path.split('v1/')[1])) : this.http.get(this.uri + path);
  }

  /**
   *
   * @param id
   */
  get(path) {
    return this.http.get(this.uri+path);
  }


  /**
   *
   * @param object
   */
  update(path, object) {
    return this.http.patch(this.uri+path, object);
  }

  /**
   *
   * @param object
   */
  create(path, object) {
    return this.http.post(this.uri+path, object);
  }


  /**
   *
   * @param id
   */
  delete(path) {
    return this.http.delete(this.uri+path);
  }
}
