import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'placeholder'
})
export class PlaceholderPipe implements PipeTransform {
  transform(items: any, ..._args: any[]): any {
    if (!items || items=='""') {
      return 'Data Non Disponible';
    }
    return items.toString();
  }
}
