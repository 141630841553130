import { NgxSpinnerService } from 'ngx-spinner';
import { GroupService } from 'src/app/shared/services/group.service';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  constructor(

    private groupService: GroupService,
    private spinner: NgxSpinnerService,
  ) { }

  @Input() groups;
  @Input() slug;
  @Input() users;

  ngOnInit() {
  }


  /**
   *
   * @param data
   * @param index
   */
  createOrUpdate(data, index) {
     if (!data['id']) {
      this.groupService.create({ ...data, hotel: this.slug }).subscribe(res => {
       });
    } else {
      this.groupService.update(data).subscribe(res => {

      });
    }
  }

  addField() {
    let group = {
      name: '',
      users: ''
    }
    this.groups.push(group);
  }

  saveGroups() {
    let index = 0;
    for (let group of this.groups) {
      index++;
      this.createOrUpdate(group, index);

    }
  }
  /**
   * DELETE VALUE BY INDEX
   * @param i
   */
  deleteField(group, i) {
    this.groups.splice(i, 1);
    if(group.id){
      this.groupService.delete(group.id).subscribe(res=>{
      })
    }
  }
}
