<h2 mat-dialog-title>{{(subcategory.id ? "Edit Subcategory" : "Add new Subcategory")| translate}} 
  <app-select-translate [hotel]="hotel" [languages]="languages"></app-select-translate>

</h2>
<mat-dialog-content class="mat-typography">

<app-form-builder
  [attrs]="attrs"
  [hotel]="hotel"
  [model]="subcategory"
  [superFields]="superFields"
  [type]="'subcategory'"
  [languages]="languages"
  [errors]="errors">
</app-form-builder>

</mat-dialog-content>
<mat-dialog-actions align="end">
<button class="btn btn-warning" mat-dialog-close>Cancel</button>
<button (click)="createOrUpdate(subcategory, true)"  class="btn btn-success" *ngIf="!subcategory.id">{{'save and add another' | translate}}</button>
<button (click)="createOrUpdate(subcategory)"  class="btn btn-success">{{"Save" | translate}} </button>
</mat-dialog-actions>