import { PERIODS } from 'src/app/shared/settings/_const';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stat-param',
  templateUrl: './stat-param.component.html',
  styleUrls: ['./stat-param.component.scss']
})
export class StatParamComponent implements OnInit {

  constructor() { }

  periods = PERIODS

  @Input() type:any;
  @Input() period:any;
  @Input() template :any;
  @Output()  actionEvent = new EventEmitter();
  @Input() change_type = true;


  ngOnInit(){
    this.type=this.template.configs.chart.type;
    this.period=this.template.default_period;
  }

  actions(action){
    this.actionEvent.emit({period : this.period, type : this.type, template : this.template, action})
  }

}
