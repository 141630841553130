import { _commun, _multiple } from './_commun';

export const _select = {
    ..._commun,
    ..._multiple,
    "type": "select",
    "type_name":"Select field",
    "multiple":false,
    "constant":null,
}


export const _tags = {
    ..._commun,
    ..._multiple,
    "type": "tags",
    "type_name":"Tags field",
    "multiple":true,
    "constant":null,
}