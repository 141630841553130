 
<h2 class="mat-dialog-title with-trans">
  {{ "Notification log" | translate }}
</h2>

<mat-dialog-content class="mat-typography">
  


<table class="table table-bordered">
<tr>
  <th>{{ "Trame" | translate }}</th>
  <!-- <th *appHasRole="'super-user'">Conditions</th> -->
  <th>{{"Trigger Type" | translate }}</th>
  <th>{{ "Notification" | translate }}</th>
  <th>{{ "Read" | translate }}</th>
  <th>{{ "Hardware" | translate }}</th>
  <th>{{ "Created" | translate }}</th>
</tr>
<tr *ngFor="let log of logs">
  <td>{{log.trame_text}}</td>
  <!-- <td *appHasRole="'super-user'"><debug *ngIf="log.condition" [expanded]=true [string]="log.condition" [convert]="true"></debug> </td> -->
  <td>{{log.trigger_type}}</td>
  <td>{{log.notification}}</td>
  <td>{{log.is_read}}</td>
  <td>{{log.hardware}}</td>
  <td>{{log.created_at | date : 'short'}}</td>
 
</tr>
</table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-default">{{ "Cancel" | translate }}</button>
</mat-dialog-actions>