import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class TransportService {

  constructor(private http: HttpClient) { }
  	uri = URI;
  	getTransports(id){
		return this.http.get(`${this.uri}`+"transports/?hotel="+id);
	}

	updateTransport(id, transport){
		return this.http.put(`${this.uri}`+"transports/"+id+"/", transport);
	}

	deletTransport(id){
		return this.http.delete(`${this.uri}`+"transports/"+id+"/");
	}

	createTransport(transport){
		return this.http.post(`${this.uri}`+"transports/", transport);
	}

	createOrUpdateTransport(transport){
		if(transport.id){
			return this.createTransport(transport);
		}else {
			return this.updateTransport(transport.id, transport);
		}
	}
}
