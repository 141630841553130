import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {

  constructor() { }

  @Input() percent = 90;
  @Input() text = '560 widgets' ;
  @Input() color = "#2196F3";
  @Input() custom : any;
  @Input() displayValue = true;
  @Input() size : ''
  stroke :any;
  ngOnInit() {
    this.stroke = this.percent + ' '+ (100-this.percent);
  }

}
