import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { BASE_URL } from 'src/app/shared/settings/global';

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stat-pms',
  templateUrl: './stat-pms.component.html',
  styleUrls: ['./stat-pms.component.scss']
})
export class StatPmsComponent implements OnInit {

  constructor(
    private dash: DashboardService,
    private route: ActivatedRoute,

  ) { }

  pms :any;
  slug : any;
  @Input() data:any;
  @Input() template:any;
  BASE_URL = BASE_URL;
  ngOnInit() {
    this.pms = this.data;
     this.route.params.subscribe(params => {
      this.slug = params.slug;
     })

     setInterval(()=>{
      this.getPMSdata();
     },60*1000)
  }



  getPMSdata() {
    this.dash.getPMSstatus(this.slug).subscribe(res => {
      this.pms = res;
    });
  }

}
