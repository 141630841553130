<div class="white-box">
    <div class="row">
        <div class="col">
            <h4>{{tile?.title ? tile?.title : template?.name}}</h4>
        </div>
        <div class="col">
            <app-stat-param [template]="template" (actionEvent)="actions($event)"></app-stat-param>
        </div>
    </div>
    <div [chart]="chart" style="height:393px" *ngIf="dataSeries"></div>
</div>