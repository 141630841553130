import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-notif-setting',
  templateUrl: './dialog-notif-setting.component.html',
  styleUrls: ['./dialog-notif-setting.component.scss']
})
export class DialogNotifSettingComponent implements OnInit {

  constructor() { }

  sound =( localStorage.getItem('notif_sound') == 'true' );
  notif_pile_timing =  localStorage.getItem('notif_pile_timing');

  ngOnInit() {
  }

  soundOffOn(event){
    localStorage.setItem('notif_sound',(event.checked).toString())
  }

  updatePileTiming(event){
    localStorage.setItem('notif_pile_timing', event.target.value)
  }

}
