<!-- Page Content -->
<div class="container-fluid">
  <app-fixed-title [title]='title' [hotel]='hotel'></app-fixed-title>
  <div class="row" *ngIf="hotel">

    <div class="col-md-12">
      <div class="white-box content">

        <mat-tab-group>
          <!-- success -->
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fa fa-check-circle" aria-hidden="true"></i>
              {{'Success Transactions list' | translate}} <strong> ({{count.success}})</strong>
            </ng-template>

            <payment-table-detail [hotel]="hotel" [slug]="slug" type="success" (count)="updateCount($event, 'success')"></payment-table-detail>
          </mat-tab>
          <!-- success -->

          <!-- failed -->
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fa fa-times-circle" aria-hidden="true"></i>
              {{'Failed Transactions list' | translate}} <strong> ({{count.failed}})</strong>
            </ng-template>

            <payment-table-detail [hotel]="hotel" [slug]="slug" [failed]="true" type="failed" (count)="updateCount($event, 'failed')">
            </payment-table-detail>
          </mat-tab>
          <!-- failed -->


          <!-- warning -->
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              {{'Warning Transactions list' | translate}} <strong> ({{count.warning}})</strong>
            </ng-template>
            <payment-table-detail [hotel]="hotel" [slug]="slug" [failed]="true" type="warning" (count)="updateCount($event, 'warning')">
            </payment-table-detail>
          </mat-tab>
          <!-- warning -->


        </mat-tab-group>
      </div>
    </div>
  </div>
</div>