<!-- whitebox-->
<div class="white-box content">
    <span (click)="openDialogInformations()" class="open-modal">
        <i class="fa fa-plus-circle" aria-hidden="true"></i>
    </span>
    
    <h2 class="title">{{ 'More informations' | translate }}</h2>
    <div class="box-scroll h-200">
        <table class="info" *ngIf="informations?.length">
            <tr *ngFor="let info of informations; let i = index ">

                <td class="icon">
                    <img src="{{MEDIA+info.icon}}"  alt="">
                </td>
                 
                <td>{{info.value}}</td>

                <td class="text-right">
                    <button class="btn dis-onhover edit" (click)="openDialogInformations(info)"><i
                            class="fa fa-pencil" aria-hidden="true"></i></button>
                    <button class="btn dis-onhover delete"
                        (click)="deleteConfirm(info, info.value,'Information',i)"><i class="fa fa-trash-o"
                            aria-hidden="true"></i></button>
                </td>
            </tr>
        </table>
        <p *ngIf="!informations?.length" (click)="openDialogInformations()" class="link-first">
            {{ 'Be the first to add informations' | translate }}</p>
    </div>
</div>


<!-- whitebox-->