<ng-container *ngIf="hotel">

  <ng-container *ngFor="let lang of hotel.languages">
    <div class="form-group form-translate-{{lang.lang_code}} translated" *ngIf="lang.lang_code == currentLang">

      <!--label-->
      <label *ngIf="label">{{label | translate}} ({{lang.lang_code}}) </label>
      <div class="input-container">
        <span *appHasRole="'super-user'">[{{indication}}]</span>
        <span>{{lang.lang_code}}</span>
        <span class="html" (click)="html = !html">HTML</span>
        <input *ngIf="!html" type="text" class="form-control" [(ngModel)]="model[lang.lang_code]" (blur)="updateAction($event)">

      </div>
      <app-html-editor *ngIf="html" [model]="model" [attr]="lang.lang_code" [mediaManager]="false" ></app-html-editor>

    </div>
  </ng-container>
</ng-container>

