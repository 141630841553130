<app-build-categories
[categoryId]="2"
[title]="title"
[category_type]="category_type"
[id]="id"
[filteredCategories]="filteredCategories"
[label]="label"
[subcategoryIsOption]="false"
[superFields]="superFields"
[presentation]="presentation"
></app-build-categories>