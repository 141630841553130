<div class="svg-item {{size}}">
  <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
    <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
    <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="1"></circle>
    <circle class="donut-segment donut-segment-2" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="1" [attr.stroke-dasharray]="stroke" stroke-dashoffset="25" [attr.stroke]="color"></circle>
    <g class="donut-text donut-text-1" [attr.fill]="color">

      <text y="50%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-percent" *ngIf="displayValue" [innerHTML]="custom ? custom : percent + '%'"></tspan>   
      </text>
      <text y="60%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-data">{{text}}</tspan>   
      </text>
    </g>
  </svg>
</div>

