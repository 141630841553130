<div class="white-box">

  <h4 class="title">
    {{tile?.title ? tile?.title : template?.name}}
    <ng-container *ngIf="template.can_change_period">
      {{getPeriod()}}
    </ng-container>
  </h4>
  <button class="btn  btn-xs btn-default btn-refresh" (click)="actions('reload')"> 
    <i class="fa fa-refresh" aria-hidden="true"></i>
  </button>
  <app-donut-chart 
    class="size-{{tile.cols}}"
    [color]="template.color ? template?.color : '#9c27b0b3'"
    [percent]="percent"
    [text]="template?.subtitle" [size]="tile.cols == 4? 'bigger' : ''">
  </app-donut-chart>

  

  <span class="text-chart" *ngIf="template?.configs?.special_text">
    <span class="cadre green"></span> <strong>{{data[template?.configs?.x1] }} {{template?.configs?.labels[0]}}</strong> <br>
    <span class="cadre grey"></span> <strong>{{data[template?.configs?.x2]}} {{template?.configs?.labels[1]}}</strong>
  </span>


</div>

