import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-supervision-chart-config',
  templateUrl: './supervision-chart-config.component.html',
  styleUrls: ['./supervision-chart-config.component.scss']
})
export class SupervisionChartConfigComponent implements OnInit {

  constructor(
    public dialog: MatDialog,

  ) { }
  @Input() chartsConfig: any;
  @Input() headers: any;
  config = {
    axeX: '_Date_modification',
    xtype: 'datetime',
    axeY: '',
    type: 'spline',
    series: 'Device',
    classname:'col-md-12'
  }
  ngOnInit() {

  }

  /**
   * DELETE CHART
   * @param index
   */
  deleteChart(index){
     const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
       data: { name : this.chartsConfig[index].name, type : 'Chart' }
     });
     dialogRef.afterClosed().subscribe(result => {
       if(result){
        this.chartsConfig.splice(index, 1)
       }
     });
   }

   /**
    *
    * @param val
    * @param date
    */
  isDate(val, date = true) {
    if (date) {
      return val.toLowerCase().indexOf('date') != -1;
    } else {
      return val.toLowerCase().indexOf('date') == -1;
    }
  }

  addChart(){
    this.chartsConfig.push({...this.config})
  }

}
