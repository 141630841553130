<div class="row">
  <!-- field -->
  <div class="form-group  col-md-4">
    <label>Module Name</label>
    <input type="text" class="form-control" [(ngModel)]="module.name">
  </div>
  <!-- field -->


  <!-- field -->
  <!-- <div class="form-group  col-md-4">
    <label>Menu Name</label>
    <input type="text" class="form-control" [(ngModel)]="module.configs.menu_name">
  </div> -->
  <!-- field -->

  <!-- field -->
  <div class="form-group  col-md-4">
    <label>Icon</label>
    <input type="text" class="form-control" [(ngModel)]="module.configs.icon">
  </div>
  <!-- field -->


  <div class="form-group col-md-4">
    <label>Display form sections</label>
    <select [(ngModel)]="module.configs.dispay_form_sections" class="form-control">
      <option value="tabs">Tabs</option>
      <option value="steps">Steps</option>
    </select>
  </div>

  <!-- field -->
  <div class="form-group col-md-4">
    <label>Path to data </label>
    <input type="text" class="form-control" [(ngModel)]="module.configs.access_to_data">
  </div>
  <!-- field -->


  <div class="col-md-12"></div>
  <div class="form-group col-md-3">
    <mat-checkbox [(ngModel)]="module.configs.can_delete">Deletable items</mat-checkbox>
  </div>


  <!-- field -->
  <div class="form-group col-md-3">
    <mat-checkbox [(ngModel)]="module.configs.pagination_serverside">Pagination server side</mat-checkbox>
  </div>
  <!-- field -->

  <!-- field -->
  <div class="form-group col-md-3">
    <mat-checkbox [(ngModel)]="module.configs.one_row">Module for one row</mat-checkbox>
  </div>
  <!-- field -->
  <!-- field -->
  <div class="form-group col-md-3">
    <mat-checkbox [(ngModel)]="module.visible">Visible</mat-checkbox>
  </div>
  <!-- field -->

  <!-- 
  <div class="form-group col">
    <mat-checkbox [(ngModel)]="module.configs.active">Active</mat-checkbox>
  </div> -->
  <!-- 
  <div class="form-group col">
    <mat-checkbox [(ngModel)]="module.configs.open_in_dialog">Edit on dialog</mat-checkbox>
  </div> -->


  <!-- 
  <div class="form-group col">
    <mat-checkbox [(ngModel)]="module.configs.pagination_clientside">Pagination client side</mat-checkbox>
  </div> -->
</div>