<div class="container-fluid">
  <app-fixed-title [title]='title' [hotel]='hotel' [customAction]="true" (onCustomAction)="addNew()"></app-fixed-title>

  <div class="white-box">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Module name</th>
          <th>Active</th>
          <th>Menu name</th>
          <th>Visible on settigns</th>
          <th>Endpoint</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let module of modules; let i = index">
          <td>{{module.name}}</td>
          <td>{{module.configs?.active}}</td>
          <td>{{module.configs?.menu_name}}</td>
          <td>{{module.visible}}</td>

          <td>{{module.endpoint}}</td>
          <td>
            <button class="btn btn-xs btn-info"><i class="fa fa-clone" aria-hidden="true"></i> Clone</button>
            <button class="btn btn-xs btn-success " [routerLink]="['/module-manager-detail',slug, module.id]"><i class="fa fa-pencil" aria-hidden="true"></i> Edit</button>
            <button class="btn btn-xs btn-danger" (click)="deleteModule(module, i)"><i class="fa fa-trash" aria-hidden="true"></i> Delete</button>
            
        </td>
        </tr>
      </tbody>
    </table>
    
  </div>
