<div class="white-box">
  <div class="row">
    <div class="col">
        <h4>{{tile?.title ? tile?.title : template?.name}}</h4>
    </div>
    <div class="col">
        <app-stat-param [template]="template" (actionEvent)="actions($event)" [change_type]="false"></app-stat-param>
    </div>
</div>
<app-chart-supervision [data]="convertedData" [chartConfig]="template?.configs?.chart">
</app-chart-supervision>
</div>