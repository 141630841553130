import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { DeviceService } from 'src/app/shared/services/device.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { SignageService } from 'src/app/shared/services/signage.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DialogDeviceComponent } from '../modals/dialog-device/dialog-device.component';

@Component({
  selector: 'app-digital-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DigitalsignageDevicesComponent implements OnInit {
  /**
    * CONSTRUCTEUR
    */
  constructor(
    private signage: SignageService,
    private device: DeviceService,
    private hs: HotelService,
    notifierService: NotifierService,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _router: Router,
    public dialog: MatDialog,
    private translate: TranslateService,
    private excel : ExcelService
  ) {
    this.notifier = notifierService;
  }

  /**
   * VARS
   */
  private readonly notifier: NotifierService;

  view: string = 'month';
  title = "Devices List"
  devices: any;
  list = [];
  slug: any;
  hotel: any;
  date = new Date();
  year = this.date.getFullYear();
  month = this.date.getMonth() + 1;
  user_id = localStorage.getItem('user_id');
  languages: any;
  eventsList: any;
  devicesWithEvents: any;
  filteredDevices: any;



  /**
   *
   * @param event
   */
  viewDateChange(event) {

  }

  ngOnInit() {
    this.spinner.show('global');

    this.hs.getLanguages().subscribe((data) => {
      this.languages = data
    });

    this.router.params.subscribe(params => {

      if (params.slug == "undefined") {
        this._router.navigate(['/hotels'])
      }
      this.slug = params.slug;


      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
      });

      this.signage.getDevicesWithEvents(this.slug, this.year, this.month).subscribe(res => {
        this.spinner.hide('global');
        this.devicesWithEvents = res['devices'];
        this.filteredDevices = this.devicesWithEvents;
      });

    });






  }

  exportexcel(): void {
    this.excel.exportExcel('export-devices', this.devicesWithEvents.map(x=>x.device));
  }

  /**
   * DIALOG ADD OR EDIT DEVICE
   * @param device
   */
  editOrAddDevice(device = null) {
    let data = {
      devices: this.devicesWithEvents,
      device: device ? device : {
        ID_CUSTOMER: this.hotel.id,
        HIDDEN: false,
        FOR_WORKFLOW: false
      }
    }
    const dialogRef = this.dialog.open(DialogDeviceComponent, { data, width: "600px" });
  }


  /**
   *
   * @param device_id
   */
  getLengthEvents(device_id) {
    if (typeof (this.eventsList[device_id]) != "undefined") {
      let device = this.eventsList[device_id]['events'];
      return device.directs.length + device.recurrences.length + device.schedules.length;
    } else {
      return "0"
    }
  }


  /**
   *
   * @param device_id
   */
  getActiveEvent(i) {

    //if device has events
    if (this.devicesWithEvents) {
      let device = this.devicesWithEvents[i]['events'];
      let active = device.directs.find(obj => obj.is_active == true)
        || device.recurrences.find(obj => obj.is_active == true)
        || device.schedules.find(obj => obj.is_active == true);
      return active;
    } else {
      return false;
    }

  }

  /**
   * SEARCH IN DEVICES
   * @param searchValue
   */
  searchDevice(searchValue: string) {
    if (!searchValue) {
      this.filteredDevices = Object.assign([], this.devicesWithEvents);
    } else {
      this.filteredDevices = Object.assign([], this.devicesWithEvents).filter(
        device => (device.device.NAME.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
      )
    }
  }


  /**
   * CONFIRM DELETE DEVICE
   * @param id
   */
  deleteDevice(device){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name:device.NAME, type:"Device" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'delete') {
        this.spinner.show('global');
        this.signage.deleteDevice(device.id).subscribe(res => {
          let index = this.devicesWithEvents.findIndex(x=>x.device.id == device.id)
          this.devicesWithEvents.splice(index, 1);
          this.translate.get('Transport successfully deleted').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
          this.spinner.hide('global');

        },
        err => {
          this.spinner.hide('global');
        })
      }
    });

  }

}
