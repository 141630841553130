import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ItemService } from 'src/app/shared/services/item.service';
import { SubcategoryService } from 'src/app/shared/services/subcategory.service';
import { EXTRA_OBJECT } from 'src/app/shared/settings/_const';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-item',
  templateUrl: './dialog-item.component.html',
  styleUrls: ['./dialog-item.component.scss']
})
export class DialogItemComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public itemModel: ItemService,
    public subcat: SubcategoryService,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    public dialog : MatDialog
  ) {
    this.notifier = notifierService;
  }
  private readonly notifier: NotifierService;

  item          : any;
  hotel         : any;
  attrs         : any;
  category_type : any;
  superFields   : any;
  extraObject   : any;
  languages     : any;
  STOCK_TYPE = "";
  EXTRA_OBJECT = EXTRA_OBJECT;
  errors = null;
  parent : any;
  empty : any;


  ngOnInit() {
    this.STOCK_TYPE = (this.category_type != "UpsellService") ? this.category_type : "";

    this.languages = this.data.languages;
    this.item = this.data.item;
    this.empty = {...this.item};
    this.hotel = this.data.hotel;
    this.attrs = this.data.attrs;
    this.category_type = this.data.category_type;
    this.superFields =this.data.superFields.super_item_attr;
    this.parent = this.data.parent;

  }


  /**
   * UPDATE ITEM
   * @param item
   * @param fb : FORM DATA
   * @param extraObject
   */
  updateItem(item){

      this.spinner.show('global');
      this.itemModel.updateItem(item).subscribe(
        res => {
          this.spinner.hide('global');
          item = res;
          //close modal
          this.dialogRef.close(res);
        },
        err => {
          this.spinner.hide('global');
          this.translate.get('An error has occurred').subscribe(
            value => {
              this.notifier.notify('error', value);
            }
          );
          this.errors = err.error;
        }
      )

  }

  /**
   * CREATE ITEM
   * @param item
   * @param fb
   * @param extraObject
   */
  addItem(item, addNew= false){
    if (item) {

      this.spinner.show('global');
      this.itemModel.addItem(item).subscribe(
        res => {
          this.spinner.hide('global');
          //add item to list
          item = res;
          this.parent.items.unshift(item);

          //close modal
          this.dialogRef.close(res);

          //open another
          if(addNew){
            this.dialog.open(DialogItemComponent, {data:{...this.data, item: this.empty}})
          }
        },
        err => {
          this.spinner.hide('global');
          this.translate.get('An error has occurred').subscribe(
            value => {
              this.notifier.notify('error', value);
            }
          );
          this.errors = err.error;
        }
      )

    }
  }


  /**
   * CREATE OR UPDATE ITEM DECISION
   * @param item
   * @param fb
   */
  createOrUpdate(item, addNew=false){
     if(item.id){
       this.updateItem(item);
     }else {
       this.addItem(item,addNew);
     }
  }



}
