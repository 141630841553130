import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { LANGUAGES } from 'src/app/shared/settings/_const';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-languages',
  templateUrl: './dialog-languages.component.html',
  styleUrls: ['./dialog-languages.component.scss']
})
export class DialogLanguagesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogLanguagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private hs : HotelService,
    private translate: TranslateService,

  ) {
    this.notifier = notifierService;
  }

  private readonly notifier: NotifierService;

  hotel: any;
  languages: any;
  langselect ;
  selected : any[];
  errors : any;
  langs = LANGUAGES;

  ngOnInit() {
    this.languages = this.data.languages;
    this.hotel = this.data.hotel;
    this.langselect = [];
    this.hotel.languages_param = this.hotel.languages_param.map(x=>x.toString())
    for (let i in this.languages) {
      this.langselect.push(
        {
          id: this.languages[i]['id'].toString(),
          text: this.languages[i]['lang_name']
        }
      )

    }
  }

    /**
   *
   * @param text_hotel
   * @param text_hopital
   */
     getText(text_hotel, text_hopital){
      if(this.hotel.customer_type == 'HOTEL'){
        return text_hotel;
      }else {
        return text_hopital;
      }
    }
    
  /**
   *
   * @param id
   */
  isSelected(id) {
    return (this.hotel.languages_param).indexOf(x => x.id == id) !== -1;
  }

  /**
   *
   */
  onChange($event){
    if(this.hotel.languages_param.indexOf(this.hotel.default_lang.toString()) == -1){
      this.errors = {"Default language": ["The lange selected in Default langage must be also selected in Active langage."]}
    }else {
      this.errors = null;
    }
  }

  /**
   *
   * @param hotel
   */
  updateOrCreate() {
    console.log('update action')
    this.spinner.show('global');

    this.hs.updateHotelLang(this.hotel.id, this.hotel).subscribe(
      res => {
      this.hotel.languages_param =  res.languages_param ;
      this.hotel.default_lang =  res.default_lang ;
        this.translate.get('Hotel languages successfully updated').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
        window.location.reload();

      this.spinner.hide('global');
      this.dialogRef.close(true);

    },
    err=> {
      this.errors = err.error;
      console.log(err)
    })
  }

}
