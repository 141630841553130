<div class="row">
  <div class="col">
    <!-- filters -->
    <select [(ngModel)]="defaultPeriod" class="form-control inline">
      <option disabled>{{'Select a period' | translate}}</option>
      <option *ngFor="let period of periods" value="{{period.value}}">{{period.name | lowercase | translate}}</option>
    </select>

    <select [(ngModel)]="filter.operator" class="form-control inline">
      <option disabled>{{'Amount' | translate}}</option>
      <option value="gte">{{'Greater than or equal' | translate}}</option>
      <option value="lte">{{'Less than or equal' | translate}}</option>
    </select>
    {{'To' | translate}} :
    <input type="text" [(ngModel)]="filter.amount" placeholder="Amount" class="form-control inline">
    <button class="btn btn-success" (click)="init()">{{'Search' | translate}}</button>
    <!-- filters -->
  </div>
  <!-- search -->
  
  <div class="col-4 align-end">
    <button class="btn btn-success" (click)="exportexcel()"><i class="fa fa-cloud-download" aria-hidden="true"></i>         Excel Export</button>
    
    <input class="form-control" [(ngModel)]="searchValue" placeholder="{{'Search' | translate}}" type="search" (input)="applyFilter()">
  </div>
  <!-- search -->

</div>

<div class="box-scroll minth bold-scroll">
  <div class="table-content">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="transaction_date" matSortDirection="desc"
      (matSortChange)="sortData($event)">



      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Reference' | translate}} </th>
        <td mat-cell *matCellDef="let trans" [ngClass]="getClassTd(trans.reference)"> {{trans.reference}} </td>
      </ng-container>


      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Name' | translate}} </th>
        <td mat-cell *matCellDef="let trans" [ngClass]="getClassTd(trans.first_name+trans.last_name)">
          {{trans.first_name | titlecase}} {{trans.last_name | titlecase}}</td>
      </ng-container>


      <ng-container matColumnDef="invoice_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Invoice' | translate}} </th>
        <td mat-cell *matCellDef="let trans" [ngClass]="getClassTd(trans.invoice_number)"> {{trans.invoice_number}}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Email' | translate}} </th>
        <td mat-cell *matCellDef="let trans" [ngClass]="getClassTd(trans.email)"> {{trans.email}} </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Amount' | translate}} </th>
        <td mat-cell *matCellDef="let trans" [ngClass]="getClassTd(trans.amount)"> {{trans.amount}} </td>
      </ng-container>

      <ng-container matColumnDef="reservation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{getText('Reservation', 'Stay ID') | translate}} ID </th>
        <td mat-cell *matCellDef="let trans" [ngClass]="getClassTd(trans.reservation_or_appointment_id)">
          {{trans.reservation_or_appointment_id}} </td>
      </ng-container>


      <ng-container matColumnDef="transaction_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Transaction Date' | translate}}</th>
        <td mat-cell *matCellDef="let trans" [ngClass]="getClassTd(trans.transaction_date | date:'medium')">
          {{trans.transaction_date | date:'d MMM y, H:mm:ss '}} </td>
      </ng-container>


      <ng-container matColumnDef="transaction_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Transaction ID' | translate}}</th>
        <td mat-cell *matCellDef="let trans" [ngClass]="getClassTd(trans.transaction_id)"> {{trans.transaction_id}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20,30,40,50,100]" showFirstLastButtons></mat-paginator>


</div>
<br>
<br>

<div class="chart-content" *ngIf="transactions?.length">
  <h2 class="text-center title">{{'Transactions chart' | translate}} TOTAL {{type | titlecase}}</h2>
  <select [(ngModel)]="chartConfig['type']" class="btn actions btn-default" (change)="rebuildChart()">
    <option value="column">{{'Column' | translate}}</option>
    <option value="area">{{'Area' | translate}}</option>
    <option value="spline">{{'Spline' | translate}}</option>
  </select>

  <app-chart-supervision [dataSeries]="convertedData" [chartConfig]="chartConfig" ></app-chart-supervision>
</div>