<div class="row">
    <div class="col-lg-10">
        <!--row-->
        <div class="row">
            <!--ligne-->
            <div class="col-md-3 text-right">
                <label for="" class="label">{{"Guest had Room's in theses categories" | translate}}
                </label>
            </div>

           
            <div class="col-md-9 form-group">
                <mat-select class="form-control" [multiple]="true" *ngIf="offer"
                    [(ngModel)]="offer['CONDITIONS']['categories']">
                    <mat-option *ngFor="let room of rooms" [value]="room.id">
                        {{room.text}}
                    </mat-option>
                </mat-select>




            </div>
            <!--ligne-->
        </div>
        <!--row-->
        <div class="row">
            <!--ligne-->
            <div class="col-md-3 text-right">
                <label for="" class="label"> {{"Guests had Room's with these features" | translate}}
                </label>
            </div>
            <div class="col-md-9 form-group">
                <mat-select class="form-control" *ngIf="offer && rooms" [multiple]="true"
                    [(ngModel)]="offer['CONDITIONS']['features']">
                    <mat-option *ngFor="let room of rooms" [value]="room.id">
                        {{room.text}}
                    </mat-option>
                </mat-select>


            </div>
            <!--ligne-->
        </div>
        <!--row-->

        <!--row-->
        <div class="row">

            <!--ligne-->
            <div class="col-md-3 text-right">
                <label for="" class="label"> {{"Available Categories" | translate}}</label>
            </div>
            <div class="col form-group">
                <select class="form-control"
                    [(ngModel)]="offer['CONDITIONS']['availability']['categories']['category']">
                    <option *ngFor="let room of rooms" value="{{room.id}}">{{room.text}}</option>
                </select>
            </div>
            <div class="col form-group">
                <select class="form-control"
                    [(ngModel)]="offer['CONDITIONS']['availability']['categories']['comparison']">
                    <option value="" disabled>{{'Please select a' | translate}}</option>
                    <option value=" == ">{{ 'Equal to' | translate }}</option>
                    <option value=" != ">{{'Different to' | translate }}</option>
                    <option value=" >= " selected="selected">{{'Superior or equal to'| translate}}</option>
                    <option value=" > ">{{'Superior to' | translate}}</option>
                    <option value=" <= ">{{'Inferior or equal to' | translate}}</option>
                    <option value=" < ">{{'Inferior to' | translate}}</option>
                </select>

            </div>
            <div class="col form-group">
                <input type="text" *ngIf="offer" class="form-control"
                    [(ngModel)]="offer['CONDITIONS']['availability']['categories']['total']">
            </div>
            <!--ligne-->

        </div>
        <!--row-->

        <!--row-->
        <div class="row">

            <!--ligne-->
            <div class="col-md-3 text-right">
                <label for="" class="label"> {{'Number of rooms' | translate}} </label>
            </div>
            <div class="col form-group">
                <input type="number" [(ngModel)]="offer['CONDITIONS']['number_of_rooms']" class="form-control">
            </div>

            <!--ligne-->

        </div>
        <!--row-->

        <!--row-->
        <div class="row">
            <!-- ligne -->
            <div class="col-md-3 text-right">
                <label for="" class="label"> {{'Available Features' | translate }}</label>
            </div>

            <div class="col form-group">
                <select class="form-control"
                    [(ngModel)]="offer['CONDITIONS']['availability']['features']['comparison']">
                    <option value="" disabled>{{'Please select a' | translate}}</option>
                    <option value=" == ">{{'Equal to' | translate}}</option>
                    <option value=" != ">{{'Different to'| translate}}</option>
                    <option value=" >= " selected="selected">{{'Superior or equal to'| translate}}</option>
                    <option value=" > ">{{'Superior to'| translate}}</option>
                    <option value=" <= ">{{'Inferior or equal to'| translate}}</option>
                    <option value=" < ">{{'Inferior to'| translate}}</option>
                </select>
            </div>
            <div class="col form-group">
                <input type="text" class="form-control"
                    [(ngModel)]="offer['CONDITIONS']['availability']['features']['total']">
            </div>
            <!--ligne-->
        </div>
        <!--row-->

        <!--row-->
        <div class="row">
            <!-- ligne -->
            <div class="col-md-3 text-right">
                <label for="" class="label">{{'Price Room type' | translate}}</label>
            </div>
            <div class="col-md-9 form-group">
                <mat-select class="form-control" *ngIf="offer && priceType" [multiple]="true"
                    [(ngModel)]="offer['CONDITIONS']['price_room_type']">
                    <mat-option *ngFor="let price of priceType" [value]="price.id">
                        {{price.text}}
                    </mat-option>
                </mat-select>

            </div>
            <!--ligne-->
        </div>
        <!--row-->

        <!--row-->
        <div class="row">
            <!-- ligne -->
            <div class="col-md-3 text-right">
                <label for="" class="label"> {{'Total Amount' | translate}}</label>
            </div>

            <div class="col form-group">
                <select class="form-control" [(ngModel)]="offer['CONDITIONS']['amount']['comparison']">
                    <option value="" disabled>{{'Please select a' | translate}}</option>
                    <option value=" == ">{{'Equal to' | translate}}</option>
                    <option value=" != ">{{'Different to' | translate}}</option>
                    <option value=" >= " selected="selected">{{'Superior or equal to' | translate}}</option>
                    <option value=" > ">{{'Superior to' | translate}}</option>
                    <option value=" <= ">{{'Inferior or equal to' | translate}}</option>
                    <option value=" < ">{{'Inferior to' | translate}}</option>
                </select>
            </div>
            <div class="col form-group">
                <input type="text" class="form-control" [(ngModel)]="offer['CONDITIONS']['amount']['total']">
            </div>
            <!--ligne-->
        </div>
        <!--row-->

        <!--row-->
        <div class="row">
            <!-- ligne -->
            <div class="col-md-3 text-right">
                <label for="" class="label"> {{'Number of Guests'| translate}}</label>
            </div>

            <div class="col form-group" *ngIf="offer">
                <select class="form-control" [(ngModel)]="offer['CONDITIONS']['guests']['comparison']">
                    <option value="" disabled>{{'Please select a'| translate}}</option>
                    <option value=" == ">{{'Equal to' | translate}}</option>
                    <option value=" != ">{{'Different to' | translate}}</option>
                    <option value=" >= " selected="selected">{{'Superior or equal to' | translate}}</option>
                    <option value=" > ">{{'Superior to' | translate}}</option>
                    <option value=" <= ">{{'Inferior or equal to' | translate}}</option>
                    <option value=" < ">{{'Inferior to' | translate}}</option>
                </select>
            </div>
            <div class="col form-group">
                <input type="text" class="form-control" [(ngModel)]="offer['CONDITIONS']['guests']['total']">
            </div>
            <!--ligne-->
        </div>
        <!--row-->

        <!--row-->
        <div class="row">
            <!-- ligne -->
            <div class="col-md-3 text-right">
                <label for="" class="label"> {{'Number of Nights' | translate}}</label>
            </div>

            <div class="col form-group">
                <select class="form-control" [(ngModel)]="offer['CONDITIONS']['nights']['comparison']">
                    <option value="" disabled>{{'Please select a' | translate}}</option>
                    <option value=" == ">{{'Equal to' | translate}}</option>
                    <option value=" != ">{{'Different to' | translate}}</option>
                    <option value=" >= " selected="selected">{{'Superior or equal to' | translate}}</option>
                    <option value=" > ">{{'Superior to' | translate}}</option>
                    <option value=" <= ">{{'Inferior or equal to' | translate}}</option>
                    <option value=" < ">{{'Inferior to' | translate}}</option>
                </select>

            </div>
            <div class="col form-group">
                <input type="text" class="form-control" [(ngModel)]="offer['CONDITIONS']['nights']['total']">
            </div>
            <!--ligne-->
        </div>
        <!--row-->

        <!--row-->
        <div class="row">
            <!-- ligne -->
            <div class="col-md-3 text-right">
                <label for="" class="label">{{'Guests type'| translate}}</label>
            </div>

            <div class="col-md-9 form-group">
 
                <mat-select class="form-control" *ngIf="offer && guest" [multiple]="true"
                    [(ngModel)]="offer['CONDITIONS']['guest_type']">
                    <mat-option *ngFor="let item of guest" [value]="item.id">
                        {{item.name}}
                    </mat-option>
                </mat-select>


            </div>
        </div>
        <!--row-->

        <!--row-->
        <div class="row">
            <!-- ligne -->
            <div class="col-md-3 text-right">
                <label for="" class="label">{{'Devices' | translate}}</label>
            </div>

            <div class="col-md-9 form-group">

                <mat-select class="form-control" *ngIf="offer && devices" [multiple]="true"
                    [(ngModel)]="offer['CONDITIONS']['devices']">
                    <mat-option *ngFor="let item of devices" [value]="item.id">
                        {{item.text}}
                    </mat-option>
                </mat-select>

            </div>
        </div>
        <!--row-->

        <!--row-->
        <div class="row">
            <!-- ligne -->
            <div class="col-md-3 text-right">
                <label for="" class="label">{{'Scenarios' | translate}}</label>
            </div>

            <div class="col-md-9 form-group">

                <mat-select class="form-control" *ngIf="offer && scenarios" [multiple]="true"
                    [(ngModel)]="offer['CONDITIONS']['workflows']">
                    <mat-option *ngFor="let item of scenarios" [value]="item.id">
                        {{item.text}}
                    </mat-option>
                </mat-select>

            </div>
        </div>
        <!--row-->

    </div>
</div>