import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  constructor(private http: HttpClient) { }
	uri = URI;
	getSliders(id) {
		return this.http.get(`${this.uri}` + "sliders/?hotel="+id);
	}

	createSlider(slider) {
		return this.http.post(`${this.uri}` + "sliders/", slider);
	}

	updateSlider(slider){
		return this.http.patch(`${this.uri}`+"sliders/"+slider.id+"/", slider);
	}
	deleteSlider(id){
		return this.http.delete(`${this.uri}`+"sliders/"+id+"/")
	}

	sortSlider(sliders){
		return this.http.post(`${this.uri}` + "sliders/sort", sliders);
	}
}
