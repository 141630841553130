export var Upsell = {
    "NAME": "",
    "CONDITIONS": {
      "date_stop": new Date(),
      "features": [],
      "guests": {
        "comparison": "",
        "total": ""
      },
      "workflows": [],
      "availability": {
        "features": {
          "category": "",
          "comparison": "",
          "total": ""
        },
        "categories": {
          "category": "",
          "comparison":"",
          "total": ""
        }
      },
      "categories": [],
      "nights": {
        "comparison": "",
        "total": ""
      },
      "date_start": new Date(),
      "guest_type": [],
      "devices": [],
      "price_room_type": [],
      "amount": {
        "comparison": "",
        "total": ""
      }
    },
    "PROMOTIONS": {
      "no_apply_promo_to_rooms": false,
      "no_apply_promo_to_items": false,
      "is_free": "",
      "can_downgrade": false,
      "applyTo": {
        "items": [],
        "type": "",
        "features": [],
        "categories": [],
      },
      "type": "",
      "type_show": "",
      "value": "",
      "items_upgrade": ""
    },
    "DISPLAY_ITEMS": {
      "items":[],
      "price_room_type": "",
      "categories": [],
      "features": [],
    },
    "DATE_START": new Date(),
    "DATE_STOP": new Date(),
    "IS_PERMANENT": false,
    "IS_ACTIVATE": false,
    "IS_UPSELL": false,
    "IS_UPGRADE": false,
    "IS_WALKIN": false,
    "ORDER": "",
    "ID_CUSTOMER": ""

  }

