<div class="row">
  <ng-container *ngFor="let attr of group.fields ; let i = index">
    <ng-container *appHasRole="getPermission(attr.permission)">

      <ng-container *ngIf="attr.input == 'hidden'" >
         
        <input type="hidden" [(ngModel)]="model[attr.name]" value="{{getVal(attr.name, attr.value)}}" >

      </ng-container>

      <div class="form-group" [ngClass]=" attr.width ? attr.width : 'col-lg-6'" *ngIf="attr && attr.input != false">

        <!--text input -->

        <ng-container  *ngIf="attr.input == 'input' && !attr.translatable"
          [ngClass]="attr.class? attr.class : '' ">
          <label>{{attr.label | translate}}</label>
          
          <input type="{{attr.type ? attr.type : attr.input}}" autocomplete="off" class="form-control"
            [(ngModel)]="model[attr.name]">

        </ng-container>
        <!--text input -->

        <!--textarea-->
        <ng-container  *ngIf="attr.input == 'textarea' && !attr.translatable">
          <label>{{attr.label | translate}}</label>
          <textarea [(ngModel)]="model[attr.name]" rows="3" class="form-control"></textarea>
        </ng-container>
        <!--textarea-->

        <!--checkbox-->
        <ng-container  *ngIf="attr.input == 'checkbox'">
          <label class="container-checkbox">{{attr.label | translate}}
            <input type="checkbox" [(ngModel)]="model[attr.name]">
            <span class="checkmark"></span>
          </label>
        </ng-container>
        <!--checkbox-->


        <!--select icons-->
        <ng-container  *ngIf="attr.input == 'select-icon'">

          <form-select-icons [attr]="attr" [model]="model" [hotel]="hotel"></form-select-icons>
        </ng-container>
        <!--select icons-->

        <!--select-simple-->
        <ng-container  *ngIf="attr.input == 'select-simple'">
          <label for="">{{attr.label | translate}}</label>
          <select [(ngModel)]="model[attr.name]" class="form-control">
            <option *ngFor="let value of attr.values" [value]="value[0]">{{value[1]}}</option>
          </select>
        </ng-container>
        <!--select-simple-->

        <!--select-->
        <ng-container  *ngIf="attr.input == 'select'">
          <mat-form-field>
            <mat-label>{{attr.label | translate}}</mat-label>
            <mat-select [(ngModel)]="model[attr.name]" class="icons-select">
              <mat-option *ngFor="let value of attr.values" [value]="value[0]">{{value[1]}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <!--select-->

        <!--translatable-->

        <translated-input [model]="model" [attr]="attr.name" [hotel]="hotel" [type]="attr.input" [label]="attr.label | translate"
          [rows]="2" *ngIf="attr.translatable" [languages]="languages" className="">
        </translated-input>
        <!--translatable-->

        <!--upload image-->

        <ng-container  *ngIf="attr.input == 'media'">
          <form-select-icons [maintainRatio]="attr.maintainRatio != undefined ? attr.maintainRatio : false" [aspectRatio]="attr.aspectRatio != undefined ? attr.aspectRatio : 1" [attr]="attr" [model]="model" [hotel]="hotel" size='big' [tab_icon]="false"></form-select-icons>
        </ng-container>
  
        <!--upload image-->

        <!-- display errors -->
        <app-error-input *ngIf="errors" [errors]="errors" [name]="attr.name"></app-error-input>

      </div>
    </ng-container>
  </ng-container>
</div>
