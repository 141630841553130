 <!-- whitebox-->
 <div class="white-box content">
    <span (click)="openDialogTransport()" class="open-modal">
        <i class="fa fa-plus-circle" aria-hidden="true"></i>
    </span>
    <h2 class="title">{{'Transport arround the hotel' | translate}}</h2>
    <div class="box-scroll h-150">
        <table class="info" *ngIf="transports?.length">
            <tr *ngFor="let tr of transports; let i = index ">
                <td class="icon"> <img src="/assets/img/icons/svg/transport-{{tr.category | lowercase}}.svg"
                        style="height: 25px"></td>
                <td>{{tr.category}}</td>
                <td>{{tr.description}}</td>
                <td class="text-right">
                    <button class="btn dis-onhover edit" (click)="openDialogTransport(tr)"><i
                            class="fa fa-pencil" aria-hidden="true"></i></button>
                    <button class="btn dis-onhover delete"
                        (click)="deleteConfirm(tr, tr.category,'Transport',i)"><i class="fa fa-trash-o"
                            aria-hidden="true"></i></button>
                </td>
            </tr>
        </table>

        <p *ngIf="!transports?.length" class="link-first" (click)="openDialogTransport()">
            {{'Be the first to add transport arround the hotel' | translate}}</p>
    </div>
</div>
<!-- whitebox-->