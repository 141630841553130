<h2 class="mat-dialog-title">
  {{'Choose'| translate}} {{data.label_name | translate}} {{'from list'| translate}}
</h2>

<mat-dialog-content class="mat-typography">
  <mat-tab-group>
    <mat-tab   *ngIf="data.tab_icon">
      <ng-template mat-tab-label>
        {{'General icons' | translate}}
      </ng-template>
      <div class="row">
        <div class="col-md-9">
          </div>
          <div class="col-md-3">
          <input type="search" (input)="searchMedia($event.target.value)" class="form-control" placeholder="Search">
        </div>
      </div>

      <div class="icons-list justify-content-center row">
        <div class="col" *ngFor="let icon of icons">
          <div class="icon" (click)="selectIcon(icon)"
            [ngClass]="{'selected': selected == '/media/customers/'+icon.url}">
            <img src="{{buildUrl(icon.url)}}">
            <p *ngIf="icon.name">{{getName(icon.name)}}</p>
          </div>

        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="data.tab_media">
      <ng-template mat-tab-label>
        {{'Media Manager' | lowercase | translate}}
      </ng-template>
      <div class="row" *ngIf="hotel">
          <app-media-manager
          [maintainRatio]="data.maintainRatio? data.maintainRatio : maintainRatio"
          [aspectRatio]="data.aspectRatio? data.aspectRatio : aspectRatio"
          [cropperMaxWidth]="data.cropperMaxWidth"
          [hotel]="hotel"
          [SelectMultiple]="false"
          [DIALOG]="true"
          (selectimage)="selectIconFromMedia($event)">
          </app-media-manager>
      </div>
     
    </mat-tab>
  </mat-tab-group>




  <ngx-spinner bdOpacity=0.7 bdColor="#3271c0" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="false"
    [name]="'icons'" style="border-radius: 7px">
    <p style="color: white"> {{'Loading' | translate}}... </p>
  </ngx-spinner>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{'Cancel'| translate}}</button>
  <button mat-button [mat-dialog-close]="selected"  class="btn btn-success">{{'Select' | translate}} {{data.label_name | translate}}</button>
</mat-dialog-actions>