<h1 class="mat-dialog-title">{{data.btn.text | titlecase}}</h1>
<div mat-dialog-content>

  <div class="row">
    <div class="col-md-4">
      <input type="search" class="form-control" (input)="searchItem()" [(ngModel)]="search">
    </div>
  </div>
  <br>
  <mat-accordion  *ngIf="rows">
    <mat-expansion-panel *ngFor="let row of rows">
      <mat-expansion-panel-header>
        <mat-panel-title>
         {{row._ID}} - {{row.NAME}} - <a target="_blank" href="https://api.tabhotel.com/admin/MyHotel/request/{{row._ID}}/change/">LINK</a>
        </mat-panel-title>
       
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-md-6">
          <h5>DATA REQUEST</h5>
          <debug [obj]="[row.json_DATA_REQUEST]" *ngIf="isobject(row.json_DATA_REQUEST)"></debug>
          <ng-container  *ngIf="!isobject(row.json_DATA_REQUEST)">
            {{row.json_DATA_REQUEST}}
          </ng-container>
          
        </div>
        <div class="col-md-6">
          <h5>DATA RESPONSE</h5>
          <debug [obj]="[row.json_RESPONSE]" *ngIf="isobject(row.json_RESPONSE)"></debug>
          <ng-container  *ngIf="!isobject(row.json_RESPONSE)">
            {{row.json_RESPONSE}}
          </ng-container>
        </div>
        
      </div>
    </mat-expansion-panel>
  
  </mat-accordion>
  <!-- <table class="table table-bordered" *ngIf="rows">
    <thead>
      <tr>
        <td *ngFor="let head of headers">{{getName(head)}}</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows">
        <td *ngFor="let head of headers">
         <ng-container *ngIf="!startsWith(head, 'json_')">
          {{row[head]}}
         </ng-container>
         <debug [obj]="[row[head]]" *ngIf="startsWith(head, 'json_')"></debug>
          
        </td>
      </tr>
    </tbody>
  </table> -->

  <div class="loader" *ngIf="isLoading">
    <div></div>
    <div></div>
  </div>

</div>
<div mat-dialog-actions align=end>
  <button class="btn btn-success" [mat-dialog-close]="" >{{'Close' | translate}}</button>
</div>