import { EventEmitter } from 'events';
import { ModuleManagerService } from 'src/app/shared/services/module-manager.service';
import { SignageService } from 'src/app/shared/services/signage.service';

import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-form-stepper-or-tabs',
  templateUrl: './form-stepper-or-tabs.component.html',
  styleUrls: ['./form-stepper-or-tabs.component.scss']
})
export class FormStepperOrTabsComponent implements OnInit {

  constructor(
    private signage: SignageService,
    private moduleService: ModuleManagerService,
    private route: ActivatedRoute,

  ) { }

  isLinear = false;

  currentLang = 'en';
  defaultLang = 'en';
  customVars: any;
  variablesAPILIST = {};
  slug;
  @Input() response = {};
  @Input() type = 'tabs';
  @Input() hotel: any;
  @Input() languages: any;
  @Input() errors: any;
  @Input() form: any;
  @Input() variablesAPI = [];
  @Output() saveForm = new EventEmitter();


  ngOnInit() {
    this.route.params.subscribe(params => {
      this.slug = params.slug;
    });

    //GET ALL VARIABLE
    if (!!this.hotel) {
      this.signage.getCustomVars(this.hotel.id).subscribe(res => {
        this.customVars = res['data'];
      });
    }

    //FIND VARIABLE DATA BY APIS
    this.getVariablesByAPI();


  }



  /**
   * find varibale data by api
   */
  getVariablesByAPI() {
    for (let variable of this.variablesAPI) {
      let key = variable.keys.split(",");
      this.moduleService.api(variable.api.replace('{{hotel}}', this.slug)).subscribe((res: string[]) => {
        this.variablesAPILIST[variable.name] = this.getDataFromRes(res, key[0], key[1])
      })
    }
  }


  /**
   *
   * @param data
   * @param key1
   * @param key2
   */
  getDataFromRes(data, key1, key2) {
    let arr = [];
    for (let item of data) {
      if(item[key1] != undefined && item[key2] != undefined)
        arr.push({ value: item[key1], label: item[key2] })
    }
    return arr.sort(compare);
  }

}

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = a['label'].toLowerCase();
  const bandB = b['label'].toLowerCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}
