import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CategoryService } from '../../../shared/services/category.service';
import { ItemService } from '../../../shared/services/item.service';
import { SubcategoryService } from '../../../shared/services/subcategory.service';
import { presentation, super_category_attr, super_item_attr, super_subcat_attr } from './fields';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss']
})
export class TransportComponent implements OnInit {

  constructor(
    public cats: CategoryService,
    public dialog: MatDialog,
    public item: ItemService,
    public subcat: SubcategoryService,
  ) { }

  slug: any;
  title = "Transports";
  label = "Transport";
  hotel: any;
  id: any;
  categories: any;
  copy: any;
  searchResults = [];
  filteredCategories = [];
  see_details = {};
  category_type="Transport";
  superFields = {super_category_attr, super_item_attr,super_subcat_attr};
  presentation  = presentation;



  ngOnInit() {

  }



}
