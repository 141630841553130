<div class="container-fluid">
    <div  class="white-box">

   
    <mat-tab-group>
        <mat-tab label="Graphism & Emails">
    
            <app-device-params [onglet]="true"></app-device-params>
        </mat-tab>
        <mat-tab label="Texts">
            <app-workflow-list  [onglet]="true"></app-workflow-list>
         </mat-tab>
         <mat-tab label="Products">
            <app-upsellservice   [onglet]="true"></app-upsellservice>    
        </mat-tab>
        <mat-tab label="Rooms"> 
            <app-room-category  [onglet]="true"></app-room-category>
         </mat-tab>
        <mat-tab label="Library"> 
            <app-media-manager  [onglet]="true"></app-media-manager>    
        </mat-tab>
      </mat-tab-group>
      
</div>
</div>



