import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'display-dialog-detail',
  templateUrl: './dialog-detail.component.html',
  styleUrls: ['./dialog-detail.component.scss']
})
export class DialogDetailDiaplayComponent  {

  constructor(
    public dialogRef: MatDialogRef<DialogDetailDiaplayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}



}
