import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { ModuleManagerService } from 'src/app/shared/services/module-manager.service';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-module-manager-tab-custom-link',
  templateUrl: './module-manager-tab-custom-link.component.html',
  styleUrls: ['./module-manager-tab-custom-link.component.scss']
})
export class ModuleManagerTabCustomLinkComponent implements OnInit {

  constructor(
    private moduleService: ModuleManagerService,
    private dialog : MatDialog,
  ) { }


  @Input() module:any;
  @Input() slug;
  all_modules;

  ngOnInit() {
    this.moduleService.list('?hotel='+this.slug).subscribe(res=>{
      this.all_modules = res;
    })
    if(this.module.configs.go_to == undefined){
      this.module.configs.go_to = {}
      this.module.configs.go_to = {
          button_text : "",
          params : "",
          module : "",
      }
    }
  }

  addBtn(){
    if(this.module.configs.go_to_btns == undefined){
      this.module.configs.go_to_btns = [];
    }
    this.module.configs.go_to_btns.push({
          id : new Date().valueOf(),
          type : "module",
          button_text : "",
          params : "",
          module : "",
          link : "",
    });
  }

  delete(i){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : {type : "button"}, width:"600px"
    });
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.module.configs.go_to_btns.splice(i,1);
      }
    });
  }

}
