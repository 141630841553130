<h2 class="mat-dialog-title with-trans">
  {{'Preview Template' | translate}}
  <app-select-translate [hotel]="hotel" [default]="lang" (changeEvent)="updateLang($event)" [model]="lang"
    [languages]="languages">
  </app-select-translate>
</h2>
<mat-dialog-content class="mat-typography">
  <div class="content-iframe">
    <iframe [src]="getSafeUrl()" frameborder="0" *ngIf="init"></iframe>
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">

  <button mat-button mat-dialog-close [mat-dialog-close]="" class="btn btn-default">{{'Close' | translate}}</button>
</mat-dialog-actions>