<!-- Page Content -->
<div class="container-fluid">
    <app-fixed-title [customtitle]='title' [hotel]='hotel' [links]="_breadCrumbs"></app-fixed-title>

    <div class="white-box" *ngIf="offer">
       

        <mat-tab-group>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{'General informations' | translate}}
                </ng-template>
                <upsell-informations [offer]="offer" *ngIf="offer"></upsell-informations>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{'Conditions' | translate}}
                </ng-template>
             <upsell-conditions
             [offer]="offer"
             [guest]="guest"
             [devices]="devices"
             [scenarios]="scenarios"
             [categories]="categories"
             [priceType]="priceType"
             [features]="features"
             [rooms]="rooms"></upsell-conditions>

            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{'Promotions/Reductions' | translate}}
                </ng-template>
               <upsell-promotions
                [offer]="offer"
                [items]="items"
                [guest]="guest"
                [devices]="devices"
                [scenarios]="scenarios"
                [priceType]="priceType"
                [categories]="categories"
                [rooms]="rooms"
                [features]="features"
                ></upsell-promotions>

            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{'Items to show' | translate}}
                </ng-template>
                <upsell-items-to-show
                [offer]="offer"
                [guest]="guest"
                [devices]="devices"
                [features]="features"
                [scenarios]="scenarios"
                [priceType]="priceType"
                [categories]="categories"
                [rooms]="rooms"></upsell-items-to-show>
            </mat-tab>
        </mat-tab-group>
        <footer class="text-right">
            <button class="btn btn-success" (click)="updateOrCreate(offer, true)"><i class="fa fa-floppy-o" aria-hidden="true"></i> {{ 'Save and Exit'| translate}}</button>
            <button class="btn btn-success" (click)="updateOrCreate(offer, false)"><i class="fa fa-floppy-o" aria-hidden="true"></i> {{ 'Save'| translate}}</button>
        </footer>
    </div>
</div>