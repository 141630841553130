<!-- Page Content -->
<div class="container-fluid">
    <app-fixed-title [title]='title' *ngIf="!onglet" [hotel]='hotel'></app-fixed-title>

    <h4 *ngIf="onglet" class="mt-3">{{title}}</h4>
    <div class="white-box">
        <!--head-->
            <div class="row">
                <div class="col-md-6">
                    <p>{{'These offers wil be deployed in the Upsell and Upgrade module' | translate}}</p><br>
                </div>
                <div class="col-md-6 text-right">
                    <button class="btn btn-success" [routerLink]="['/upsell-create', slug]">
                        <i class="fa fa-tags" aria-hidden="true"></i>
                         {{'Create new offer' | translate}}
                    </button>
                </div>
            </div>
        <!--head-->

            <table class="table table-striped">
                
                <thead>
                    <tr>
                        <th>#ID</th>
                        <th>{{'Name' | translate}}</th>
                        <th>{{'Date start' | translate}}</th>
                        <th>{{'Date end' | translate}}</th>
                        <th>{{'Active' | translate}}</th>
                        <th>{{'Permanent' | translate}}</th>
                        <th>{{'Upsell' | translate}}</th>
                        <th>{{'Upgrade' | translate}}</th>
                        <th>{{'Walkin' | translate}}</th>
                        <th>{{'Actions' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let offer of offers; let index = index ">
                        <td>{{offer.id}}</td>
                        <td>{{offer.NAME}}</td>
                        <td>{{offer.DATE_START | date}}</td>
                        <td>{{offer.DATE_STOP | date}}</td>
                        <td class="btn-td">
                                <i class="fa fa-{{offer.IS_ACTIVATE? 'check' : 'times'}}-circle" aria-hidden="true"></i>
                            </td>
                        <td class="btn-td">
                            <i class="fa fa-{{offer.IS_PERMANENT? 'check' : 'times'}}-circle" aria-hidden="true"></i>
                        </td>
                        <td class="btn-td">
                            <i class="fa fa-{{offer.IS_UPSELL? 'check' : 'times'}}-circle" aria-hidden="true"></i>
                        </td>
                        <td class="btn-td">
                            <i class="fa fa-{{offer.IS_UPGRADE? 'check' : 'times'}}-circle" aria-hidden="true"></i>
                        </td>
                        <td class="btn-td">
                            <i class="fa fa-{{offer.IS_WALKIN? 'check' : 'times'}}-circle" aria-hidden="true"></i>
                        </td>
                        <td class="text-right">
                            <button class="btn btn-sm btn-success" [routerLink]="['/upsell-detail', slug,offer.id]" >{{'Edit offer' | translate}}</button>
                            <button class="btn btn-sm btn-danger" (click)="deleteOffer(offer, index)">{{'Delete' | translate}}</button>
                        </td>
                    </tr>
                </tbody>

            </table>
    </div>
</div>