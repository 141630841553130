import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: "debug",
  templateUrl: "./debug.component.html",
  styleUrls: ["./debug.component.scss"],
})
export class DebugComponent implements OnChanges, OnInit {
  constructor() {}
  @Input() obj: any;
  @Input() string: any;
  @Input() expanded = false;
  @Input() convert = false;
  @Input() globalObject = false;
  display = true;

  ngOnInit() {
    if (this.convert && this.string && this.string != undefined) {
      let obj = JSON.parse(this.string.split("'").join('"'));
      this.obj = this.globalObject ? [{ ...obj }] : obj;
    }
  }

  ngOnChanges() {
    this.display = false;
    setTimeout(() => {
      this.display = true;
    }, 10);
  }
}
