<h2 class="mat-dialog-title with-trans">
    {{ "Notifications" | translate }} ({{data.length}})
    
    <button class="btn btn-xs btn-warning"(click)="openDialogSetting()"><i class="fa fa-cog" aria-hidden="true"></i> Setting </button>
</h2>
<mat-dialog-content class="mat-typography">
 
    <ul>
        <li *ngFor="let notif of data; let i = index" [ngClass]="{'error':!notif.status, 'success':notif.status}">
            <img [src]="getUrl(notif.icon)" style="width: 25px;" alt=""> {{notif.text}}</li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <!-- <button class="btn btn-success" (click)="soundOffOn()">
        <ng-container *ngIf="!sound">
            <i class="fa fa-volume-off" aria-hidden="true"></i> Enable sound
        </ng-container>
        <ng-container *ngIf="sound">
            <i class="fa fa-volume-up" aria-hidden="true"></i> Disable sound
        </ng-container>
    </button> -->
    <button mat-button mat-dialog-close class="btn btn-default">{{ "Close" | translate }}</button>
    <button mat-button (click)="deleteAllNotif()" class="btn btn-danger">{{ "Delete All" | translate }}</button>


</mat-dialog-actions>