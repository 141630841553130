
  export const PERIODS = [
    { value: "TODAY", name: 'Today' },
    { value: "1_HOURS", name: '1 hour ago' },
    { value: "4_HOURS", name: '4 hours ago' },
    { value: "8_HOURS", name: '8 hours ago' },
    { value: "1_DAYS", name: '1 Day ago' },
    { value: "2_DAYS", name: '2 Days ago' },
    { value: "3_DAYS", name: '3 Days ago' },
    { value: "5_DAYS", name: '5 Days ago' },
    { value: "6_DAYS", name: '6 Days ago' },
    { value: "7_DAYS", name: '7 Days ago' },
    { value: "THIS_WEEK", name: 'This week' },
    { value: "LAST_WEEK", name: 'Last week' },
    { value: "THIS_MONTH", name: 'This month' },
    { value: "LAST_MONTH", name: 'Last month' },
    { value: "ALL", name: 'ALL' },
  ]

  export const _PERIODS = {
    'TODAY': 'Today' ,
    '1_HOURS': '1 hour ago' ,
    '4_HOURS': '4 hours ago' ,
    '8_HOURS': '8 hours ago' ,
    '1_DAYS': '1 Day ago' ,
    '2_DAYS': '2 Days ago' ,
    '3_DAYS': '3 Days ago' ,
    '5_DAYS': '5 Days ago' ,
    '6_DAYS': '6 Days ago' ,
    '7_DAYS': '7 Days ago' ,
    'THIS_WEEK': 'This week' ,
    'LAST_WEEK': 'Last week' ,
    'THIS_MONTH': 'This month' ,
    'LAST_MONTH': 'Last month' ,
    'ALL':'ALL'
  }