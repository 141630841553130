<h2 class="mat-dialog-title with-trans">
  Notification Setting 
</h2>
<mat-dialog-content class="mat-typography">
  <br>

<div class="form-group">
  <mat-slide-toggle [(ngModel)]="sound" (change)="soundOffOn($event)">
    <ng-container *ngIf="!sound">
   {{'Enable sound' | translate}}
  </ng-container>
  <ng-container *ngIf="sound">
    {{'Disable sound' | translate}}
  </ng-container>

  </mat-slide-toggle>
</div>  

<div class="form-group">
  <label for="">{{'Fetch notifications before'  | translate}}</label>
  <select class="form-control" [(ngModel)]="notif_pile_timing" (change)="updatePileTiming($event)">
    <option value="1">1 {{'minute' | translate}}</option>
    <option value="2">2 {{'minutes' | translate}}</option>
    <option value="3">3 {{'minutes' | translate}}</option>
    <option value="4">4 {{'minutes' | translate}}</option>
    <option value="5">5 {{'minutes' | translate}}</option>
    <option value="6">6 {{'minutes' | translate}}</option>
    <option value="7">7 {{'minutes' | translate}}</option>
    <option value="8">8 {{'minutes' | translate}}</option>
    <option value="9">9 {{'minutes' | translate}}</option>
    <option value="10">10 {{'minutes' | translate}}</option>
    <option value="15">15 {{'minutes' | translate}}</option>
    <option value="30">30 {{'minutes' | translate}}</option>
    <option value="60">60 {{'minutes' | translate}}</option>
    <option value="90">90 {{'minutes' | translate}}</option>
  </select>
</div>

 
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-default">{{'Close' | translate}}</button>
</mat-dialog-actions>