<!-- default lang -->
<div class="row">
  <div class="col-md-6">
    <!-- <button class="btn btn-success">Export excel</button> -->
  </div>
  <div class="col-md-6">

    <div class="select-lang">
  <!-- automatic trans -->
  <button class="btn btn-default trans" (click)="autoTranslate(group)">
    <i class="fa fa-flag"></i>  {{'Automatic translate' | translate}} 
  </button>
  <!-- automatic trans -->

  <!-- preview -->
  <button class="btn btn-success" (click)="dialogPreview(group)">
    <i class="fa fa-eye"></i> {{'Preview all' | translate}}
  </button>
  <!-- preview -->

  <!-- save -->
  <button class="btn btn-success" (click)="saveContent(group)">
    <i class="fa fa-bookmark" aria-hidden="true"></i> {{'Save' | translate}}
  </button>
  <!-- save -->
</div>
<!-- default lang -->
  </div>


</div>

<div class="box-scroll">

<app-steps-trans *ngIf="hotel" [slug]="slug" [defaultLang]="defaultLang" [languages]="languages" [hotel]="hotel"
  [workflow]="workflow" [group]="group" (getList)="updateContent($event, group)">
</app-steps-trans>

</div>