import { Injectable , Injector} from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import { server } from '../shared/settings/global';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private _injecor : Injector) { }

	intercept(req, next){
		let authservice = this._injecor.get(AuthService);
		let configuredLang = localStorage['hotel_language'];
		let labg = configuredLang? configuredLang+'-'+configuredLang.toUpperCase(): 'en-EN'
		let token = authservice.getToken();
		//IF REQUEST DOESNT HAVE AN Authorization
		if(!req.headers.get('Authorization') && token){
			let tokenizedReq = req.clone({
				setHeaders:{
					Authorization : "Token " + token,
					"Accept-Language":'fr-FR'
				}
			})
			return next.handle(tokenizedReq)
		}else {
			return next.handle(req);
		}
		
	}
}
