<mat-nav-list> 
  <ng-container *ngFor="let item of menu; let i = index">
    <ng-container *ngIf="!item.submenu && item.active">
      <a mat-list-item *appHasRole="item.role" id="menu-{{item.id}}" [routerLink]="item.param ? [item.link, slug] : item.link"
        routerLinkActive="active-link">
        <i class="{{item.icon}}" aria-hidden="true"></i>
        <span class="menu-name">{{translate(item)}}</span>
      </a>
    </ng-container>

    <ng-container *ngIf="item.submenu && item.active">
      <button mat-list-item [attr.data-toggle]="'collapse'" id="submenu-{{item.id}}" *appHasRole="item.role"  [attr.data-target]="'#collapse-menu-'+i"
        class="mat-list-item has-submenu">
        <i class="{{item.icon}}" aria-hidden="true"></i>
        <span class="menu-name">{{translate(item)}}</span>
        <i class="fa fa-caret-down" aria-hidden="true"></i>

      </button>
    </ng-container>

    <div class="submenu collapse" id="collapse-menu-{{i}}">
      <sidebar-menu [user]='user' [menu]="item.submenu" [slug]="slug" *ngIf="item?.submenu?.length"></sidebar-menu>
    </div>

  </ng-container>

</mat-nav-list>

<!-- <div *appHasRole="item.role"></div> -->