export const fields = [
    {
      name : 'Text',
      type:'text',
      params :"",
      icon : 'fa fa-text-width'
    },
    {
      name : 'Checkbox (Multiple choice)',
      type:'checkbox',
      params :"",
      icon : 'fa fa-check-square-o'

    },
    {
      name : 'Radio (Unique choice)',
      type:'radio',
      params :"",
      icon : 'fa fa-check-circle-o'

    },
    {
      name : 'Boolean (true or false)',
      type : 'boolean',
      params : '',
      icon : 'fa fa-star-half-o'
    },
    {
      name : 'Image',
      type:'image',
      params :"",
      icon : 'fa fa-picture-o'
    },
    {
      name : 'Slider',
      type:'slider',
      params :"",
      icon : 'fa fa-film'
    },
   {
      name : 'Select',
      type:'select',
      params :"",
      icon : 'fa fa-chevron-circle-down'

    },
    {
      name : 'Tags',
      type:'tags',
      params :"",
      icon : 'fa fa-tags'

    },
    {
      name : 'Code editor',
      type:'code_editor',
      params :"",
      icon : 'fa fa-html5'
      
    },
    {
      name : 'Separator',
      type:'separator',
      params :"",
      icon : 'fa fa-arrows-h'

    },
    {
      name : 'Textarea',
      type:'textarea',
      params :"",
      icon : 'fa fa-align-left'

    },
    {
      name : 'Text editor',
      type:'htmleditor',
      params :"",
      icon : 'fa fa-font'
    },
    {
      name : 'Title',
      type:'title',
      params :"",
      icon : 'fa fa-text-height'
    },
    {
      name : 'Button',
      type:'btn',
      params :"",
      icon : 'fa fa-link'
    },
 
  ]