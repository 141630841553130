
export const category_type = "RoomService";
export const category_attr = [

	{
		label: "Informations",
		fields: [
			{
				name: "hotel",
				label: "Hotel",
				input: false,
				translatable: false
			},
			{
				name: "name",
				label: "Name",
				input: 'input',
				translatable: false,
				width: "col-lg-4"
			},
			{
				name: "color",
				label: "Color",
				input: 'input',
				type : 'color',
				translatable: false,
				width: "col-lg-4"
			},
			{
				name: "visible",
				label: "Visible",
				input: 'checkbox',
				translatable: false,
				width: 'col-lg-3'
			},
			{
				name: "short_description",
				label: "Short description",
				input: 'htmleditor',
				translatable: true,
				width: 'col-lg-12'
			},
			{
				name: "long_description",
				label: "Long description",
				input: 'htmleditor',
				translatable: true,
				width: 'col-lg-12'
			},
            {
                name:"image",
                label:"Image",
                input: "media",
                translatable:false,
                width: 'col-lg-12'
            }
		]
	}
];
