<ng-container *ngIf="!workflowContent">
  <div class="gradient"></div>
  <div class="gradient"></div>
  <div class="gradient"></div>
</ng-container>

<div class="accordion" id="accordion" *ngIf="hotel && workflowContent">
  <ng-container *ngFor="let template of workflowContent.data; let i = index">
    <div class="card" id="card-{{i}}" *ngIf="displayCardCondition(template)">
      <div class="card-header"   [ngClass]="{'empty': template?.translations?.length == 0}" id="heading-{{i}}">
        <div class="row flex-vertical">

          <!-- title -->
          <div class="col-md-8 head-click {{(i == 0)? '' : 'collapsed'}} action-collapse" [attr.data-toggle]="'collapse'"
          [attr.data-target]="'#collapse-'+i" [attr.aria-expanded]="'false'"
          [attr.aria-controls]="'collapse-'++i">
            <h4 class="title">{{i+1}} - {{formatText(template.label) | translate}} </h4>
          </div>
          <!-- title -->

          
          <div class="col-md-4 text-right">
            <!-- edit template -->
            <ng-container *ngIf="isTemplate(template)">
              <button class="btn btn-success" *appHasRole="'super-user'" (click)="dialogTemplate(template)">
                <i class="fa fa-file-text"></i>
              </button>
            </ng-container>
            <!-- edit template -->

            <!-- preview -->
            <button class="btn btn-success" *ngIf="!template.is_general" (click)="dialogPreview(template, i)">
              <i class="fa fa-eye"></i>
            </button>
            <!-- preview -->

          </div>
        </div>
      </div>

      <!-- collapse body -->
      <div id="collapse-{{i}}" *ngIf="template?.translations?.length" class="collapse {{(i == 0)? 'show' : ''}}" [attr.aria-labelledby]="'heading-'+i"
        data-parent="#accordion">
        <div class="card-body">
          <!--body-->
          <div class="row ligne" *ngFor="let text of template.translations; let k = index">

            <div class="col reference">
              <strong>{{'Reference text' | translate}}</strong> 
              <ng-container *ngIf="text.content[defaultLang].length>100">
                (<span class="link" (click)="displayLongText(text.content[defaultLang])">Click here</span>)
              </ng-container>
              <ng-container  *ngIf="text.content[defaultLang].length<=100">
                (<span [innerHTML]="text.content[defaultLang]"> </span>)
              </ng-container>
              <app-trans-custom-input
              [model]="text.content"
              [hotel]="hotel"
              [defaultLang]="defaultLang"
              [languages]="languages"
              [indication]="text.variable_name"
              (updateInput)="saveContent(template, false)"></app-trans-custom-input>

              
            </div>
  
          </div>
        </div>
      </div>
      <!-- collapse body -->

    </div>
  </ng-container>
</div>

<!-- <button (click)="clearData()">Clear data</button> -->