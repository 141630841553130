import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { MEDIA } from 'src/app/shared/settings/global';

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

declare var $ :any;

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss']
})
export class HotelComponent implements OnInit {



  constructor(
    public dialogRef: MatDialogRef<HotelComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private hs : HotelService,
    private formbuilder: UntypedFormBuilder,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,

    ) {
      this.notifier = notifierService;
    }

  private readonly notifier: NotifierService;
  hotel: any;
  languages: any;
  formLang = new UntypedFormControl();
  MEDIA = MEDIA;
  errors =null;

  files = [
    {'name':'main_image', 'label':'Property main image'},
    {'name':'logo', 'label':'Property logo'},
    {'name':'logo_for_slide', 'label':'Property logo for slide'},
    {'name':'home_image_seconde', 'label':'Home seconde image'}
  ]

  /**
   *
   * @param lang_id
   */
  isSelected(lang_id){

    if(this.hotel.languages_param.find(x=>x.id == lang_id) !== -1){
      return true
    }else {
      return false
    }
  }


  ngOnInit() {
    this.languages = this.data.languages;
    this.hotel = this.data.hotel;
  }


  /**
   *
   */
  createOrUpdate(){
    this.spinner.show()

    this.hs.updateHotel(this.hotel.id, this.hotel)
    .subscribe(
      res => {
        if(res){
          this.spinner.hide();
          this.dialogRef.close();
          this.translate.get('Property informations successfully updated').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
        }
      },
      err => {
        this.spinner.hide();
        this.errors = err.error
      }
    )

  }

}
