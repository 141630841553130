import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'upsell-config',
  templateUrl: './upsell-config.component.html',
  styleUrls: ['./upsell-config.component.scss']
})
export class UpsellConfigComponent implements OnInit {

  constructor() { }

  @Input() offer :any;

  ngOnInit() {
  }

}
