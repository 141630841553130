import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';

;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	constructor(
		private router: Router,
		private _auth : AuthService,
		private formBuilder: UntypedFormBuilder
	)
	{}

	registerForm: UntypedFormGroup;
    submitted = false;
	loading = false;

	loginUserData = {
		email:"",
		password:""
	}
	message : any;

	loginUser(){
		this.loading = true;
		this.submitted = true;
		if (this.registerForm.invalid) {
			this.loading=false;
            return;
        }
        let userData = {
        	email : this.loginUserData.email.trim(),
        	password :  this.loginUserData.password
		}
		this._auth.loginUser(userData)
		.subscribe(
			res=>{
				console.log(res)
				this.loading=false;
				this.message = null;
				localStorage.setItem('_token', res.key);
				localStorage.setItem('user_role',res.role );
				localStorage.setItem('user_id', res.user);
				localStorage.setItem('user_obj', JSON.stringify(res));
				localStorage.setItem('id', res.id);
				localStorage.setItem('language', res.language);
				localStorage.setItem('roles', res.role);

				res.hotels.length == 1 ? this.router.navigate(['/redirection',res.hotels[0].id ]) : this.router.navigate(['/hotels'])


			},
			()=>{
				this.loading=false;
				this.message = "Votre connexion n'a pas réussi";
			}
		)

	}

	ngOnInit() {

		this.registerForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(4)]]
        });

		if(this._auth.loggedIn()){
			this.router.navigate(['/hotels'])
		}

	}



}
