import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-error',
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.scss']
})
export class DialogErrorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}

  message = {
    title : "Can't fetch data",
    icon : "error",
    text : "Please check your connection, if problem persist please contact Tabhotel IT Team"
  }

  ngOnInit(){
    if(this.data)
      this.message = this.data
  }


}
