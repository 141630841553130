<h2 class="mat-dialog-title">
  {{(device.id ? 'Edit Device' : 'Add Device')| translate }}
</h2>

<mat-dialog-content class="mat-typography">

  <div class="row">

    <div class="col-lg-12 form-group">
      <label>{{'Name'| translate}}</label>
      <input class="form-control" type="text" [(ngModel)]="device.NAME">
      <app-error-input *ngIf="errors" [errors]="errors" name="NAME"></app-error-input>
    </div>
  </div>
  <div class="row" *appHasRole="'administrator'">
    <div class="col-lg-6 form-group">
      <label>{{'IP DEVICE'| translate}}</label>
      <input class="form-control" type="text" [(ngModel)]="device.IP_DEVICE">
      <app-error-input *ngIf="errors" [errors]="errors" name="IP_DEVICE"></app-error-input>
    </div>



    <div class="col-lg-6 form-group">
      <label>{{'UUID DEVICE'| translate}}</label>
      <input class="form-control" type="text" [(ngModel)]="device.UUID_DEVICE">
      <app-error-input *ngIf="errors" [errors]="errors" name="UUID_DEVICE"></app-error-input>
    </div>


    <div class="col-lg-6 form-group">
      <label>{{'ID SCREEN FORMAT'| translate}}</label>
      <input class="form-control" type="number" [(ngModel)]="device.ID_SCREEN_FORMAT">
      <app-error-input *ngIf="errors" [errors]="errors" name="ID_SCREEN_FORMAT"></app-error-input>
    </div>

    <div class="col-lg-6 form-group">
      <label>{{'FOLDER'| translate}}</label>
      <input class="form-control" type="text" [(ngModel)]="device.FOLDER">
      <app-error-input *ngIf="errors" [errors]="errors" name="FOLDER"></app-error-input>
    </div>
    <div class="col-lg-6 form-group">
      <label>{{'NAME SCREEN FORMAT' | translate}}</label>
      <input class="form-control" type="text" [(ngModel)]="device.NAME_SCREEN_FORMAT">
      <app-error-input *ngIf="errors" [errors]="errors" name="NAME_SCREEN_FORMAT"></app-error-input>
    </div>



  </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'Cancel'| translate}}</button>
  <button mat-button (click)="createOrUpdate(device)"  class="btn btn-success">
    {{(device.id ? 'Edit Device' : 'Add Device')| translate }}
  </button>
</mat-dialog-actions>