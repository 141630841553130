import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-debug-form',
  templateUrl: './debug-form.component.html',
  styleUrls: ['./debug-form.component.scss']
})
export class DebugFormComponent implements OnInit , OnChanges{

  constructor() { }


  @Input() form;
  @Output() formUpdate = new EventEmitter()
  formText = "";


  ngOnInit() {
    this.formText = JSON.stringify(this.form);
  }

  ngOnChanges(){
    // this.ngOnInit()
  }

  updateJson(){
    console.log(JSON.parse(this.formText), "----------")
    this.formUpdate.emit(JSON.parse(this.formText))
  }

}
