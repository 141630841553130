<div class="row">
  <ng-container *ngFor="let field of form ; let i = index">
    <ng-container *appHasRole="field.role? field.role : 'concierge'">
      <div [ngClass]=" field.width ? field.width :  'col-lg-12'" class="{{getClassName(field.classname)}}" *ngIf="field.active">

        <!--text input -->
        <div class="form-group  {{field.input_type}}" *ngIf="field.type == 'text' && !field.translatable">
          <label *ngIf="field.input_type !='hidden'">{{field.label}}</label>
          <p *ngIf="field.description"><small>{{field.description }}</small></p>
          <input type="{{field.input_type ? field.input_type : 'text'}}" class="form-control"
            [ngModel]="getField(field)" (input)="updateField($event.target.value,field)">
        </div>
        <!--text input -->

        <!--textarea-->
        <div class="form-group" *ngIf="field.type == 'textarea' && !field.translatable">
          <label>{{field.label}}</label>
          <p *ngIf="field.description"><small>{{field.description }}</small></p>

          <textarea [ngModel]="getField(field)" (input)="updateField($event.target.value,field)" rows="{{field.rows}}"
            class="form-control"></textarea>
        </div>
        <!--textarea-->

        <!-- separator -->
        <hr *ngIf="field.type == 'separator'">
        <!-- separator -->

        <!--checkbox-->
        <div *ngIf="field.type == 'checkbox'" class="form-group">
          <label>{{field.label}}</label>
          <p *ngIf="field.description"><small>{{field.description}}</small></p>
          <div class="row">
            <div [ngClass]="{'col':field.inline,'col-12 inline':!field.inline }" *ngFor="let option of field.values">

              <mat-checkbox (change)="updateCheckbox($event, field.name, option.value)"
                [(ngModel)]="response[field.name][option.value]">{{option.label}}</mat-checkbox>
            </div>
          </div>
        </div>
        <!--checkbox-->


        <!--select-simple-->
        <div class="form-group" *ngIf="field.type == 'select' && !field.multiple ">
          <label for="">{{field.label}}</label>
          <select [ngModel]="getField(field)" (change)="updateField($event.target.value,field)" class="form-control">
            <ng-container *ngIf="field.api && !field.constant && !field.customSelect && variablesAPILIST[field.api]?.length">
              <option *ngFor="let option of variablesAPILIST[field.api]" [value]="option.value">{{option.label}}
              </option>
            </ng-container>

            <ng-container *ngIf="field.apiendpoint">
              <option *ngFor="let option of dataForSelect[field.name]" [value]="option.value">{{option.label}}
              </option>
            </ng-container>

            <ng-container *ngIf="!field.constant &&  !field.customSelect">
              <option *ngFor="let option of field.values" [value]="option.value">{{option.label}}</option>
            </ng-container>
            <ng-container *ngIf="field.constant && customVars && !field.customSelect && customVars[field.constant]?.length">
              <option *ngFor="let option of customVars[field.constant]" [value]="option.value"
                [innerHTML]="option.label"></option>
            </ng-container>

            <ng-container *ngIf="field.customSelect">
              <option *ngFor="let option of customSelect[field.customSelect]" [value]="option.value"
                [innerHTML]="option.label"></option>
            </ng-container>
          </select>
        </div>
        <!--select-simple-->

        <!--select 2-->
        <div class="form-group" *ngIf="field.type == 'select' && field.multiple ">
          <label>{{field.label}}</label>
          <mat-form-field appearance="fill">
            <mat-label>{{field.label}}</mat-label>
            <mat-select [ngModel]="getField(field)"  (selectionChange)="updateField($event.value,field)" multiple>
              <!--  -->
              <ng-container *ngIf="!field.constant && !field.api">
                <mat-option *ngFor="let option of field.values" [value]="option.value">{{option.label}}</mat-option>
              </ng-container>
          
              <ng-container *ngIf="field.constant && customVars && !field.customSelect && customVars[field.constant].length">
                <mat-option *ngFor="let option of customVars[field.constant]" [value]="option.value"
                  >{{option.label}}</mat-option>
              </ng-container>
  
              <ng-container *ngIf="field.api && variablesAPILIST[field.api] != undefined && variablesAPILIST[field.api]?.length">
                <mat-option *ngFor="let option of variablesAPILIST[field.api]" [value]="option.value"
                   >{{option.label}}</mat-option>
              </ng-container>

              <!--  -->
            </mat-select>
          </mat-form-field>

         
        </div>
        <!--select 2-->


     
        <!-- code editor -->
        <div class="form-group" *ngIf="field.type == 'code_editor'">
          <label>{{field.label}}</label>
        <td-code-editor
        [style.height.px]="300"
        flex
        theme="vs-dark"
        [language]="field.language"
        [editorOptions]="{readOnly:false, fontSize:20}"
        [ngModel]="getField(field)"
        (ngModelChange)="updateField($event,field )">
        </td-code-editor>
      </div>
        <!-- code editor -->
        

        <!--image field-->
        <div class="form-group" *ngIf="field.type == 'image' ">
          <app-image-translatable (openMedia)="openMediaManager($event)" [field]="field" [hotel]="hotel"
            [languages]="languages" [response]="response" [serverApi]="serverApi"></app-image-translatable>

        </div>
        <!--image field-->


        <!--slider field-->
        <div class="form-group group-images" *ngIf="field.type == 'slider' ">
          <label>{{field.label}}</label><br>

          <p *ngIf="field.description"><small>{{field.description}}</small></p>
          <button class="btn btn-primary" (click)="openMediaManagerSlider(field)"> {{'Add Image from media' }}</button>
          <div class="list-images">
            <div class="img-container" *ngFor="let img of extractListSlider(response[field.name]); let i = index">
              <i class="fa fa-times-circle" aria-hidden="true" (click)="removeImgSlider(field, i)"></i>
              <img src="{{serverApi}}{{img}}" alt="">
            </div>
          </div>
        </div>
        <!--slider field-->

        <!--radio-->
        <div class="form-group" *ngIf="field.type == 'radio' || field.type == 'boolean' ">
          <label>{{field.label}}</label><br>
          <p *ngIf="field.description"><small>{{field.description}}</small></p>

          <mat-radio-group aria-label="Select an option" [ngModel]="getField(field)"
            (change)="updateField($event.value,field)">
            <mat-radio-button *ngFor="let v of field.values" [value]="v.value">{{v.label}}</mat-radio-button>
          </mat-radio-group>

        </div>
        <!--radio-->


        <!--translatable-->
        <div class="form-group" *ngIf="field.translatable && field.type != 'image'">
          <translated-input [displayDefaultTrans]="true" [model]="getParentField(field.name, response)"
            [attr]="getPath(field.name)" [hotel]="hotel" [type]="field.type" [label]="field.label" [rows]="2"
            [languages]="languages" [initialAttr]="false" [defaultLang]="false">
          </translated-input>
        </div>
        <!--translatable-->

        <!--rich text-->
        <div class="form-group" *ngIf="field.type == 'htmleditor' && !field.translatable">
          <label>{{field.label}}</label>
          <p *ngIf="field.description"><small>{{field.description}}</small></p>
          <app-html-editor [model]="getParentField(field.name, response)" attr="getPath(field.name)" [hotel]="hotel">
          </app-html-editor>

        </div>
        <!--rich text-->


         <!--title-->
         <div class="form-group" *ngIf="field.type == 'title'">
          <label>{{field.label}}</label>
          <p *ngIf="field.description"><small>{{field.description}}</small></p>
        </div>
        <!--title-->


        <!--title-->
        <div class="form-group" *ngIf="field.type == 'button'">
          <a href="{{field.url}}" class="btn" class="{{field.classname}}" [attr.target]="field.target">{{field.label}}</a>
        </div>
        <!--title-->


      </div>
    </ng-container>
  </ng-container>

</div>
