<div class="container-fluid">

  <!-- <app-fixed-title [title]='title' [hotel]='hotel'></app-fixed-title> -->


  <div class="row">
    <div class="col-md-4 col-6">
      <div class="white-box triggers">
        <div class="row">

          <div class="col-8">
            <h1>{{'Triggers' | translate}} ({{triggers?.length}})</h1>
            <p>{{'Follow events' | translate}}</p>
          </div>
          <div class="col-4">
            <img src="/assets/img/icons/svg/click.svg" class="img-responsive" alt="">
          </div>
        </div>
        <div class="footer-white-box" (click)="openDialog()">
          {{'Add new trigger' | translate}} 
          <i class="fa fa-plus-circle" aria-hidden="true"></i>

        </div>
      </div>
    </div>
    <div class="col-md-4 col-6">
      <div class="white-box triggers">
        <div class="row">

          <div class="col-8">
            <h1>{{'Notifications' | translate}} </h1>
            <p>{{'Follow logs' | translate}}</p>
          </div>
          <div class="col-4">
            <img src="/assets/img/icons/svg/bell.svg" class="img-responsive" alt="">
          </div>
        </div>
        <div class="footer-white-box" (click)="logDialoag()">
          {{'Find logs' | translate}}
          <i class="fa fa-chevron-right" aria-hidden="true"></i>

        </div>
      </div>
    </div>

    <div class="col-md-4 col-6">
      <div class="white-box triggers">
        <div class="row">

          <div class="col-8">
            <h1>{{'Groups' | translate}}</h1>
            <p>{{'Manage groups' | translate}} </p>
          </div>
          <div class="col-4">
            <img src="/assets/img/icons/svg/team.svg" class="img-responsive" alt="">
          </div>
        </div>
        <div class="footer-white-box" (click)="openGroupDialog()">
          {{'Add group' | translate}}
          <i class="fa fa-chevron-right" aria-hidden="true"></i>

        </div>
      </div>
    </div>
  </div>


  <mat-accordion (cdkDropListDropped)="drop($event)" cdkDropList *ngIf="triggers">

    <mat-expansion-panel *ngFor="let trigger of triggers ; let i = index" cdkDrag>
      <!-- HEADER -->
      <mat-expansion-panel-header>
        <mat-panel-title>
          <img src="/assets/img/icons/svg/029-success.svg" *ngIf="trigger.active">
          <img src="/assets/img/icons/svg/error.svg" *ngIf="!trigger.active"> {{trigger.name}}
        </mat-panel-title>
        <mat-panel-description>

          <ng-container *ngFor="let type of getExportType(trigger)">
            <img src="/assets/img/icons/svg/gmail.svg" alt="" *ngIf="type == 'mail'">
            <img src="/assets/img/icons/svg/headphones.svg" alt="" *ngIf="type == 'audio'">
          </ng-container>


          {{getCategoryName(trigger?.category)}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- HEADER -->



      <!-- MANAGE TRAME COMP -->
      <app-manage-trames [trigger]="trigger" [trames]="trigger.trames" [groups]="groups"
        (editDialog)="openTramDialog($event)"></app-manage-trames>
      <!-- MANAGE TRAME COMP -->

      <!-- ACTIONS -->
      <button (click)="duplicate(trigger, i)" class="btn btn-default btn-xs">
        <i class="fa fa-clone" aria-hidden="true"></i>
        {{'Duplicate trigger' | translate}} 
      </button>
      <button (click)="openDialog(trigger, i)" class="btn btn-success btn-xs">
        <i class="fa fa-pencil" aria-hidden="true"></i>
        {{'Edit trigger' | translate}}
      </button>


      <button (click)="trameDialog(trigger.id)" class="btn btn-xs btn-success">
        <i class="fa fa-commenting" aria-hidden="true"></i>
        {{'Add trame' | translate}} 
      </button>

      <button class="btn btn-xs btn-danger dis-onhover delete" (click)="deleteConfirm(trigger, i)">
        <i class="fa fa-trash-o" aria-hidden="true"></i> {{'Delete trigger' | translate}}
      </button>
      <!-- ACTIONS -->




    </mat-expansion-panel>

    <mat-expansion-panel>
      <!-- HEADER -->
      <mat-expansion-panel-header>
        <mat-panel-title>
          <img src="/assets/img/icons/svg/kiosk-hard.svg"> {{'Maintenance' | translate}}
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- HEADER -->



      <!-- MANAGE TRAME COMP -->
      <app-manage-trames [actions]="false" [hotel]="hotel"  [categories]="categories"
        [trames]="triggersMaintenance" [groups]="groups" (editDialog)="openTramDialog($event)"></app-manage-trames>
      <!-- MANAGE TRAME COMP -->




    </mat-expansion-panel>


  </mat-accordion>


  <ng-container *appHasRole="'super-user'">
    <div class="white-box">
    <strong>{{'This part is shown only for super user' | translate}}</strong>
    <button class="btn btn-success" (click)="exportTriggers()">
      {{'Export / import' | translate}} 
    </button>
    
  </div>
  </ng-container>







</div>