/**
 * CONVERT DATA BEFOR DISPALY ON VIEW
 * @param content 
 */

export const convertBefore = function convertWorkflowBefore(content) {

  let newContent = { message: "", data: [], success: "" }
  for (let v of content.data) {
    let translations = [];
    for (let a in v.translations) {
      translations.push({ variable_name: a, content: v.translations[a] });
    }
    newContent.data.push({
      templates: v.templates,
      template_name: v.template_name,
      label: v.label,
      url_preview: v.url_preview,
      translations,
      template_id: v.template_id,
      is_general: v.is_general,
      component_id: v.component_id
    })
  }
  return newContent;
}

/**
* CONVERT DATA BEFOR SENT TO SERVER, SO AFTER EDITING 
*/
export const convertAfter = function convertWorkflowAfter(workflowContent) {
  let newContent = []
  //  console.log('gett', workflowContent)
  for (let template of workflowContent) {
    let trans = {};
    for (let v of template.translations) {
      trans[v.variable_name] = v.content
    }
    let item = {
      template_name: template.template_name,
      translations: trans,
      component_id:template.component_id,
      is_general:template.is_general,
      order:template.order,
      template_id:template.template_id,
      templates: template.templates
    };
    newContent.push(item);
  }
  //  console.log(newContent)
  return newContent;
}
