import { NgxSpinnerService } from 'ngx-spinner';
import { WorkflowService } from 'src/app/shared/services/workflow.service';

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-form-stepper',
  templateUrl: './form-stepper.component.html',
  styleUrls: ['./form-stepper.component.scss']
})
export class FormStepperComponent implements OnInit {

  isLinear = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  currentLang = 'en';
  defaultLang = 'en'
  @Input() response = {};
  @Input() hotel: any;
  @Input() languages: any;
  @Input() form: any;
  @Output() saveForm = new EventEmitter();
  @Input() variablesAPILIST = [];
  @Input() customVars;

  @ViewChild('stepper') stepper: MatStepper;


  constructor(
    private _formBuilder: UntypedFormBuilder,
    private workflowService: WorkflowService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  saveEvent() {
    this.saveForm.emit(this.response);
  }

  autoTranslate() {
    let obj = this.findTextsToTranslate();

    if (obj.texts.length) {
      this.spinner.show('global');

      this.workflowService.translate({ texts: obj.texts, lang: this.currentLang, lang_src: this.defaultLang }).subscribe(res => {

        for (let k in res['translations']) {
          this.response[obj.keys[k]] = res['translations'][k].translation
        }
        this.spinner.hide('global');
        if(res['error']){
          alert(res['error']);
        }

      }, err => {
        alert('An error was accured');
        this.spinner.hide('global');
      })
    } else {
      alert('Nothing to translate')
    }
  }

  setCurrentLang(lang) {
    console.log(lang, "-------------")
    this.currentLang = lang
  }

  /**
   *
   */
  findTextsToTranslate() {
    let texts = [];
    let keys = []
    if(this.defaultLang != this.currentLang){
      for (let i in this.response) {
        //IF TRANSLATED FIELD : i.indexOf('_en') exist; and not image
        if (i.indexOf('_' + this.currentLang) != -1 && this.response[i].indexOf('/') == -1) {

          //GET TEXT OF DEFAULT LANG : this.response['fieldName_en']
          let text = this.response[i.replace('_' + this.currentLang, '_' + this.defaultLang)];

          //ADD TEXT TO TRANSLATE TO LIST, IF NOT NULL
          if (text) {
            texts.push(text) //FILL TEXT VALUES
            keys.push(i); // FILL TEXT KEYS
          }
        }
      }
    }else {
      alert("Can't translate default language");
    }
    return { texts, keys };
  }

  /**
   *
   * @param $event
   */
  getReaction($event){
    if($event == "submit"){
      this.saveForm.emit(true);
    }else {
      this.stepper.selectedIndex = $event;
    }
  }
}
