import { Subject } from 'rxjs';

import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appIsLink]'
})
export class IsLinkDirective implements OnInit, OnDestroy {

  @Input() appIsLink: string;

  stop$ = new Subject();
  isVisible = false;



  /**
   * @param {ViewContainerRef} viewContainerRef
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef
   *   -- the templateRef to be potentially rendered
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    //private rolesService: RolesService
  ) { }


  ngOnInit() {
    // is not null content  && ( doesnt contain http or doenst contain /)
    /**
     *  @param iconPath '/assets/img.png' --> true
     *  @param iconFullPath 'http://localhost/static/img/assets/img.png --> true
     * */

    if (this.appIsLink && (this.appIsLink.indexOf('http') != -1 || this.appIsLink.indexOf('/') != -1)) {
      this.isVisible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);

    } else {
      // If the user does not have the role,
      // we update the `isVisible` property and clear
      // the contents of the viewContainerRef
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }
  // Clear the subscription on destroy
  ngOnDestroy() {
    //this.stop$.next();
  }
}
