import { Subject } from 'rxjs';

import { Directive, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[CustomerType]'
})
export class CustomerTypeDirective implements OnInit , OnDestroy  {

  @Input('CustomerType') CustomerType: string;

  isVisible = false;
  stop$ = new Subject();

  constructor() { }

  ngOnInit(){
  }
  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next('');
  }
}
