import { _commun, _multiple } from './_commun';

/**
 * CHECKBOX
 */
export const _checkbox = {
     ..._commun,
     ..._multiple,
     "type": "checkbox",
     "other": true,
     "type_name":"Multiple choice",
}


