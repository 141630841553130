<div class="row">
    <div class="col-md-3 text-right">
        <label for="" class="label">{{'Offer Description' | translate}}</label>
    </div>
    <div class="col-md-8 form-group">
        <input type="text" [(ngModel)]="offer.NAME" class="form-control" *ngIf="offer">
    </div>
</div>

<!-- validity -->
<div class="row" *ngIf="offer && !offer.IS_PERMANENT">
    <div class="col-md-3 label text-right">
        <label for="">{{'Validity Period' | translate}}</label>
    </div>
    <div class="col-md-4" >
        <input [owlDateTime]="dt1" [min]="today" [owlDateTimeTrigger]="dt1" placeholder="{{'Date Time' | translate}}" class="form-control"
            [(ngModel)]="offer.DATE_START">
        <owl-date-time #dt1></owl-date-time>
    </div>
    <div class="col-md-4">

        <input [owlDateTime]="dt2" [min]="today" [owlDateTimeTrigger]="dt2" placeholder="{{'Date Time' | translate}}" class="form-control"
            [(ngModel)]="offer.DATE_STOP">
        <owl-date-time [startAt]="startAt" #dt2></owl-date-time>
    </div>
</div>
<!-- validity -->


<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-3">
        <br>
        <!--checkbox-->
        <div class="form-group">
            <label class="container-checkbox"> {{'Is permanent' | translate}}
                <input type="checkbox" [(ngModel)]="offer.IS_PERMANENT" *ngIf="offer" (change)="updateIsPermanent($event)">
                <span class="checkmark"></span>
            </label>
        </div>
        <!--checkbox-->
    </div>

    <div class="col-md-3">
        <br>
        <!--checkbox-->
        <div class="form-group">
            <label class="container-checkbox"> {{'Is active' | translate}}
                <input type="checkbox" [(ngModel)]="offer['IS_ACTIVATE']" *ngIf="offer">
                <span class="checkmark"></span>
            </label>
        </div>
        <!--checkbox-->
    </div>
    
</div>


<div class="row label">
    <div class="col-md-3"></div>

    <div class="col">
        <!--checkbox-->
        <div class="form-group">
            <label class="container-checkbox">{{'WALKIN' | translate}}
                <input type="checkbox" [(ngModel)]="offer['IS_WALKIN']" >
                <span class="checkmark"></span>
            </label>
        </div>
        <!--checkbox-->
    </div>
    <div class="col">
        <!--checkbox-->
        <div class="form-group">
            <label class="container-checkbox">{{'UPGRADE' | translate}}
                <input type="checkbox"  [(ngModel)]="offer['IS_UPGRADE']">
                <span class="checkmark"></span>
            </label>
        </div>
        <!--checkbox-->
    </div>
    <div class="col">
        <!--checkbox-->
        <div class="form-group">
            <label class="container-checkbox">{{'UPSELL' | translate}}
                <input type="checkbox"   [(ngModel)]="offer['IS_UPSELL']">
                <span class="checkmark"></span>
            </label>
        </div>
        <!--checkbox-->
    </div>
</div>


