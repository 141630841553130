import { _commun, _multiple } from './_commun';

export const _image = {
    ..._commun,
    "type": "image",
    "type_name":"Image field",
    "multiple":false,
    "inline":false,
    "maintainRatio":false,
    "cropperWidth":300,
    "cropperHeight":300,
}