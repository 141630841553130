import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DefaultValueService } from '../../../../shared/services/default-value.service';

@Component({
  selector: 'app-dialog-default-values',
  templateUrl: './dialog-default-values.component.html',
  styleUrls: ['./dialog-default-values.component.scss']
})
export class DialogDefaultValuesComponent implements OnInit {

  constructor(
  	public dialogRef: MatDialogRef<DialogDefaultValuesComponent>,
    @Inject(MAT_DIALOG_DATA) public data :any,
    private dv : DefaultValueService,
    ) { }

  ngOnInit() {

  }

  deleteItem(key){

    delete this.data.values[key]
  }

}
