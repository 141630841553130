<h2 mat-dialog-title>{{'Sliders' | translate}} ({{sliders?.length}})
  <!-- <button class="btn btn-success btn-sm" (click)="toggleSize()"><i class="fa fa-bars" aria-hidden="true"></i></button> -->
</h2>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
  standard dummy text ever since the 1500
  <br>
  <br>
  <button class="btn btn-success btn-sm"  (click)="editSlider()">
    <i class="fa fa-plus" aria-hidden="true"></i>
     {{'Add new slider' | translate}}
  </button>
</p>
 
    
  
<mat-dialog-content class="mat-typography">

  <div
  cdkDropListLockAxis
  cdkDropList cdkDropListOrientation="horizontal" 
  class="slider-list container-list-drop box-scroll"
  [ngClass]="size"
  id="slider-scroll"

    (cdkDropListDropped)="drop($event)">
     
    <div class="slider-box" *ngFor="let slider of sliders; let i = index" cdkDrag>
 
  
      <div class="custom-placeholder" *cdkDragPlaceholder></div>

      <!--delete btn-->
      <button class="btn dis-onhover delete"
        (click)="deleteConfirm(slider, i)">
        <i class="fa fa-trash-o" aria-hidden="true"></i>
      </button>
      <!--delete btn-->

      <!--img-->
      <img src="{{buildUrl(slider.first_image)}}" class="slider">
      <div class="text">
        <div class="row vertical-align">
          <div class="col desc">
            {{slider.first_description}}
          </div>
          <div class="col text-right">
              <button  class="btn btn-white"><i class="fa fa-arrows"
                aria-hidden="true"></i></button>
              <button class="btn btn-white" (click)="editSlider(slider)">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              </button>
          </div>
        </div>
      </div>
      <!--img-->


    </div>
   
  </div>



</mat-dialog-content>