import { WorkflowService } from 'src/app/shared/services/workflow.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-preview-list',
  templateUrl: './dialog-preview-list.component.html',
  styleUrls: ['./dialog-preview-list.component.scss']
})
export class DialogPreviewListComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogPreviewListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private workflowService: WorkflowService

  ) { }

  url = {};
  languages = this.data.languages;
  hotel = this.data.hotel;
  lang = this.data.defaultLang;
  init = false;
  urls = [];
  grid = 4;

  ngOnInit() {
    this.getSafeUrl();
  }


  updateGrid(val){
    this.grid = val;
  }
  getSafeUrl(){
    let i = 0;
    this.urls = []
    for(let step of this.data.workflows){
      if(!step.is_general){
        i++;
        this.urls.push({i,name :step.template_name, url:this.sanitizer.bypassSecurityTrustResourceUrl(step.url_preview.replace('http','https') + this.lang)})
      }
   }
    this.init = true;
   }

  updateLang(val){
    if(this.lang != val){
      this.init = false;
      this.lang = val;
      this.getSafeUrl();
      this.init = true;
    }

  }

    /**
   * DISPLAT GOOD TEXT
   * @param text
   */
  formatText(text) {
    return text.split('_').join(' ')
  }


}
