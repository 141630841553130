<!-- Page Content -->
<div class="container-fluid">
  <app-fixed-title [title]='title' [hotel]='hotel' *ngIf="!onglet"></app-fixed-title>

  <h4 class="mt-3" *ngIf="onglet">{{title}}</h4>

  <div class="white-box content">
    <mat-tab-group>
      <mat-tab [label]="getName(tab)" *ngFor="let tab of tabs">
        <div class="row">
          <!--fetch worflows-->
          <div class="col-lg-3 col-md-6" *ngFor="let workflow of groupedWorflows[tab]; let i = index"
            [routerLink]="['/workflow-translate', slug, workflow.id]">
            <!-- card -->
            <div class="card">
              <!-- media -->
              <div class="media">
                <img src='/assets/img/icons/svg/workflow-icon.svg' alt="">
                <div class="media-body">
                  <h4 class="mt-0">{{i+1}} - {{workflow.WORKFLOW_NAME}}</h4>
                  <p *appHasRole="'super-user'"> {{workflow.UUID_WORKFLOW}} - ID : {{workflow.id}}</p>
                </div>
              </div>
                <!-- end media -->
              <!-- btn -->
                 <button class="btn btn-success">{{'Translate content' | translate}}</button>
            
              <!-- btn -->
    
            </div>
            <!-- end card -->
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>


  

  </div>
</div>