<ng-container *ngIf="hotel">

  <!-- boucle languages -->
  <div *ngFor="let lang of hotel.languages" class="{{className}} form-translate-{{lang.lang_code}} translated" [ngClass]="{'now-active':currentLang == lang.lang_code && changeEvent}">
    <ng-container  *ngIf="currentLang == lang.lang_code">
    <!--label-->
    <label *ngIf="translate && label">{{label | translate }} ({{lang.lang_code}}) 
      <small *ngIf="displayDefaultTrans && model[getAttr(attr, defaultLangVal)] && type != 'htmleditor'">(Ref : {{model[getAttr(attr, defaultLangVal)]}} )</small>
    </label>
    <label *ngIf="!translate  && label">{{label}} </label>

    <!-- small description -->
    <small *ngIf="description">{{description | translate}}</small>

    <!--textarea-->
    <textarea *ngIf="type == 'textarea'" [(ngModel)]="model[getAttr(attr, lang)]" rows="{{rows ? rows : 5 }}"
      class="form-control" (input)="changed($event, lang.id)" (blur)="updateAction($event)">
    </textarea>

    <!--input-->
    <input autocomplete="off" *ngIf="type == 'input' || type == 'text'" type="{{input ? input : 'text'}}" name="" class="form-control"
      [(ngModel)]="model[getAttr(attr, lang)]" (input)="changed($event, lang.id)" (blur)="updateAction($event)" placeholder="{{placeholder}}">

    <!-- html editor -->
    <app-html-editor [model]="model" [attr]="getAttr(attr, lang)" [hotel]="hotel" *ngIf="type == 'htmleditor'"></app-html-editor>
  </ng-container>
  </div>
  


  <!--default value get the value of default language-->
  <input type="text" [(ngModel)]="model[attr]" style="display:none" *ngIf="initialAttr && defaultLang">
</ng-container>