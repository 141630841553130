<h1 class="mat-dialog-title">{{'Config module Supervision' | translate}}</h1>
<div mat-dialog-content>
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        {{'Displayed Columns' | translate}}
      </ng-template>
      <app-supervison-table-columns (updateEvent)="updateEvent($event)" [displayedColumns]="data.displayedColumns" [columns]="data.headers"></app-supervison-table-columns>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        {{'Manage columns order' | translate}}
      </ng-template>
      <p>{{'You can sort the table columns' | translate}}</p>
      <app-supervision-table-config [displayedColumns]="data.displayedColumns" [displayedFilter]="data.displayedFilter" [options]="{filter:false, drag:true}" [headers]="data.headers"></app-supervision-table-config>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions align=end>
  <button class="btn btn-default" [mat-dialog-close]="">{{'Cancel' | translate}}</button>
  <button class="btn btn-success" [mat-dialog-close]="{headers:data.displayedColumns, charts:data.chartsConfig}" >{{'Confirm' | translate}}</button>
</div>