import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { server, URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

 	uri = URI;

	constructor(private http: HttpClient) {
	}


	/**
	 *
	 * @param data
	 */
	postData(data){
		return this.http.post(this.uri+"stats/", data)
	}


	/**
	 *
	 * @param params
	 */
	getData(params){
		return this.http.get(this.uri+params)
	}


	/**
	 *
	 * @param params
	 */
	getDataWithQuery(params){
		return this.http.get(this.uri+"stats/",{params})
	}

	/**
	 *
	 * @param id customer_id
	 */
	getPMSstatus(id){
		return this.http.get(this.uri+"pms-connected/"+id);
	}

	getPms(params = ''){
		return this.http.get(this.uri+"pms/"+params);
	}



	/**
	 *
	 * @param template
	 * @param slug
	 * @param period
	 * @param endpoint
	 * @param method
	 */
	getStatsResult(temp, slug, period = null){
		let obj = {
			template : temp['template_api'],
			period: temp['default_period'],
			method :temp['method'],
			endpoint : temp['endpoint']
		}
		if(temp.display_type == 'pms'){
			return this.getPMSstatus(slug)
		}
		if(!temp.can_change_period){
			period = obj.period;
		}
		if(obj.method == 'POST'){
			let fb = new FormData();
			fb.append('customer', slug);
			fb.append('template', obj.template);
			fb.append('period', period? period : obj.period);

			return this.http.post(this.uri+obj.endpoint+'/', fb)
		}else {
			let params = new HttpParams()
			.set('period', obj.period)
			.set('type', obj.template)
			.set('customer_id', slug)
			return this.http.get(this.uri+obj.endpoint, {params:params})
		}

	}

}
