<h2 class="mat-dialog-title with-trans">
  {{slide.id ? 'Edit slider' : 'Add Slider' }}
</h2>

<mat-dialog-content class="mat-typography">
  <input-validator *ngIf="errors" [errors]="errors"></input-validator>

  <div class="edit-slider row">
    
    
    <div class="col-lg-6 form-group">
     <input type="number" class="form-control" [(ngModel)]="slide.th_width">
    </div>

    <div class="col-lg-6">
      <input type="number" class="form-control" [(ngModel)]="slide.th_height">
    </div>
 
    <ng-container *ngIf="languages && hotel">
      <div class="col-lg-12 file form-group" *ngFor="let lang of hotel.languages">
        <form-select-icons label="First slide Image ({{lang.lang_code}})" [attr]="{name : 'image_'+lang.lang_code}" [model]="slide" [hotel]="hotel" size='big' [tab_icon]="false"></form-select-icons>
      </div>
    </ng-container>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="addOrEditSlide(slide)"  class="btn btn-success">
    {{slide.id ? 'Edit Slide' : 'Add Slide' }}
  </button>
</mat-dialog-actions>