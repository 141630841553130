import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-menu-module',
  templateUrl: './dialog-menu-module.component.html',
  styleUrls: ['./dialog-menu-module.component.scss']
})
export class DialogMenuModuleComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogMenuModuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}

  module="";

  ngOnInit() {

  }

  getMenuParamsFromModule(module){
    return {
      name: module.name,
      link: "/module-worker/"+this.data.slug+"/"+module.id,
      param: false,
      icon: module.configs.icon,
      role: module.configs.permission,
      active: true,
      id : "module_"+module.id
    }
  }
}
