import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { copyAttr, iterationCopy } from 'src/app/helpers/commun';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CategoryService } from '../../../shared/services/category.service';
import { HotelService } from '../../../shared/services/hotel.service';
import { ItemService } from '../../../shared/services/item.service';
import { SubcategoryService } from '../../../shared/services/subcategory.service';
import { CATEGORY_TYPE } from '../../../shared/settings/_const';
import { ConfirmDeleteComponent } from '../../modals/confirm-delete/confirm-delete.component';
import { DialogErrorComponent } from '../../modals/dialog-error/dialog-error.component';
import { category_attr } from '../fields/category-fields';
import { item_attr } from '../fields/item-fields';
import { subcat_attr } from '../fields/subcategory-fields';
import { DialogCategoryComponent } from '../modals/dialog-category/dialog-category.component';
import { DialogChooseComponent } from '../modals/dialog-choose/dialog-choose.component';
import { DialogDuplicateComponent } from '../modals/dialog-duplicate/dialog-duplicate.component';
import { DialogGetDetailComponent } from '../modals/dialog-get-detail/dialog-get-detail.component';
import { DialogSubcategoryComponent } from '../modals/dialog-subcategory/dialog-subcategory.component';

@Component({
    selector: 'app-build-categories',
    templateUrl: './build-categories.component.html',
    styleUrls: ['./build-categories.component.scss'],
})
export class BuildCategoriesComponent implements OnInit {

    private readonly notifier: NotifierService;


    constructor(
        public cats: CategoryService,
        public dialog: MatDialog,
        public item: ItemService,
        public subcat: SubcategoryService,
        private route: ActivatedRoute,
        private hs: HotelService,
        notifierService: NotifierService,
        private translate: TranslateService,
        private _router: Router,
        public items: ItemService,
        public subcategories: SubcategoryService,
        private spinner: NgxSpinnerService

    ) {
        this.notifier = notifierService;
    }

    @Input() hotel: any;
    @Input() categories: any;
    @Input() slug: any;
    @Input() title = "Item crud";
    @Input() category_type = "RoomService";
    @Input() filteredCategories: any;
    @Input() id: any;
    @Input() label = "Category";
    @Input() subcategoryIsOption = true;
    @Input() superFields: any;
    @Input() displaySubcategory: any;
    @Input() categoryId: any;
    @Input() presentation: any;
    @Input() hideprice = true;
    @Input() onglet=false;


    languages: any;
    copy: any;
    searchResults = [];

    see_details = {};
    category_attr = category_attr;
    item_attr = item_attr;
    subcat_attr = subcat_attr;
    CATEGORY_TYPE = CATEGORY_TYPE;


    ngOnInit() {

        this.spinner.show('global');

        if (typeof (this.displaySubcategory) == "undefined") {
            this.displaySubcategory = true;
        }
        this.route.params.subscribe(params => {

            if (params.slug == "undefined") {
                this._router.navigate(['/hotels'])
            }

            this.slug = params.slug

            this.hs.getLanguages().subscribe((data) => {
                this.languages = data;

            })

            this.hs.getHotel(this.slug).subscribe((data) => {
                this.hotel = data;
                this.id = this.hotel.id;
                this.initCategory();
            });



        });

    }

    /**
     *
     */
    initCategory(){
        this.cats.getCategoriesByType(this.slug, this.categoryId).subscribe((data) => {
            this.spinner.hide('global');
            this.categories = data;
            console.log(data, "kkkkkk")
            this.filteredCategories = this.categories;
            let a = [703, 704];
            let i= 0
            

            for (let categorie of this.categories) {
                this.see_details[categorie['id']] = false;
            }
        }, () => {
            this.dialog.open(DialogErrorComponent, {
                width: '500px'
            })
            this.spinner.hide('global');
        })
    }


    /**
     * DIALOG ADD CATEGORY
     * @param cat
     */
    openDialogCategory(cat = null) {
        let category = cat ? cat : { hotel: this.id, category_type: this.category_type, ranked: 0, visible: true }
        console.log('add categorie')
        const dialogRef = this.dialog.open(DialogCategoryComponent, {
          data: {
              languages: this.languages,
              category,
              attrs: this.category_attr,
              hotel: this.hotel,
              superFields: this.superFields,
              category_type: this.category_type,
              categories: this.categories,
          }
      });
    }

    /**
     * DIALOG EDIT CATEGORY
     * @param category
     */
    editCategory(category) {
        const dialogRef = this.dialog.open(DialogCategoryComponent, {
            data:
            {
                category,
                attrs: this.category_attr,
                hotel: this.hotel,
                superFields: this.superFields,
                languages: this.languages,
                category_type: this.category_type,
                categories: this.categories
            }
        });
        dialogRef.afterClosed().subscribe(res=>{
            if(res.success){
                category = res.category
            }
        })

    }

    /**
     *
     * @param items
     * @param res
     * @param subcategory_item
     */
    createItems(items, res, subcategory_item) {
        for (let item of items) {
            if (subcategory_item) {
                item = { ...item, id: null, sub_category: res['id'], room_service:null, map_position:null, transport:null }
            } else {
                item = { ...item, id: null, category: res['id'] }
            }
            this.items.addItem(item).subscribe(() =>{})
        }
    }
    /**
     *
     * @param category
     * @param res
     */
    createSubcategories(category, res) {
        if (category.subcategories) {
            for (let subcategory of category.subcategories) {
                let new_subcategory = { ...subcategory, id: null, category: res['id'] }
                this.subcategories.addSubcategory(new_subcategory).subscribe(res => {
                    if (subcategory.items.length) {
                        this.createItems(subcategory.items, res, true);
                    }
                })
            }
        }
        if (category.items) {
            this.createItems(category.items, res, false);
        }
    }


    /**
     *
     * @param category
     */
    openChooseDialog(category): void {
        let copy = iterationCopy(category);
        copy  = copyAttr(copy, ['name'], this.languages );
        copy['ranked'] = 0;
        delete copy['id'];
        const newDialogRef = this.dialog.open(DialogChooseComponent, {
            data: copy, width:"600px"
        });
        newDialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.spinner.show('global');

                setTimeout(()=>{this.initCategory()}, 2000)

                this.cats.postCategory(res).subscribe(result => {
                    if (result) {
                        this.categories.unshift(result);
                        this.updatePosition();
                        this.createSubcategories(res, result);
                        this.notifier.notify('success', 'Category successfully created');
                    }
                })
            }
        })
    }

    /**
     *
     * @param category
     */
    openDuplicateDialog(category): void {
        let copy = { ...category, items: null, id: null, subcategories: null, name: "copy " + category.name };

        if (category.subcategories.length != 0 || category.items.length != 0) {
            const dialogRef = this.dialog.open(DialogDuplicateComponent, {
                width: '600px',
                data: { name: category.name, type: 'Category' }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result == 'yes') {
                    this.openChooseDialog(category);
                } else if (result == 'no') {
                    this.openDialogCategory(copy);
                }
            });

        } else {
            this.openDialogCategory(copy);
        }
    }

    /**
     * DUPLICATE CATEGORY
     * @param category
     */
    duplicateCategory(category) {
        this.openDuplicateDialog(category);
    }


    /**
     * DELETE CATEGORY BY INDEX
     * @param category
     * @param index
     */
    deleteCategory(category, index) {
        this.openDialogCat(category, index)
    }

    /**
     * delete category confirm
     * @param category
     * @param index
     */
    openDialogCat(category, index): void {
        let customtext = '';
        if (category.subcategories.length) {
            customtext = 'All sub-categories within this category will be deleted';
        } else if (category.items.length) {
            customtext = 'All items within this category will be deleted';
        }
        const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            width: '490px',
            data: customtext ? { customtext , name: category.name} : { name: category.name, type: 'Category' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'delete') {
                this.cats.deteleCategory(category.id).subscribe(
                    () => {
                        this.translate.get('Category successfully deleted').subscribe(
                            value => {
                                this.notifier.notify('error', value);
                            }
                        );
                        this.categories.splice(index, 1);
                    },
                    () => {
                        this.translate.get('An error has occurred').subscribe(
                            value => {
                                this.notifier.notify('error', value);
                            }
                        );
                    }
                )
            }
        });
    }


    /**
     * ADD SUBCATEGORY
     * @param category
     */
    addSubCategory(category, subcat = null) {

        this.dialog.open(DialogSubcategoryComponent, {
            data: {
                subcategory: subcat ? subcat : { category: category.id, visible: true },
                hotel: this.hotel,
                attrs: this.subcat_attr,
                superFields: this.superFields,
                languages: this.languages,
                category,
            }
        });

    }

    /**
     * DRAGE AND DROP EVENT
     * @param event
     */
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.categories, event.previousIndex, event.currentIndex);


        this.updatePosition();

    }

    updatePosition(){
        let obj = {
            categories: this.categories.map(a => a.id),
            hotel: this.hotel.id,
            category_type: this.category_type
        }
        this.cats.sortCategories(obj).subscribe(
            () => {
                this.translate.get('Category order successfully updated').subscribe(
                    value => {
                        this.notifier.notify('success', value);
                    }
                );
            },
            () => {
                this.translate.get('An error has occurred').subscribe(
                    value => {
                        this.notifier.notify('error', value);
                    }
                );
            }
        )
    }
    /**
     * GET CATEGORY DETAILS
     * @param id
     */
    getDetails(category) {

        this.see_details[category.id] = !this.see_details[category.id];
        const dialogRef = this.dialog.open(DialogGetDetailComponent, {
            data: {
                model:category,
                label: 'Category',
                attrs: this.category_attr,
            },
            width: '700px'
        });
        dialogRef.afterClosed().subscribe(() => {
            this.see_details[category.id] = !this.see_details[category.id];
        });

    }


    /**
     * SEARCH FUNCTION
     * @param item
     * @param searchValue
     */
    search(item, searchValue) {
        let response = false;
        //search in category name
        if (item.name) {
            response = (item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
        }
        //search in category short_desc
        if (item.short_description) {
            response = response || (item.short_description.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
        }
        //search in subcategory
        if (item.subcategories) {
            let result = Object.assign([], item.subcategories).filter(
                subcategory => subcategory.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            ).length
            response = response || !!result;
        }
        for (let sub of item.subcategories) {
            if (sub.items) {
                let result = Object.assign([], sub.items).filter(
                    itemOfObject => itemOfObject.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                ).length
                response = response || !!result;
            }
        }
        return response;
    }

    /**
     * SEARCH FUNCTION SUBCAT
     * @param searchValue
     */
    searchInCategories(searchValue: string) {
        this.searchResults = [];
        if (!searchValue) {
            this.filteredCategories = Object.assign([], this.categories);
        } else {
            this.filteredCategories = Object.assign([], this.categories).filter(
                item => this.search(item, searchValue),
            )
        }

    }

    /**
     * FIND ITEMS AND SUBCAT LENGTHS
     * @param category
     */
    lengthItems(category) {
        if (category.items && category.items.length) {
            return category.items.length;
        } else {
            let items = 0;
            for (let v in category.subcategories) {
                if (category.subcategories[v].items) {
                    items += category.subcategories[v].items.length;
                }
            }
            return items;
        }

    }

}
