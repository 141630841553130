<div class="row">
    <!--checkbox-->
    <div class="col-3"></div>
    <div class="form-group col-6">
        <label class="container-checkbox"> {{'Is Free' | translate}}
            <input type="checkbox" name="offer"
                [attr.checked]="getValue(offer['PROMOTIONS']['is_free'], 'True') ? '' : null"
                (change)="updateModel($event,'PROMOTIONS.is_free', 'True')">
            <span class="checkmark"></span>
        </label>
    </div>
    <!--checkbox-->

    <div class="col-md-12 label" [ngClass]="{'hidden':offer['PROMOTIONS']['is_free'] == 'True'}">
        <div class="row">
            <div class="col-md-3 text-right">
                <label> {{'Promotions/Reductions' | translate}} </label>
            </div>

            <div class="form-group col-md-3">
                <input type="text" class="form-control" [(ngModel)]="offer['PROMOTIONS']['value']">
            </div>
            <div class="form-group col-md-2">
                <select id="" class="form-control" *ngIf="offer" [(ngModel)]="offer['PROMOTIONS']['type']">
                    <option selected="selected">{{'None' | translate}}</option>
                    <option value="percent">%</option>
                    <option value="amount">{{'euros' | translate}}</option>
                    <option value="fixed_price">{{'Fix Price(€)' | translate}}</option>
                    <option value="percent_of_invoice">{{'Percent of invoice' | translate}}</option>
                    <option value="amount_of_invoice">{{'Amount on price paid' | translate}}</option>
                    <option value="purcentage_add_conditional_product">{{'Conditional product' | translate}}</option>

                </select>
            </div>
        </div>

    </div>


    <div class="col-md-12 label" [ngClass]="{'hidden':offer['PROMOTIONS']['is_free'] == 'True'}">
        <div class="row">
            <div class="col-md-3 text-right">
                <label> {{'Items upgrade fix' | translate}} </label>
            </div>


            <div class="form-group col-md-5">
                <select id="" class="form-control" *ngIf="offer" [(ngModel)]="offer['PROMOTIONS']['items_upgrade']">
                    <option selected="selected">{{'None' | translate}}</option>
                    <option *ngFor="let item of items" value="{{item.item_pms_id}}">{{ item.name }}</option>
                </select>
            </div>
        </div>

    </div>

</div>

<div class="row">
    <!-- ligne -->
    <div class="col-3"></div>
    <div class="form-group  col-6">
        <label class="container-checkbox"> {{'Apply to total Invoice' | translate}}
            <input type="checkbox"
                [attr.checked]="getValue(offer['PROMOTIONS']['applyTo']['type'], 'invoice') ? '' : null"
                (change)="updateModel($event, 'PROMOTIONS.type_show', 'invoice')" *ngIf="offer">
            <span class="checkmark"></span>
        </label>
    </div>
    <!-- ligne -->
</div>
<div class="row">
    <!-- ligne -->
    <div class="col-md-3 text-right">
        <label for="" class="label"> {{'Price Room type' | translate}}</label>
    </div>

    <div class="col-md-7">
        <mat-select class="form-control" *ngIf="priceType" [multiple]="true"
            [(ngModel)]="offer['PROMOTIONS']['price_room_type']">
            <mat-option *ngFor="let item of priceType" [value]="item.id">
                {{item.text}}
            </mat-option>
        </mat-select>


    </div>
    <!--ligne-->
</div>
<div [ngClass]="{'hidden':offer['PROMOTIONS']['type_show'] == 'invoice'}">
    <div class="row">
        <!-- ligne -->
        <div class="col-3"></div>
        <div class="form-group  col-6">
            <label class="container-checkbox"> {{'NOT APPLY TO ROOMS' | translate}}
                <input type="checkbox" *ngIf="offer"
                    [attr.checked]="getValue(offer['PROMOTIONS']['no_apply_promo_to_rooms'], 'True') ? '' : null"
                    (change)="updateModel($event, 'PROMOTIONS.no_apply_promo_to_rooms', 'True')">
                <span class="checkmark"></span>
            </label>
        </div>
        <!-- ligne -->
    </div>

    <div class="row" [ngClass]="{'hidden':offer['PROMOTIONS']['no_apply_promo_to_rooms'] ==  'True'}">
        <!-- ligne -->
        <div class="col-md-3 text-right">
            <label for="" class="label"> {{'Rooms in these categories' | translate}}</label>
        </div>

        <div class="col-md-7 form-group">
            <mat-select class="form-control" *ngIf="offer" [multiple]="true"
                [(ngModel)]="offer['PROMOTIONS']['applyTo']['categories']">
                <mat-option *ngFor="let item of rooms" [value]="item.id">
                    {{item.text}}
                </mat-option>
            </mat-select>


        </div>
        <!--ligne-->
    </div>
    <div [ngClass]="{'hidden':offer['PROMOTIONS']['type_show'] ==  'True'}">
        <div class="row">
            <!-- ligne -->
            <div class="col-md-3 text-right">
                <label for="" class="label">{{'Rooms with these features' | translate}}</label>
            </div>

            <div class="col-md-7 form-group">
                <mat-select class="form-control" *ngIf="offer" [multiple]="true"
                    [(ngModel)]="offer['PROMOTIONS']['applyTo']['features']">
                    <mat-option *ngFor="let item of features" [value]="item.id">
                        {{item.name}}
                    </mat-option>
                </mat-select>



            </div>
            <!--ligne-->
        </div>
        <div class="row">
            <!-- ligne -->
            <div class="col-3"></div>
            <div class="form-group col-6">
                <label class="container-checkbox"> {{'NOT APPLY TO ITEMS' | translate}}
                    <input type="checkbox"
                        [attr.checked]="getValue(offer['PROMOTIONS']['no_apply_promo_to_items'], 'True') ? '' : null"
                        (change)="updateModel($event, 'PROMOTIONS.no_apply_promo_to_items', 'True')" *ngIf="offer">
                    <span class="checkmark"></span>
                </label>
            </div>
            <!-- ligne -->
        </div>
        <div class="row " [ngClass]="{'hidden':offer['PROMOTIONS']['no_apply_promo_to_items'] ==  'True'}">
            <!-- ligne -->
            <div class="col-md-3 text-right">
                <label for="" class="label">{{'Items' | translate}}</label>
            </div>

            <div class="col-md-7 form-group">
                <mat-select class="form-control" *ngIf="offer" [multiple]="true"
                    [(ngModel)]="offer['PROMOTIONS']['applyTo']['items']">
                    <mat-option *ngFor="let item of categories" [value]="item.id">
                        {{item.text}}
                    </mat-option>
                </mat-select>

            </div>
            <!--ligne-->
        </div>
    </div>
</div>