import { HardwareService } from 'src/app/shared/services/hardware.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-status-details',
  templateUrl: './dialog-status-details.component.html',
  styleUrls: ['./dialog-status-details.component.scss']
})
export class DialogStatusDetailsComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogStatusDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hardwareService : HardwareService
  )
  {}
  title : any;
  edit = false;
  ngOnInit() {
  	 this.filterTitle()
  }

  filterTitle(){
  	this.title = this.data.Status.find(x=>x.module== 'System').clientErrorMessage;
  }

  editName(){
    this.edit = !this.edit
  }
    /**
   *
   * @param st
   */
  getClass(st){
    if(st.Ok && st.Code != 2){
      return 'success';
    }else if(!st.Ok && st.Code == 2){
      return 'warning'
    }else if(!st.Ok  && st.Code == 1){
      return 'danger'
    }
    else {
      return 'info'
    }
  }

  updateHardware(){
    this.edit = false;
    this.hardwareService.updateHardware({id:this.data.id, NAME:this.data.Name}).subscribe(res=>{
      // this.data = res;
    })
  }

}
