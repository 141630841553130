import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { SignageService } from 'src/app/shared/services/signage.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-forms',
  templateUrl: './device-forms.component.html',
  styleUrls: ['./device-forms.component.scss']
})
export class DeviceFormsComponent implements OnInit {

  constructor(
    private hs: HotelService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private signage: SignageService,
    private spinner: NgxSpinnerService,
    notifierService: NotifierService,
    // private _el: ElementRef,
    // private dv: DefaultValueService,
    private translate: TranslateService,
  ) {
    this.notifier = notifierService;
  }
  private readonly notifier: NotifierService;


  deviceParamId: any;
  deviceParam: any;
  _breadCrumbs = [];
  slug: any;
  form: any;
  hotel: any;
  title = "Device "

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.deviceParamId = params.device;
      this.slug = params.slug
      this.signage.getDevicesDetails(this.deviceParamId).subscribe(res => {
        this.spinner.hide('global');
        this.title += res['NAME'];
        if (res) {
          this.deviceParam = res;
          //BREADCRUMBS
          this._breadCrumbs = [
            {
              "href": '/digital-signage/params',
              "label": "Devices Params"
            }, {
              "label": this.deviceParam.NAME
            }
          ]
          if (this.deviceParam['FORM_JSON']) {
            this.form = JSON.parse(this.deviceParam['FORM_JSON']);
            if (this.form && typeof (this.form.params) == 'undefined') {
              this.form = this.deviceParam['FORM_JSON'] != '{}' ? JSON.parse(this.deviceParam['FORM_JSON']) : [];
              this.form = { params: { autoTranslate: false }, sections: [{ sectionName: "Section 1", fields: this.form }] }
            }
          }

        }
      });


      //GET THE HOTEL
      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
        this.spinner.hide('global');
      });
    })
  }

  save() {
    this.deviceParam['FORM_JSON'] = JSON.stringify(this.form);

    this.signage.updateDevice(this.deviceParam).subscribe(res => {
      this.deviceParam = res;
      this.spinner.hide('global');
      this.translate.get('Form successfully updated').subscribe(
        value => {
          this.notifier.notify('success', value);
        }
      );
    })
  }

}
