import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-chart-conf',
  templateUrl: './dialog-chart-conf.component.html',
  styleUrls: ['./dialog-chart-conf.component.scss']
})
export class DialogChartConfComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogChartConfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  /**
 *
 * @param val
 * @param date
 */
  isDate(val, date = true) {
    if (date) {
      return val.toLowerCase().indexOf('date') != -1;
    } else {
      return val.toLowerCase().indexOf('date') == -1;
    }
  }

  ngOnInit() {
  }

}
