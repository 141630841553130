<h1 class="mat-dialog-title">
	<ng-container *ngIf="!edit">
		{{data.Name  | translate}} <i class="fa fa-pencil" aria-hidden="true" (click)="editName()"></i>
	</ng-container>
	<ng-container *ngIf="edit"> 
		<input type="text" class="form-control" [(ngModel)]="data.Name">
		<button class="btn btn-success" (click)="updateHardware()">{{'Save'| translate}}</button>
	</ng-container>
</h1>
<div mat-dialog-content>
	<h3></h3>
 	<table class="table table-striped">
   	 	<ng-container *ngFor="let status of data.Status">
	 		<tr *ngIf="status.Module != 'System' && status.module != 'System'">
				 <td style="width: 70px;">
						<span class="{{getClass(status)}} icon">
								<i [class]="status.icon"></i>
							</span>
						 </td>
				 <td style="width: 230px;">
				{{status.name_en | translate}}
	 			</td>
	 			<td>
					 {{status?.ClientErrorMessage}}
	 			</td>
	 		</tr>
 		</ng-container>
	 </table>
	 
</div>
<div mat-dialog-actions align=end>
  <button class="btn btn-success" [mat-dialog-close]="" >{{'OK'| translate}}</button>
</div>