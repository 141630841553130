import { DialogMediaComponent } from 'src/app/pages/media-manager/modals/dialog-media/dialog-media.component';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { URI } from 'src/app/shared/settings/global';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private hs: HotelService,
    private route: ActivatedRoute,

  ) { }

  slug;
  @Input() mediaManager = true;
  @Input() hotel;
  @Input() model : any;
  @Input() extra : any;
  @Input() attr : any;
  @Input() config = "full"; // medium + minimum
  @Output() changeEvent = new EventEmitter();
  @Output() onChange= new EventEmitter();
  @Input() options

  modelChange(event){
    this.changeEvent.emit(event);
  }

  addImage(url) {
    this.model[this.attr] += "<img src=" + url + ">"
  }
  openMediaManager() {
    /**
     * OPEN MEDIA MANAGER IN DIALOG
     * @param field
     */
    let data = { hotel: this.hotel, multiple: false };
    const dialogRef = this.dialog.open(DialogMediaComponent, { data, width: '1350px' });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.addImage(res);
      }
    })

  }

  /**
   *
   * @param $event
   */
  summernoteModelChange($event){
    this.onChange.emit($event);
  }

  ngOnInit() {
   this.options = {
      placeholder: '',
      tabsize: 2,
      height: 250,
      uploadImagePath: URI + 'media-upload-image/'+this.slug,
      toolbar: [],
      dialogsInBody : true
    };

    if(typeof this.model[this.attr] == 'undefined'){
      this.model[this.attr]=""
    }
    if (!this.hotel) {
      this.route.params.subscribe(params => {
        this.slug = params.slug

        this.hs.getHotel(this.slug).subscribe((data) => {
          this.hotel = data;
        });
      })
    }
    if(this.config == "full"){
      this.options.toolbar =  [
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear','fontsize', 'color','style', 'ul', 'ol', 'paragraph', 'height','table', 'link', 'hr','codeview', 'undo', 'redo']],
      ]
    }else if (this.config == "medium"){
        this.options.toolbar = [
          ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear','style', 'ul', 'ol', 'paragraph', 'height', 'picture']],
        ]
    }else if (this.config == "minimum"){
      this.options.toolbar = [
        ['font', ['bold', 'italic', 'underline','style', 'ul', 'ol', 'paragraph', 'height']],
      ]
    }
  }

}
