// import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { HotelService } from '../../shared/services/hotel.service';
import { MediaService } from '../../shared/services/media.service';
import { MEDIA } from '../../shared/settings/global';
import { DialogCropperComponent } from './modals/dialog-cropper/dialog-cropper.component';
import { DialogFolderComponent } from './modals/dialog-folder/dialog-folder.component';

@Component({
  selector: 'app-media-manager',
  templateUrl: './media-manager.component.html',
  styleUrls: ['./media-manager.component.scss']
})
export class MediaManagerComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public mediaService: MediaService,
    private route: ActivatedRoute,
    public hs: HotelService,
    private spinner: NgxSpinnerService,
    // private _lightbox: Lightbox
  ) {
    this.selectimage = new EventEmitter();
  }


  medias: any;
  slug: any;
  title = "Media manager";
  parent: any;
  strict: any;
  resp : any;
  uploadResponse = [];
  fb = new FormData();
  selected : any;
  initialRout = "";
  files  = [];

  image_extensions = [
    'png','gif', 'jpg', 'jpeg',
  ]
  @Input() maintainRatio = false; //for cropper
  @Input() cropperWidth = 300; //for cropper
  @Input() cropperHeight = 300; //for cropper
  @Input() cropperMaxWidth;
  @Input() aspectRatio = 1

  @ViewChild("fileUpload", {read:false}) fileUpload: ElementRef;
  @ViewChild("fileUploadCropper", {static:true}) fileUploadCropper: ElementRef;

  @Input() DIALOG = false;
  @Input() SelectMultiple = false;
  @Input() SelectedImages :any;
  @Input() hotel: any;
  @Output() selectimage : EventEmitter<any>;
  @Input() onglet = false;

  filteredMedia : any;




  ngOnInit() {

    this.spinner.show('media');

    //ROUTE PARAMS
    this.route.params.subscribe(params => {
      //GET HOTEL INPUT
      if(!this.hotel){
        this.slug = params.slug;
        this.hs.getHotel(this.slug).subscribe((data) => {
          this.hotel = data;
        });
      }else {
        this.slug = this.hotel.id
      }

      //FETCH MEDIA BY HOTEL
      this.mediaService.getMediaByHotel(this.slug).subscribe(res => {
        if (res['result']){
          //exclude files 
          console.log(res['result'].length)
          res['result'] = res['result'].filter(x=>x.name.indexOf('minifile_') == -1 && x.name.indexOf('file_'));
          console.log(res['result'].length)

          //SORT MEDIA BY NAME
          this.medias = res['result'].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

          console.log(this.medias.length)
          //SORT DATA BY TYPE
          this.medias = this.sortObjects(this.medias);
          this.filteredMedia = this.medias;
        }
        this.strict = res['initial-root'];
        this.parent = res['initial-root'];

        this.strict = res['initial-root'];
        this.parent = res['initial-root'];

        this.spinner.hide('media');

      }, err=>{
        this.spinner.hide('media');
      });

    });

  }

  /**
   * SEARCH MEDIA
   * @param searchValue
   */
  searchMedia(searchValue : string){
      if(!searchValue){
          this.filteredMedia = Object.assign([], this.medias);
      }else {
          this.filteredMedia = Object.assign([], this.medias).filter(
              media => (media.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
          )
      }
  }


  /**
   * LIGHTBOX
   * @param img
   */
  openImage(img){
    // this._lightbox.open([
    //   {
    //     src  : img,
    //     thumb: img,
    //     caption : '',
    //     downloadUrl:img
    //   }
    // ]);
  }

  /**
   * CLOSE LIGHTBOX
   */
  close(): void {
    // close lightbox programmatically
    // this._lightbox.close();
  }


  /**
   * UPLOAD IMAGE
   * @param event
   * @param attr
   */
  imageSelected(event) {
    console.log(event)
    const fileUploadCropper = this.fileUploadCropper.nativeElement;
    let extension = event.target.files[0].name.split('.').pop(); 
    

    //IF NOT AN IMAGE UPLOAD FILE DIRECTLY
    if( this.image_extensions.indexOf(extension) == -1){
      let i = 0;
      for(let file of event.target.files){
        this.saveFile(file);
        i++;
      }
      (i == event.target.files.length ) && this.parseFolder(this.parent);
    }
    //IF IMAGE OPEN DIALOG CROPPER
    else {
      const dialogref = this.dialog.open(DialogCropperComponent, {
        data: {
          fileUploadCropper,
          index: 0,
          event,
          parent: this.parent,
          uploadResponse: this.uploadResponse,
          maintainRatio : this.maintainRatio,
          cropperWidth:this.cropperWidth,
          cropperHeight : this.cropperHeight,
          cropperMaxWidth : this.cropperMaxWidth,
          aspectRatio : this.aspectRatio
        }
        , width: '1600px'
      });
      dialogref.componentInstance.onAdd.subscribe((data: any) => {
  
          setTimeout(()=>{
            this.parseFolder(this.parent);
          },500)
  
      });
    }
   
  }

  saveFile(file){
  
      let fb = new FormData();
      fb.append('files', file, Date.now() + '---' + file.name );
      fb.append('destination', this.parent);
      this.mediaService.uploadMedia(fb).subscribe(res => {
        
  
      }, () => {
        this.spinner.hide('media');
      })
 
  }




  /**
   * SELECT IMAGE FROM MODAL ACTION OR FROM MEDIA
   * @param media
   */
  getSelectedImg(media){
    if(!this.DIALOG){//IF MEDIA
      this.selected = media;
    }else {//IF FROM DIALOG

      if(this.SelectMultiple){//IF SELECT IS MULTIPLE
        if( typeof(this.SelectedImages) == "undefined" ){
          this.SelectedImages = [];
        }
        this.SelectedImages.push(this.changePath(media.url))
      }else {//IF SELECT IS UNIQUE
        this.medias.forEach(function(v){ v.selected = false });
        this.SelectedImages = this.changePath(media.url);
      }
      media['selected'] = true;
      this.selectimage.emit(this.SelectedImages);
    }
  }

  /**
   * DELETE DIRECTORY
   * @param event
   */
  deleteDirectory(event, item = false) {
    let itemToDelete = item ? item : event.item;
    this.mediaService.deleteDirectory(itemToDelete['path']).subscribe(res => {
      this.parseFolder(this.parent);
      if(this.selected.path == itemToDelete.path  ){
        this.selected = null
      }
    })
  }


  /**
   * RENAME FOLDER OR FILE
   * @param $event
   */
  renameFolder($event, item = false) {
    const dialogRef = this.dialog.open(DialogFolderComponent, { data: item ? item : $event.item, width: '500px' });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        let initial_path = item['path'] ? item['path'] : $event['item']['path'];
        let new_path = (this.parent ? this.parent : this.strict) + '/' + result;

        this.mediaService.renameDirectory({ initial_path, new_path }).subscribe(res => {
            this.parseFolder(this.parent);
        });
      }
    });

  }

  getName(name){
    if(name.indexOf('---') != -1){
      return name.split('---')[1];
    }
    return name;
  }
  /**
   * SORT OBJ
   * @param obj
   */
  sortObjects(obj) {
    if(obj){
      obj.sort(function (a, b) {
        if (a.type < b.type) { return -1; }
        if (a.type > b.type) { return 1; }
        return 0;
      });
    }

    return obj;
  }

  /**
   * PARSE FOLDER BY PATH
   * @param path
   * @param parent
   */
  parseFolder(path, parent = false, spinner = true) {
    if(spinner)
      this.spinner.show('media');
    if (parent) {
      path = path.split('/');
      path.pop();
      path = path.join('/')
    }
    this.parent = path;
    this.mediaService.getMediaByFolder(path).subscribe(res => {
      this.medias = this.sortObjects(res['result']);
      this.filteredMedia = this.medias;
      this.spinner.hide('media');
    });
  }

  /**
   * CREATE FOLDER
   * @param path
   */
  createFolder() {
    const dialogRef = this.dialog.open(DialogFolderComponent, {data:{type:'folder'}, width: '500px' });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let path = (this.parent ? this.parent : this.strict) + '/' + result;
        this.mediaService.createFolder(path).subscribe(res => {
          this.parseFolder(this.parent);
        });
      }
    });
  }

  /**
   * UPDATE MEDIA IMAGE URL
   * @param path
   */
  changePath(path) {
    return (MEDIA +'/media/customers/'+ path);
  }
  /**
   *
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    console.log(event.previousIndex, event.container)
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

}

