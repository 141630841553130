import { NgxSpinnerService } from 'ngx-spinner';
import { TramesService } from 'src/app/shared/services/trames.service';
import { TriggersService } from 'src/app/shared/services/triggers.service';
import { EXPORT_NOTIF_TYPES } from 'src/app/shared/settings/_const';

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-trigger-maintenance',
  templateUrl: './dialog-trigger-maintenance.component.html',
  styleUrls: ['./dialog-trigger-maintenance.component.scss']
})
export class DialogTriggerMaintenanceComponent {


  constructor(
    public dialogRef: MatDialogRef<DialogTriggerMaintenanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private triggerService: TriggersService,
    private trameService : TramesService,
    private spinner: NgxSpinnerService,


  ) { }

  admin_id = localStorage.getItem('admin_id');
  export_types = EXPORT_NOTIF_TYPES
  trigger = { name: '', category: '', is_maintenance : true, id:null }
  trame = { name: '', send_to: [], export_type: '', tags: '' };
  errors: any;
  /**
   *
   * @param data
   */
  create() {

    if(this.checkInputs()){
      this.triggerService.create({ ...this.trigger, customer: this.data.hotel.id, created_by: this.admin_id }).subscribe(res => {
        this.trameService.create({...this.trame, trigger:res['id']}).subscribe(res=>{
          this.dialogRef.close(res);
          this.spinner.hide('global');
        })

      }, err => {
        this.errors = err.error
        this.spinner.hide('global');

      });
    }

  }

  checkInputs(){
    this.errors = {trigger:{}, trame:{}};
    let valid = true;
    if(!this.trigger.name){
      this.errors['trigger']['name']=['Name should not be empty']
      valid = false;
    }
    if(!this.trigger.category){
      this.errors['trigger']['category']=['Category should not be empty']
      valid = false;
    }
    if(!this.trame.name){
      this.errors['trame']['name']=['Name should not be empty']
      valid = false;
    }
    if(!this.trame.send_to.length){
      this.errors['trame']['send_to']=['Send to should not be empty']
      valid = false;
    }
    if(!this.trame.export_type){
      this.errors['trame']['export_type']=['Export type should not be empty']
      valid = false;
    }
    if(!this.trame.tags){
      this.errors['trame']['tags']=['Tags should not be empty']
      valid = false;
    }
    if(valid){
      this.errors = null;
    }
    return valid;
  }


}
