import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-manager-tab-variable',
  templateUrl: './module-manager-tab-variable.component.html',
  styleUrls: ['./module-manager-tab-variable.component.scss']
})
export class ModuleManagerTabVariableComponent implements OnInit {

  constructor() { }
  @Input() module;

  ngOnInit() {
  }

    /**
   *
   */
  addVariable(){
    if(this.module.configs.variables == undefined){
      this.module.configs.variables = []
    }
    this.module.configs.variables.push({
      name:'',
      api:'',
      keys:'',
    })
  }


  delete(i){
    this.module.configs.variables.splice(i,1)
  }

}
