import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { objectToArray } from 'src/app/helpers/commun';
import { DashboardService } from 'src/app/shared/services/dashboard.service';

@Component({
  selector: 'app-dialog-excel-charging',
  templateUrl: './dialog-excel-charging.component.html',
  styleUrls: ['./dialog-excel-charging.component.scss']
})
export class DialogExcelChargingComponent implements OnInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<DialogExcelChargingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private dash: DashboardService,


  ) { }

  
  subscription;
  configs;
  slug;
  totalData = [];
  total = 1;
  current = 0;
  period ;

  ngOnInit(): void {
    this.configs = this.data.configs;
    this.slug = this.data.slug;
    this.period = this.data.period;
    this.getData()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getData(page = 1) {
    this.data = [];
    let params = new HttpParams();
    
    for(let conf of this.configs.paramsUrl){
      let value = conf.value.replace("{{hotel}}",this.slug);
      params = params.set(conf.key, value);
    }

    params = params.set('page_number', page.toString());
    params = params.set('period', this.period)
    params = params.set('number_per_page', '100');


    this.subscription = this.dash.getDataWithQuery(params).subscribe(res=>{
      this.totalData = this.totalData.concat(objectToArray(res['data']));
      this.total = res['num_pages'];
      this.current = res['page'];
      if (res['num_pages'] > res['page'] && this.totalData.length < 60000){
       setTimeout(()=>{
        this.getData(page+1)
       },1000)
      }else {
        setTimeout(()=>{
        this.dialogRef.close(this.totalData)
      },400)
      }
    })
  }

}
