<div class="row">
    <div class="col-md-5">
        <p> {{'Description' | translate}} : {{offer?.NAME}}</p>
        <p> {{'Validity Period'| translate}} : {{offer?.DATE_START | date: "short"}} TO {{offer?.DATE_STOP | date: "short"}}</p>
    </div>
    <div class="col-md-5" *ngIf="offer">
      
    </div>
    <div class="col-md-2 text-right ks-cboxtags">
        <input id="isActivate" name="IS_ACTIVATE" type="checkbox" [(ngModel)]="offer['IS_ACTIVATE']" *ngIf="offer" >
        <label for="isActivate">{{'Is active' | translate}}</label>
    </div>


</div>