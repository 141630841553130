import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DefaultValueService } from 'src/app/shared/services/default-value.service';
import { HotelService } from 'src/app/shared/services/hotel.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { DialogAddEditSupervisionComponent } from '../../dialogs/dialog-add-edit-supervision/dialog-add-edit-supervision.component';

@Component({
  selector: 'app-supevision-manager',
  templateUrl: './supevision-manager.component.html',
  styleUrls: ['./supevision-manager.component.scss']
})
export class SupevisionManagerComponent implements OnInit {

  constructor(
    private hs: HotelService,
    notifierService: NotifierService,
    private router: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private dv: DefaultValueService,
  ) {
    this.notifier = notifierService;
  }

  private readonly notifier: NotifierService;

  hotel: any;
  slug: any;
  defaultTabs = {
    hotel : 29,
    configs: [{
      title: "Supervision ",
      detail:"",
      active:true,
      customBtns:[],
      paramsUrl : [
        {key:"id", value:""},
        {key:"customer", value:"{{hotel}}"},
        {key:"template", value:"PROCESS_HISTORY"},
        {key:"init_stats", value:"true"},
        {key:"period", value:"7_DAYS"},
      ]
    }],
    name: 'MONITORING-TABS'
  };
  inherit = false;
  tabs : any;
  title = "Monitoring";
  customBtn = {
    role:"super-user",
    text : "Manage sections",
    icon:"fa fa-sliders"
  }
  titles = []

  ngOnInit() {
    //display spinnenr
    this.spinner.show('global');

    //GET CURRENT HOTEL / CLIENT
    this.router.params.subscribe(params => {
      this.slug = params.slug;
      this.defaultTabs.hotel  = this.slug;
      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
        this.spinner.hide('global');
      });

      this.getSupervisionsConfig();

    });
  }

  getTitle(index, event){
    this.titles[index] =  this.tabs.configs[index].title+ ' ('+event+')';
  }


  /**
   *
   * @param id
   */
  getSupervisionsConfig(id = this.slug) {
    this.dv.getDefaultValue(id, 'MONITORING-TABS').subscribe(res => {
      this.tabs = res;
    },err=>{
      if(err.status == 404 && this.slug != 29 && !this.inherit){
        this.inherit = true;
        this.notifier.notify('success', 'Set default configuration')
        this.getSupervisionsConfig(29);
      }
    });
  }

  /**
   *
   * @param tab : object
   */
  createSupervisionConfig() {
    console.log(this.tabs.id , this.inherit)
    if(this.tabs.id != undefined && !this.inherit){
      this.dv.updateDefaultValue(this.tabs).subscribe(() => {
        this.notifier.notify('success', 'Configuration updated successfully')
      })
    }else {
      this.dv.createDefaultValue({...this.tabs, hotel:this.slug}).subscribe(res => {
        this.inherit = false;
        this.tabs = res;
        this.notifier.notify('success', 'Configuration updated successfully')
      })
    }

  }


  /**
   *
   */
  openDialogSection(){
    const dialogRef = this.dialog.open(DialogAddEditSupervisionComponent, {
      data : {tabs:JSON.parse(JSON.stringify(this.tabs)), hotel:this.hotel}, width:"1100px"
    });

    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.tabs = res;
        this.createSupervisionConfig();
      }
    })
  }

}
