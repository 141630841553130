import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { LANGUAGES } from 'src/app/shared/settings/_const';
import { MEDIA } from 'src/app/shared/settings/global';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '../../shared/services/user.service';
import { BASE_URL } from '../../shared/settings/global';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  private readonly notifier: NotifierService;


  constructor(
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private hs:HotelService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private us: UserService
  ) {
    this.notifier = notifierService;

   }
  fb = new FormData();
  user : any;
  id = localStorage.getItem('id');
  user_role = localStorage.getItem('user_role');

  base_url = BASE_URL
  MEDIA = MEDIA;
  image_url : any;
  slug  :any;
  hotel :any;
  langs = LANGUAGES;
  languages : any;
  ngOnInit() {
    this.spinner.show('global');
    this.us.get(this.id).subscribe(res=>{
      this.spinner.hide('global');
      this.user = res;
      this.image_url = res['image'];
      delete this.user['image'];
    });

   this.hs.getLanguages().subscribe((data : string[]) => {
      this.languages = data
    })


    this.route.params.subscribe(params => {
      this.slug = params.slug
      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
      });
    })

  }

  fillFormData(user=null){
    this.fb.append('user', this.user.user);
    this.fb.append('email', user ? user.email: this.user.email);
    this.fb.append('first_name', user ? user.first_name: this.user.first_name);
    this.fb.append('last_name', user ? user.last_name: this.user.last_name);
    this.fb.append('language', user ? user.language: this.user.language);
    this.fb.append('send_notification_email', user ? user.send_notification_email: this.user.send_notification_email);
    if (user && user.password1 && user.password2) {
      this.fb.append('password1', user.password1);
      this.fb.append('password2', user.password2);
    }
  }

  /**
   * UPDATE PROFILE
   * @param user
   */
  updateProfile(user){
    this.spinner.show('global');
    this.fillFormData(user);
    this.us.update(this.fb, this.id).subscribe(res=>{
      this.user = res;
      this.spinner.hide('global');
      setTimeout(() => {
        window.location.reload();

      }, 500);
      this.translate.get('Profile updated').subscribe(
        value => {
          this.notifier.notify('success', value);
        }
      );
    }, ()=>{
      this.spinner.hide('global');
      this.translate.get('An error has occurred').subscribe(
        value => {
          this.notifier.notify('error', value);
        }
      );
    })

  }

  /**
   *
   * @param event
   * @param attr
   */
  changeImageProfile(event){
    this.spinner.show('global');
    this.fillFormData();
    this.fb.append('image', event.target.files[0],  event.target.files[0].name);
    this.us.update(this.fb, this.id).subscribe(res=>{
      this.image_url = res['image']
      this.spinner.hide('global');
      this.translate.get('Image updated').subscribe(
        value => {
          this.notifier.notify('success', value);
        }
      );
    }, ()=>{
      this.spinner.hide('global');
      this.translate.get('An error has occurred').subscribe(
        value => {
          this.notifier.notify('error', value);
        }
      );
    })
  }

  setUrl(media){
    return (media.indexOf('http') == -1) ? this.base_url+media   :  media;
  }

}
