import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { HotelService } from 'src/app/shared/services/hotel.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Customer } from './_newCustomer';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-manage-customer',
  templateUrl: './manage-customer.component.html',
  styleUrls: ['./manage-customer.component.scss']
})
export class ManageCustomerComponent implements OnInit {

  constructor(
    private hs: HotelService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private api : ApiService,
    private deviceService : DeviceService,
    private ds : DashboardService,
  ) { }

  title = "New Customer";
  hotel: any;
  languages: any;
  slug: any;
  customer : any;
  errorsMessage : any;
  successMessage : any;
  hotels : any;
  devices : any;
  pmses : any;
  edit = false;

  ngOnInit() {

    this.customer = new Customer();
    this.spinner.show('global');

    this.route.params.subscribe(params => {
      this.slug = params.slug

      this.hs.getLanguages().subscribe((data) => {
        this.languages = data
      });
      this.hs.getHotels().subscribe((res:any)=>{
        let data = [];
        for (let v of res) {
          if(v.is_template){
            data.push(v);
          }
        }
        this.hotels = data;
      })

      this.getDevices(42);
      this.getPms()

      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
        this.spinner.hide('global');
      });
    });

  }
  onChange(){
    this.edit = true;
    setTimeout(()=>{this.edit=false},100);
  }
  /**
   *
   * @param val
   */
  getDevices(val){
    this.spinner.show('global');
    this.deviceService.devices('?ID_CUSTOMER='+val+'&HIDDEN=false').subscribe(res=>{
      this.devices = res;
      this.spinner.hide('global');
    });
  }

  /**
   *
   */
  getPms(){
    this.ds.getPms('?ordering=id').subscribe(res=>{
      this.pmses = res;
    });
  }

  getLanguages(){
    let langs : any[] = [];
    let i =0;
    for(let lang of this.customer.Get['LanguageHotel$1']){
      i++
      langs.push({lang_code:lang.lang_code, lang_name : lang.lang_code, id:i})
    }
    return {languages : langs, default_lang:1}
  }

  /**
   *
   * @param val
   */
  updateName(val){
    this.customer.data_to_update.DEVICES.params_generaux.INPUTS_VALUES.hotel_name_en = val;
  }

  /**
   *
   * @param val
   */
  updateSlug(val){
    this.customer.data_to_update.DEVICES.params_techniques.INPUTS_VALUES.keyboard_plugin= "MyWorkflow/"+val+"/_workflow_keyboard_script_demo.html"
    this.customer.data_to_update.DEVICES.params_techniques.INPUTS_VALUES.workflow_message= "MyWorkflow/"+val+"/_workflow_modal.html"
  }

  createCustomer(){
    this.spinner.show('global');
    this.api.postbase('/api/v1/create_new_customer/', this.customer).subscribe(res=>{
      if(!res['success']){
        this.errorsMessage = res['message'];
        this.successMessage = null;
      }else {
        this.errorsMessage = null;
        this.successMessage = res['data']
      }
      this.spinner.hide('global');

    }, err=>{
      this.spinner.hide('global');

    })
  }

  updateLang(event) {
    console.log(event)
    this.customer.Get['LanguageHotel$1'] = event.value
  }

  corrigerCaracteres(valeur) {
    // Expression régulière : autorise les caractères alphanumériques et le tiret (-)
    const regex = /^[a-zA-Z0-9\-]+$/;
    
    // Supprimer les caractères non autorisés en utilisant la fonction replace()
    const valeurCorrigee = valeur.replace(/[^a-zA-Z0-9\-]/g, '');

    if (regex.test(valeurCorrigee)) {
      return valeurCorrigee.toLowerCase(); // Renvoyer la valeur corrigée
    } else {
      return ''; // Renvoyer une chaîne vide si la valeur est invalide
    }
  }
}
