<table class="table table-striped table-bordered view-details">
  <ng-container *ngFor="let attr of attrs ">
    <ng-container *ngFor="let field of attr.fields ">
       
      <tr *ngIf="field.name != 'hotel'">
        <td style="width:300px">{{ field.label | translate}}</td>
        <td *ngIf="field.input != 'media' && field.input != 'select-icon'">
          <div [innerHTML]="model[field.name]"></div>
        </td>
        <td *ngIf="field.input == 'media' || field.input == 'select-icon'">
          <img src="{{buildUrl(model[field.name])}}">
        </td>
       
      </tr>

    </ng-container>
  </ng-container>
  <tr *ngIf="model?.slides?.length">
      <td>{{'Slides'| translate}}</td>
      <td>
        <img src="{{buildUrl(img.image)}}" alt="" *ngFor="let img of model.slides;let i = index" class="slides" (click)="open(i)">
      </td>
  </tr>
</table>