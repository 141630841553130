import { objectToArray } from 'src/app/helpers/commun';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { _PERIODS } from '../../constants';

@Component({
  selector: 'app-stat-supervision',
  templateUrl: './stat-supervision.component.html',
  styleUrls: ['./stat-supervision.component.scss']
})
export class StatSupervisionComponent implements OnInit {

  constructor() { }

  @Input() tile :any;
  @Input() data :any;
  @Input() template :any;
  @Output() actionEvent = new EventEmitter();

  convertedData : any;
  ngOnInit() {
    this.convertedData = objectToArray(this.data);

  }

  /**
   *
   * @param event
   */
  actions(event){
    this.actionEvent.emit(event);
  }

  getPeriod(){
    return _PERIODS[this.template.default_period]
  }


}
