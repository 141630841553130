<div class="{{options.class}}" *ngIf="needValues()">
  <table class="table">
    <thead *ngIf="options.displayheader">
      <tr>
        <th *ngFor="let th of options.header">{{th}}</th>
      </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="drop($event)">


      <tr *ngFor="let field of values; let i = index" cdkDrag [cdkDragData]="values">
        <td *ngIf="options.checkbox">
          <!--checkbox-->
          <div class="form-group">
            <label class="container-checkbox">
              <input type="checkbox" [(ngModel)]="field.selected">
              <span class="checkmark"></span>
            </label>
          </div>
          <!--checkbox-->

        </td>
        <td><input type="text" [(ngModel)]="field.label" class="form-control" placeholder="{{options.placeholder[0]}}">
        </td>
        <td><input type="text" [(ngModel)]="field.value" class="form-control" placeholder="{{options.placeholder[1]}}">
        </td>
        <td *ngIf="!field.multiple">
          <select class="form-control" [(ngModel)]="field.condition">
            <option value="submit">Submit form</option>
            <ng-container *ngFor="let section of form.sections; let i = index">
              <option [value]="i" *ngIf=" i > currentSection" >Go to section {{i+1}}</option>
            </ng-container>
          </select>
        </td>
        <td>
          <button class="btn btn-success" cdkDragHandle>
            <i aria-hidden="true" class="fa fa-arrows"></i>
          </button>
          <button class="btn btn-danger" (click)="deleteField(i)">
            <i aria-hidden="true" class="fa fa-trash"></i>
          </button>
        </td>

      </tr>

      <!--options other-->
      <tr *ngIf="options.other && otherModel">
        <td colspan="2"  class="other">
          <!--checkbox-->
          <div class="form-group">
            <label class="container-checkbox">
              {{options.otherText}}
              <input type="checkbox" [(ngModel)]="otherModel[options.attr]">
              <span class="checkmark"></span>
            </label>
          </div>
          <!--checkbox-->
        </td>
      </tr>
    </tbody>
  </table>

  <div class="text-right">
    <button class="btn btn-default btn-sm" (click)="addField()">+ {{'Add' | translate}} {{options.add}}</button>
  </div>
</div>