import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-media',
  templateUrl: './dialog-media.component.html',
  styleUrls: ['./dialog-media.component.scss']
})
export class DialogMediaComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogMediaComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  hotel : any;
  multiple:any;
  selectedImages : any;
  maintainRatio = false;
  cropperWidth = 300;
  cropperHeight = 300;

  ngOnInit() {
    this.hotel = this.data.hotel;
    this.multiple = this.data.multiple;
    if(typeof this.data.field.maintainRatio != 'undefined'){
      this.maintainRatio = this.data.field.maintainRatio
      this.cropperWidth = this.data.field.cropperWidth
      this.cropperHeight = this.data.field.cropperHeight
    }
  }

  onSelectImage(event){
    this.selectedImages = event;
  }

}
