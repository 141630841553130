<!-- Page Content -->
<div class="container-fluid">
	<app-fixed-title [title]='title' [hotel]='hotel' *ngIf="!onglet"></app-fixed-title>

	<h4 class="mt-3" *ngIf="onglet">{{title}}</h4>
	<div class="row">
		<div class="col-lg-12">
			<div class="white-box padd30 content">
				<div class="row">
					<div class="col-lg-6">
						<p>{{presentation | translate}}</p>
					</div>
					<div class="col-lg-6 text-right btn-inline">
						<input type="search" class="form-control" placeholder="{{'Search'  | translate}}"
							(input)="searchInCategories($event.target.value)">
						<button class="btn btn-success" (click)="openDialogCategory()">
							<i class="fa fa-plus" aria-hidden="true"></i> {{'Add' | translate}} {{label | lowercase | translate}}
						</button>
					</div>
				</div>
				<div class="accordion" id="accordion" cdkDropList  (cdkDropListDropped)="drop($event)">
					<div class="card" *ngFor="let category of filteredCategories; let i = index " cdkDrag
						[cdkDragData]="category">
						<div class="card-header" id="heading-{{category.id}}">
							<div class="row flex-vertical category">
								<div class="col-md-4 collapsed action-collapse"  [attr.data-toggle]="'collapse'" [attr.data-target]="'#collapse-'+category.id" [attr.aria-expanded]="'false'" [attr.aria-controls]="'collapse-'+category.id">
									<h3>
										<i class="fa fa-plus" aria-hidden="true"></i> 	
										{{category.name | translate}}
										<span class="status" [ngClass]="{disabled: !category.visible }"></span>
									</h3>
								</div>

								<div class="col-md-6">
									<span *ngIf="category?.subcategories?.length" class="mrg-r">
										{{'Total subcategories' | translate}} :
										<span>{{category?.subcategories?.length ? category.subcategories.length : 0}}</span>
									</span>
									<span>
										{{'Total items'| translate}} : {{lengthItems(category)}}
									</span>
								</div>

								<div class="col text-right">
									<button class="btn"
										(click)="deleteCategory(category, i)"><i class="fa fa-trash-o"
											aria-hidden="true"></i>
									</button>

									<button class="btn" [ngClass]="{active:see_details[category.id]}" (click)="getDetails(category)" >
										<i class="fa fa-eye" aria-hidden="true"></i>
									</button>

									<button class="btn" (click)="duplicateCategory(category)">
										<i class="fa fa-files-o" aria-hidden="true"></i>
									</button>

									<button class="btn" (click)="editCategory(category)">
										<i class="fa fa-pencil" aria-hidden="true"></i>
									</button>

									<button cdkDragHandle class="btn">
										<i class="fa fa-arrows" aria-hidden="true"></i>
									</button>
								</div>
							</div>
						</div>

						<div id="collapse-{{category.id}}" class="collapse" [attr.aria-labelledby]="'heading-'+category.id"
							data-parent="#accordion">
							<div class="card-body">
								<!--body-->
								<app-subcategories
									[category]="category"
									[see_details]="see_details[category.id]"
									[hotel]="hotel"
									[category_type]="category_type"
									[btnNewItem]="category?.subcategories?.length"
									[superFields]="superFields"
									[languages]="languages"
									[hideprice]="hideprice">
								</app-subcategories>
								<app-item 
									[subcategory]="category"
									[hotel]="hotel"
									[categoryParent]="true"
									[category_type]="category_type"
									[superFields]="superFields"
									[btnNewItem]="false"
									[btnDelete]="false"
									*ngIf="category?.subcategories?.length == 0"
									[languages]="languages"
									[hideprice]="hideprice">
								</app-item>

								<button class="btn btn-success btn-sm btn-subcategory"
									*ngIf="displaySubcategory && (category?.subcategories?.length == 0 && category?.items?.length == 0) || (!category.subcategories && !category.items)"
									(click)="addSubCategory(category)">
									{{'Create subcategory'| translate }}</button>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</div>
