import * as $ from 'jquery';
import { MEDIA } from 'src/app/shared/settings/global';

import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface customBtn {
  text: string;
  role: string;
  action: string;
  icon: string;
}

@Component({
  selector: "app-fixed-title",
  templateUrl: "./fixed-title.component.html",
  styleUrls: ["./fixed-title.component.scss"],
})
export class FixedTitleComponent {
  constructor() {}

  @Input() hotel: any;
  @Input() title = "";
  @Input() customtitle = "";
  @Input() links;
  @Input() action = false;
  @Input() settingAction = false;
  @Input() icon: any;
  @Input() customAction: false;
  @Input() customActionText = "Add new";
  @Input() customBtn: customBtn;
  @Output() actionCustomBtn = new EventEmitter();
  @Output() clickEvent = new EventEmitter();
  @Output() onCustomAction = new EventEmitter();
  @Output() menuClickEvent = new EventEmitter();
  @Output() settingClickEvent = new EventEmitter();

  @Input() text = "Setting";
  @Input() texticon = "fa-area-chart";
  @Input() select = [];
  @Input() menus = [];
  selectModel = "7_DAYS";

  /**
   *
   * @param btn
   */
  onClickCustomBtn(btn) {
    this.actionCustomBtn.emit(btn);
  }

  onClickEvent() {
    this.onCustomAction.emit(true);
  }

  triggerEvent() {
    this.clickEvent.emit(true);
  }

  settingtriggerEvent() {
    this.settingClickEvent.emit(true);
  }

  getIcon() {
    let logo =
      this.hotel.logo.indexOf("media") == -1
        ? MEDIA + "/media/" + this.hotel.logo
        : MEDIA + this.hotel.logo;
    let img = logo.indexOf("http") != -1 ? logo : MEDIA + logo;
    return this.icon ? this.icon : img;
  }

  getTitle() {
    let active = getTitle();
    return this.customtitle ? this.customtitle : active ? active : this.title;
  }

  /**
   *
   * @param i :index of menu
   */
  triggerEventMenu(i, val = null) {
    this.menuClickEvent.emit({ index: i, val });
  }
}

function getTitle() {
  return $(".active-link").text();
}
