import { NgxSpinnerService } from 'ngx-spinner';
import { TabpayService } from 'src/app/shared/services/tabpay.service';

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { PERIODS } from '../../../shared/settings/_const';
import { ExcelService } from 'src/app/shared/services/excel.service';

@Component({
  selector: 'payment-table-detail',
  templateUrl: './table-detail.component.html',
  styleUrls: ['./table-detail.component.scss']
})
export class TableDetailComponent implements OnInit {

  constructor(
    private tabpay: TabpayService,
		private spinner: NgxSpinnerService,
    private excel : ExcelService,
  ) { }

  @Input() hotel :any;
  @Input() slug :any;
  @Input() type : any;
  @Input() failed = false;
  @Output() count = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  periods = PERIODS
  defaultPeriod = '7_DAYS'
  transactions : any;
  filter = {operator : 'lte' , amount : 0};
  convertedData:any;
  dataSource : any;
  sortedData : any;
  searchValue = "";
  displayedColumns = [
    'reference',
    'name',
    'invoice_number',
    'email',
    'amount',
    'reservation',
    'transaction_date',
    // 'transaction_id',
  ];

  chartConfig = {
    axeX : '',
    xtype: 'datetime',
    axeY: '',
    type: 'column',
    series: '',
    classname: 'col-md-12',
    name: '',
  }


  ngOnInit() {
    if(!this.failed){
        this.displayedColumns.push('transaction_id')
    }
    this.init()
  }


  rebuildChart(){
    this.convertToChart()
  }
  /**
   *
   * @param text_hotel
   * @param text_hopital
   */
  getText(text_hotel, text_hopital){
    if(this.hotel.customer_type == 'HOTEL'){
      return text_hotel;
    }else {
      return text_hopital;
    }
  }

  applyFilter() {
    if(this.searchValue){
      this.dataSource.filter = this.searchValue.trim().toLowerCase();
    }else {
      this.dataSource.filter = ""
    }
  }

  getClassTd(val){

    if(this.searchValue
      && val != ""
      && typeof(val) == 'string'
      && val.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1 ){
      return 'warning'
    }
  }

    /**
   *
   * @param sort
   */
  sortData(sortEvent: Sort){
    let data = Object.assign([],this.dataSource);
    if (!sortEvent.active || sortEvent.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sortEvent.direction === 'asc';
      return compare(a[sortEvent.active], b[sortEvent.active], isAsc)
    });
  }

  init(){
    this.spinner.show('global');
    this.tabpay.getTransactionList(this.slug, '', this.type, this.defaultPeriod, this.filter).subscribe((res : string[]) => {
      if(res['success']){
        this.transactions = res['response'];
        if(this.transactions.length){
          this.transactions.map(x => {x.transaction_date = x.transaction_date.replace('T',' ')});
          this.convertToChart(this.transactions);

        }
        this.count.emit(this.transactions.length);
        this.dataSource = new MatTableDataSource(this.transactions);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }

      this.spinner.hide('global');

    });
  }

  exportexcel(){

    this.excel.exportExcel('Payement-export', this.transactions);

  }

  /**
   *
   */
  convertToChart(transactions = this.transactions){
    this.convertedData  = {}
    let obj = {};
    let arr = [];
      for(let trans of transactions){
        let date =  Date.parse(new Date(trans.transaction_date).toISOString().slice(0, 10))
        if(typeof obj[date] != 'undefined'){
          obj[date] += trans.amount
        }else {
          obj[date] = trans.amount;
        }
      }
      for(let i in obj){
        arr.push([parseInt(i), parseFloat(obj[i].toFixed(2))])
      }
      this.convertedData = {name : this.type,values: arr};
    }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
