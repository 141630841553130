import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PagePermissionDeniedComponent } from './components/page-permission-denied/page-permission-denied.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HotelsComponent } from './pages/hotels/hotels.component';
import { InvitationComponent } from './pages/invitation/invitation.component';
import { LoginComponent } from './pages/login/login.component';
import { MainTemplateComponent } from './pages/main-template/main-template.component';

const routes: Routes = [

	{ path: '', component: LoginComponent	},
	{ path: 'login', component: LoginComponent	},
	{ path: 'forgot-password', component: ForgotPasswordComponent	},
	{ path: 'invitation/:token', component: InvitationComponent},
	{
		path: '',
		component: MainTemplateComponent,
		children: [
			{ path: 'hotels', component: HotelsComponent, canActivate: [AuthGuard] },
		]
	},
  {
    path:'',
    canActivate: [AuthGuard],
    loadChildren: () => import('../app/main-template/sidebar.module').then(m => m.SidebarModule)
  },
	{ path: '403', component: PagePermissionDeniedComponent },
	{ path: '**', component: PageNotFoundComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
