import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-config-supervision',
  templateUrl: './dialog-config-supervision.component.html',
  styleUrls: ['./dialog-config-supervision.component.scss']
})
export class DialogConfigSupervisionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogConfigSupervisionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}


  ngOnInit() {
    console.log(this.data)
  }

  /**
   *
   * @param value
   */
  updateEvent(value){
    this.data.displayedColumns = value
  }
}
