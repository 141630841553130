<h2 mat-dialog-title>Slides ({{slides?.length}})
  <!-- <button class="btn btn-success btn-sm" (click)="toggleSize()"><i class="fa fa-bars" aria-hidden="true"></i></button> -->
</h2>
<p>
  
  <button class="btn btn-success btn-sm" (click)="AddOrEditslide()">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Add new slide
  </button>
</p>



<mat-dialog-content class="mat-typography">

  <div cdkDropListLockAxis cdkDropList cdkDropListOrientation="horizontal"
    class="slide-list container-list-drop box-scroll" [ngClass]="size" id="slide-scroll"
    (cdkDropListDropped)="drop($event)">

    <div class="slide-box" *ngFor="let slide of slides; let i = index" cdkDrag>


      <div class="custom-placeholder" *cdkDragPlaceholder></div>

      <!--delete btn-->
      <button class="btn dis-onhover delete" (click)="deleteConfirm(slide, i)">
        <i class="fa fa-trash-o" aria-hidden="true"></i>
      </button>
      <!--delete btn-->

      <!--img-->
      <img src="{{buildUrl(slide.image)}}" class="slide">
      <div class="text">
        <div class="row vertical-align">
          <div class="col text-right">
            <button class="btn btn-white"><i class="fa fa-arrows" aria-hidden="true"></i></button>
            <button class="btn btn-white" (click)="AddOrEditslide(slide, i)">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <!--img-->
    </div>
  </div>
</mat-dialog-content>