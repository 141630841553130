import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {

  constructor() { }
  @Input() attrs :any;
  @Input() hotel :any;
  @Input() model :any;
  @Input() superFields : any;
  @Input() type : any;
  @Input() extraObject : any;
  @Input() languages : any;
  @Input() fb : any;
  @Input() errors : any;
  
  ngOnInit() {
    if(this.superFields == "_all"){
      this.superFields = this.attrs;
    }else if(typeof(this.superFields) ==="object"){
      this.attrs = this.superFields;
    }
  }

  activeTab(name){
      if(this.type == "item"){
        return this.superFields.item.tabs.indexOf(name) != -1  ;
      }else {
        return true;
      }
  }



  needsTab(arr){
    return (Object.keys(arr).length != 1)
  }

}
