import { MEDIA } from 'src/app/shared/settings/global';

import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HotelComponent } from '../../modals/hotel/hotel.component';

@Component({
  selector: 'hotel-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss']
})
export class InformationsComponent {

  constructor(
    public dialog: MatDialog
  ) {

   }



  @Input() hotel :any;
  @Input() languages:any;
  MEDIA = MEDIA
  counter(i) {
    return new Array(parseInt(i));
  }

  openDialogHotel() {
        let data = { hotel: this.hotel, languages: this.languages };
    const dialogRef = this.dialog.open(HotelComponent, { data: data , width:'900px'});
  }



}
