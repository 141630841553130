import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class TriggerTypeService {

  constructor(private http: HttpClient) { }

  uri = URI+"trigger-types/";


	list(){
			return this.http.get(this.uri);
	}

}
