import { NotifierService } from 'angular-notifier';
import { UserService } from 'src/app/shared/services/user.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-invitation-user',
  templateUrl: './dialog-invitation-user.component.html',
  styleUrls: ['./dialog-invitation-user.component.scss']
})
export class DialogInvitationUserComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogInvitationUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private userService: UserService,
     notifierService: NotifierService,
  ) {

     this.notifier = notifierService;
  }
  private readonly notifier: NotifierService;
  errors : any;

  invitation  = {
    email: '',
    hotels: [],
    roles : [],
    groups: [this.data.group_id],
  };

  ngOnInit() {
  }

  sendInvitation(){
    this.userService.sendInvitation(this.invitation).subscribe(res=>{
      if(res['message']){
        this.notifier.notify('error', res['message'])
      }else {
        this.dialogRef.close(true);
      }
    },err=>{
      console.log(err)
      if(err.status == 404){
        this.errors ={email:['There is no user with this email']}
      }else {
        this.errors = err.error;
      }
    })
  }


  getHotel(id){
    return this.data.hotelsToManage.find(x=>x.id == id);
  }


  updateRoles($event){
    console.log($event)
    for(let hotel_id of $event.value){
     console.log(this.invitation.roles);
      if(this.invitation.roles.findIndex(x=>x.id == hotel_id) == -1){
        this.invitation.roles.push({
          id :hotel_id,
          role :this.data.type
        })
      }
    }
    setTimeout(()=>{
      for(let role_hotel of this.invitation.roles){
        if($event.value.indexOf(role_hotel['id']) == -1){
          let index = this.invitation.roles.findIndex(x=>x.id == role_hotel.id);
          this.invitation.roles.splice(index, 1)
        }
      }
    },100)

  }
  deleteRole(i) {
    this.invitation.roles.splice(i, 1);
  }

}
