import { DialogErrorComponent } from 'src/app/components/modals/dialog-error/dialog-error.component';
import { UserService } from 'src/app/shared/services/user.service';

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private _auth : AuthService,
    
  ) { }

  formgroup: UntypedFormGroup;
  formgroupReset: UntypedFormGroup;
  loading = false;
  user = { email: "" }
  reset = { token: "", password: "", confirm_password: "", uid: "" }
  message = "";
  message2 = "";
  uid: any;
  submitted = false;
  submittedReset = false;
  token = "";
  success = false

  ngOnInit() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('token')) {
      this.token = urlParams.get('token');
      this.uid = urlParams.get('uid');
      this.reset.uid = this.uid;
    }


    this.formgroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.formgroupReset = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
      confirm_password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
    }, { validator: this.checkPasswords });

  }


  /**
   * 
   * @param group 
   * @returns 
   */
  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('confirm_password').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  sendCode() {
    this.loading = true;
    this.submitted = true;
    if (this.formgroup.invalid) {
      this.loading = false;
      return;
    }
    else {
      this.userService.forgotPassword(this.user.email).subscribe(res => {
        this.loading = false;
        if (res['error']) {
          this.message = res['error']
        } else {
          this.success = true
        }

      }, err => {
        if (err.status == 404) {
          this.message = "Mail not found"
        }
        if (err.status == 500) {
          this.dialog.open(DialogErrorComponent, { panelClass: 'error-dialog' });
        }
        this.loading = false;
      })
    }
  }

  resetPassword() {
    this.loading = true;
    this.submittedReset = true;
    if (this.formgroupReset.invalid) {
      this.loading = false;
      return;
    }
    else {
      this.userService.resetPassword({ ...this.reset, token: this.token })
        .subscribe(
          res => {
            this.loading = false;
            if (res.status == 200) {

              this.router.navigate(['/login'])

            } else {
              this.message2 = res.error
            }
          }, err => {
            console.log(err)
            if (err.status == 404) {
              this.message2 = "Code not found"
            }
            if (err.status == 400) {
              this.message2 = err.error;
            }
            if (err.status == 500) {
              this.dialog.open(DialogErrorComponent, { panelClass: 'error-dialog' });
            }
            this.loading = false;
          })
    }


  }

}
