import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private http: HttpClient) {}
  uri = URI;

  /**
   * GET LIST DEVICES BY CUSTOMER ID
   * @param id
   */
  getDevices(id) {
		return this.http.get(`${this.uri}`+"devices/?ID_CUSTOMER="+id);
  }

  /**
   *
   * @param params
   */
  devices(params){
    return this.http.get(`${this.uri}`+"devices/"+params);
  }

  /**
   * CREATE DEVICE
   * @param device
   */
  createDevice(device){
    return this.http.post(`${this.uri}`+"devices/", device);
  }

  /**
   * UPDATE DEVICE
   * @param device
   */
  updateDevice(device){
    return this.http.put(`${this.uri}`+"devices/"+device.id+"/", device);
  }

  /**
   * GET DEVICE BY ID
   * @param id
   */
  getDeviceDetail(id){
    return this.http.get(`${this.uri}`+"devices/" +id);
  }

  /**
   * DELETE DEVICE BY ID
   * @param id
   */
  deleteDevice(id){
    return this.http.delete(`${this.uri}`+"/devices/"+id+"/");
  }




}
