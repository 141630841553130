import { _commun } from './_commun';

export const _htmleditor = {
    ..._commun,
    "type": "htmleditor",
    "htmleditor_options":"Standard",
    "type_name":"Rich texteditor",
}

export const _code_editor = {
    ..._commun,
    "type": "code_editor",
    "language":"html",
    "type_name":"Code editor",
}

