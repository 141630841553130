import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceService } from 'src/app/shared/services/device.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-device',
  templateUrl: './dialog-device.component.html',
  styleUrls: ['./dialog-device.component.scss']
})
export class DialogDeviceComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    public deviceService : DeviceService,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,

  ) {
    this.notifier = notifierService;
  }
  private readonly notifier: NotifierService;

  devices :any;
  device  : any;
  emptyEvents = {
      directs: [],
      recurrences: [],
      schedules: []
  }

  errors = null;


  ngOnInit() {
    this.device = this.data.device ? this.data.device : {};
    this.devices = this.data.devices;
  }

  /**
   * CREATE OR UPDATE DEVICE
   * @param device
   */
  createOrUpdate(device){
    //ADD DEVICE
    this.spinner.show('global');
    if(!device.id){
      this.deviceService.createDevice(device).subscribe(res=>{
        this.devices.push({device:res, events:this.emptyEvents});
        this.translate.get('Device successfully created').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
        this.spinner.hide('global');
        this.dialogRef.close(res);
      },err=>{
        this.spinner.hide('global');
        this.errors = err.error;
      })
    }else {//EDIT DEVICE
      this.deviceService.updateDevice(device).subscribe(res=>{
        this.translate.get('Device successfully updated').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
        this.device = res;
        this.spinner.hide('global');
        this.dialogRef.close(res);
      },err=>{
        this.spinner.hide('global');
        this.errors = err.error;
      })
    }
  }



}
