import { NgxSpinnerService } from 'ngx-spinner';
import { InvitationService } from 'src/app/shared/services/invitation.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: InvitationService,
    private spinner: NgxSpinnerService,
    ) { }
  slug: any;
  token: any;
  hotel: any;

  ngOnInit() {
    this.spinner.show('global');
    this.route.params.subscribe(params => {
      this.token = params.token

      this.service.get(this.token).subscribe(
        res => {
          this.spinner.hide('global');
          this.router.navigate(['/hotels'])
        }
      );
    })
  }

}
