<div class="row">
<div class="col-md-6">
  <h2 class="title">{{ 'List of' | translate }} {{type | translate}}s</h2>
</div>
<div class="col-md-6 text-right">
  <button class="btn btn-success" (click)="inviteUser()">Invite user</button>
</div>
</div>

<div class="text-center" *ngIf="!users?.length">
  <mat-spinner></mat-spinner>
</div>


<table class="table table-borderd" *ngIf="users?.length">
  <thead>
    <tr>
      <th>#ID</th>
      <!-- <th>Photo</th> -->
      <th>{{ 'Name' | translate }}</th>
      <th>{{ 'Email' | translate }}</th>
      <th>{{ 'Language' | translate }}</th>
      <th>{{ 'Notifications' | translate }}</th>
      <th>{{ 'Groups' | translate }}</th>
      <th class="text-center">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let user of users; let i = index">
      <ng-container *ngIf="user.id != id && inTypeOfList(user)">
      <tr>
        <td>{{user.id}}</td>
        <td>{{user.first_name}} {{user.last_name}}</td>
        <td>{{user.email}}</td>
        <td>{{user.language}}</td>
        <td>{{user.send_notification_email}}</td>
        <td>{{user.groups_th}}</td>
        <td class="text-right">
          <button class="btn btn-danger btn-sm" (click)="deleteUser(user, i)" *appHasRole="'super-user'">{{ 'Delete' | translate }}</button>
          <button class="btn btn-success btn-sm" (click)="openDialogUser(type, user)">{{ 'Edit' | translate }} {{type}}</button>
        </td>
      </tr>
    </ng-container>
    </ng-container>
  </tbody>
</table>
