import { NotifierService } from 'angular-notifier';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { HotelinformationsService } from 'src/app/shared/services/hotelinformations.service';
import { MEDIA } from 'src/app/shared/settings/global';

import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hotel-more-infos',
  templateUrl: './more-infos.component.html',
  styleUrls: ['./more-infos.component.scss']
})
export class MoreInfosComponent implements OnDestroy{

  constructor(
    public dialog: MatDialog,
    private hi: HotelinformationsService,
    private translate: TranslateService,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  private readonly notifier: NotifierService;
  @Input() informations = [];
  @Input() icons: any;
  @Input() hotel: any;
  @Input() languages: any;
  MEDIA = MEDIA;
  information: any;
  subscriber = [];



  fontIcon(id) {
    if (this.icons) {
      return this.icons.find(x => x.id == id);
    }
  }

  openDialogInformations(information = null) {


  }

  //DELETE CONFIRM
  deleteConfirm(object, name, type, index) {

    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name, type }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //Request Information
        this.subscriber.push(this.hi.deleteInformation(object.id).subscribe(() => {
          this.informations.splice(index, 1);
          this.translate.get('Information successfully deleted').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
        },
          err => console.log(err)))
      }
    });

  }

  /**
   *
   * @param attr
   */
  isUrl(attr) {

    return attr && (attr.indexOf('http') != -1 || attr.indexOf('/') != -1)
  }

  ngOnDestroy(){
    for(let sub of this.subscriber){
      sub.unsubscribe();
    }
  }
}
