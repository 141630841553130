import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { CategoryService } from '../../../shared/services/category.service';
import { HotelService } from '../../../shared/services/hotel.service';
import { ItemService } from '../../../shared/services/item.service';
import { SubcategoryService } from '../../../shared/services/subcategory.service';
import { presentation, super_category_attr, super_item_attr, super_subcat_attr } from './fields';

@Component({
  selector: 'app-upsellservice',
  templateUrl: './upsellservice.component.html',
  styleUrls: ['./upsellservice.component.scss']
})
export class UpsellserviceComponent implements OnInit {

  constructor(
    private hs: HotelService,
    public cats: CategoryService,
    public dialog: MatDialog,
    public item: ItemService,
    public subcat: SubcategoryService,
    private route: ActivatedRoute,
  ) { }

  slug: any;
  title = "Upsell Services";
  label = "Upsell service";
  category_type = "Upsell";
  hotel: any;
  id: any;
  categories: any;
  copy: any;
  searchResults = [];
  filteredCategories = [];
  see_details = {};
  presentation  = presentation;

  @Input() onglet = false;
  superFields = {super_category_attr, super_item_attr,super_subcat_attr};



  ngOnInit() {

  }
}
