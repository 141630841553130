<div class="container-fluid">
  <app-fixed-title [title]='title' [hotel]='hotel' (actionCustomBtn)="openDialogSection()" [customBtn]="customBtn">
  </app-fixed-title>


  <div class="white-box">
    <mat-tab-group *ngIf="tabs?.configs?.length">
      <mat-tab label="Hardware">
        <app-dashboard-hardware [onglet]="true"></app-dashboard-hardware>
      </mat-tab>

      <ng-container *ngFor="let tabconfig of tabs.configs; let index = index">
        <mat-tab [label]="titles[index]" *ngIf="tabconfig.active">
          <app-supervision [hotel]="hotel" [configs]="tabconfig" (onDataReady)="getTitle(index, $event)"></app-supervision>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
</div>