<h2 class="mat-dialog-title">
  {{(data?.id ? "Edit": "Add new")| translate}} {{type | translate}}
</h2>

<mat-dialog-content class="mat-typography">

  <div class="row">
    <div class="col-lg-12">
      <!-- <input type="hidden" class="form-control" [(ngModel)]="data['id']" *ngIf="data['id']"> -->
      <div class="row">
        <div class="form-group col-md-6">
          <label>{{ 'First name' | translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="data['first_name']"
            (change)="hideError('data.first_name')">
          <app-error-input *ngIf="errors" [errors]="errors" name="first_name"></app-error-input>

        </div>

        <div class="form-group col-md-6">
          <label>{{ 'Last name' | translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="data['last_name']"
            (change)="hideError('data.last_name')">
          <app-error-input *ngIf="errors" [errors]="errors" name="last_name"></app-error-input>

        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label>{{ 'Email' | translate }}</label>
          <input type="email" class="form-control" [(ngModel)]="data['email']"
            (change)="hideError('data.email')">
          <app-error-input *ngIf="errors" [errors]="errors" name="email"></app-error-input>

        </div>
        <div class="form-group col-md-6" *ngIf="hotelsToManage">
            <label>{{ 'Propertie(s)  to manage' | translate }}</label>
            <mat-select [(ngModel)]="data['hotels']" (selectionChange)="updateRoles()" multiple class="form-control">
              <mat-option *ngFor="let hotel of hotelsToManage" [value]="hotel.id">{{hotel.text}}</mat-option>
            </mat-select>
          <app-error-input *ngIf="errors" [errors]="errors" name="hotels"></app-error-input>
        </div>

      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label>{{ 'Password' | translate }} 
            <small *ngIf="data.id">{{ 'Leave it to keep the same password' | translate }}</small>
          </label>
          <input type="password" class="form-control" [(ngModel)]="data['password1']"
            (change)="hideError('data.password1')">
          <small class="error-text" *ngIf="data.password1 && data.password1.length<6">
            {{ 'Password has at least 6 characters' | translate }}</small>
          <app-error-input *ngIf="errors" [errors]="errors" name="password1"></app-error-input>

        </div>

        <div class="form-group col-md-6 {{lengthPassword()}}" *ngIf="data['password1']"
          [ngClass]="{'was-validated':!errorPassword , 'error':errorPassword}">
          <label>{{ 'Confirm Password' | translate }}</label>
          <input type="password" class="form-control" [(ngModel)]="data['password2']" (input)="verifyPassword($event)">
          <app-error-input *ngIf="errors" [errors]="errors" name="password2"></app-error-input>

        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label>{{ 'Select Language' | translate }}</label>
          <select [(ngModel)]="data.language" class="form-control" >
              <option *ngFor="let lang of hotel.languages" value="{{lang.lang_code}}">{{lang.lang_name}}
              </option>
            </select>
             <app-error-input *ngIf="errors" [errors]="errors" name="language"></app-error-input>
        </div>

      </div>
      <div class="row">
    

        <!--checkbox-->
        <div class="form-group col-md-4" *appHasRole="'super-user'">
          <label class="container-checkbox">{{ 'Is tabhotel member' | translate }}
            <input type="checkbox" class="form-control" [(ngModel)]="data['is_tabhotel_member']">
            <span class="checkmark"></span>
          </label>
        </div>
        <!--checkbox-->

        <!--checkbox-->
        <div class="form-group col-md-4">
          <label class="container-checkbox">{{ 'Receive notifications' | translate }}
            <input type="checkbox" class="form-control" [(ngModel)]="data['send_notification_email']">
            <span class="checkmark"></span>
          </label>
        </div>
        <!--checkbox-->

        <!--checkbox-->
        <div class="form-group col-md-4" *appHasRole="'super-user'">
          <label class="container-checkbox">{{ 'Is test account' | translate }}
            <input type="checkbox" class="form-control" [(ngModel)]="data['compte_test']">
            <span class="checkmark"></span>
          </label>
        </div>
        <!--checkbox-->

 

        <table class="table" *appHasRole="'super-user'">
          <thead>
            <tr>
              <th>{{ 'Client' | translate }}</th>
              <th>{{ 'Role' | translate }}</th>
            </tr>
          </thead>
          <tbody>
          
            <tr *ngFor="let role of data['roles']; let i = index">
              <td style="width: 50%;">
                {{getHotel(role['id'])?.text}}
               
              </td>

              <td style="width: 40%;">
                    <select [(ngModel)]="role['role']" class="form-control">
                      <option [value]="'concierge'">Concierge</option>
                      <option [value]="'administrator'">Administrator</option>
                      <option [value]="'super-user'">Administrator</option>
                    </select> 
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'Cancel' | translate }}</button>
  <button mat-button (click)="addOrUpdateUser(data)"  class="btn btn-success">
    {{(data.id ? "Edit": "Add new") | translate }} {{type}}</button>
</mat-dialog-actions>