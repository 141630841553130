<div class="white-box content">
  <h2 class="title with-trans">
    {{'Menu Manager' | translate}}
    <app-select-translate [hotel]="hotel" [languages]="languages"></app-select-translate>

  </h2>

  <ul *ngIf="menuItems" class="menu-manager" (cdkDropListDropped)="drop($event, activeMenus)" cdkDropList>
    <li *ngFor="let menu of activeMenus ; let i = index" cdkDrag>
      <div class="active">
        <translated-input [getDefault]="true" [model]="menu" attr="name" [hotel]="hotel" [placeholder]="menu.name"
          type="text" [languages]="languages" [translate]="false">
        </translated-input>

      </div>

      <select class="form-control" [(ngModel)]="menu.role">
        <option value="concierge">{{'Concierge' | translate}}</option>
        <option value="administrator">{{'Admin' | translate}}</option>
        <option value="super-user">{{'Super admin' | translate}}</option>
      </select>

      <button class="btn btn-xs btn-default" (click)="addSubMenu(i)">+</button>
      <button class="btn btn-xs btn-danger" (click)="remove(i)">-</button>

      <ul *ngIf="menu?.submenu" (cdkDropListDropped)="drop($event, menu.submenu)" cdkDropList>
        <li *ngFor="let submenu of menu.submenu; let j = index" cdkDrag>
          <div class="submenu">
            <translated-input [getDefault]="true"  [model]="submenu" attr="name" [hotel]="hotel" type="text" [placeholder]="submenu.name"
             [languages]="languages"   [translate]="false">
            </translated-input>
            <select class="form-control" [(ngModel)]="submenu.role">
              <option value="concierge">{{'Concierge' | translate}}</option>
              <option value="administrator">{{'Admin' | translate}}</option>
              <option value="super-user">{{'Super admin' | translate}}</option>
            </select>
            <button class="btn btn-xs btn-danger" (click)="remove(i, j)">-</button>
          </div>
        </li>
      </ul>
    </li>
  </ul>
  <div class="row">

    <div class="col-6">

      <mat-select [(ngModel)]="newItem">
        <ng-container *ngFor="let menu of menuItems">
          <mat-option [value]="menu">{{menu.name  | translate}}</mat-option>
          <ng-container *ngFor="let submenu of menu.submenu">
            <mat-option [value]="submenu">-- {{submenu.name  | translate}}</mat-option>
          </ng-container>
        </ng-container>
        
        <ng-container *ngFor="let module of modules">
          <mat-option [value]="getMenuParamsFromModule(module)">Module Manager : {{module.name | translate}}</mat-option>
        </ng-container>
        <mat-option [value]="other">Custom link</mat-option>

      </mat-select>

      <!-- <select class="form-control" [(ngModel)]="newItem">
        <ng-container *ngFor="let menu of menuItems">
          <option [value]="menu">{{menu.name}}</option>
          <ng-container *ngFor="let submenu of menu.submenu">
            <option [value]="submenu">-- {{submenu.name}}</option>
          </ng-container>
        </ng-container>
        
      </select> -->
    </div>
    <div class="col-4">
      <button class="btn btn-success" (click)="addNewItem()">{{'Add new module'  | translate}}</button>
    </div>
  </div>
  <div class="text-right">
    <br>

    <button class="btn btn-danger" (click)="reset()">{{'Reset' | translate}}</button>
    <button class="btn btn-warning" (click)="toggleSelect(activeMenus)">{{'Toggle Select'  | translate}}</button>
    <button class="btn btn-success" (click)="AddOrUpdateMenu()">{{'Save Menu' | translate}}</button>
  </div>
</div>