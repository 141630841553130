import { Directive, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective  implements OnInit, OnDestroy {

    @Input() appHasRole: string;
    userRole : any;
    stop$ = new Subject();
  
    isVisible = false;

    order = {
      'super-user':3,
      'administrator':2,
      'concierge':1
    }
  
    /*
    if super user (true)
    if administrator (concierge , adminsitraor ) == true et concierge
    */
    /**
     * @param {ViewContainerRef} viewContainerRef 
     * 	-- the location where we need to render the templateRef
     * @param {TemplateRef<any>} templateRef 
     *   -- the templateRef to be potentially rendered
     * @param {RolesService} rolesService 
     *   -- will give us access to the roles a user has
     */
    constructor(
      private viewContainerRef: ViewContainerRef,
      private templateRef: TemplateRef<any>,
    ) {}
  
    ngOnInit() {
      //  We subscribe to the roles$ to know the roles the user has
      this.userRole = localStorage.getItem('user_role');
 
        // If he doesn't have any roles, we clear the viewContainerRef
        if (!this.userRole) {
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
         // this.isVisible = false;
          //this.viewContainerRef.clear();
          //alert('Role undefined, please login again')
        }
        // If the user has the role needed to 
        // render this component we can add it
        if (this.order[this.userRole] >= this.order[this.appHasRole]) {
          // If it is already visible (which can happen if
          // his roles changed) we do not need to add it a second time
          if (!this.isVisible) {
            // We update the `isVisible` property and add the 
            // templateRef to the view using the 
            // 'createEmbeddedView' method of the viewContainerRef
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          // If the user does not have the role, 
          // we update the `isVisible` property and clear
          // the contents of the viewContainerRef
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      
    }
    
    // Clear the subscription on destroy
    ngOnDestroy() {
      this.stop$.next('');
    }

}
