import { Subject } from 'rxjs';

import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appIsNotLink]'
})
export class IsNotLinkDirective implements OnInit, OnDestroy {

  @Input() appIsNotLink: string;

  stop$ = new Subject();
  isVisible = false;



  /**
   * @param {ViewContainerRef} viewContainerRef
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef
   *   -- the templateRef to be potentially rendered
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    //private rolesService: RolesService
  ) { }


  ngOnInit() {
    if (this.appIsNotLink && this.appIsNotLink.indexOf('http') == -1 && this.appIsNotLink.indexOf( '/') == -1) {
      this.isVisible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);

    } else {
      // If the user does not have the role,
      // we update the `isVisible` property and clear
      // the contents of the viewContainerRef
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }
  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next("");
  }
}
