import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MEDIA } from '../../shared/settings/global';
import { DialogIconsComponent } from './dialog-icons/dialog-icons.component';

@Component({
  selector: 'form-select-icons',
  templateUrl: './select-icons.component.html',
  styleUrls: ['./select-icons.component.scss']
})
export class SelectIconsComponent implements OnInit {

  constructor(
    public dialog: MatDialog,

  ) { }
  @Input() model: any;
  @Input() attr: any;
  @Input() svg = true;
  @Input() hotel: any;
  @Input() tab_icon = true;
  @Input() tab_media = true;
  @Input() label = "Icon";
  @Input() size = 'small';
  @Input() maintainRatio = false;
  @Input() aspectRatio = 1
  icon: any;


  ngOnInit() {
    if (this.attr.label) {
      this.label = this.attr.label
    }
    if (this.model[this.attr.name]) {
      this.icon = this.model[this.attr.name]
    }
  }

  /**
   *
   */
  openDialogIcon() {
    const dialogRef = this.dialog.open(DialogIconsComponent,
      {
        data: {
          type: this.svg ? 'svg' : 'fa',
          hotel: this.hotel,
          tab_icon: this.tab_icon,
          tab_media: this.tab_media,
          label_name: this.attr.label,
          maintainRatio:this.maintainRatio,
          aspectRatio:this.aspectRatio,
          cropperMaxWidth : 600,
        },
        width: '1200px'
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.icon = result.replace(MEDIA, '');
        this.model[this.attr.name] = result.replace(MEDIA, '');
      }
    });
  }

  /**
 *
 * @param src
 */
  buildUrl(src) {
    return (src && src.search('http') === -1) ? (MEDIA + src) : src;
  }


  /**
   *
   * @param attr
   */
  isUrl(attr) {
    return attr && (attr.indexOf('http') != -1 || attr.indexOf('/') != -1)
  }
}
