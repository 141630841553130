import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-debit',
  templateUrl: './dialog-debit.component.html',
  styleUrls: ['./dialog-debit.component.scss']
})
export class DialogDebitComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDebitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}

  amount;
  errors;

  ngOnInit() {
    if(this.data.trans.amount)
      this.amount  = this.data.trans.amount/100;
  }

  confirmDebit(){
    if(this.amount*100>this.data.trans.amount){
      this.errors = {};
      this.errors['amount'] = ["The amount can't be more than "+ (this.data.trans.amount/100) +" " +this.data.currencyCode]
      return false;
    }
    else {
      this.dialogRef.close(parseFloat(this.amount)*100);
    }
  }

}
