import { NgxSpinnerService } from 'ngx-spinner';
import { ImageCroppedEvent, ImageTransform } from 'src/app/libs/ngx-image-cropper/src/lib/interfaces/index';
import { base64ToFile } from 'src/app/libs/ngx-image-cropper/src/lib/utils/blob.utils';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { MediaService } from 'src/app/shared/services/media.service';

import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-cropper',
  templateUrl: './dialog-cropper.component.html',
  styleUrls: ['./dialog-cropper.component.scss']
})

export class DialogCropperComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public mediaService: MediaService,
    public hs: HotelService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) { }

  cropperMaxWidth = 0;
  default = true
  hideResizeSquares = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  extension = '.png';
  base64 = ""
  fileName = "";
  index = 0;
  height = 300;
  width = 300;
  maintainAspectRatio = false;
  aspectRatio = 4 / 4
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  openDialog = false;
  CropperPosition = {x1 :0, y1:100, x2:100,y2:100}

  @Output() onAdd = new EventEmitter;
  ngOnInit() {
    if(this.data.cropperMaxWidth){
      this.cropperMaxWidth = this.data.cropperMaxWidth;
    }
    this.index = this.data.index;
    this.imageChangedEvent = this.data.event;
    this.fileName = this.getName(this.data.fileUploadCropper.files[this.index].name);
    this.hideResizeSquares = this.data.hideResizeSquares;


    if(this.data.maintainRatio){
      this.default = false;
      this.maintainAspectRatio = true;
      this.aspectRatio = this.data.cropperWidth / this.data.cropperHeight;
      this.height = this.data.cropperHeight;
      this.width =  this.data.cropperWidth;
    }
  }

  /**
   *
   * @param event
   */
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = base64ToFile(event.base64);
    this.base64 = event.base64;
    this.width = this.CropperPosition.x2 - this.CropperPosition.x1
    this.height = this.CropperPosition.y2 - this.CropperPosition.y1
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
    if(!this.default){
      this.CropperPosition = {x1 :20, y1:20, x2:this.data.cropperWidth + 20,y2:this.data.cropperHeight + 20}
      console.log(this.CropperPosition, 'hhhh')

    }
  }
  loadImageFailed() {
    // show message
  }

  saveImage(file = this.croppedImage) {
    let fb = new FormData();
    fb.append('files',file, Date.now() + '---' + this.fileName + this.extension);
    fb.append('destination', this.data.parent);
    this.mediaService.uploadMedia(fb).subscribe(res => {
      if (res['status'] == 'progress') {
        this.data.uploadResponse[this.index] = { status: res['status'], message: res['message'] };
      } else if (res['result']) {
        this.onAdd.emit(true)
      }

      if (!this.openDialog) {
        this.dialogRef.close(true);
        this.openNewDialog();
      }

    }, () => {
      this.spinner.hide('media');
    })
  }

 

  openNewDialog() {
    this.openDialog = true;
    if (this.data.fileUploadCropper.files.length > this.index + 1) {
      const dialogref = this.dialog.open(DialogCropperComponent, {
        data: {
          uploadResponse: this.data.uploadResponse,
          fileUploadCropper: this.data.fileUploadCropper,
          index: this.index + 1,
          event: this.data.event,
          parent: this.data.parent,
          hideResizeSquares : this.hideResizeSquares
        },
        width: '1600px'
      });
      dialogref.componentInstance.onAdd.subscribe(() => {
        this.onAdd.emit(true)
      });

    }
  }

  updateCropper($event,attr){
    console.log('etestst')
    if(attr == 'w'){
      this.CropperPosition.x1 = 20;
      this.CropperPosition.x2 = 20 + parseInt($event.target.value);
    }
    if(attr == 'h'){
      this.CropperPosition.y1 = 20;
      this.CropperPosition.y2 = 20 + parseInt($event.target.value);
    }

  }

  getName(name) {
    let file = name.split('.');
    this.extension = '.' + file[1];
    return file[0];
  }



  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }
}
