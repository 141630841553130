import { NgxSpinnerService } from 'ngx-spinner';
import { MediaService } from 'src/app/shared/services/media.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MEDIA } from '../../../shared/settings/global';

@Component({
  selector: 'app-dialog-icons',
  templateUrl: './dialog-icons.component.html',
  styleUrls: ['./dialog-icons.component.scss']
})
export class DialogIconsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogIconsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private spinner: NgxSpinnerService,
    public mediaService: MediaService,

  ) { }

  icons : any;
  selected = '';
  hotel : any;
  slug : any;
  path = "/home/tabhotel/projects/mykiosk/media/customers/demo/media/icons";
  staticIcons : any;
  maintainRatio = false;
  aspectRatio = 1

  ngOnInit() {
    this.spinner.show('icons');



    this.hotel = this.data.hotel;

    this.mediaService.getMediaByFolder(this.path).subscribe(res => {
      this.icons = res['result'];
      this.staticIcons = res['result'];
      this.spinner.hide('icons');

    });
  }

  /**
   *
   * @param icon
   */
  selectIcon(icon){
    this.selected = '/media/customers/'+ icon.url;
  }

  /**
   *
   * @param $event
   */
  selectIconFromMedia(img) {
    this.selected =  img;

  }

  getName(name){
    if(name.indexOf('---') != -1){
      return (name.split('---')[1]).replace('.png', "").replace('.jpg', "")
    }else {
      return name.replace('.png', "").replace('.jpg', "")
    }
  }

  /**
   *
   * @param src
   */
  buildUrl(src){
    return (src && src.search('http') === -1) ?  (MEDIA +'/media/customers/' +src) : src ;
  }

  searchMedia(searchValue){
    if(!searchValue){
      this.icons = Object.assign([], this.staticIcons);
  }else {
      this.icons = Object.assign([], this.staticIcons).filter(
          media => (media.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
      )
  }
  }
}
