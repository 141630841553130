import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeeplTransService {

  constructor(
    private http : HttpClient
  ) { }

    /**
   *
   * @param from
   * @param to
   */
  apiDeepl(from='EN', to='FR', texts= []){
    let text = ''
    let headers = new HttpHeaders();
    headers.append('Authorization',  'edb2dc3e-e905-4d6a-2b56-7f6483ad3ab7');
    if(typeof(texts) == 'object'){
      for(let item of texts){
        text += '&text='+item.label
      }
    }else {
      text = '&text='+texts
    }

    return this.http.get('https://api.deepl.com/v2/translate?auth_key=edb2dc3e-e905-4d6a-2b56-7f6483ad3ab7'+text+'&target_lang='+to+'&source_lang='+from, {headers})
  }

  /**
   *
   * @param from
   * @param to
   * @param texts
   */
  apiWatson(from='EN', to='FR', texts= []){
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Basic YXBpa2V5OmFRWEJfMDFVZlV6eURNVnRQaTgtaTlXeU5ncXVONmFfNENmTV9aa2ZWNVlu");
    headers.append("Content-Type", "text/plain");    // headers.append("Content-Type", "application/json");
    let object = {"text" : [texts],"model_id":from.toLowerCase()+"-"+to.toLowerCase()}
    return this.http.post("https://api.eu-gb.language-translator.watson.cloud.ibm.com/instances/a8e2aa3c-bbb7-41cc-9735-3b5f192ab8f9/v3/translate?version=2018-05-01", object, {headers})
  }
}
