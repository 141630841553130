<!-- Page Content -->
<div class="container-fluid">
  <app-fixed-title [title]='title | translate' [hotel]='hotel' *ngIf="!DIALOG && !onglet"></app-fixed-title>

  <h4  *ngIf="onglet" class="mt-3">{{title}}</h4>
  <div class="content" [ngClass]="{'white-box':!DIALOG}">


    <!-- ACTION BTNS -->
    <div class="actions row">
      <div class="col-md-3">
        <input type="search" (input)="searchMedia($event.target.value)" class="form-control" placeholder="{{'Search' | translate}}">
      </div>
      <div class="col-md-9  text-right">


        <!-- UPLOAD IMAGE -->
        <div class="upload-input">
          <input type="file" id="input-file-cropper" #fileUploadCropper (change)="imageSelected($event)" accept="image/*" multiple="true">
          <button class="btn btn-success">
            <i class="fa fa-picture-o" aria-hidden="true"></i>
            {{'Upload Image' | translate}}
          </button>
        </div>
        <!-- UPLOAD IMAGE -->

        <!-- CREATE FOLDER -->
        <button class="btn btn-success createFolder" (click)="createFolder()">
          <i class="fa fa-folder" aria-hidden="true"></i>
          {{'Create folder' | translate}}
        </button>
        <!-- CREATE FOLDER -->

      </div>

    </div>
    <!-- ACTION BTNS -->


    <hr>
 
    <!-- PROGRESS BAR -->
    <ng-container *ngFor="let resp of uploadResponse; let i =index">
      <div class="progress" *ngIf="resp?.status === 'progress' && resp.message < 100">
        <div class="progress-bar progress-bar-striped" [style.width.%]="resp.message"
          [attr.aria-valuenow]="resp.message + 'Image '+(i+1)" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
   
    </ng-container>


    <!-- PROGRESS BAR -->

    <!-- LIST OF MEDIA -->
    <div class="row">
      <div [ngClass]="{'col-md-9':!DIALOG, 'col-md-12':DIALOG}">

        <div class="row">
          <!-- DISPLAY RETURN IF BROWSE FOLDER -->
          <div class="col" *ngIf="parent && (strict != parent)">
            <div class="folder" (click)="parseFolder(parent, true)">
              <img src="/assets/img/icons/svg/left-arrow.svg" alt="">
              {{'Return' | translate}}
            </div>
          </div>
           <!-- DISPLAY RETURN IF BROWSE FOLDER -->

           <!-- LOOP FOR MEDIA : FILES AND DIRECTORIES -->
          <div class="col-md-3" *ngFor="let media of filteredMedia">
            <!-- if is directory -->
            <ng-container *ngIf="media.type == 'dir'">
              <div class="folder" [matMenuTriggerFor]="menu"
                (click)="parseFolder(media.path)">
                <img src="/assets/img/icons/svg/folder.svg" alt="">
                {{media.name}}
              </div>
            </ng-container>
            <!--END if is directory -->

            <!-- if is a file -->
            <div class="img media" *ngIf="media.type == 'file'" (click)="getSelectedImg(media)"
              [ngClass]="{'selected':media.selected}" [matMenuTriggerFor]="menu">
              <img src="{{changePath(media.url)}}" alt="" class="mr-3">
              <div class="media-body">
                <h5> {{getName(media.name)}}</h5>
                <small>{{media.size / 1000000 | number: '1.2-2'}} Mo</small>
              </div>
            </div>
            <!-- END if is a file -->


          <mat-menu #menu="matMenu">
            <button mat-menu-item  (click)="renameFolder(menu)" *appHasRole="'super-user'">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              {{'Rename' | translate}} "{{getName(media.name)}}"

            </button>
            <button mat-menu-item (click)="deleteDirectory(menu)">

              <i class="fa fa-trash-o" aria-hidden="true"></i>
              {{'Delete' | translate}} "{{getName(media.name)}}"

            </button>
          </mat-menu>

          </div>
          <!-- END LOOP FOR MEDIA : FILES AND DIRECTORIES -->

        </div>
      </div>

      <!-- PREVIEW -->
      <div class="col-md-3" *ngIf="selected && !DIALOG">
        <img src="{{changePath(selected.url)}}" alt="" class="img-responsive"
          (click)="openImage(changePath(selected.url))">
        <br>
        <h4 class="name">{{getName(selected.name)}}</h4>
        <p>{{'Size' | translate}} : {{selected.size / 1000000 | number: '1.2-2'}} Mo</p>
        <hr>
        <!-- <button class="btn btn-success" (click)="renameFolder($event, selected)">
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        Rename 
      </button> -->
        <button class="btn btn-danger" (click)="deleteDirectory($event, selected)" *appHasRole="'super-user'">
          <i class="fa fa-trash-o" aria-hidden="true"></i>
          {{'Delete' | translate}}
        </button>
      </div>
    </div>
    <!-- PREVIEW -->
    <!-- LIST OF MEDIA -->


    <!--spinner-->

    <ngx-spinner bdOpacity=0.7 bdColor="#1976d2a8" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="false"
      [name]="'media'" style="border-radius: 7px">
      <p style="color: white"> {{'Loading' | translate}}... </p>
    </ngx-spinner>
  </div>

</div>




