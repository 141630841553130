import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-amout',
  templateUrl: './dialog-amout.component.html',
  styleUrls: ['./dialog-amout.component.scss']
})
export class DialogAmoutComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogAmoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}

  amount :any;
  ngOnInit() {
  	this.amount = this.data;
  }

}
