import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-order-items',
  templateUrl: './dialog-order-items.component.html',
  styleUrls: ['./dialog-order-items.component.scss']
})
export class DialogOrderItemsComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogOrderItemsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}


  ngOnInit() {
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }
}
