function isObject(obj) {
    var type = typeof obj;
    return type === 'function' || type === 'object' && !!obj;
};

/**
 * COPY COMPLEX OBJECT
 * @param src 
 * @param array 
 */
export function iterationCopy(src, array = false) {
    let target = array?  [] : {};
    for (let prop in src) {
        if (src.hasOwnProperty(prop)) {
            // if the value is a nested object, recursively copy all it's properties
            if (isObject(src[prop]) && !Array.isArray(src[prop])) {
                target[prop] = iterationCopy(src[prop]);
            } 
            else if(Array.isArray(src[prop])){
                target[prop] = iterationCopy(src[prop], true);
            } else {
                target[prop] = src[prop];
            }
        }
    }
    return target;
}


export function isLink(attr){
    return attr.indexOf('http') != -1;
}

/**
 * OBJECT TO ARRAY
 * @param obj 
 */
export function objectToArray(obj){
    let array = [];
    for (let i in obj){
        array.push(obj[i]);
    }
    return array;
}

export function objectToArrayAttr(obj){
    let array = [];
    for (let i in obj){
        array.push({name : i.split('_').join(' ') , data : [obj[i].finished, obj[i].total], type:undefined});
    }
    return array;
}


export function copyObject(obj){
  return JSON.parse(JSON.stringify(obj))
}


export function hasRole(current, ask){
  let roles = {
    "super-user":3,
    "administrator":2,
    "concierge":1
  }

  return roles[current] >= roles[ask];
}



export function compareValues(key, order = 'asc', date = false) {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
      let varA = a[key]
      let varB = b[key]
      if (date) {
        varA = Date.parse(a[key])
        varB = Date.parse(b[key])
      }
      else if (typeof a[key] === 'string') {
        varA = a[key].toUpperCase()
        varB = b[key].toUpperCase()
      }

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  /**
   * 
   * @param object 
   * @param attrs 
   * @param langs 
   */
  export function copyAttr(object, attrs = [], langs = [] ){
    for(let attr of attrs){
      object[attr] =  "Copy "+ object[attr]
      for(let lang of langs){
        object[attr + '_' +lang.lang_code] = "Copy "+ object[attr + '_' +lang.lang_code];
      }
    }
    return object;
  }