<h1 class="mat-dialog-title">{{'Config Chart' | translate}}</h1>
<div mat-dialog-content>

  <div class="form-group">
    <label for="">Axe X</label>
    <select class="form-control" [(ngModel)]="chart.axeX">
      <ng-container *ngFor="let attr of attrs">
        
        <option value="{{attr}}" *ngIf="isDate(attr)">{{attr}}</option>

      </ng-container>
    </select>
  </div>



  <div class="form-group">
    <label for="">{{'Chart Type' | translate}}</label>
    <select class="form-control" [(ngModel)]="chart.type">
      <option value="area">{{'Area' | translate}}</option>
      <option value="spline">{{'Spline' | translate}}</option>
      <option value="column">{{'Column' | translate}}</option>
      <option value="columnpercent">{{'Column Percent by type' | translate}}</option>
    </select>
  </div>

  <div class="form-group">
    <label for="">{{'Categories'| translate}}</label>
    <select class="form-control" [(ngModel)]="chart.series">
      <option value="">{{'All'| translate}}</option>
      <ng-container *ngFor="let attr of attrs">
        <option value="{{attr}}" *ngIf="isDate(attr, false)">{{attr}}</option>
      </ng-container>
    </select>
  </div>
  
</div>
<div mat-dialog-actions align=end>
  <button class="btn btn-default" [mat-dialog-close]="">{{'Cancel'| translate}}</button>
  <button class="btn btn-success" [mat-dialog-close]="chart" >{{'Confirm'| translate}}</button>
</div>