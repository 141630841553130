import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

	uri = URI;

	constructor(private http: HttpClient) {
	}

	//GET ITEMS
	getItems(params?){
		return this.http.get(`${this.uri}`+"content-items/"+params);
	}

	/**
	 *
	 * @param item
	 */
	addItem(item){
		return this.http.post(`${this.uri}`+"content-items/", item);
	}

	//UPDATE ITEM
	updateItem(item){
		return this.http.put(`${this.uri}`+"content-items/"+item.id+"/", item);
	}

	//UPDATE ITEM
	updateImageItem(item){
		return this.http.put(`${this.uri}`+"item/update-image/"+item.id, item);
	}

	//DELETE ITEM
	deleteItem(id){
		return this.http.delete(this.uri+"content-items/"+id+"/")
	}

	//SORT ITEMS
	sortItems(obj){
		return this.http.post(`${this.uri}`+"sort-items", obj);
	}

	//GET MAP POSITION
	getMapPosition(id){
		return this.http.get(`${this.uri}`+"map-services/"+id);
	}

	//ADD AREAMAP
	addMapPosition(mapPosition){
		return this.http.post(`${this.uri}`+"map-services/", mapPosition);
	}

	//EDIT AREAMAP
	editMapPosition(mapPosition){
		return this.http.put(`${this.uri}`+"map-services/"+mapPosition.id+"/", mapPosition);
	}

	//GET TRANSPORT
	getTransport(id){
		return this.http.get(`${this.uri}`+"transport-services/"+id);
	}

	//ADD TRANSPORT
	addTransport(transport){
		return this.http.post(`${this.uri}`+"transport-services/", transport);
	}

	//EDIT AREAMAP
	editTransport(transport){
		return this.http.put(`${this.uri}`+"transport-services/"+transport.id+"/", transport);
	}

	//GET ROOM SERVICE
	getRoomService(id){
		return this.http.get(`${this.uri}`+"room-services/"+id);
	}

	//ADD ROOM SERVICE
	addRoomService(roomService){
		return this.http.post(`${this.uri}`+"room-services/", roomService);
	}

	//EDIT ROOM SERVICE
	editRoomService(roomService){
		return this.http.put(`${this.uri}`+"room-services/"+roomService.id+"/", roomService);
	}

	//ADD EXTRA OBJECT
	addExtraObject(extraObject, category_type){
		if(category_type == "RoomService"){
			return this.addRoomService(extraObject);
		}else if(category_type == "Transport"){
			return this.addTransport(extraObject);
		}else if(category_type == "MapPosition"){
			return this.addMapPosition(extraObject);
		}
		return false
	}

	//Edit EXTRA OBJECT
	editExtraObject(extraObject, category_type){
		if(category_type == "RoomService"){
			return this.editRoomService(extraObject);
		}else if(category_type == "Transport"){
			return this.editTransport(extraObject);
		}else if(category_type == "MapPosition"){
			return this.editMapPosition(extraObject);
		}
		return false

	}



}
