import { NgxSpinnerService } from 'ngx-spinner';
import { MENU_ITEMS } from 'src/app/main-template/menu/menu';
import { DefaultValueService } from 'src/app/shared/services/default-value.service';
import { ModuleManagerService } from 'src/app/shared/services/module-manager.service';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogMenuModuleComponent } from './dialog-menu-module/dialog-menu-module.component';

@Component({
  selector: "app-menu-manager",
  templateUrl: "./menu-manager.component.html",
  styleUrls: ["./menu-manager.component.scss"],
})
export class MenuManagerComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private defaultConfig: DefaultValueService,
    public dialog: MatDialog,
    private moduleService: ModuleManagerService
  ) {}

  @Input() slug: any;
  @Input() hotel: any;
  @Input() languages: any;

  modules: any;
  menuItems = MENU_ITEMS;
  defaultMenu: any;
  activeMenus = MENU_ITEMS;
  openSelect = {};
  newItem: any;
  other =  { "name": "New link", "link": "/", "param": false, "icon": "fa fa-binoculars", "role": "concierge", "active": true, "id": "other", "name_de": "" }

  
  ngOnInit() {
    this.moduleService.list("?hotel=" + this.slug).subscribe((res) => {
      this.modules = res;
    });

    this.defaultConfig.getDefaultValue(this.slug, "main-menu").subscribe(
      (res) => {
        this.activeMenus = JSON.parse(res["values"]);
        this.defaultMenu = res;
        this.spinner.hide("global");
      },
      () => {
        this.defaultConfig
          .getDefaultValue("29", "main-menu")
          .subscribe((res) => {
            this.activeMenus = JSON.parse(res["values"]);
          });
        this.spinner.hide("global");
      }
    );
  }

  /**
   *
   * @param menu
   */
  toggleActiveMenu(menu) {
    if (typeof menu.active == "undefined") {
      menu.active = true;
    } else {
      menu.active = !menu.active;
      if (menu.submenu) {
        menu.submenu.map((x) => {
          x.active = menu.active;
        });
      }
    }
  }

  /**
   *
   */
  AddOrUpdateMenu() {
    let mainMenu = {
      hotel: this.slug,
      name: "main-menu",
      values: JSON.stringify(this.activeMenus),
    };
    if (typeof this.defaultMenu != "undefined") {
      this.defaultConfig
        .updateDefaultValue({ ...mainMenu, id: this.defaultMenu.id })
        .subscribe(() => {
          window.location.reload();
        });
    } else {
      this.defaultConfig.createDefaultValue(mainMenu).subscribe(() => {
        window.location.reload();
      });
    }
  }

  drop(event: CdkDragDrop<string[]>, menu) {
    moveItemInArray(menu, event.previousIndex, event.currentIndex);
  }

  reset() {
    this.activeMenus = MENU_ITEMS;
  }

  /**
   *
   * @param listMenu
   */
  toggleSelect(listMenu) {
    for (let menu of listMenu) {
      menu.active = !menu.active;
      if (menu.submenu) {
        this.toggleSelect(menu.submenu);
      }
    }
  }

  addNewItem() {
    this.activeMenus.push(this.newItem);
  }

  /**
   *
   * @param i
   * @param j
   */
  remove(i, j = null) {
    if (j === null) {
      this.activeMenus.splice(i, 1);
    } else {
      this.activeMenus[i].submenu.splice(j, 1);
      if (this.activeMenus[i].submenu.length == 0) {
        delete this.activeMenus[i].submenu;
      }
    }
  }

  /**
   *
   * @param i
   */
  addSubMenu(i) {
    const dialogRef = this.dialog.open(DialogMenuModuleComponent, {
      width: "500px",
      data: {
        menuItems: this.menuItems,
        modules: this.modules,
        slug: this.slug,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (typeof this.activeMenus[i].submenu == "undefined") {
          this.activeMenus[i].submenu = [];
        }
        this.activeMenus[i].submenu.push(result);
      }
    });
  }

  getMenuParamsFromModule(module) {
    return {
      name: module.name,
      link: "/module-worker/" + this.slug + "/" + module.id,
      param: false,
      icon: module.configs.icon,
      role: module.configs.permission,
      active: true,
      id: "module_" + module.id,
    };
  }
}
