<div class="row">

    <!-- ligne -->
    <div class="col-md-3 text-right">
        <label for="" class="label"> {{'Rooms in these categories' | translate}}</label>
    </div>

    <div class="col-md-7 form-group">
        <mat-select class="form-control" *ngIf="rooms"  [multiple]="true"
        [(ngModel)]="offer['CONDITIONS']['categories']">
        <mat-option *ngFor="let item of rooms" [value]="item.id">
            {{item.text}}
        </mat-option>
    </mat-select>

       
    </div>
    <!--ligne-->
</div>

<div class="row">

    <!-- ligne -->
    <div class="col-md-3 text-right">
        <label for="" class="label">{{'Rooms with these features' | translate}}</label>
    </div>

    <div class="col-md-7 form-group">

        <mat-select class="form-control" *ngIf="rooms" [multiple]="true"
        [(ngModel)]="offer['CONDITIONS']['features']">
        <mat-option *ngFor="let item of rooms" [value]="item.id">
            {{item.text}}
        </mat-option>
    </mat-select>
    </div>
    <!--ligne-->
</div>

<div class="row">

    <!-- ligne -->
    <div class="col-md-3 text-right">
        <label for="" class="label">{{'Items' | translate}}</label>
    </div>

    <div class="col-md-7 form-group">
        <mat-select class="form-control" multiple [(value)]="offer['DISPLAY_ITEMS']['items']" [(ngModel)]="offer['DISPLAY_ITEMS']['items']">
            <mat-option *ngFor="let category of categories" [value]="category.id">{{category.text}}</mat-option>    
        </mat-select>

    </div>
    <!--ligne-->
</div>