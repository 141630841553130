<h2 mat-dialog-title>
    {{ (category.id ? "Edit Category" : "Add Category") | translate }}
    <app-select-translate [hotel]="hotel" [languages]="languages"></app-select-translate>
  </h2>
  <mat-dialog-content class="mat-typography" *ngIf="category">

    
    <app-form-builder
      [attrs]="attrs"
      [hotel]="hotel"
      [model]="category"
      [superFields]="superFields"
      [type]="'category'"
      [languages]="languages"
      [errors]="errors"></app-form-builder>
  
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="btn btn-default">{{'Cancel' | translate}}</button>
    <button mat-button (click)="createOrUpdate(category, true)"  class="btn btn-success" *ngIf="!category.id">
      {{'Save and add another' | translate}}
    </button>
    <button mat-button (click)="createOrUpdate(category)" class="btn btn-success">
      {{ "Save" | translate}}
    </button>
  </mat-dialog-actions>