import { Subject } from 'rxjs';

import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appHasntRole]'
})
export class HasntRoleDirective implements OnInit {

  @Input() appHasntRole: string;
  userRole : any;
  stop$ = new Subject();

  isVisible = false;


  /*
  if super user (true)
  if administrator (concierge , adminsitraor ) == true et concierge
  */
  /**
   * @param {ViewContainerRef} viewContainerRef
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef
   *   -- the templateRef to be potentially rendered
   * @param {RolesService} rolesService
   *   -- will give us access to the roles a user has
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  ngOnInit() {
    this.userRole = localStorage.getItem('user_role');

      if (!this.userRole) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);

      }

      if (this.userRole != this.appHasntRole) {

        if (!this.isVisible) {

          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {

        this.isVisible = false;
        this.viewContainerRef.clear();
      }

  }

  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next('');
  }

}
