import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class RoomCategoryService {

  constructor(private http: HttpClient) { }
  uri = URI + "room-categories/";

  getRoomsCategories(params) {
    return this.http.get(this.uri+params);
  }

  deleteRoomCategory(id){
    return this.http.delete(this.uri+id)
  }

  create(data) {
    return this.http.post(this.uri, data);

  }
  update(data) {
    return this.http.put(this.uri+data.id+'/', data);
  }
}
