import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransportService } from 'src/app/shared/services/transport.service';

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss']
})
export class DialogTransportComponent  implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<DialogTransportComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private trans: TransportService,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,

  )
  {
    this.notifier = notifierService;
  }

  private readonly notifier: NotifierService;

  form: UntypedFormGroup;
  control: UntypedFormControl;
  errors = null;
  transport;
  types = [
    "Metro",
    "RER",
    "Tramway",
    "Bus"
  ]

  ngOnInit(){
    this.transport = this.data.transport;
  }



  /**
   * CREATE OR UPDATE
   * @param transport
   */
  createOrUpdate(transport = null){
    if (!transport['id']) {
      this.createTransport(this.data.transport);
    } else {
      this.updateTransport(this.data.transport);
    }
  }


  /**
   * CREATE TRANSPORT
   * @param transport
   */
  createTransport(transport) {
    this.spinner.show('global');
    transport['hotel'] = this.data.hotel.id
    this.trans.createTransport(transport)
      .subscribe(
        res => {
          this.translate.get('Transport successfully added').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
          //this.data.transports.push(res);
          this.spinner.hide('global');
          this.dialogRef.close({action:'add', response:res});
        },
        err => {
          this.errors = err.error;
          this.spinner.hide('global');

        }
      )
  }

  /**
   * UPDATE TRANSPORT
   * @param transport
   */
  updateTransport(transport) {
    this.spinner.show('global');
    this.trans.updateTransport(transport['id'], transport)
      .subscribe(
        res => {
          this.translate.get( 'Transport successfully updated').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
          this.transport = res;
          this.spinner.hide('global');
          this.dialogRef.close({action:'update', response:res});

        },
        err => {
          this.spinner.hide('global');
          this.errors = err.error;
        }
      )
  }
}
