<div class="alert alert-danger" role="alert">

 <p *ngFor="let obj of errors | keyvalue">
     
     <strong>{{obj.key}} :</strong>
     <ng-container *ngFor="let err of obj.value">
        <span >{{err | translate}}</span><br>
     </ng-container>
     
 </p>
</div>


