<div class="accordion" id="accordionExample">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h2 class="mb-0">
        <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
          aria-expanded="true" aria-controls="collapseOne">
          {{'Variables'  | translate}}  ({{form.length}})
        </button>
      </h2>
    </div>

    <!-- <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div class="card-body no-padding">
        <table class="table table-striped">
          <tr  *ngFor="let field of form.sections; let i = index ">
            <td>{{i}}</td>
            <td><strong>{{field.name | translate}}</strong></td>
            <td>{{field.type == 'text' ? field.input_type : field.type}}</td>
          </tr>
        </table>
   
      </div>
    </div> -->
    <div class="card">
      <div class="card-header" id="headingTwo">
        <h2 class="mb-0">
          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo"
            aria-expanded="false" aria-controls="collapseTwo">
            {{'Form JSON'  | translate}}
          </button>
        </h2>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
        <div class="card-body">
          <textarea [(ngModel)]="formText" class="form-control" rows="10"></textarea>
          <br>
          <button (click)="updateJson()" class="btn btn-success">Update</button>
        </div>
      </div>
    </div>
  </div>