<div class="container-fluid">



    <div class="row mt-3 mb-4">
        <div class="col">
            <h4>
                <i class="fa fa-bed"></i>
                Item links PMS
            </h4>
        </div>

        <div class="text-right col">
            <button class="btn bnt-sm btn-info" (click)="openDialog()">
                <i class="fa fa-plus" aria-hidden="true"></i>
                Add new link
            </button>
        </div>
    </div>


    <table class="table-bordered table">

        <thead>
            <tr>
                <th>Source Item (Tabhotel)</th>
                <th>Items to hide</th>
                <th>Actions</th>
            </tr>
        </thead>

        <tr *ngFor="let item of items">

            <td><strong>{{item?.item_source?.name}}</strong>
                <br> ID : {{item?.item_source?.item_pms_id}}
            </td>
            <td>
                <div [innerHTML]="getText(item.item_pms_id)"></div>
            </td>
            <td>
                <button class="btn btn-xs btn-success" (click)="openDialog(item)"><i class="fa fa-pencil"></i></button>
                <button class="btn btn-xs btn-danger" (click)="delete(item)"><i class="fa fa-trash"></i></button>
            </td>
        </tr>
    </table>
</div>