import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class DashboardTemplateService {

  uri = URI + 'dashboard-templates/';

  constructor(private http: HttpClient) { }


  getDashboardTemplates(params = '') {
    return this.http.get(this.uri+params);
  }

  createDashboardTemplates(temp) {
    return this.http.post(this.uri, temp);
  }


  getDashboardTemplate(id) {
    return this.http.get(this.uri+id);
  }
}
