<h1 mat-dialog-title>{{'Defaut values list' | translate}}</h1>
<div mat-dialog-content>

<table class="table" *ngIf="data">
	<tr *ngFor="let item of data.values | keyvalue ;  let i = index">

		<td><input type="text" class="form-control" [(ngModel)]="item.key"> </td>
		<td><input type="text" class="form-control" [(ngModel)]="item.value"></td>
		<td><button class="btn btn-danger" (click)="deleteItem(item.key)"><i class="fa fa-trash"></i></button></td>
	</tr>

</table>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'Close' | translate}}</button>
  <button mat-button [mat-dialog-close]="data" class="btn btn-success" >{{'Update Default values' | translate}}</button>
</div>


