import { NgxSpinnerService } from 'ngx-spinner';
import { WorkflowService } from 'src/app/shared/services/workflow.service';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

import { getNestedObject, setNestedKey } from '../../module-worker/_nestedProp';
import { buildJson, buildResponseType, setArraySection } from '../view/_buildDefaultValue';

@Component({
  selector: 'app-form-tabs',
  templateUrl: './form-tabs.component.html',
  styleUrls: ['./form-tabs.component.scss']
})
export class FormTabsComponent implements OnInit, OnChanges {

  constructor(
    private spinner: NgxSpinnerService,
    private workflowService: WorkflowService,

  ) { }

  isLinear = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  currentLang = 'en';
  defaultLang = 'en'
  @Input() response = {} ;
  @Input() hotel: any;
  @Input() languages: any;
  @Input() form: any;
  @Input() variablesAPILIST=[];
  @Output() saveForm = new EventEmitter();
  @ViewChild('stepper') stepper: MatStepper;
  @Input() customVars;
  translatable = false;
  @Input() errors : any;


  ngOnChanges(){
    console.log(this.errors)
  }


  ngOnInit() {

    this.form.sections.forEach((section) => {
      //BUILD RESPONSE
      buildResponseType(section, this.response, this.hotel);

      section.fields.forEach(field => {
        this.translatable = this.translatable || field.translatable
      });
    })

    setInterval(()=>{
      this.saveForm.emit(this.response);
    },5000)
  }

  saveEvent() {
    this.saveForm.emit(this.response);
  }

  autoTranslate() {
    let obj = this.findTextsToTranslate();

    if (obj.texts.length) {
      this.spinner.show('global');

      this.workflowService.translate({ texts: obj.texts, lang: this.currentLang, lang_src: this.defaultLang }).subscribe(res => {

        for (let k in res['translations']) {
          this.response[obj.keys[k]] = res['translations'][k].translation
        }
        this.spinner.hide('global');
        if(res['error']){
          alert(res['error']);
        }

      }, err => {
        alert('An error was accured');
        this.spinner.hide('global');
      })
    } else {
      alert('Nothing to translate')
    }
  }

  setCurrentLang(lang) {
    this.currentLang = lang
  }

  /**
   *
   * @param response
   * @param section
   */
  getResponse(response, section, j = 0){
    if(section.is_array && section.path && j !== null){
      // return response[section.path][j];
      return getNestedObject(response, section.path.split('.'))[j]
    }
    if(section.is_array && section.path && j === null){
      return getNestedObject(response, section.path.split('.'))
    }
    else {
      return response;
    }
  }

  /**
   *
   */
  findTextsToTranslate() {
    let texts = [];
    let keys = []
    if(this.defaultLang != this.currentLang){
      for (let i in this.response) {
        //IF TRANSLATED FIELD : i.indexOf('_en') exist; and not image
        if (i.indexOf('_' + this.currentLang) != -1 && this.response[i].indexOf('/') == -1) {

          //GET TEXT OF DEFAULT LANG : this.response['fieldName_en']
          let text = this.response[i.replace('_' + this.currentLang, '_' + this.defaultLang)];

          //ADD TEXT TO TRANSLATE TO LIST, IF NOT NULL
          if (text) {
            texts.push(text) //FILL TEXT VALUES
            keys.push(i); // FILL TEXT KEYS
          }
        }
      }
    }else {
      alert("Can't translate default language");
    }
    return { texts, keys };
  }


  /**
   *
   * @param response
   * @param section
   */
  addObjectToSection(response,section){
    let newObject = {};
    let nestedResponse = getNestedObject(response, section.path.split('.') )
    if(nestedResponse == undefined){
      nestedResponse = buildJson(response,section.path).response;
    }
    buildResponseType({...section, is_array:false}, newObject, this.hotel);
    nestedResponse.push(newObject);
  }






}
