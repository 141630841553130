<h1 class="mat-dialog-title">
  <i class="material-icons" *ngIf="data.icon">{{data.icon}}</i>{{'Process detail' | translate}}
</h1>
<div mat-dialog-content>
  <div class="container">

    <div class="timeline-block timeline-block-left" *ngFor="let data of values">
      <div class="marker"></div>
      <div class="timeline-content">
        <h3>{{data.name | translate}}</h3>
        <p>{{data.date}}</p>
      </div>
    </div>
  </div>

</div>
<div mat-dialog-actions align=end>
  <button class="btn btn-success" [mat-dialog-close]="">{{'OK' | translate}}</button>
</div>