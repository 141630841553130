import { server } from 'src/app/shared/settings/global';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DialogNotifSettingComponent } from '../dialog-notif-setting/dialog-notif-setting.component';

@Component({
  selector: 'app-dialog-list-notifications',
  templateUrl: './dialog-list-notifications.component.html',
  styleUrls: ['./dialog-list-notifications.component.scss']
})
export class DialogListNotificationsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogListNotificationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog : MatDialog
  ) { }

  getUrl(url){
    return server+'/media/' + url
  }
  ngOnInit() {
  }

  deleteAllNotif(){
    this.dialogRef.close({close:true});
  }



  openDialogSetting(){
    this.dialog.open(DialogNotifSettingComponent, {
      width : '500px'
    })
  }
}
