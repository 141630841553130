import { NgxSpinnerService } from 'ngx-spinner';
import { SliderService } from 'src/app/shared/services/slider.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-slider',
  templateUrl: './edit-slider.component.html',
  styleUrls: ['./edit-slider.component.scss']
})
export class EditSliderComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditSliderComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private sl: SliderService,
    private spinner: NgxSpinnerService,
  ) { }

  slider: any;
  hotel: any;
  sliders: any;
  languages: any;
  errors = null;


  ngOnInit() {
    this.sliders = this.data.sliders;
    this.slider = this.data.slider;
    this.hotel = this.data.hotel;
    this.languages = this.data.languages;
  }



  /**
   * CREATE OR UPDATE SLIDER
   * @slider : slider
   */
  createOrUpdate(slider) {
    this.spinner.show('global');
    //create form data

    //init ranked
    if (slider.ranked === null) {
      slider.ranked = 1;
    }
    //if slider exist --> update
    if (slider.id) {
      this.updateSlider(slider);
    } else {//if new slider --> create
      this.createSlider(slider)
    }
  }

  /**
   * UPDATE SLIDER
   * @param fb
   * @param slider
   */
  updateSlider(slider){
    this.sl.updateSlider(slider).subscribe(res => {
      if (res) {
        this.spinner.hide('global');
        this.sliders[this.sliders.findIndex(x=>x.id == res['id'])]=res;
        this.dialogRef.close( this.slider);
      }
    }, err => {
      this.errors = err.error;
      this.spinner.hide('global');
      console.log(err)
    })
  }
  /**
   * CREATE SLIDER FUNCTION
   * @param slider
   */
  createSlider(slider){
    this.sl.createSlider(slider).subscribe(res => {
      if (res) {
        this.spinner.hide('global');
        this.sliders.push(res);
        this.dialogRef.close(res);
      }
    }, err =>{
      this.errors = err.error;
      this.spinner.hide('global');
    } )
  }

}
