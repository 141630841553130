import { NgxSpinnerService } from 'ngx-spinner';
import { SlideService } from 'src/app/shared/services/slide.service';
import { MEDIA } from 'src/app/shared/settings/global';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-slide',
  templateUrl: './dialog-slide.component.html',
  styleUrls: ['./dialog-slide.component.scss']
})
export class DialogSlideComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSlideComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private sl: SlideService,
    private spinner: NgxSpinnerService

  ) { }

  slide : any;
  slides : any;
  item : any;
  hotel : any;
  MEDIA = MEDIA;
  languages : any;
  errors = null;

  ngOnInit() {
    this.slide = this.data.slide;
    this.slides = this.data.slides;
    this.languages = this.data.languages;
    this.hotel = this.data.hotel;

  }

  /**
   * CREATE OR UPDATE SLIDE
   * @param slide
   */
  addOrEditSlide(slide){

    //BUILD FORM DATA

    this.spinner.show('global');
    //UPDATE
    if(slide.id){
      this.sl.updateSlide(slide, slide.id).subscribe(res=>{
        this.data.slides[this.data.index] = res;
        this.dialogRef.close(true);
        this.spinner.hide('global');
      }, err=>{
        //ERROR
        this.errors = err.error;
        this.spinner.hide('global');
      })
    }else {
      //CREATE SLIDE
      this.sl.createSlide(slide).subscribe(res=>{
        this.slides.unshift(res);
        // this.item
        this.dialogRef.close(true);
        this.spinner.hide('global');


      }, err=>{
        //ERROR
        this.errors = err.error;
        this.spinner.hide('global');
      })
    }
  }


}
