import { Chart } from 'angular-highcharts';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { _PERIODS, PERIODS } from '../../constants';

@Component({
  selector: 'stat-chart',
  templateUrl: './stat-chart.component.html',
  styleUrls: ['./stat-chart.component.scss']
})
export class StatChartComponent implements OnInit , OnChanges {



  @Input() tile:any;
  @Input() template:any;
  @Input() data: any;
  @Input() chartConfig  = {
    xtype: 'datetime',
    type: 'spline',
    grouped : true,
    labels : []
  }
  @Input() dataSeries = [];
  @Output() actionEvent = new EventEmitter();
  chart: Chart;
  series = [];
  xChartType : any;
  xAxies :any;
  PERIODS = PERIODS;


  getPeriod(){
    return _PERIODS[this.template.default_period]
  }

  ngOnInit() {
    setTimeout(()=>{
      this.chart.ref$.subscribe();
    },10)

  }

  buildChart() {
    this.xAxies = {
      type: this.template.configs.chart.xtype,
      visible: true,
    }
    this.chartConfig = this.template.configs.chart;
    this.xChartType = this.chartConfig.xtype ? this.chartConfig.xtype : 'datetime';

    if(this.template.configs.chart.xtype == 'category'){
      this.xAxies['categories'] = [];
      for(let label of this.template.configs.chart.labels){
        let serie = [];
        for(let i in this.data){
          let category = i.split('_').join(' ');
          serie.push(this.data[i][label])
          if(this.xAxies['categories'].indexOf(category) == -1){
            this.xAxies['categories'].push(category)
          }
        }
        this.dataSeries.push({name : label, data : serie, type:undefined})
      }
      this.chart = this.getChart(this.dataSeries)
    }
    else {
      if(this.template.configs.chart.grouped){
        for(let i in this.data){
          let serie = {
            name : i,
            data : this.convert(this.data[i]),
            type : undefined
          }
          this.dataSeries.push(serie)
        }
      }else {
        let serie = {
          name : this.template.configs.chart.title,
          data : this.convert(this.data),
          type : undefined
        }
        this.dataSeries.push(serie)
      }
      this.chart = this.getChart(this.dataSeries)
    }
  }


  /**
   *
   * @param arr
   */
  convert(arr){
    let serie = []
    for(let item of arr){
      serie.push([Date.parse(item.date), item.value])
    }
    return serie;
  }

  ngOnChanges() {
    this.dataSeries = [];
    this.buildChart();
  }

  /**
   *
   * @param data
   */
  getChart(data) {
    return new Chart({
      chart: {
        type: this.chartConfig.type     
      },
      title: {
        text: ''
      },
      credits: {
        enabled: true
      },
      xAxis: this.xAxies,
      yAxis: {
        min: 0,
        visible: true
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
          }
        }
      },
      series: data
    });
  }





  /**
   *
   * @param event
   */
  actions(event){
    this.actionEvent.emit(event);
  }

}
