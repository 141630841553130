 <!-- whitebox-->
 <div class="white-box content">
    <h2 class="title">{{'Languages' | translate }}</h2>  
  <span (click)="openDialogLanguages()" class="open-modal">
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
    </span>

    <table class="info" *ngIf="hotel && languages">
      <tr>
        <td>{{'Default Language' | translate }}</td>
        <td *ngIf="hotel">
            <span class="lang active">
                {{langs[getLang(hotel?.default_lang)?.lang_code]}}
            </span>
          </td>
      </tr>
      <tr>
        <td>
            {{ 'Active Languages' | translate }}
        </td>
        <td>
          <ng-container *ngFor="let lang of hotel.languages">            
            <span class="lang active" >
                {{langs[lang.lang_code] | translate}}
            </span>
          </ng-container>
        </td>
      </tr>
    </table>

  </div>