<ng-container *ngIf="form.sections.length>1">

  <div class="text-right">
    <!-- automatic trans -->
    <button class="btn btn-default trans" (click)="autoTranslate()" *ngIf="form.params.autoTranslate">
      <i class="fa fa-flag"></i> {{'Automatic translate' | translate}}
    </button>
    <!-- automatic trans -->

    <app-select-translate [default]="currentLang" (changeEvent)="setCurrentLang($event)" [hotel]="hotel"
      [data_id]="'form-view-built'" [fixed]="false" *ngIf="hotel"></app-select-translate>
  </div>

  <div id="form-view-built">
    <mat-vertical-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup" *ngFor="let section of form.sections; let j = index ">
        <form [formGroup]="firstFormGroup">

          <ng-template matStepLabel>
            {{section.sectionName}}
          </ng-template>

          <form-view [customVars]="customVars" [section]="section" [variablesAPILIST]="variablesAPILIST" [form]="section.fields" (reaction)="getReaction($event)" [hotel]='hotel' [defaultValues]="''"  
            [response]="response">
          </form-view>

          <div class="text-right">
            <button class="btn btn-default" *ngIf="j>0" mat-button matStepperPrevious>Back</button>
            <button class="btn btn-success" *ngIf="j < form.sections.length - 1" mat-button matStepperNext>Next</button>
            <!-- <button class="btn btn-success" (click)="saveEvent()" *ngIf="j == form.sections.length - 1" mat-button
            matStepperNext>Save</button> -->
          </div>
        </form>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</ng-container>

<!-- IF IS NOT FORM STEPS -->
<ng-container *ngIf="form.sections.length == 1">
  <div class="row">
    <div class="col-6">
    </div>
    <div class="col-6 text-right">
      <!-- automatic trans -->
      <button class="btn btn-default trans" (click)="autoTranslate(group)" *ngIf="form.params.autoTranslate">
        <i class="fa fa-flag"></i> {{'Automatic translate' | translate}}
      </button>
      <!-- automatic trans -->
      <app-select-translate (changeEvent)="setCurrentLang($event)" [hotel]="hotel" [data_id]="'form-view-built'" [fixed]="false"
        *ngIf="hotel"></app-select-translate>
    </div>
  </div>


  <form-view id="form-view-built" [section]="form.sections[0]" [customVars]="customVars" [variablesAPILIST]="variablesAPILIST" [form]="form.sections[0].fields" [hotel]='hotel' [defaultValues]="''"
    [languages]="languages" [response]="response"></form-view>

</ng-container>

<!-- END SECTOON IF IS NOT FORM STEPS -->