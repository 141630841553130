<h2 mat-dialog-title>{{(social.id ? "Edit" : "Add") | translate}}  {{'Social media and other links'| translate}} </h2>
<mat-dialog-content class="mat-typography">
  
  
  <div class="row">
    <div class="col-lg-12">
        <div class="form-group">
          <label>Réseau Social</label>
          <select class="form-control" [(ngModel)]="social.name_social">
            <option value="facebook">FaceBook</option>
            <option value="twitter">Twiter</option>
            <option value="instagram">Instagram</option>
            <option value="youtube">Youtube</option>
            <option value="link">{{'Web Link'  | translate}}</option>
            <option value="google-plus">Google plus</option>
            <option value="linkedin">Linkedin</option>
          </select>
          <app-error-input *ngIf="errors" [errors]="errors" name="name_social"></app-error-input>
        </div>
    </div>
    <input type="hidden" [(ngModel)]="social.hotel">
     <div class="col-lg-12">
        <div class="form-group">
          <label>{{'Link'| translate }}</label>
          <input type="text" name="link" class="form-control" [(ngModel)]="social.link">
          <app-error-input *ngIf="errors" [errors]="errors" name="link"></app-error-input>
        </div>
    </div>
    
    </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'Cancel'| translate }}</button>
  <button mat-button class="btn btn-success" (click)="createOrUpdate(social)" >{{(social.id ? "Edit link" : "Add link") | translate }}</button>
</mat-dialog-actions>
