import { _commun } from './_commun';

export const _text = {
    ..._commun,
    "type": "text",
    "type_name":"Text field",
    "default":"",
    "input_type":"text" //text | date | number | email | password | color
}

