import { NotifierService } from 'angular-notifier';
import { SlideService } from 'src/app/shared/services/slide.service';
import { MEDIA } from 'src/app/shared/settings/global';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmDeleteComponent } from '../../modals/confirm-delete/confirm-delete.component';
import { DialogSlideComponent } from './dialog-slide/dialog-slide.component';

@Component({
  selector: 'app-slides',
  templateUrl: './slides.component.html',
  styleUrls: ['./slides.component.scss']
})
export class SlidesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SlidesComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private sl : SlideService,
    private translate: TranslateService,
    notifierService: NotifierService
    ) {
      this.notifier = notifierService;
    }

    private readonly notifier: NotifierService;

    slides : any;
    languages : any;

    slider = {};
    item : any;
    MEDIA = MEDIA;
    size = "";

    /**
     * DRAG AND DROP EVENT
     * @param event
     */
    drop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.slides, event.previousIndex, event.currentIndex);
      let obj = {
        slides: this.slides.map(a => a.id),
      }
      this.sl.sortSlides(obj).subscribe(
        res => {
          this.translate.get('Slide order successfully updated').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
        },
        err => {
          this.translate.get('An error has occurred').subscribe(
            value => {
              this.notifier.notify('error', value);
            }
          );
        }
      )
    }


    ngOnInit() {
      this.item = this.data.item;
      this.languages = this.data.languages;
      this.slides = this.item.slides;
    }


    /**
     * ADD OR EDIT SLIDE
     * @param slide
     */
    AddOrEditslide(slide = null, index = null){
      //if new one
      if(!slide){
        slide = {item:this.data.item.id, ranked : 0, th_width:600, th_height:500}
      }
      let data = {slide, hotel:this.data.hotel, languages : this.languages, slides:this.slides, index};

      this.dialog.open(DialogSlideComponent, {data:data, width:'800px'});

    }

    /**
     * BUILD URL
     * @param src
     */
    buildUrl(src){
      if(src && src.search('http') === -1){
        return this.MEDIA + src;
      }else {
        return src;
      }
    }

    /**
     * CONFIRM DELETE
     * @param slide
     * @param index
     */
    deleteConfirm(slide, index){
      let type = "slide";
      let name = slide.first_description
      const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
        data: { name, type }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          //Request Information
          this.sl.deleteSlide(slide.id).subscribe(res => {
            this.slides.splice(index, 1);
            this.translate.get('slide successfully deleted').subscribe(
              value => {
                this.notifier.notify('success', value);
              }
            );
          },
            err =>{})
        }
      });

    }

}
