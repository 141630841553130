<h2 mat-dialog-title>{{'Choose elements to copy' | translate}}</h2>

<!--content-->
<mat-dialog-content class="mat-typography">

  <div class="block">{{'Category'| translate}}: {{ object.name }}</div>
  <!-- subcategories -->

  <ng-container *ngIf="object?.subcategories?.length > 0">
    <ul *ngFor="let subcategory of object.subcategories; let i = index">
      <li>
        <!-- block -->
        <div class="block">
          {{'Subcategory'| translate}} : {{subcategory.name}}
          <button class="btn btn-xs btn-danger" (click)="deleteElement('subcategories',subcategory, i)"><i class="fa fa-trash-o"
              aria-hidden="true"></i>
          </button>
        </div>
        <!-- block -->


        <!-- items -->
        <ul *ngFor="let item of subcategory.items; let j = index">
          <li>
            <!-- block -->
            <div class="block">
              {{'Item'| translate}} : {{item.name}}
              <button class="btn btn-xs btn-danger" (click)="deleteSubElement('items', i, j)"><i class="fa fa-trash-o"
                  aria-hidden="true"></i>
              </button>
            </div>
            <!-- block -->


          </li>
        </ul>
        <!-- items -->
      </li>
    </ul>
  </ng-container>
  <!-- subcategories -->

  <!-- items -->
  <ng-container *ngIf="object?.items?.length > 0">
    <ul *ngFor="let item of object.items; let i = index">
      <li>{{'Item'| translate}} : {{item.name}}
        <button class="btn" (click)="deleteElement('items', item, i)"><i class="fa fa-trash-o" aria-hidden="true"></i>
        </button>
      </li>
    </ul>
  </ng-container>



  <!-- items -->

</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button class="btn btn-danger" [mat-dialog-close]="">{{'Cancel' | translate}}</button>
  <button class="btn btn-success" [mat-dialog-close]="object">{{'Confirm' | translate}}</button>

</div>