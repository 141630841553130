import { getNestedObject, setNestedKey } from '../../module-worker/_nestedProp';

/**
 * 
 * @param model 
 * @param name 
 * @param value 
 * @param field 
 */
function createDefaultResponse(model, name, value, field, hotel) {
    if (field.translatable) {
        for (let lang of hotel.languages) {
            let code = lang.lang_code;
            model[name + '_' + code] = model[name + '_' + code] ? model[name + '_' + code] : value
        }
    } else {
        model[name] = model[name] ? model[name] : value
    }
    return model;
}


/**
 * FUNCTION TO BUILD RESPONSE FORMAT FROM JSON (FORM BUILDER JSON)
 * @param section 
 * @param response 
 * @param hotel 
 * @param languages 
 */
export function buildResponseType(section, response, hotel) {
    let nestedResponse = response
    if(section.is_array && section.path != undefined){
        nestedResponse = setArraySection(response, section.path.split('.'));
        console.log(nestedResponse, "----nested resonse")
        for (let field of section.fields) {
            buildJsonValue(field, nestedResponse, hotel)
        }
    }else {
        for (let field of section.fields) {
            //IF NOT NESTED FIELD
            if (field.name.indexOf('.') == -1) {
                buildJsonValue(field, response, hotel)
            }else {
                //IF NESTED FIELD
                let json =  buildJson(response,field.name);
            }
        }
    } 
}


/**
 * FUNCTION BUILD FIELD DEFAULT VALUE / DETECT TRANSLATABLE FIELD AND CREATE SUB FIELDS
 * @param field 
 * @param response 
 * @param hotel 
 * @param languages 
 */
function buildJsonValue(field, response, hotel){
        if(!response){
            response = {};
        }

        //IF IS IMAGE OR SELECT OR CHECKBOX INIT RESPONSE AS EMPTY ARRAY
        if ((((field.type == "select" && field.multiple)
            || (field.type == "image" && field.multiple))
            || field.type == "checkbox")
            && typeof (response[field.name]) == "undefined") {
            //if response if empty
            createDefaultResponse(response, field.name, [], field, hotel)
        }
        //IF CHECKBOX AND NOT EMPTY CREATE CONVERT VARIABLE
        if (field.type == "checkbox" && typeof (response[field.name]) != "undefined") {
            if(Object.keys(response[field.name]).length == 0){
                response[field.name] = {};
                //check default selected
                for (let v of field.values) {
                    response[field.name][v.value] = v.selected
                }
            }

            for (let v of field.values) {
                response[field.name][v.value] = response[field.name][v.value]? true : false
            }

        }
        //IF EMPTY VALUE GET DEFAULT VALUE 
        else if (typeof (response[field.name]) == "undefined") {
            let defaultVal = field.default ? field.default.replace("{{hotel}}", hotel.id) : "";
            createDefaultResponse(response, field.name,defaultVal , field, hotel)

        }
}

/**
 * 
 * @param response 
 * @param path 
 */
export function buildJson(response,path ){
    let arr = path.split('.');
    let attr = arr[0];
    if(response[attr] == undefined){
        response[attr] = {};
    }
    arr.splice(0,1);
    if(arr.length>1){
        buildJson(response[attr] , arr.join('.'))
    }

    return {response, last_attr:arr[0]}
}

/**
 * 
 * @param response 
 * @param path 
 */
export function setArraySection(response:any, path:string[]){
    //if path has sub paths "a.b.c" recall function
    if(response[path[0]] == undefined){
        response[path[0]] = {};
    }
    if(path.length>1){
        return setArraySection(response[path[0]], path.slice(1))
    }
    //if path is finished
    else  if(path.length == 1){
        response[path[0]] = [{}]
        return response[path[0]][0]
    }
}