import { NgxSpinnerService } from 'ngx-spinner';
import { WorkflowService } from 'src/app/shared/services/workflow.service';

import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-template-editor',
  templateUrl: './dialog-template-editor.component.html',
  styleUrls: ['./dialog-template-editor.component.scss'],
})
export class DialogTemplateEditorComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogTemplateEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private workflowService: WorkflowService,
    private spinner: NgxSpinnerService,

  ) { }

  templates = [];
  step ;

  @ViewChild('editor') editor;

  ngOnInit() {
    // console.log(this.data)
    this.spinner.show('global');
    if(this.data.template.template_id != -1){
      for(let temp in this.data.template.templates){
        this.workflowService.getTemplateContent(this.data.template.templates[temp]).subscribe(res => {
          this.templates.push(res);
          this.spinner.hide('global');
        },err=>this.spinner.hide('global'));
      }
    }else {
      this.workflowService.getWorkflowStep(this.data.template.component_id).subscribe(res => {
        this.step = res;
        this.spinner.hide('global');
      },err=>this.spinner.hide('global'));
    }

  }

  /**
   *
   */
  saveTemplate(){
    this.spinner.show('global');
    if(this.templates.length){
      for(let temp of this.templates){
        this.workflowService.updateTemplateContent(temp).subscribe(res=>{
          if(res){
            this.dialogRef.close(true);
          }
        })
      }
    }else {
      this.workflowService.updateWorkflowStep(this.step).subscribe(res=>{
        if(res){
          this.spinner.show('global');
          this.dialogRef.close(true);
        }
      })
    }


  }

  getName(name){
    return name.split('_').join(' ')
  }



}
