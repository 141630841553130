<h2 class="mat-dialog-title with-trans">{{ 'More informations' | translate }}
  <app-select-translate [hotel]="hotel" [languages]="languages"></app-select-translate>

</h2>
<mat-dialog-content class="mat-typography">
  <div class="row">
   
     
    <div class="col-lg-12 form-group">
      <!--translated input-->
      <translated-input [model]="information" [attr]="'value'" [hotel]="hotel" [type]="'input'" [label]="'Description'" [languages]="languages">
      </translated-input>
      <!--translated input-->
      <app-error-input *ngIf="errors" [errors]="errors" name="value"></app-error-input>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <form-select-icons [attr]="{name:'icon'}" [model]="information" [hotel]="hotel" [svg]="false"></form-select-icons>
        <app-error-input *ngIf="errors" [errors]="errors" name="icon"></app-error-input>
      </div>
    </div>



  </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'Cancel' | translate }}</button>
  <button mat-button (click)="createOrUpdate(information)"  class="btn btn-success">
  {{(information.id ? 'Edit' : 'Create') | translate}}  {{ 'Information' | translate }}</button>
</mat-dialog-actions>