<div class="container-fluid">
  <div class="title">
    <img src="assets/img/logo-tabhotel-black.svg" class="logo">
    <h1>{{'How can we help you?' | translate }}</h1>
    <div class="form-group inputcontainer">
        <div class="icon-container" [ngClass]="{'hidden' : !searchLoading}">
          <i class="loader"></i>
        </div>
      <input class="form-control" type="seach" placeholder="{{'Describe your issue' | translate}}" (input)="search($event.target.value)">
      <button class="promoted-search__search-button" aria-hidden="true"><svg class="promoted-search__search-icon"
          viewBox="0 0 24 24">
          <path
            d="M20.49 19l-5.73-5.73C15.53 12.2 16 10.91 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.41 0 2.7-.47 3.77-1.24L19 20.49 20.49 19zM5 9.5C5 7.01 7.01 5 9.5 5S14 7.01 14 9.5 11.99 14 9.5 14 5 11.99 5 9.5z">
          </path>
          <path d="M0 0h24v24H0V0z" fill="none"></path>
        </svg></button>
      <!-- <ul *ngIf="filtered && searchValue" class="searched">
        <li *ngFor="let faq of filtered">
          <img src="{{faq.image}}" alt="">
          <h3 [innerHTML]="findTextSearched(faq.title) | titlecase"></h3>
        </li>
      </ul> -->
    </div>
  </div>

  <div align="right" *appHasRole="'super-user'">
    <button class="btn btn-success" (click)="openDialogCategory()" *ngIf="permissionCategory()">
      <i class="fa fa-plus" aria-hidden="true"></i> {{'Add Category' | translate}}
    </button>
    <button class="btn btn-success" (click)="openDialogQuestion()" *ngIf="permissionQuestion()"> 
      <i class="fa fa-plus" aria-hidden="true"></i> {{'Add Question'  | translate}}
    </button>
  </div>
  
  <ng-container *ngIf="faqCloned">
    <div *ngIf="currentCat">
      <app-fixed-title [title]='currentCat.title' [hotel]='hotel' [icon]="getUrl(currentCat.image)"></app-fixed-title>

    </div>
    <div class="row justify-content-center">
      <div class="col-12 flex col-md-3" *ngFor="let faq of getListOfCategory(); let i = index">
        <a class="white-box content faq-content" [routerLink]="['/faq/group', slug, faq.id]">
          <img src="{{getUrl(faq.image)}}" alt="">
          <h3 [innerHTML]="findTextSearched(faq.title)"></h3>
          <p [innerHTML]="findTextSearched(faq.description)"></p>
        </a>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
            <mat-icon>more_vert</mat-icon>
          </button>
      
      
          <!-- actions -->
          <mat-menu #menu="matMenu" yPosition="above" xPosition="before">
      
      
            <button mat-menu-item (click)="openDialogCategory(faq)">
              <mat-icon>edit</mat-icon>
              <span>{{'Edit item'| translate}}</span>
            </button>
            <button mat-menu-item (click)="deleteCategoryConfirm(faq, i)">
              <mat-icon>delete_forever</mat-icon>
              <span>{{'Delete Category'| translate}}</span>
            </button>
          </mat-menu>
          <!-- actions -->
      </div>
    </div>


    <!-- questions -->
    <ng-container  *ngIf="parent && faqCloned[0].subcategory.length == 0">
      <mat-expansion-panel *ngFor="let question of faqCloned[0].itemsfaq; let i = index">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{question.title}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-md-6" *ngIf="question.image">
            <div class="img">
              <img src="{{question.image}}" alt="" (click)="openImage(question.image,  question.title)">
            </div>
          </div>
          <div class="col-md-{{question.image ? 6 : 12}}">
            <div class="text" [innerHTML]="question.description"></div>
          </div>
        </div>
        <div class="right-btn" *appHasRole="'super-user'">
            <button class="btn btn-danger" (click)="deleteQuestionConfirm(question, i)" *ngIf="true"> 
                <i class="fa fa-trash" aria-hidden="true"></i> {{'Delete Question'| translate}}
              </button>
            <button class="btn btn-success" (click)="openDialogQuestion(question)" *ngIf="true"> 
              <i class="fa fa-plus" aria-hidden="true"></i> {{'Edit Question'| translate}}
            </button>
        </div>
      
      </mat-expansion-panel>
    </ng-container>
    <!-- questions -->


  </ng-container>

</div>