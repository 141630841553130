import { NotifierService } from 'angular-notifier';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { DialogInvitationUserComponent } from 'src/app/pages/users/dialog-invitation-user/dialog-invitation-user.component';
import { UserService } from 'src/app/shared/services/user.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogUserComponent } from '../dialog-user/dialog-user.component';

@Component({
  selector: 'app-table-users',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent  {

  constructor(
    public dialog: MatDialog,
    private us : UserService,
    notifierService: NotifierService,
  ) {
    this.notifier = notifierService;

   }

  @Input() group_id : any;
  // @Input() admins:any;
  @Input() users:any;
  @Input() type:any;
  @Input() hotel:any;
  @Input() id = localStorage.getItem('id');
  @Input() hotelsToManage : any;
  @Input() user;
  @Input() groups;
  @Output() addEvent = new EventEmitter();
  role = localStorage.getItem('user_role');
  tabhotelMember = JSON.parse(localStorage.getItem('user_obj')).is_tabhotel_member;

  private readonly notifier: NotifierService;



  /**
   * OPEN DIALOG ADD OR EDIT USER
   * @param type
   * @param user
   */
  openDialogUser(type , user = null,) {
    let data = {
      hotel: this.hotel,
      hotelsToManage:this.hotelsToManage,
      type: type,
      id: user ? user.id  : null,
      email: user ? user.email : null,
      first_name: user ? user.first_name : null,
      last_name: user ? user.last_name : null,
      send_notification_email: user ? user.send_notification_email : null,
      compte_test: user ? user.compte_test : null,
      is_tabhotel_member: user ? user.is_tabhotel_member : null,
      language: user ? user.language : null,
      user: user ? user.user : null,
      hotels: user ? user.hotels: null,
      groups: user ? user.groups: null,
      roles: user ? user.roles: null
    }
    const dialogRefInfo = this.dialog.open(DialogUserComponent, { data: data, width:'900px' });

  }


  /**
   *
   */
  inviteUser(){
    let data = {
      group_id : this.group_id,
      hotel: this.hotel,
      type:this.type,
      users:this.users,
      hotelsToManage:this.hotelsToManage,
      groups:this.groups,
    };

    const dialogRefInfo = this.dialog.open(DialogInvitationUserComponent, { data, width:'700px' });
    dialogRefInfo.afterClosed().subscribe(res=>{
      if(res){
        this.notifier.notify('success', "The invitation has been sent.");
      }
    })
  }



  inTypeOfList(user){
    return  this.hasRole(user) && this.canViewTabhotelMember(user)
  }

  /**
   *
   */
  hasRole(user){
    return (user.role.indexOf(this.type) != -1);
  }

  /**
   *
   * @param user
   */
  canViewTabhotelMember(user){
    return (user.is_tabhotel_member && this.tabhotelMember) || !user.is_tabhotel_member
  }

  deleteUser(user, index){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name : user.first_name + ' ' + user.last_name , type : "User" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.us.deleteUser(user.id).subscribe(res=>{
          this.users.splice(index, 1)
        })
      }
    });

  }

}
