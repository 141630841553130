import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { MENU_ITEMS } from 'src/app/main-template/menu/menu';
import { DefaultValueService } from 'src/app/shared/services/default-value.service';
import { HotelService } from 'src/app/shared/services/hotel.service';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrls: ['./manage-roles.component.scss']
})
export class ManageRolesComponent implements OnInit {

  constructor(
    private hs : HotelService,
    private router : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private defaultConfig : DefaultValueService,
    public dialog: MatDialog,
    notifierService: NotifierService,

  ) {
    this.notifier = notifierService;

  }


  private readonly notifier: NotifierService;
  @Input() slug :any;
  @Input() hotel :any;
  @Input() languages :any;
  @Input() onglet=false;
  title = "Role manager";
  modules : any;
  menuItems = MENU_ITEMS;
  defaultMenu : any;
  activeMenus = MENU_ITEMS;
  openSelect = {}
  newItem : any;
  tempValues : any;


  ngOnInit() {

    this.router.params.subscribe(params => {

      this.slug = params.slug;

      this.hs.getHotel(this.slug).subscribe((data) => {
          this.hotel = data;
      });
      this.defaultConfig.getDefaultValue(this.slug, 'main-menu').subscribe(res=>{
        this.activeMenus = JSON.parse(res['values']);
        this.tempValues =  JSON.parse(res['values']);
        this.defaultMenu = res;
        this.spinner.hide('global');
      }, ()=>{
        this.defaultConfig.getDefaultValue('29', 'main-menu').subscribe(res=>{
          this.activeMenus = JSON.parse(res['values']);
        })
        this.spinner.hide('global');
      });


    });


  }


  /**
   *
   * @param val
   */
  searchMenu(val){
    if(!val){
      this.activeMenus = this.tempValues;
    }else {
      this.activeMenus = this.findMenu(this.tempValues, val)
    }
  }

  /**
   *
   * @param menu
   */
  edit(menu){
    menu.edit = menu.edit == undefined ? true : !menu.edit
  }


/**
 *
 * @param menus
 * @param val
 */
  findMenu(menus, val, old = false){
    return menus.filter((x)=>{
      let result = old;
      for(let lang of this.hotel.languages){
        let attr = 'name_' + lang.lang_code;
        if(x[attr]){
          result = result || x[attr].toLowerCase().indexOf(val.toLowerCase()) > -1
        }
      }
      if(typeof x.submenu != "undefined"){
         return result = result || this.findMenu(x.submenu, val, result).length
      }
      console.log(result, x.name, val)

      return result

    })
  }





  /**
   *
   * @param menu
   */
  updateMenu(menu = null){
    if(menu){
      menu.edit = false
    }else {
      this.activeMenus.map(x=>{
        x['edit'] =false;
        if(x.submenu)
          x.submenu.map(y=>y['edit'] = false)
      })
    }


    let mainMenu = {hotel : this.slug, name : 'main-menu', values : JSON.stringify(this.activeMenus)}
    if(typeof(this.defaultMenu) != "undefined"){
      this.defaultConfig.updateDefaultValue({...mainMenu, id : this.defaultMenu.id}).subscribe(()=>{
          this.notifier.notify('success', 'Roles updated with success');
      }, ()=>{
        this.notifier.notify('error', 'Roles not updated');
      });
    }else {
      this.defaultConfig.createDefaultValue(mainMenu).subscribe(()=>{
        this.notifier.notify('success', 'Roles updated with success');
      }, ()=>{
        this.notifier.notify('error', 'Roles not updated');
      });
    }

  }



  /**
   *
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.updateMenu();
  }




}
