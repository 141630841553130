<!-- Page Content -->
<div class="container-fluid">
  <app-fixed-title [title]='title' [hotel]='hotel'></app-fixed-title>
  <div class="row">

    <div class="col-md-12">
      <div class="white-box content">
        <mat-tab-group>
          <mat-tab label="{{device.name}}" *ngFor="let device of devices">
          
                
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>