<h2 class="mat-dialog-title with-trans">
  {{(slider.id ? 'Edit slider Item' : 'Add slider Item') |translate}}
  <app-select-translate [hotel]="hotel" [languages]="languages"></app-select-translate>

</h2>
<mat-dialog-content class="mat-typography">

  <div class="edit-slider row">
    
    <div class="col-lg-6 form-group">

      <!--translated input-->
      <translated-input [model]="slider" [attr]="'first_description'" [hotel]="hotel" [type]="'textarea'"
        [label]="'First description'" [rows]="2" [languages]="languages" className="">
      </translated-input>
      <!--translated input-->
      <app-error-input *ngIf="errors" [errors]="errors" name="first_description"></app-error-input>
    </div>
    <div class="col-lg-6 form-group">
      <!--translated input-->
      <translated-input [model]="slider" [attr]="'second_description'" [hotel]="hotel" [type]="'textarea'"
        [label]="'Second description'" [rows]="2" [languages]="languages" className="">
      </translated-input>
      <!--translated input-->
      <app-error-input *ngIf="errors" [errors]="errors" name="second_description"></app-error-input>
    </div>

    <div class="col-lg-6 form-group">
      <!--translated input-->
      <translated-input [model]="slider" [attr]="'third_description'" [hotel]="hotel" [type]="'textarea'"
        [label]="'Third description'" [rows]="2" [languages]="languages" className="">
      </translated-input>
      <!--translated input-->
      <app-error-input *ngIf="errors" [errors]="errors" name="third_description"></app-error-input>
    </div>

    <div class="col-lg-3 form-group">
      <!--translated input-->
      <translated-input [model]="slider" [attr]="'button_content'" [hotel]="hotel" [type]="'input'"
        [label]="'Button Content'" [languages]="languages" className="">
      </translated-input>
      <!--translated input-->
      <app-error-input *ngIf="errors" [errors]="errors" name="button_content"></app-error-input>
    </div>

    <div class="col-lg-3 form-group">
      <!--translated input-->
      <translated-input [model]="slider" [attr]="'button_url'" [hotel]="hotel" [type]="'input'" [label]="'Link'"
        [input]="'url'" [languages]="languages" className="">
      </translated-input>
      <!--translated input-->
      <app-error-input *ngIf="errors" [errors]="errors" name="button_url"></app-error-input>
    </div>

    <div class="col-lg-6 file form-group">
      <form-select-icons label="First slide Image" [attr]="{name : 'first_image'}" [model]="slider" [hotel]="hotel" size='big' [tab_icon]="false"></form-select-icons>
      <app-error-input *ngIf="errors" [errors]="errors" name="first_image"></app-error-input>
    </div>

    <div class="col-lg-6 file form-group">
      <form-select-icons label="Second slide Image" [attr]="{name : 'second_image'}" [model]="slider" [hotel]="hotel" size='big' [tab_icon]="false"></form-select-icons>
      <app-error-input *ngIf="errors" [errors]="errors" name="second_image"></app-error-input>
    </div>



  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'Cancel' | translate}}</button>
  <button mat-button (click)="createOrUpdate(slider)"  class="btn btn-success">
    {{(slider.id ? 'Edit Slider' : 'Add Slider') | translate}}
  </button>
</mat-dialog-actions>