import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: HttpClient) { }

  uri = URI+"groups-th/";


	list(params){
		return this.http.get(this.uri+params);
	}

	/**
	 *
	 * @param id
	 */
  	get(id){
		return this.http.get(this.uri+id);
	}

	/**
	 *
	 * @param object
	 */
	update(object){
		return this.http.put(this.uri+object.id+'/', object);
	}

	create(object){
		return this.http.post(this.uri, object);
	}

	delete(id){
		return this.http.delete(this.uri+id);
	}
}
