import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from 'src/app/shared/services/excel.service';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { ConfirmDeleteComponent } from '../../components/modals/confirm-delete/confirm-delete.component';
import { HotelService } from '../../shared/services/hotel.service';
import { UpsellService } from '../../shared/services/upsell.service';

@Component({
  selector: 'app-upsell-list',
  templateUrl: './upsell-list.component.html',
  styleUrls: ['./upsell-list.component.scss']
})
export class UpsellListComponent implements OnInit {

  constructor(
    private hs: HotelService,
    private route: ActivatedRoute,
    private ups : UpsellService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private excel : ExcelService

  ) { }

  title = "OFFERS";
  slug   : any;
  hotel  : any;
  offers : any;
  @Input() onglet = false;


  ngOnInit() {
    this.spinner.show('global');
    this.route.params.subscribe(params => {
      this.slug = params.slug
      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
        this.ups.getOffers(this.slug).subscribe(res=>{
          this.offers = res;
          this.spinner.hide('global');
        })
      });
    });




  }


  exportexcel(): void {
    this.excel.exportExcel('offers', this.offers);
  }
  deleteOffer(offer, index){

    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name : offer.name, type : "Upsell" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.ups.deleteOffer(offer.id).subscribe(res=>{
          this.offers.splice(index, 1);
        })
      }
    });
  }

}
