import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onglet-contents',
  templateUrl: './onglet-contents.component.html',
  styleUrls: ['./onglet-contents.component.scss']
})
export class OngletContentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
