<div class="white-box content">
    <span (click)="openDialogHotel()" class="open-modal">
        <i class="fa fa-pencil-square-o"></i>
    </span>
    <h2 class="title">{{'Informations and media' | translate }}</h2>
    <div class="box-scroll">
        <mat-tab-group mat-stretch-tabs>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{'Informations' | translate}}
                </ng-template>
                <table class="info">
                    <tbody>
                        <tr *ngIf="hotel?.stars">
                            <td style="width: 40%"><i class="fa grey fa-building-o"></i> {{'Stars' | translate }}</td>
                            <td>
                                <i class="fa fa-star" *ngFor='let i of counter(hotel?.stars)'></i>
                            </td>
                        </tr>
                        <tr>
                            <td><i class="fa grey fa-phone"></i> {{'Phone' | translate }}</td>
                            <td>{{hotel?.Telephone}}</td>
                        </tr>
                        <tr>
                            <td><i class="fa grey fa-fax"></i> {{'Fax' | translate }}</td>
                            <td>{{hotel?.fax}}</td>
                        </tr>
                        <tr>
                            <td><i class="fa grey fa-envelope"></i> {{'Email' | translate }}</td>
                            <td>{{hotel?.email}}</td>
                        </tr>
                        <tr>
                            <td><i class="fa grey fa-map-pin"></i> {{'Address' | translate }}</td>
                            <td>{{hotel?.adresse}}</td>
                        </tr>
                        <tr>
                            <td><i class="fa grey fa-clock-o" ></i>
                                {{'Check-in' | translate }}</td>
                            <td>{{hotel?.check_in}}</td>
                        </tr>
                         <tr>
                            <td><i class="fa grey fa-clock-o" ></i>
                                {{'Check-out' | translate }}</td>
                            <td>{{hotel?.check_out}}</td>
                        </tr>


                    </tbody>
                </table>
            </mat-tab>
            
            <mat-tab>
                <ng-template mat-tab-label>
                    {{'Logos' | translate}}
                </ng-template>
                <table class="info">
                    <tbody>

                        <tr>
                            <td><i class="fa fa-picture-o" ></i> {{'Main images' | translate }}</td>
                            <td><img src="{{MEDIA+hotel?.main_image}}" alt=""></td>
                        </tr>
                        <tr>
                            <td><i class="fa fa-picture-o" ></i> {{'Logo' | translate }}</td>
                            <td><img src="{{MEDIA+hotel?.logo}}" alt=""></td>
                        </tr>

                    </tbody>
                </table>
            </mat-tab>
        </mat-tab-group>
    </div>



</div>