<app-build-categories
[categoryId]="0"
[title]="title"
[category_type]="category_type"
[id]="id"
[filteredCategories]="filteredCategories"
[label]="label"
[superFields]="superFields"
[presentation]="presentation"

></app-build-categories>