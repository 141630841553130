import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { _commun } from './configs/_commun';

@Component({
  selector: 'field-constructor',
  templateUrl: './field-constructor.component.html',
  styleUrls: ['./field-constructor.component.scss']
})
export class FieldConstructorComponent implements OnInit {

  constructor() { }

  @Output() changeFieldEvent = new EventEmitter();

  @Input() field;
  @Input() languages;
  @Input() hotel;
  @Input() form;
  @Input() customVars;
  @Input() currentSection;
  @Input() variablesAPI;

  @Input() options = {
    checkbox: true,
    displayheader: true,
    placeholder: [
      'Label',
      'Value'
    ],
    header:   [
      'Selected',
      'Label',
      'Value',
      'Conditions',
      'Actions'
    ],
    add: 'Add option',
    class: '',
    selected: false,
    other: false,
    otherText : 'Form data',
    attr : 'formdata'
  }
  listVars = [];


  ngOnInit() {
    if (this.customVars) {
      for (let i in this.customVars.data) {
        this.listVars.push({ value: i, text: i.replace('_', ' ').replace('_', ' ') })
      }
    }
  }

  /**
   *
   * @param event
   * @param field
   */
  changeEvent(event,field, fieldname = 'field'){
    this.changeFieldEvent.emit({val :event.target.value, field, fieldname});
  }

}
