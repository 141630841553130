import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http: HttpClient) { }
  uri = URI;

  /**
   * GET LIST DEVICES BY CUSTOMER ID
   * @param id
   */
  getFaqList() {
    return this.http.get(`${this.uri}` + "category-faq/");
  }

  /**
   *
   * @param id
   */
  getFaqDetailGroup(id) {
    return this.http.get(`${this.uri}` + "category-faq/" + id);
  }


  /**
   *
   * @param cat
   * @param id
   */
  editFaqCategory(cat, id) {
    return this.http.put(`${this.uri}` + "category-faq/" + id + "/", cat);
  }

  /**
   *
   * @param id
   */
  deleteFaqCategory(id) {
    return this.http.delete(`${this.uri}` + "category-faq/" + id + "/");
  }


  /**
   *
   * @param id
   */
  getQuestionDetail(id) {
    return this.http.get(`${this.uri}` + "item-faq/" + id);
  }

  /**
   *
   * @param cat
   */
  addFaqCategory(cat) {
    return this.http.post(`${this.uri}` + "category-faq/", cat);
  }

  /**
   *
   * @param quest
   */
  addFaqItem(quest) {
    return this.http.post(`${this.uri}` + "item-faq/", quest);
  }
  /**
   *
   * @param quest
   * @param id
   */
  editFaqItem(quest, id) {
    return this.http.put(`${this.uri}` + "item-faq/" + id + "/", quest);
  }

  /**
   *
   * @param id
   */
  deleteFaqItem(id) {
    return this.http.delete(`${this.uri}` + "item-faq/" + id + "/");
  }

  /**
   *
   * @param word
   */
  searchFaq(word){
    return this.http.post(`${this.uri}` + "faq-search", word);
  }

}
