import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class HardwareService {


  constructor(private http: HttpClient) {}
  uri = URI;

  /**
   * GET LIST hARDWARES BY CUSTOMER ID
   * @param id
   */
  getHardwares(id) {
		return this.http.get(this.uri+"hardware/"+id);
  }

  updateHardware(hard){
    return this.http.patch(this.uri+"hardwares/"+hard.id, hard);
  }
  /**
   * REBOOT HARDWARE
   * @param id
   */
  rebootHardware(id){
    return this.http.post(this.uri+"hardware-reboot",{id});
  }

}
