
<mat-dialog-content class="mat-typography">
    <app-media-manager
    [hotel]="hotel"
    [SelectMultiple]="multiple"
    [DIALOG]="true"
    [maintainRatio]="maintainRatio"
    [cropperWidth]="cropperWidth"
    [cropperHeight]="cropperHeight"
    (selectimage)="onSelectImage($event)">
    </app-media-manager>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="btn btn-warning">{{'Cancel'| translate }}</button>
    <button mat-button [mat-dialog-close]="selectedImages"  class="btn btn-success">{{'Select Image(s)'| translate }}</button>
  </mat-dialog-actions>
