import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-html-preview',
  templateUrl: './dialog-html-preview.component.html',
  styleUrls: ['./dialog-html-preview.component.scss']
})
export class DialogHtmlPreviewComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogHtmlPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog)
  {}



  @ViewChild('iframe', {static: false}) iframe: ElementRef

  name = 'Angular';

  ngOnInit() {
    setTimeout(()=>{
      this.setIframeReady(this.iframe.nativeElement);
    },10)
  }


  private setIframeReady(iframe): void {
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(this.data);
    iframe.contentWindow.document.close();
  }

}
