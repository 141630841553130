<h1 class="mat-dialog-title">{{'Capture' | translate}}</h1>
<div mat-dialog-content>

	<div class="alert alert-info">
		You can change the amount to be captured before confirming, the maximum amount you can captured is {{data.trans.amount/100|currency:data.currencyCode:"symbol"}}
	</div>

	<input-validator [errors]="errors" *ngIf="errors"  ></input-validator>


	<div class="form-group">
		<input type="number" [(ngModel)]="amount" class="form-control">  
		<small>The amount curreny is {{data.currencyCode}}</small>

	</div>
</div>
<div mat-dialog-action  align="end">
  <button class="btn btn-default" [mat-dialog-close]="" >{{'Cancel' | translate}}</button>
  <button class="btn btn-success" (click)="confirmDebit()" >{{'Capture' | translate}}</button>
</div>