<div class="row">
  <div class="left-side col-md-4">
    <button class="btn move" cdkDragHandle><span class="material-icons">drag_indicator</span></button>
    <button class="btn edit"><span class="material-icons">create</span></button>
    
    {{menu.name}}
  </div>
  <div class="right-side col-md-4 ml-auto text-right">
    <label>Display for : </label> 
    <select class="form-control" [(ngModel)]="menu.role">
      <option value="concierge">{{'Concierge' | translate}}</option>
      <option value="administrator">{{'Admin' | translate}}</option>
      <option value="super-user" *appHasRole="'super-user'">{{'Super admin' | translate}}</option>
    </select>
  </div>
</div>