

<mat-tab-group *ngIf="category?.subcategories?.length && !see_details"
 animationDuration="0ms"
[selectedIndex]="activeIndex" >


  <mat-tab *ngFor="let subcategory of category.subcategories; let index = index" [label]="subcategory.name" class="{disabled: !subcategory.visible }">
      <!--list items-->
      <app-item
      [subcategory]="subcategory"
      [type]="'sub'"
      [hotel]="hotel"
      [category]="category"
      [category_type]="category_type"
      [categoryParent]="false"
      [superFields]="superFields"
      [btnNewItem]="btnNewItem"
      [btnDelete]="true"
      [languages]="languages"
      [hideprice]="hideprice"
      ></app-item>
      <!--list items-->
    
  </mat-tab>
  <mat-tab l *ngIf="category.subcategories.length">
      <ng-template mat-tab-label>
          <span (click)="tabClick()">{{'Add Subcategory' | translate}}</span>
      </ng-template>
  </mat-tab>
</mat-tab-group>