<div class="alert alert-info">
  You can use &#123; &#123;hotel&#125; &#125; as variables in your url
</div>
<div class="row">


  <!-- field -->
  <div class="form-group col-md-6">
    <label>Endpoint</label>
    <input type="text" class="form-control" [(ngModel)]="module.endpoint">
  </div>
  <!-- field -->

  <!-- field -->
  <div class="form-group col-md-6">
    <label>API create</label>
    <input type="text" class="form-control" [(ngModel)]="module.configs.api_create">
  </div>
  <!-- field -->

  <!-- field -->
  <div class="form-group col-md-6">
    <label>API get</label>
    <input type="text" class="form-control" [(ngModel)]="module.configs.api_get">
  </div>
  <!-- field -->

  <!-- field -->
  <div class="form-group col-md-6">
    <label>API update</label>
    <input type="text" class="form-control" [(ngModel)]="module.configs.api_update">
  </div>
  <!-- field -->

  <!-- field -->
  <div class="form-group col-md-6">
    <label>API list</label>
    <input type="text" class="form-control" [(ngModel)]="module.configs.api_list">
  </div>
  <!-- field -->

  <!-- field -->
  <div class="form-group col-md-6">
    <label>API delete</label>
    <input type="text" class="form-control" [(ngModel)]="module.configs.api_delete">
  </div>
  <!-- field -->


    <!-- field -->
    <div class="form-group col-md-6">
      <label>Form API</label>
      <input type="text" class="form-control" [(ngModel)]="module.configs.api_form">
    </div>
    <!-- field -->
</div>