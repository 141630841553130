<h2 class="title">{{ 'List of' | translate }} {{ 'groups' | translate }} </h2>


<table class="table" *ngIf="groups?.length">
  <tbody>
    <tr *ngFor="let group of groups; let i = index">
      <td style="width: 30%;">
        <input type="text" [(ngModel)]="group.name" class="form-control" (blur)="createOrUpdate(group, i)">
      </td>
      <td style="width: 70%;">
        <mat-select [(ngModel)]="group.users" name="group.users" multiple (selectionChange)="createOrUpdate(group, i)">
          <mat-option *ngFor="let user of users" [value]="user.id">
            {{user.first_name}} {{user.last_name}}
          </mat-option>
        </mat-select>
      </td>
      <td>
        <button class="btn btn-danger" (click)="deleteField(group, i)">
          <i aria-hidden="true" class="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<div class="alert alert-info" *ngIf="!groups?.length">
  {{ "There's no groups yet, be the first to create a new group" | translate }}
</div>
