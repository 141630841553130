<!-- Page Content -->
<div class="container-fluid">
  <app-fixed-title [customtitle]='title' [hotel]='hotel' [links]="_breadCrumbs"></app-fixed-title>
 
      <div class="white-box content">
		<app-form-tabs [form]="form" [hotel]="hotel" [response]="response" *ngIf="form && hotel && languages"></app-form-tabs>

	
		<div class="text-right">
		      <a class="btn btn-warning" [routerLink]="['/device/form', slug, device.id]" *ngIf="device">
		      	<i class="fa fa-bookmark" aria-hidden="true"></i> {{'Edit form' | translate}}
		      </a>
		      <button (click)="saveValue()" class="btn btn-success">
		      	<i class="fa fa-floppy-o" aria-hidden="true"></i>
				 {{'Save' | translate}}
			</button>
		 </div>
  </div>
</div>