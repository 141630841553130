import { Component, OnInit, Input } from '@angular/core';
import { MEDIA } from 'src/app/shared/settings/global';

@Component({
  selector: 'build-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  constructor() { }

  @Input() src : any;
  @Input() className : any;
  @Input() id : any;
  
  MEDIA = MEDIA;


  ngOnInit() {
  }

  buildUrl(){
    if(this.src.search('http') === -1){
      return this.MEDIA + this.src;
    }else {
      return this.src;
    }
  }
  

}
