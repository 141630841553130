import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { ModuleManagerService } from 'src/app/shared/services/module-manager.service';
import { ModuleWorkerService } from 'src/app/shared/services/module-worker.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { getEndPoint } from '../_nestedProp';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-module-worker-detail',
  templateUrl: './module-worker-detail.component.html',
  styleUrls: ['./module-worker-detail.component.scss']
})
export class ModuleWorkerDetailComponent implements OnInit {

  constructor(
    private hs: HotelService,
    notifierService: NotifierService,
    private moduleWorkerService: ModuleWorkerService,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private moduleService: ModuleManagerService,
    private apiService : ApiService,
    private _router: Router,
    public dialog: MatDialog) {

  }



  //GLOBAL VARS
  slug: any;
  module_id: any;
  module: any;
  hotel: any;
  data: any;
  title: any;
  item_id: any;
  item: any;
  errors: any;
  jsonTextfields = [];

  ngOnInit() {
    this.spinner.show('global');

    this.router.params.subscribe(params => {

      this.slug = params.slug;
      this.item_id = params.item_id;
      this.module_id = params.module_id;

      this.hs.getHotel(this.slug).subscribe((res) => {
        this.hotel = res;
      });


      this.moduleService.get(this.module_id).subscribe((res: any) => {
        this.module = res;
        if(this.module.configs.api_form != undefined && this.module.configs.api_form != "" ){
          let url = this.module.configs.api_form.replace('{{hotel}}', this.slug).replace('{{id}}', this.item_id);
          this.apiService.getByUrl(url).subscribe(res=>{
            this.module.form = res;
          })
        }
        this.title = this.module.name;
        if (this.item_id != "new") {

          this.getDataFromEndPoint();
        }else {
          this.spinner.hide('global');
          this.item = {};
          this.prepareDataOnGet();
        }

      })

    });
  }



  /**
 *
 * @param module
 */
  getDataFromEndPoint() {
    let endpoint = getEndPoint('get',this.slug, this.module).replace('{{id}}', this.item_id);
    this.moduleWorkerService.get(endpoint).subscribe(res => {
      this.spinner.hide('global');
      this.item = res;
      this.prepareDataOnGet();
      console.log(res, "")
    }, () => {
      this.spinner.hide('global');

      alert('Error');
    })
  }


  /**
   *
   */
  saveData() {
    let data = this.prepareDataBeforeSave();
    if (this.data.id) {
      let endpoint = getEndPoint('update',this.slug, this.module ).replace('{{id}}', this.item.id);
      
      this.moduleWorkerService.update(endpoint, data).subscribe(() => {
        this.spinner.hide('global');
        this._router.navigate(['module-worker', this.slug, this.module.id]);
      }, err => {
        console.log(err)

        this.errors = err.error
        this.spinner.hide('global');
        alert('Error');
      })
    } else {
      let endpoint = getEndPoint('create',this.slug, this.module);
        this.moduleWorkerService.create(endpoint, data).subscribe(() => {
        this.spinner.hide('global');
        this._router.navigate(['module-worker', this.slug, this.module.id]);
      }, err => {
        console.log(err)
        this.errors = err.error
        this.spinner.hide('global');
        alert('Error');
      })
    }

  }


  /**
   *
   * @param row
   * @param path
   */
  prepareDataOnGet() {
    let headers = this.module.configs.header_table;
    let row = this.item;
    for (let header of headers) {
      let attr = header.name.split('.')[0];
      if (typeof row[attr] == "string" && row[attr] && header.name.indexOf('.') != -1) {
        row[attr] = JSON.parse(row[attr]);
        this.jsonTextfields.push(attr);
      }
    }
    this.data = row;
  }

  prepareDataBeforeSave() {
    let data = this.data;
    for (let field of this.jsonTextfields) {
      data[field] = JSON.stringify(this.data[field])
    }
    return data;
  }


}
