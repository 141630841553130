import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-chart-config',
  templateUrl: './dialog-chart-config.component.html',
  styleUrls: ['./dialog-chart-config.component.scss']
})
export class DialogChartConfigComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogChartConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}

  attrs : any;
  chart : any;
  ngOnInit() {
    this.attrs = this.data.attrs;
    this.chart = this.data.chart;
  }

  isDate(val, date = true){
    if(date){
      return val.toLowerCase().indexOf('date') != -1;
    }else {
      return val.toLowerCase().indexOf('date') == -1;
    }
  }

}
