<h2 class="mat-dialog-title with-trans">
  {{'Edit' | translate }} {{ 'Property informations'| translate}} 
  <app-select-translate [hotel]="hotel"></app-select-translate>
</h2>

<mat-dialog-content class="mat-typography">
  
  <mat-tab-group>
    <mat-tab label="Informations">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="form-group">
              <label>{{'Name'| translate}}</label>
              <input type="text" class="form-control" [(ngModel)]="hotel.name_hotel">
              <app-error-input *ngIf="errors" [errors]="errors" name="name_hotel"></app-error-input>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="form-group">
              <label>{{'Stars'| translate}}</label>
              <select class="form-control" [(ngModel)]="hotel.stars">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>

          <div class="col-lg-6">
            <label>{{'Address'| translate}}</label>
             <input type="text" class="form-control" [(ngModel)]="hotel.adresse">
             <app-error-input *ngIf="errors" [errors]="errors" name="adresse"></app-error-input>
          </div>

          <div class="col-lg-3">
            <div class="form-group">
              <label>{{'Check-in'| translate}}</label>
              <input type="time" class="form-control" [(ngModel)]="hotel.check_in">
              <app-error-input *ngIf="errors" [errors]="errors" name="check_in"></app-error-input>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>{{'Check-out'| translate}}</label>
              <input type="time" class="form-control" [(ngModel)]="hotel.check_out">
              <app-error-input *ngIf="errors" [errors]="errors" name="check_out"></app-error-input>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>{{'Email'| translate}}</label>
              <input type="text" class="form-control" [(ngModel)]="hotel.email">
              <app-error-input *ngIf="errors" [errors]="errors" name="email"></app-error-input>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>{{'Phone'| translate}}</label>
              <input type="text" class="form-control" [(ngModel)]="hotel.Telephone">
              <app-error-input *ngIf="errors" [errors]="errors" name="Telephone"></app-error-input>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label>{{'Fax'| translate}}</label>
              <input type="text" class="form-control" [(ngModel)]="hotel.fax">
              <app-error-input *ngIf="errors" [errors]="errors" name="fax"></app-error-input>
            </div>
          </div>
          <div class="col-lg-12 form-group">
            <!--translated input-->
            <translated-input [model]="hotel" [attr]="'welcome_message'" [hotel]="hotel" [type]="'input'"
              [label]="'Welcome message'" [languages]="languages">
            </translated-input>
            <!--translated input-->
            <app-error-input *ngIf="errors" [errors]="errors" name="welcome_message"></app-error-input>
          </div>
          <div class="col-lg-12 form-group">
            <!--translated input-->
            <translated-input [model]="hotel" [attr]="'description'" [hotel]="hotel" [type]="'textarea'"
              [label]="'Description'" [languages]="languages">
            </translated-input>
            <app-error-input *ngIf="errors" [errors]="errors" name="description"></app-error-input>
            <!--translated input-->
          </div>


        </div>
      </div>

    </mat-tab>
    <mat-tab label="Medias">
      <div class="container">
        <div class="row">
          <div class="input-group">
            <div class="col-lg-6 file form-group" *ngFor="let file of files; let i = index">
              <form-select-icons [label]="file.label" [attr]="file" [model]="hotel" [hotel]="hotel" size='big' [tab_icon]="false"></form-select-icons>
            </div>
          </div>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{'Cancel'| translate}}</button>
  <button mat-button (click)="createOrUpdate()"  class="btn btn-success">{{'Update'| translate}}  {{'Hotel'| translate}}</button>
</mat-dialog-actions>