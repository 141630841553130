import { Injectable } from '@angular/core';


export const item_attr = [
    {
        label: "Informations",
        name : "information_tab",
        fields: [
            {
                name: "name",
                label: "Name",
                input: "input",
                translatable: true
            },
        
            {
                name: "initial_price",
                label: "Unit price",
                input: "input",
                type: "number",
                translatable: false
            },
            {
                name: "price_text",
                label: "Text for unit price",
                input: "input",
                translatable: true
            },
            {
                name: "qte_type",
                label: "Quantity type",
                input: "select",
                translatable: false,
                values: [
                    ["MAN", "Unit"],
                    ["NGT", "Nb nuit"],
                    ["NAG", "Nb guest x Nb nuit"],
                    ["NAGA", "Nb guest adluls x Nb nuit"],
                    ["NAGC", "Nb guest children x Nb nuit"],
                ]
            },
            {
                name: "tax_rate",
                label: "Taxe rate",
                input: "input",
                type: "number",
                translatable: false
            },
            {
                name: "extern_url",
                label: "Extern Url",
                input: "input",
                type: "url",
                translatable: false
            },
            {
                name: "short_description",
                label: "Short description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "long_description",
                label: "Long description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
                {
                name: "optional_title",
                label: "Optional title",
                input: "input",
                translatable: true
            },
            {
                name: "optional_description",
                label: "Optional description",
                input: "input",
                translatable: true
            }
        ]
    },
    {
        label:"Images",
        name:"images_tab",
        fields:[
            {
                name: "image_square",
                label: "First Image",
                input: "media",
                translatable: false,
                width: 'col-lg-4'
            },
            {
                name: "image_rectangular",
                label: "Second image",
                input: "media",
                translatable: false,
                width: 'col-lg-4'
            },
            {
                name: "icon",
                label: "ICON",
                input: "media",
                translatable: false,
                width: 'col-lg-4'
            }
        ]
    },
    {
        label: "Options",
        name : "option_tab",
        fields: [
            {
                name: "visible",
                label: "Visible",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },

            {
                name: "menu_cart",
                label: "Menu cart",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },
            {
                name: "menu_cart_with_scroller",
                label: "Menu cart with scroller",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },
            {
                name: "home_page",
                label: "Home page",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },
            {
                name: "more_details",
                label: "More details",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },
            {
                name: "service_email",
                label: "Service Email",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },
            {
                name: "service_check_in",
                label: "Service checkin",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },
            {
                name: "service_check_out",
                label: "Service chekout",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            },
            {
                name: "service_multi_display",
                label: "Service multi display",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3'
            }
        ]
    },

]
