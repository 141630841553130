import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { _PERIODS, PERIODS } from '../../constants';

@Component({
  selector: 'app-stat-donuts',
  templateUrl: './stat-donuts.component.html',
  styleUrls: ['./stat-donuts.component.scss']
})
export class StatDonutsComponent implements OnInit {

  @Input() title : any;
  @Input() data : any;
  @Input() tile;
  @Input() template: any;
  @Output() actionEvent = new EventEmitter();

  percent : any;
  constructor(
  ) { }

  ngOnInit() {
    let _x1 = this.template.configs.x1;
    let _x2 = this.template.configs.x2;
    let _f = this.template.configs.formula;

    if(typeof this.data[_x1] == "undefined" || typeof this.data[_x2] == "undefined" ){
      this.percent = 0;
    }else {
      if(_f == 2 && (this.data[_x1] != 0 || this.data[_x2] != 0)){
        this.percent = (this.data[_x1]*100 / (this.data[_x1] + this.data[_x2]))
      }else if(_f == 1 &&  this.data[_x2] != 0){
        this.percent = ((this.data[_x1]*100 )/  this.data[_x2])
      }
      else {
        this.percent = 0
      }
      this.percent = Math.round(this.percent);
    }


  }


  /**
   *
   * @param action
   */
  actions(action){
      this.actionEvent.emit({template : this.template, action})
  }


  /**
   *
   */
  getPeriod(){
    return _PERIODS[this.template.default_period]
  }

}
