import { hasRole } from 'src/app/helpers/commun';

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { DialogDetailSupervisionComponent } from '../../dialogs/dialog-detail-supervision/dialog-detail-supervision.component';
import { DialogSupervisionLoadApiComponent } from '../../dialogs/dialog-supervision-load-api/dialog-supervision-load-api.component';

@Component({
  selector: 'app-table-supervison',
  templateUrl: './table-supervison.component.html',
  styleUrls: ['./table-supervison.component.scss']
})
export class TableSupervisonComponent implements OnInit, OnChanges {

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    public dialog: MatDialog,
  ) { }

  @Input() displayedColumns: string[];
  @Input() customDisplayedColumns: string[];
  @Input() dataSource: any;
  @Input() localstorage: any;
  @Input() searchValue = "";
  @Input() isLoading = false;
  @Input() configs ;
  @Input() hotel = "";
  @Input() data: any;
  @Input() page_number = 1;
  @Input() number_per_page = 10;
  @Input() count = 10;
  @Input() totalpages = 1;

  @Output() OnPagination = new EventEmitter()

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  sortedData: any;


  orderField = "__date_order";
  sortDirection = "desc";

  ngOnInit() {
    this.init();
  }


  init() {
    this.getHeaders(this.displayedColumns);
    setTimeout(()=>{
      if(this.data[0] != undefined){
        if (this.data[0]['__date_order'] != undefined){
          this.orderField = "__date_order";
          this.sortDirection  = "desc"
        }
        else if(this.data[0]['_date_order'] != undefined){
          this.orderField = "_date_order";
          this.sortDirection  = "asc"
        }
      }
    },100)
    this.dataSource = new MatTableDataSource(this.data);
    // this.dataSource.sort = this.sort;
  }


  ngOnChanges() { 
    this.init();

    // this.applyFilter(this.searchValue)
  }

  /**
   * 
   * @param event 
   */
  paginate(page, possible=true){
    if(possible){
      this.page_number = page;
      this.OnPagination.emit({page_number:this.page_number, number_per_page:this.number_per_page});
    }
  }

  changePageSize(){

    this.OnPagination.emit({page_number:this.page_number, number_per_page:this.number_per_page});
  }


  /**
   *
   * @param element
   * @param btn
   */
  openDetailAPI(element, btn){
    const defDialog = this.dialog.open(DialogSupervisionLoadApiComponent, {
      data : {element, btn, hotel:this.hotel}, width:'1500px'
    })
  }

  /**
   *
   * @param sort
   */
  sortData(sortEvent: Sort) {
    let data = Object.assign([], this.dataSource);
    if (!sortEvent.active || sortEvent.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sortEvent.direction === 'asc';
      return compare(a[sortEvent.active], b[sortEvent.active], isAsc)
    });
  }
  /**
   *
   * @param searchValue
   */
  applyFilter(searchValue) {
    if (searchValue) {
      this.dataSource.filter = searchValue.trim().toLowerCase();
    }
  }

  /**
   *
   * @param val
   */
  getClassTd(val) {

    if (this.searchValue
      && val != ""
      && typeof (val) == 'string'
      && val.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1) {
      return 'warning'
    }
  }

  /**
   *
   * @param displayedColumns
   */
  getHeaders(displayedColumns) {
    let array = Object.assign([], displayedColumns);
    let currentRole = localStorage['user_role'];
    if(this.configs.customBtnsParams != undefined){
      this.configs.customBtnsParams
      .filter(x=>hasRole(currentRole, x.role))
      .map(x=>x.text)
      .forEach(el => {
        array.unshift(el)
      });
    }

    this.configs.detail && array.unshift('Detail');
    this.customDisplayedColumns = array;
  }


  /**
   *
   * @param name
   */
  getName(name){
    return name.replace('$','').replace('_', ' ')
  }


  /**
   *
   * @param row
   */
  openDetailClient(row) {
    this.dialog.open(DialogDetailSupervisionComponent, {
      data: { detail: row, hotel: this.hotel }, width: '950px'
    });
  }



}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
