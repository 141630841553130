import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { HotelService } from '../../shared/services/hotel.service';
import { SignageService } from '../../shared/services/signage.service';

@Component({
  selector: 'app-digital-signage',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './digital-signage.component.html',
  styleUrls: ['./digital-signage.component.scss']
})
export class DigitalSignageComponent implements OnInit {
  /**
   * CONSTRUCTEUR
   */
  constructor(
    private signage : SignageService,
    private hs: HotelService,
    notifierService: NotifierService,
    private router : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _router : Router,
    public dialog: MatDialog,

  ) {
    this.notifier = notifierService;
  }

  /**
   * VARS
   */
  private readonly notifier: NotifierService;

  view: string = 'month';
  title = "Digital signage"
  devices : any;
  list = [];
  slug: any;
  hotel : any;
  date = new Date();
  year = this.date.getFullYear();
  month = this.date.getMonth() + 1;
  user_id = localStorage.getItem('user_id');
  displays :any;
  languages : any;



  ngOnInit() {
    this.spinner.show('global');

    this.hs.getLanguages().subscribe((data) => {
      this.languages = data
    });

    this.router.params.subscribe(params => {

      if(params.slug =="undefined"){
          this._router.navigate(['/hotels'])
      }
      this.slug = params.slug
      this.hs.getHotel(this.slug).subscribe((data) => {
          this.hotel = data;
      });


      this.signage.getDisplayList(this.user_id, this.slug).subscribe(res=>{
        this.displays = res;
      })

      this.signage.getDevicesWithEvents(this.slug,this.year, this.month ).subscribe(res=>{
        this.spinner.hide('global');
        this.devices = res;
      });

    });


  }



}
