<h2 class="mat-dialog-title with-trans">
  {{'Manage Template' | translate}}
</h2>

<mat-dialog-content class="mat-typography">
  <mat-tab-group *ngIf="templates.length"> 
    <mat-tab [label]="getName(template.NAME)" *ngFor="let template of templates">
      <textarea  *ngIf="template" [(ngModel)]="template.CONTENT_HTML" class="form-control" rows="20"></textarea>
    </mat-tab>
  </mat-tab-group>

  <textarea [(ngModel)]="step.COMPONENTS" class="form-control" *ngIf="step" rows="20"></textarea>

</mat-dialog-content>
<mat-dialog-actions align="end">

  <button mat-button mat-dialog-close [mat-dialog-close]="" class="btn btn-default">{{'Close' | translate}}</button>
  <button mat-button (click)="saveTemplate()" class="btn btn-success">{{'Save' | translate}}</button>
</mat-dialog-actions>