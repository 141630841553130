<!-- Page Content -->
<div class="container-fluid">

  <div class="row">
      <!--menu manager-->
    <div class="col-md-12" *ngIf="hotel">
          <app-menu-manager [slug]="slug" [hotel]="hotel" [languages]="languages"></app-menu-manager>
    </div>
    <!-- menu-manager -->

    <!-- BASE API MANAGER -->
    <div class="col-md-12">
      <div class="white-box content">
        <h2 class="title">{{'API base url' | translate}}</h2>
        <div class="form-group">
          <label for="">{{'Base url' | translate}} : https://api.tabhotel.com | http://localhost:8000</label>
          <input type="text" class="form-control" [(ngModel)]="url">
        </div>
        <button class="btn btn-success" (click)="updateUrl()">{{'Save' | translate}}</button>
      </div>


  </div>
</div>