<!-- <div class="action text-right" *ngIf="subcategory?.items.length == 0 && btnNewItem">
	<button class="btn btn-success btn-sm" (click)="addItem(subcategory , type)">Create new item</button>
</div> -->

<div class="items">

	<div class="row mrgb-20" [ngClass]="{'hideNewItem':!btnNewItem}">
		<div class="col-lg-6">
			<strong>Description : </strong><span [innerHTML]="subcategory.short_description"></span>
		</div>
		<!--add item button-->
		<div class="col-lg-6 text-right">
			<button class="btn btn-success btn-sm" (click)="addItem(subcategory , type)"
			*ngIf="displayNewItem(subcategory)">{{'Create' | translate}} {{'new item' | translate}}</button>

			<button class="btn btn-default btn-sm" (click)="updateSubcategory(subcategory)"
				*ngIf="!categoryParent">{{'Update' | translate}} {{'Subcategory'  | translate}}</button>
				<button class="btn btn-default btn-sm" (click)="duplicateSubcat(subcategory)"
				*ngIf="!categoryParent">{{'Duplicate' | translate}} {{'Subcategory' | translate}}</button>

				<button class="btn btn-danger btn-sm delete-category" (click)="deleteConfirm(subcategory)"
				*ngIf="subcategory && btnDelete">{{'Delete' | translate}}
				{{'Subcategory' | translate}}</button>
		</div>
		<!--add item button-->


	</div>



	<!--list items-->
	<div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="subcategory.items" class="tab-draggble">
		<div class="item" *ngFor="let item of subcategory.items; let i = index" cdkDrag
			[cdkDragData]="subcategory.item">

			<div class="row">

				<div class="col-lg-1 text-center preview">
					<div [ngStyle]="getStyle(buildUrl(item.image_square))" *ngIf="item.image_square"></div>
					<div [ngStyle]="getStyle('assets/img/icons/svg/'+item.icon)" *ngIf="!item.image_square"></div>
				</div>
				<div class="col-lg-8">
					<h4>{{item.name | slice:0:50}} <span class="status" [ngClass]="{disabled:!item.visible }"></span>
						<strong *ngIf="!hideprice">{{item.initial_price}}</strong>
					</h4>
					<div class="text-preview" [innerHTML]="item.long_description"></div>
					<button *ngIf="category_type == 'MapPosition' && !item.map_position" (click)="openExtraDialog(item)" class="btn btn-warning btn-sm">Add map location</button>
				</div>
				<div class="col-lg-3 flex-vertical flex-end">
					<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" >
						<mat-icon>more_vert</mat-icon>
					</button>

					<mat-menu  #menu="matMenu" yPosition="above" xPosition="before">
						<button mat-menu-item (click)="updateItem(item, subcategory)">
							<mat-icon>edit</mat-icon>
							<span>{{'Edit' | translate}} {{'item' | translate}}</span>
						</button>
						
						<button *ngIf="hotel.calendar_upsell_module && item.has_calendar_stock" mat-menu-item [routerLink]="['/item-calendar', hotel.id, item.id]" >
							<mat-icon>place</mat-icon>
							<span>{{'Configure calendar' | translate}}</span>
						</button>

						<!--only for map position category-->
						<button mat-menu-item (click)="openExtraDialog(item)" *ngIf="category_type == 'MapPosition'">
							<mat-icon>place</mat-icon>
							<span>{{(item.map_position ? 'Edit map location' : 'Add map location') | translate}}</span>
							<!-- to trans -->
						</button>
						<!--endif-->

						<button mat-menu-item (click)="duplicateItem(item, subcategory)">
							<mat-icon>file_copy</mat-icon>
							<span>{{'Duplicate' | translate}} {{'Item' | translate}}</span>
						</button>

						<!--only for room service category-->
						<button mat-menu-item  (click)="openSlide(item, i)" *ngIf="category_type == 'RoomService'">
							<mat-icon>collections</mat-icon>
							<span>{{'Edit' | translate}} {{'Slides' | translate}}</span>
						</button>
						<!--endif-->

						<button mat-menu-item (click)="deleteItem(item, i)">
							<mat-icon>delete_forever</mat-icon>
							<span>{{'Delete' | translate}} {{'Item' | translate}}</span>
						</button>
					</mat-menu>
					<button class="btn" [ngClass]="{active:see_details[item.id]}" (click)="getDetails(item.id)">
						<i class="fa fa-eye" aria-hidden="true"></i>
					</button>
					<button class="btn" *ngIf="subcategory.items.length>1" cdkDragHandle>
						<i class="fa fa-arrows" aria-hidden="true"></i>
					</button>
				</div>
				<div class="col-lg-12">
					<div class="details" *ngIf="see_details[item.id]">
						<app-view-details [model]="item" [label]="'Item details'"
							[attrs]="superFields.super_item_attr !='_all' ? superFields.super_item_attr : item_attr ">
						</app-view-details>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--list items-->
</div>
