import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelinformationsService } from 'src/app/shared/services/hotelinformations.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss']
})
export class DialogInformationsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogInformationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private hi: HotelinformationsService,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
  ) {
    this.notifier = notifierService;
  }

  private readonly notifier: NotifierService;

  information: any;
  informations:any;
  hotel : any;
  icons :any;
  languages:any;
  errors = null;

  ngOnInit() {
    this.information = this.data.information;
    this.hotel = this.data.hotel;
    this.languages = this.data.languages;
    this.icons = this.data.icons;
    this.informations = this.data.informations
  }

  /**
   * create Or Update info
   * @param info
   */
  createOrUpdate(info){
      this.spinner.show('global')
      if (info.id) {
        this.hi.updateInformation(info.id, this.information).subscribe(res => {
          info = res;
          this.translate.get('Information successfully updated').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
          this.dialogRef.close(true);
          this.spinner.hide('global')

        }, err => {
          this.spinner.hide('global')
          this.errors = err.error;
        } )
      } else {
        info.attribute = 'info';
        this.hi.createInformation(this.information).subscribe(res => {
          this.informations.push(res);
          this.translate.get('Information successfully added').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
          this.dialogRef.close(true);
          this.spinner.hide('global')

        }, err => {
          this.spinner.hide('global')
          this.errors = err.error;
        } )
      }
  }

}
