import { NotifierService } from 'angular-notifier';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { TramesService } from 'src/app/shared/services/trames.service';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DialogTriggerMaintenanceComponent } from '../../dialogs/dialog-trigger-maintenance/dialog-trigger-maintenance.component';

@Component({
  selector: 'app-manage-trames',
  templateUrl: './manage-trames.component.html',
  styleUrls: ['./manage-trames.component.scss']
})
export class ManageTramesComponent implements OnInit {

  constructor(
    private trameService: TramesService,
    public dialog: MatDialog,
    notifierService: NotifierService,
    private translate: TranslateService,

  ) {
    this.notifier = notifierService;
  }

  @Input() trigger: any;
  @Input() trames: any;
  @Input() groups: any;
  @Input() header = true;
  @Input() actions = true;
  @Input() hotel :any;
  @Input() categories :any;

  @Output() editDialog = new EventEmitter()

  slug: any;
  trigger_id: any;

  hardwares: any;
  title: any;
  private readonly notifier: NotifierService;

  export_types = [
    {value: "audio", viewValue: "Audio"},
    {value: "mail", viewValue: "Mail"},
    {value: "text", viewValue: "Text"},
    // {value: 'sms', viewValue: 'SMS'}
  ];

  ngOnInit() {

  }

  /**
   *
   * @param obj
   * @param i
   */
  edit(obj, i =null) {
    this.editDialog.emit({ trigger_id: this.trigger ? this.trigger.id : obj.trigger, obj, i })
  }

  /**
   *
   * @param obj
   */
  updateState(obj){
    this.trameService.update(obj).subscribe(res=>{console.log(res)})
  }

  addMaintenanceTrigger(){
    const dialogRef = this.dialog.open(DialogTriggerMaintenanceComponent, {
      width:'900px',
       data: {slug:this.slug , hotel: this.hotel, categories: this.categories, groups : this.groups}
    })
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.trames.unshift(res);
      }
    })
  }
  /**
   *
   * @param trame
   * @param index
   */
  deleteConfirm(trame, index) {
    let type = "trame";
    let name = trame.name;
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name, type }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //Request Information
        this.trameService.delete(trame.id).subscribe(() => {
          this.trames.splice(index, 1);
          this.translate.get('trame successfully deleted').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
        },
          err => console.log(err))
      }
    });
  }

  /**
   *
   * @param trame
   * @param i
   */
  duplicate(trame){
    let newTrame = {...trame, name : 'Copy '+trame.name};
    delete newTrame.id;
    this.edit( newTrame);
  }

  /**
   *
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.trames, event.previousIndex, event.currentIndex);
    let obj = {
      trames: this.trames.map(a => a.id),
    }
    this.trameService.sort(obj).subscribe(
      () => {
        this.translate.get('trame priority successfully updated').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
      },
      () => {
        this.translate.get('An error has occurred').subscribe(
          value => {
            this.notifier.notify('error', value);
          }
        );
      })
  }

}
