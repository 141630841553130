import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: "nav-paginator",
  templateUrl: "./nav-paginator.component.html",
  styleUrls: ["./nav-paginator.component.scss"],
})
export class NavPaginatorComponent implements OnInit {
  @Input() current_page: number;
  @Input() total_pages: number;
  @Input() previous: any;
  @Input() next: any;
  @Output() pageSize = new EventEmitter<number>();
  @Output() pageNumber = new EventEmitter<number>();
  constructor() {}

  ngOnInit(): void {
    this.current_page;
    this.total_pages;
    this.previous;
    this.next
  }

  changePageSize(pageSize) {
    this.pageSize.emit(pageSize);
  }

  paginate(pageNumber, pageDispo: boolean) {
    if (pageDispo) {
      this.pageNumber.emit(pageNumber);
    }
  }
}
