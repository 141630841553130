import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class UpsellService {

  constructor(private http: HttpClient) { }
  uri = URI;

  /**
   * GET DETAILS BY HOTEL ID
   * @param id :HOTEL ID
   */
  getLabels(id){
    return this.http.get(`${this.uri}`+"offers-labels/"+id);
  }

  /**
   * GET LIST OFFER BY HOTEL
   * @param id :HOTEL ID
   */
  getOffers(id){
    return this.http.get(`${this.uri}`+"offers/"+id);
  }

  getOffer(id){
    return  this.http.get(`${this.uri}`+"offers/"+id+"/");
  }

  deleteOffer(id){
    return this.http.delete(`${this.uri}`+"offers/"+id+"/")
  }

  /**
   * CREATE
   * @param offer
   */
  createOffer(offer){
    return this.http.post(`${this.uri}`+"offers/", this.serializeQuery(offer));
  }

  /**
   * UPDATE
   * @param offer
   */
  updateOffer(offer){

    return this.http.put(`${this.uri}`+"offers/"+offer.id+"/", this.serializeQuery(offer));
  }

  /**
   * SERIALIZE OFFER
   * @param offer
   */
  serializeQuery(offer){
    let query = {};
    let jsons = ['CONDITIONS','DISPLAY_ITEMS', 'PROMOTIONS'];
    for(let i in offer){
      if(jsons.indexOf(i) == -1){
        query[i] = offer[i];
      }else {
        query[i] = JSON.stringify( offer[i])
      }
    }
    return query;
  }



}
