<!-- Page Content -->
<div class="container-fluid">
  <app-fixed-title [title]='title' [hotel]='hotel' *ngIf="!onglet"></app-fixed-title>

  <h4 class="mt-3" *ngIf="onglet">{{title}}</h4>

  <div class="white-box">
      <!--head-->
          <div class="row">
           
                <div class="col-3 vertical-align">
                    <input type="search" placeholder="{{'Search'| translate}}" [(ngModel)]="search" class="form-control" (input)="searchInList()">
                </div>
                <div class="col-md-9 text-right">
                    <button class="btn btn-success" (click)="openDialogRoom()">
                        <i class="fa fa-tags" aria-hidden="true"></i>
                        {{'Add Room Category' | translate}}
                    </button>
                </div>
            </div>  
          
          <br>
      <!--head-->

          <table class="table table-striped">
              
              <thead>
                  <tr>
                      <th>#ID</th>
                      <th>{{'Name'| translate}}</th>
                      <th *appHasRole="'super-user'">ID PMS</th>
                      <th>{{'Max Guests'| translate}}</th>
                      <th>{{'Active'| translate}}</th>
                      <th>{{'Actions'| translate}}</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let category of searchList; let i = index ">
                      <td>{{category.id}}</td>
                      <td>{{category.name}}</td>
                      <td *appHasRole="'super-user'">{{category.id_pms}}</td>
                      <td>{{category.max_guests}}</td>
                      <td>{{category.active}}</td>
                     
                      <td class="text-right">
                        <button (click)="openDialogRoom(category, i)" class="btn btn-sm btn-success">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            {{'Edit'| translate}}
                          </button>
                          <!-- <button class="btn btn-sm btn-success" [routerLink]="['/room_categories', slug,category.id]" >Edit</button> -->
                          <button class="btn btn-sm btn-danger" (click)="deleteRoomCategory(category, index)">{{'Delete'| translate}}</button>
                      </td>
                  </tr>
              </tbody>

          </table>
  </div>
</div>