import { ActiveSelectLanguageService } from 'src/app/shared/services/active-select-language.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'translated-input',
  templateUrl: './translated-input.component.html',
  styleUrls: ['./translated-input.component.scss']
})
export class TranslatedInputComponent implements OnInit{

  constructor(
    private selectLang : ActiveSelectLanguageService
  ) { }

  @Input() type;
  @Input() hotel;
  @Input() model;
  @Input() attr;
  @Input() default;
  @Input() label;
  @Input() rows;
  @Input() input;
  @Input() languages;
  @Input() description: any;
  @Input() className = ''
  @Input() getDefault = false
  @Input() translate = true;
  @Input() placeholder :any;
  @Input() displayDefaultTrans = false;


  // @Input() options = "full";
  @Input() initialAttr = true;
  @Input() defaultLang = true;
  @Input() defaultLangVal = "en";
  @Input() style = {};
  @Input() options  = {
    placeholder: '',
    tabsize: 2,
    height: 250,

    toolbar: []
  };
  currentLang  = localStorage.getItem('hotel_language');
  changeEvent = false;
  @Output() updateInput = new EventEmitter;

  reference = "";


  ngOnInit(){

    this.selectLang.currentLanguage.subscribe(res=>{
      this.currentLang = res;
      this.changeEvent = true
    })
    if(this.hotel && this.hotel.languages){
      let lang = this.hotel.languages.find(x=>x.id  == this.hotel.default_lang);
      this.defaultLangVal = lang;
      // this.currentLang = this.defaultLangVal['lang_code'];
      // console.log(this.currentLang, '-----')

      if(this.getDefault){
        this.model[this.attr + '_' +lang.lang_code] = this.model[this.attr]
      }
    }

  }


  /**
   *
   * @param event
   * @param lang
   * @param texteditor
   */
  changed(event, lang, texteditor = false) {
    this.changeEvent = false
    if(this.defaultLang){
      let target = event.target.value;
      if (texteditor) {
        target = event.target.innerHTML;
      }
      if (lang == this.hotel.default_lang) {
        this.model[this.attr] = target
      }
    }
  }

  /**
   *
   * @param attr
   * @param lang
   */
  getAttr(attr, lang){
    if(attr == ''){
      return  lang.lang_code
    }else {
      return  attr+'_'+lang.lang_code

    }
  }



  /**
   *
   * @param $event
   */
  updateAction($event){
    this.updateInput.emit($event)
  }


  /**
   *
   * @param attr
   * @param lang
   */
  getField(attr, lang = ""){
      return this.model[this.getAttr(attr, lang)]
  }





}
