<h2 class="mat-dialog-title with-trans">
  {{'Export / import' | translate}}
</h2>

<mat-dialog-content class="mat-typography">
  <label for="">{{'Customer : choose the customer id' | translate}}</label>
  <input type="text" [(ngModel)]="customer" class="form-control">
  <br>
  <textarea class="form-control" [(ngModel)]="data.triggers" cols="30" rows="10"></textarea>
  <textarea class="form-control" [(ngModel)]="data.maintenance" cols="30" rows="10"></textarea>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{'Cancel' | translate}}</button>
  <button mat-button (click)="import()"  class="btn btn-success">{{'Save' | translate}}</button>
</mat-dialog-actions>