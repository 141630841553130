import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-timeline',
  templateUrl: './dialog-timeline.component.html',
  styleUrls: ['./dialog-timeline.component.scss']
})
export class DialogTimelineComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<DialogTimelineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}

  values : any
  ngOnInit(){
    this.values = this.data[Object.keys(this.data)[0]];
  }



}
