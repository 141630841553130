
import { Injectable } from '@angular/core';


export const super_category_attr =  [

    {
        label: "Informations",
        fields: [
            {
                name: "hotel",
                label: "Hotel",
                input: false,
                translatable: false
            },
            {
                name: "name",
                label: "Name",
                input: 'input',
                translatable: true,
                width: "col-lg-4"
            },
            {
                name: "icon",
                label: "Icon",
                input: "select-icon",
                translatable: false,
                width: 'col-lg-4'
            },
            {
                name: "visible",
                label: "Visible",
                input: 'checkbox',
                translatable: false,
                width: 'col-lg-4'
            },         
            
            
            
            {
                name: "short_description",
                label: "Short description",
                input: 'htmleditor',
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "long_description",
                label: "Long description",
                input: 'htmleditor',
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name:"image",
                label:"Image",
                input: "media",
                translatable:false,
                width: 'col-lg-4'
            },
            {
                name: "color",
                label: "Color",
                input: 'input',
                type : 'color',
                translatable: false,
                width: "col-lg-4"
            },
            
        ]
    }
];
export const super_subcat_attr = [
    {
        label: "Informations",
        fields: [
            {
                name: "name",
                label: "Name",
                input: "input",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "image",
                label: "Image",
                input: "media",
                translatable : false
            },
            {
                name: "icon",
                label: "Icon",
                input: "select-icon",
                translatable : false
            },
            {
                name: "short_description",
                label: "Short description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "visible",
                label: "Visible",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-12'
            }
        ]
    }
]
export const super_item_attr = [
    {
        label: "Informations",
        name : "information_tab",
        fields: [
            {
                name: "name",
                label: "Name",
                input: "input",
                translatable: true
            },
             {
                name: "initial_price",
                label: "Unit price",
                input: "input",
                type: "number",
                translatable: false,
                 width: 'col-lg-3'
            },
            {
                name: "icon",
                label: "Icon",
                input: "select-icon",
                translatable: false,
                 width: 'col-lg-3'
            },

            {
                name: "short_description",
                label: "Short description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "long_description",
                label: "Long description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "image_square",
                label: "First Image",
                input: "media",
                translatable: false,
                width: 'col-lg-6'
            },
            {
                name: "image_rectangular",
                label: "Second image",
                input: "media",
                translatable: false,
                width: 'col-lg-6'
            },
            {
                name: "optional_title",
                label: "Optional title",
                input: "input",
                translatable: true
            },
            {
                name: "optional_description",
                label: "Optional description",
                input: "htmleditor",
                translatable: true,
                width: 'col-lg-12'
            },
            {
                name: "visible",
                label: "Visible",
                input: "checkbox",
                translatable: false,
                width: 'col-lg-3 absoluted visible',
                checked : true
            },
        ]
    },
   

]

export const super_extra_attr = [

    {
        label: "Map card",
        name : "extra",
        fields: [
            
            {
                name: "map",
                label: "map",
                parent:"extra",
                input: "map",
                translatable: false,
                width: 'col-lg-12',
                fields : [
                    {
                        name: "adresse",
                        parent:"extra",
                        label: "Adress",
                        input: "input",
                        translatable: false,
                        width: 'col-lg-12'
                    },
                    {
                        name: "alt",
                        label: false,
                        parent:"extra",
                        input: "input",
                        translatable: false,
                        class : "hidden"
                    },
                    {
                        name: "lng",
                        label: false,
                        parent:"extra",
                        input: "input",
                        translatable: false,
                        class : "hidden"
                    }
                ]
            },
        
            
        ]
    }
]

export const presentation = "These contents are deployed on the Concierge aera map page";