<div class="white-box">
  <div class="row">
    <div class="col">
      <h1>
        <img src="{{ getIcon() }}" *ngIf="hotel" />
        {{ getTitle() }}
      </h1>
    </div>

    <div class="text-right col">
      <ng-container *ngIf="customBtn">
        <button class="btn btn-success" (click)="onClickCustomBtn(customBtn)" *appHasRole="customBtn.role">
          <i [ngClass]="customBtn.icon" aria-hidden="true"></i>
          {{ customBtn.text | translate}}
        </button>
      </ng-container>

      <button class="btn btn-success" *ngIf="customAction" (click)="onClickEvent()">
        <i class="fa fa-refresh" aria-hidden="true"></i> {{ customActionText }}
      </button>

      <button class="btn btn-success" *ngIf="action" (click)="triggerEvent()">
        <i class="fa fa-refresh" aria-hidden="true"></i> {{ "Refresh" | translate}}
      </button>

      <button
        class="btn btn-success"
        *ngIf="settingAction"
        (click)="settingtriggerEvent()"
      >
        <i class="fa {{ texticon | translate}}" aria-hidden="true"></i> {{ text }}
      </button>

      <!-- menu -->
      <ng-container *ngIf="menus.length">
        <ng-container *appHasRole="'administrator'">
          <!-- 3 points -->
          <button mat-icon-button [matMenuTriggerFor]="appMenu" class="options">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #appMenu="matMenu" xPosition="before">
            <ng-container *ngFor="let item of menus; let i = index">
              <button mat-menu-item (click)="triggerEventMenu(i)">
                <mat-icon>{{ item?.icon }}</mat-icon> {{ item?.name | translate}}
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
      </ng-container>
      <!-- menu -->

      <!-- menu -->
      <ng-container *ngIf="select.length">
        <label for="">Default Period</label>
        <select [(ngModel)]="selectModel" class="form-control"
          (change)="triggerEventMenu('change-period', $event.target.value)">
          <option value="">Period</option>
          <option [value]="option.value" *ngFor="let option of select">
            {{ option.name | translate}}
          </option>
        </select>
      </ng-container>
      <!-- menu -->
      <nav aria-label="breadcrumb" *ngIf="links">
        <ol class="breadcrumb">
          <ng-container *ngFor="let link of links">
            <li class="breadcrumb-item" *ngIf="link?.href && hotel">
              <a [routerLink]="[link?.href, hotel?.id]"
                ><i class="fa fa-angle-left" aria-hidden="true"></i>
                {{ link.label}}</a
              >
            </li>
            <li
              class="breadcrumb-item active"
              *ngIf="!link.href"
              aria-current="page"
            >
              {{ link.label}}
            </li>
          </ng-container>
        </ol>
      </nav>
    </div>
  </div>
</div>
