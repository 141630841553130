<form [formGroup]="registerForm" (ngSubmit)="loginUser()">
	<div class="limiter">
		<div class="container-login100 ">
			<div class="wrap-login100 fadeInUp">
				<div class="login100-form validate-form">

					<img src="assets/img/logo-tabhotel.svg">
					<br>
					<br>
					<br>
					<div class="login100-form-title p-b-34 p-t-27">
						{{'Login' | translate}}
					</div>
					<br>
					<div class="error" *ngIf="message">
						{{message | translate}}
					</div>
					<br>

					<div class="wrap-input100 validate-input" data-validate="Enter email">
						<label>Email</label>
						<input required class="input100" type="email" [(ngModel)]="loginUserData['email']"
							formControlName="email" placeholder="{{'Email' | translate}}" id="email_login">
						<span class="icon"><img src="/assets/img/icons/svg/user.svg" alt=""></span>
						<!-- validator -->
						<div *ngIf="submitted && registerForm.controls.email.errors" class="text-danger">
							<div *ngIf="registerForm.controls.email.errors.required">{{'Email is required' | translate}}
							</div>
							<div *ngIf="registerForm.controls.email.errors.email">
								{{'Email must be a valid email address' | translate}}</div>
						</div>
						<!-- validator -->
					</div>


					<div class="wrap-input100 validate-input" data-validate="Enter password">
						<label>Password</label>
						<input required class="input100" type="password" [(ngModel)]="loginUserData['password']"
							placeholder="{{'Password' | translate}}" formControlName="password" id="password_login">
						<span class="icon"><img src="/assets/img/icons/svg/password.svg" alt=""></span>
						<!-- validator -->
						<div *ngIf="submitted && registerForm.controls.password.errors" class="text-danger">
							<div *ngIf="registerForm.controls.password.errors.required">
								{{'Password is required' | translate}}</div>
							<div *ngIf="registerForm.controls.password.errors.minlength">
								{{'Password must be at least 4 characters' | translate}}</div>
						</div>
						<!-- validator -->
					</div>


					<a [routerLink]="['/forgot-password']" class="link">Forgot password ?</a>

					<div class="container-login100-form-btn">
						<button type="submit" class="login100-form-btn" *ngIf="!loading">
							{{'Login' | translate}}
						</button>
						<div class="lds-ellipsis" *ngIf="loading"><div></div><div></div><div></div><div></div></div>
					</div>

				</div>
			</div>
		</div>

	</div>
</form>