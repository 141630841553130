import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-manager-tab-table',
  templateUrl: './module-manager-tab-table.component.html',
  styleUrls: ['./module-manager-tab-table.component.scss']
})
export class ModuleManagerTabTableComponent implements OnInit {

  constructor() { }

  @Input() module;

  ngOnInit() {
  }

  /**
   *
   * @param event
   */
  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.module.configs.header_table, event.previousIndex, event.currentIndex);
  }

  delete(i){
    this.module.configs.header_table.splice(i,1)
  }

  addDisplayValue(){
    this.module.configs.header_table.push({
      name: 'variable.path',
      label: 'New display',
      active: true,
      type : 'display',
      id: "display_"+new Date().valueOf()
    })
  }

}
