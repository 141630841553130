import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { DashboardTemplateService } from 'src/app/shared/services/dashboard-template.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { DefaultValueService } from 'src/app/shared/services/default-value.service';
import { HotelService } from 'src/app/shared/services/hotel.service';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { PERIODS } from '../constants';
import { _templates } from '../defaultTemplates';
import { DialogStatConfigComponent } from '../dialogs/dialog-stat-config/dialog-stat-config.component';

@Component({
  selector: 'app-stat-display',
  templateUrl: './stat-display.component.html',
  styleUrls: ['./stat-display.component.scss']
})
export class StatDisplayComponent implements OnInit, OnDestroy {

  constructor(
    private hs: HotelService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private defaultValuesService: DefaultValueService,
    private DCS: DashboardTemplateService,
    private dashboardService: DashboardService
  ) { }



  title = "Dashboard";
  slug: any;
  hotel: any;
  defaultCustomer = 29;
  dashboardName = 'dashboard-v2';
  templates: any;
  responses = [];
  dashboardConfig: any;
  results = [];
  dataLoaded = {}
  editTemplates = false;
  defaultPeriods = "ALL";
  PERIODS = PERIODS;
  defaultConfiguration = false;
  subscribeData = {} ;

  menus = [
    { name: 'Refresh all', icon: 'replay', role: 'super-user' },
    { name: 'Add chart', icon: 'settings_ethernet', role: 'super-user' },
    { name: 'Edit charts', icon: 'touch_app', role: 'super-user' },
  ]


  ngOnInit() {



    this.spinner.show('global');
    this.route.params.subscribe(params => {
      this.slug = params.slug;

      //GET HOTEL
      this.hs.getHotel(this.slug).subscribe(data => {
        this.hotel = data;
        // GET DASHBOARD CONFIG
        this.defaultValuesService.getDefaultValue(this.slug, this.dashboardName).subscribe(res => {
          this.dashboardConfig = res;
          if (!this.dashboardConfig.configs)
            this.dashboardConfig.configs = [];
          this.getConfiguration();

        }, err => {
          //GET DEFAULT DASHBOARD
          this.defaultValuesService.getDefaultValue(29, this.dashboardName).subscribe(res => {
            this.dashboardConfig = res;
            this.defaultConfiguration = true;

            this.dashboardConfig.hotel = this.slug;
            delete this.dashboardConfig.id
            this.getConfiguration();

          })
        })
      });
    });



  }

  ngOnDestroy(){
    for(let i in this.subscribeData){
      this.subscribeData[i].unsubscribe()
    }
    
  }

  /**
   *
   * @param arr
   * @param index
   * @param newItems
   */
  appendInArr(arr, index, newItems) {
    return [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted items
      ...newItems,
      // part of the array after the specified index
      ...arr.slice(index)
    ]
  }


  getConfiguration() {
    //GET STATS TEMPLATES API
    this.DCS.getDashboardTemplates('?type_customer=' + this.hotel.customer_type).subscribe((res: string[]) => {
      this.templates = res.length ? res : _templates;
      (res.length == 0) && this.installTemplates();
      this.spinner.hide('global');
      //GET RESPONSES
      this.getData(null, true);
    }, err => {
      this.spinner.hide('global');
    });
  }

  installTemplates() {
    for (let temp of _templates) {
      this.DCS.createDashboardTemplates(temp).subscribe(res => {
        console.log(res, "INSTALLED ALL TEMPLATES")
      })

    }
  }
  /**
   *
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dashboardConfig.configs, event.previousIndex, event.currentIndex);
    this.saveOrUpdateConfig();
  }

  getID(template) {
    template.id
  }

  /**
   *
   */
  addBlock() {
    let newChart = { text: '', cols: 3, rows: 1, template: null }
    this.openDialogConfig(newChart, true)
  }


  /**
   *
   * @param i
   */
  delete(i) {
    const dialog = this.dialog.open(ConfirmDeleteComponent, {
      data: { name: 'Chart' }, width: '500px'
    })
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.dashboardConfig.configs.splice(i, 1);
        this.saveOrUpdateConfig();
      }

    })

  }

  /**
   *
   * @param item
   * @param create
   */
  openDialogConfig(item, create = false, index = null) {
    const dialogRef = this.dialog.open(DialogStatConfigComponent, {
      width: '650px',
      data: { item, templates: this.templates, dashboardConfig: this.dashboardConfig, create, index }
    });

    dialogRef.afterClosed().subscribe(res => {

      if (create && item.template && res) {
        this.dashboardConfig.configs.push(item);
      }
      if (res) {
        this.editTemplates = true;
        this.saveOrUpdateConfig();
      }
    })
  }

  /**
   *
   * @param id
   */
  getTemplate(id) {
    return this.templates.find(x => x.id == id);
  }

  /**
   *
   * @param id
   */
  getResponse(id) {
    return this.responses.find(x => x.id == id);
  }
  /**
   *
   */
  getData(period = null, start = false) {
    this.dataLoaded = {};
    let i = 0;
    for (let temp of this.templates) {
      if (!this.dataLoaded[temp.id] || !start) {
        this.subscribeData[temp.id] = this.dashboardService.getStatsResult(temp, this.slug, period).subscribe(res => {
          this.dataLoaded[temp.id] = res['data'] ? res['data'] : res;
        })
      }
    }
  }


  /**
   *
   */
  saveOrUpdateConfig() {
    if (this.defaultConfiguration) {
      this.defaultValuesService.createDefaultValue(this.dashboardConfig).subscribe(res => {
        this.dashboardConfig['id'] = res['id'];
        this.defaultConfiguration = false;
      })
    } else {
      this.defaultValuesService.updateDefaultValue(this.dashboardConfig).subscribe(res => {
      })
    }
  }


  /**
   *
   * @param event
   */
  menuTitleEvents(event) {
    if (event.index == 0) {
      this.getData();
    }
    if (event.index == 1) {
      this.addBlock();
    }
    if (event.index == 2) {
      this.editTemplates = true;
    }
    if (event.index == 'change-period') {
      this.updateDefaultPeriods(event.val)
      this.getData();
    }
  }

  /**
   *
   * @param val
   */
  updateDefaultPeriods(val) {
    for (let temp of this.templates) {
      if (temp.can_change_period) {
        temp.default_period = val;
      }
    }
  }
  /**
   *
   */
  templateEditing(val = false) {
    this.editTemplates = val;
  }


  /**
   *
   * @param event
   */
  actions(event) {
    let temp = this.templates.find(x => x.id == event.template.id);
    if (event.action == 'change-period' || event.action == 'reload') {
      this.dataLoaded[event.template.id] = null;
      //check if event has new period, or keep default
      if (event.period != undefined) {
        temp.default_period = event.period
      }
      this.dashboardService.getStatsResult(temp, this.slug).subscribe(res => {
        this.dataLoaded[temp.id] = res['data'] ? res['data'] : res;
      })
    } else if (event.action == 'change-type') {
      let reloadData = this.dataLoaded[event.template.id];
      temp.configs.chart.type = event.type;
      this.dataLoaded[event.template.id] = []
      setTimeout(() => { this.dataLoaded[event.template.id] = reloadData }, 10);
    }
  }

}
