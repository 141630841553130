<div class="container-fluid">
  <app-fixed-title [hotel]='hotel' [menus]='menus' (menuClickEvent)="menuTitleEvents($event)" [select]="PERIODS">


  </app-fixed-title>
  <div class="custom-row" *ngIf="responses && templates">
  <mat-grid-list cols="12" rowHeight="170px" cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-grid-tile
        [cdkDragDisabled]="!editTemplates"
        cdkDrag
        *ngFor="let tile of dashboardConfig?.configs; let i = index"
        [colspan]="tile.cols"
        [rowspan]="tile.rows">

        <!-- card -->
        <div class="card-stx" (dblclick)="templateEditing(true)">
          <app-stat-load
          [tile]="tile"
          [template]="getTemplate(tile.template)"
          [data]="dataLoaded[tile.template]"
          [text]="tile.text"
          (actionEvent)="actions($event)"></app-stat-load>

          <!-- EDIT TEMPLATE BTNS -->
          <ng-container *ngIf="editTemplates">
            <span (click)="delete(i)" class="btn btn-danger btn-xs"><i class="fa fa-times" ></i></span>
            <span cdkDragHandle class="btn btn-default btn-xs" ><i class="fa fa-arrows"></i></span>
            <span (click)="openDialogConfig(tile, false, i)" class="btn btn-success btn-xs"><i class="fa fa-cog" ></i> </span>
          </ng-container>
          <!-- EDIT TEMPLATE BTNS -->

        </div>
        <!-- card -->

    </mat-grid-tile>
  </mat-grid-list>


</div>

  <!-- <button (click)="addBlock()" class="btn btn-success">Add new chart</button> -->
</div>

<div class="alert in-left alert-default" *ngIf="editTemplates">

{{'Click on this button after finish' | translate}}
  <button class="btn btn-success" (click)="templateEditing()">{{'Finish editing' | translate}}</button>
</div>
