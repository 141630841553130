import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { RoomCategoryService } from 'src/app/shared/services/room-category.service';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { DialogRoomCategoryComponent } from '../dialog/dialog-room-category.component';
import { data } from 'jquery';

@Component({
  selector: 'app-room-category',
  templateUrl: './room-category.component.html',
  styleUrls: ['./room-category.component.scss']
})
export class RoomCategoryComponent implements OnInit {

  constructor(
    private hs: HotelService,
    private route: ActivatedRoute,
    private roomCategoryService : RoomCategoryService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,

  ) { }

  title = "Rooms Categories";
  slug   : any;
  hotel  : any;
  rooms_categories : any;
  search : any;
  searchList : any;
  room  = {name:"" , short_description:"", long_description:""}
  @Input() onglet = false;


  ngOnInit() {
    this.spinner.show('global');
    this.route.params.subscribe(params => {
      this.slug = params.slug
      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
        this.roomCategoryService.getRoomsCategories('?customer='+this.slug+'&active='+true).subscribe((res:any)=>{
          this.rooms_categories = res;
          this.searchList = res;
          this.spinner.hide('global');
        });
      });
    });

  }



  searchInList(){
    if(!this.search){
      this.searchList = this.rooms_categories;
    }else {
      this.searchList = this.rooms_categories.filter(x=> this.findText(x,'name', this.search) || this.findText(x,'short_description', this.search));
    }
  }

  findText(x,attr,  search){
    if(x[attr]){
      return x[attr].toLowerCase().indexOf(search.toLowerCase()) != -1
    }
    return false
  }

  openDialogRoom(room = {},index=null): void {

    const dialogRef = this.dialog.open(DialogRoomCategoryComponent, {
      width: '1300px',
      data : {room:{...room}, slug: this.slug, hotel: this.hotel}
    });

    dialogRef.afterClosed().subscribe(res => {
      if(res && index != null){
        this.rooms_categories[index] = res;
      } else if (res && index === null) {
        this.rooms_categories.unshift(res);
      }
    });
  }

  deleteRoomCategory(category, index){

    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name : category.name, type : "Room Category" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.roomCategoryService.deleteRoomCategory(category.id).subscribe(res=>{
          this.rooms_categories.splice(index, 1);
        })
      }
    });
  }
}
