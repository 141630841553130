<div class="container-fluid">
 
  <div class="white-box content" *ngIf="user">
    <div class="cover"></div>
    <div class="row">

      <div class="col profile">
        <div class="img-profile">
          <span class="change-img" >{{ 'Change image' | translate }}</span>
          <input class="input-file" (change)="changeImageProfile($event)" type="file">
          <img src="{{setUrl(image)}}" alt="" *ngIf="image">
        </div>
      </div>
      <div class="col">
        <div class="informations">
          <h2>{{user['first_name']}} {{user['last_name']}}</h2>
          <h3>{{user_role | translate}}</h3>
        </div>
      </div>
      
      
    </div>
    <div class="row tabs">
      <div class="col profile"></div>
      <div class="col">
        <br>
        <mat-tab-group>
          <mat-tab label="{{'My informations' | translate}}">
     
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="">{{ 'first name'| translate }}</label>
                    <input class="form-control"  [(ngModel)]="user['first_name']">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="">{{ 'last name' | translate }}</label>
                    <input class="form-control" [(ngModel)]="user['last_name']">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="">{{ 'Email' | translate }}</label>
                    <input class="form-control"[(ngModel)]="user['email']">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="">{{ 'PIN Code' | translate }}</label><br>
                    <label for="">{{user['pin_code']}}</label>
                  </div>

                  <!--<div class="form-group col-md-6" *ngIf="hotel">

                    <label>{{ 'Select Language' | translate }}</label>
                    <select [(ngModel)]="user.of_userth['language']" [(value)]="user.of_userth['language']" class="form-control">
                      <option *ngFor="let lang of languages" value="{{lang.lang_code}}">{{langs[lang.lang_code] | translate}}
                      </option>
                    </select>
                  </div>-->

        
                  <div class="form-group col-md-6">
                    <label for="">{{ 'Password' | translate }} <small>({{'leave empty to keep the same password' | translate }})</small></label>
                    <input class="form-control" type="password"  [(ngModel)]="user['password']">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="">{{ 'Confirm Password' | translate }}</label>
                    <input class="form-control" type="password" [(ngModel)]="user['confirm_password']">
                  </div>

               


                   <!--checkbox-->
                   <div class="form-group col-md-6">
                      <label class="container-checkbox">{{'Send notification email' | translate }}
                        <input type="checkbox" [(ngModel)]="user['notification']">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  <!--checkbox-->

                  <div class="form-group col-md-12 text-right">
                    <button class="btn btn-success" (click)="updateProfile(user)">{{'Update Profile' | translate }}</button>
                  </div>

                </div>

           
          </mat-tab>
         
        </mat-tab-group>
      </div>
    </div>
  </div>


</div>