import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class TramesService {

  constructor(private http: HttpClient) { }

  uri = URI+"trames/";


	list(params){
			return this.http.get(this.uri+params);
	}

  	get(id){
		return this.http.get(this.uri+id);
	}

	update(object){
		return this.http.put(this.uri+object.id+'/', object);
	}


	create(object){
		return this.http.post(this.uri, object);
	}

	sort(obj){
		console.log(obj);
		return this.http.get(`${this.uri}`, {params: obj});
	}

  	delete(id){
		return this.http.delete(this.uri+id);
	}


}
