
<h2 class="mat-dialog-title">
Crop Image {{cropperMaxWidth}}
</h2>


<mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-md-8">
        <div id="crop-media-manager">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="maintainAspectRatio"

            [aspectRatio]="data.aspectRatio? data.aspectRatio : aspectRatio"
            [containWithinAspectRatio]="false"
            [canvasRotation]="canvasRotation"
            [cropper]="CropperPosition"
            [onlyScaleDown]="true"
            [index]="index"
            [transform]="transform"
            format="jpg"
            [alignImage]="'left'"
            [hideResizeSquares]="hideResizeSquares"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"></image-cropper>
        <img [src]="base64" class="preveiw" />
         </div>

      </div>
      <div class="col-md-4">
         
        <div class="form-group">
          <label for="">Change name</label>
          <input type="text" class="form-control" [(ngModel)]="fileName" maxlength="255">
         </div>

         <!-- btns -->
         <div class="btns">
           <label for="">Actions</label>
          <button class="btn btn-default" matTooltip="Zoom out" matTooltipPosition="above" (click)="zoomOut()"><i class="fa fa-search-minus" aria-hidden="true"></i></button>
          <button class="btn btn-default" matTooltip="Zoom in" matTooltipPosition="above" (click)="zoomIn()"><i class="fa fa-search-plus" aria-hidden="true"></i></button>
          <button class="btn btn-default" matTooltip="Rotate left" matTooltipPosition="above" (click)="rotateLeft()"><i class="fa fa-undo" aria-hidden="true"></i></button>
          <button class="btn btn-default" matTooltip="Rotate right" matTooltipPosition="above" (click)="rotateRight()"><i class="fa fa-repeat" aria-hidden="true"></i></button>
          <button class="btn btn-default" matTooltip="Flip horizontal" matTooltipPosition="above" (click)="flipHorizontal()"><i class="fa fa-arrows-h" aria-hidden="true"></i></button>
          <button class="btn btn-default" matTooltip="Flip vertical" matTooltipPosition="above" (click)="flipVertical()"><i class="fa fa-arrows-v" aria-hidden="true"></i></button>
         </div>
             <!-- btns -->
         
         <div class="btns">
          <label>Width</label>
          <input type="number" [(ngModel)]="width" class="input" (change)="updateCropper($event,'w')">
          <label>Height</label>
          <input type="number" [(ngModel)]="height" class="input" (change)="updateCropper($event, 'h')">
        </div>


        
    
      
  
       </div>
    </div>
      



</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{'Cancel'| translate }}</button>
  <button mat-button  class="btn btn-success" (click)="saveImage(data.fileUploadCropper.files[index])">{{'Download without crop'| translate }}</button>
  <button mat-button (click)="saveImage()"  class="btn btn-success">{{'Crop and upload'| translate }}</button>
</mat-dialog-actions>


