import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-supervison-table-columns',
  templateUrl: './supervison-table-columns.component.html',
  styleUrls: ['./supervison-table-columns.component.scss']
})
export class SupervisonTableColumnsComponent implements OnInit {

  constructor() { }

  @Input() columns : any;
  @Input() displayedColumns : any;
  @Output() updateEvent = new EventEmitter();
  activeColumns = {};


  ngOnInit() {
    for(let i of this.displayedColumns){
      this.activeColumns[i] = true;
    }
  }

  /**
   *
   */
  updateDisplayedColumns(){
    this.displayedColumns = [];
    for(let i in this.activeColumns){
      if(this.activeColumns[i]){
        this.displayedColumns.push(i);
      }
    }
    this.updateEvent.emit(this.displayedColumns);
  }
}
