import { NotifierService } from 'angular-notifier';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { HotelinformationsService } from 'src/app/shared/services/hotelinformations.service';

import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { SocialComponent } from '../../modals/social/social.component';

@Component({
  selector: 'hotel-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent {

  constructor(
    public dialog: MatDialog,
    private hi: HotelinformationsService,
    private translate: TranslateService,
    notifierService: NotifierService
    ) {
      this.notifier = notifierService;
    }

  private readonly notifier: NotifierService;
  @Input() socials: any;
  @Input() hotel: any;
  @Input() languages: any;




  //ADD OR EDIT SOCIAL LINK
  openDialogSocial(social = null) {
    let data =  social ?  {social,socials:this.socials}  : { social :{hotel: this.hotel.id}, socials:this.socials };
    const dialogRefSocial = this.dialog.open(SocialComponent, { data: data });

    dialogRefSocial.afterClosed().subscribe(result => {
     console.log(result);
    });
  }

  //DELETE SOCIAL LINK MODAL
  deleteConfirm(object, name, type, index) {

    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name, type }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //Request Social link
        this.hi.deleteSocialLink(object.id).subscribe(() => {
          this.socials.splice(index, 1);
          this.translate.get('Link successfully deleted').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
        },
          err => console.log(err))
      }
    });

  }


}
