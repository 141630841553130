import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BASE_URL } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  uri = BASE_URL;

	constructor(private http: HttpClient) {
  }

  get(token) {
		return this.http.get(`${this.uri}`+"/api/invitation/"+ token);
	}
}
