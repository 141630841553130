import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sidebar-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit  {

  constructor(
  ) { }
 
  @Input() user;
  @Input() slug;
  @Input() menu :any;
  current_language = 'en' //localStorage.getItem('language')


  ngOnInit(){
    localStorage.getItem('language')
  }
  

  translate(item){
    if(this.current_language != "en"){
      return item['name_' + this.current_language];
    }else {
      return item.name;
    }
  }

}
