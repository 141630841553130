import { NgxSpinnerService } from 'ngx-spinner';
import { DialogErrorComponent } from 'src/app/components/modals/dialog-error/dialog-error.component';
import { DefaultValueService } from 'src/app/shared/services/default-value.service';
import { HotelService } from 'src/app/shared/services/hotel.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: "app-redirection",
  templateUrl: "./redirection.component.html",
  styleUrls: ["./redirection.component.scss"],
})
export class RedirectionComponent implements OnInit {
  constructor(
    private hs: HotelService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private dv: DefaultValueService,
    private dialog: MatDialog
  ) {}

  slug: any;
  hotel: any;
  role_status=false;
  role = localStorage["user_role"]
  
  ngOnInit() {
    this.spinner.show("global");

    this.route.params.subscribe((params) => {
      this.slug = params.slug;

     
      setTimeout(() => {
        this.dv.getDefaultValue(this.slug, "main-menu").subscribe(
          (res) => {
            this.spinner.hide("global");
            
            if (res && this.role) {
              let link = JSON.parse(res["values"]).filter(
                (x) => x.active == true
              )[0]["link"];
              this.router.navigate([link, this.slug]);
            } else {
              this.spinner.hide("global");
              this.router.navigate(['hotels'])
              this.dialog.open(DialogErrorComponent, {
                panelClass:'error-dialog',
                data: {
                  title: "Can't access to property",
                  icon: "error",
                  text: "Please check your access, if problem persist please contact Tabhotel IT Team",
                },
              });
            }
          },
          (err) => {
            this.spinner.hide("global");
            this.router.navigate(["monitoring-manager", this.slug]);
          }
        );
      }, 100);
    });
  }
}
