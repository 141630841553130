<!--form-->
<div class="row" id="parent-{{field.name}}">

    <div class="form-group col-md-6">
        <label>Label</label>
        <input type="text" class="form-control" (input)="changeEvent($event,field, 'label')"  [(ngModel)]="field['label']">
    </div>


    <!--label Class name-->
    <div class="form-group col-md-6" *ngIf="field.input_type !='hidden'">
        <label>Class name</label>
        <input type="text" class="form-control" (input)="changeEvent($event, field, 'classname')"  [(ngModel)]="field['classname']">
    </div>
    <!--label-->


    <!--label Class name-->
    <div class="form-group col-md-6">
        <label>Name</label>
        <input type="text" class="form-control" (input)="changeEvent($event,field, 'name' )"  [(ngModel)]="field['name']">
    </div>
    <!--label-->

    <!--label Description-->
    <div class="form-group col-md-6" *ngIf="field.input_type !='hidden'">
        <label>Description</label>
        <input type="text" class="form-control" (input)="changeEvent($event,field, 'description' )"  [(ngModel)]="field['description']">
    </div>
    <!--label-->

    <!--label Description-->
    <div class="form-group col-md-6" *ngIf="field.type == 'textarea'">
        <label>Rows (height of textarea)</label>
        <input type="text" class="form-control" (input)="changeEvent($event,field)"  [(ngModel)]="field['rows']">
    </div>
    <!--label-->

    <!--label Input type-->
    <div class="form-group col-md-6" *ngIf="field.input_type !='hidden'">
        <label>Width</label>
        <select (input)="changeEvent($event,field)"  [(ngModel)]="field['width']" class="form-control">
            <option value="col-md-12">100%</option>
            <option value="col-md-9">75%</option>
            <option value="col-md-8">66%</option>
            <option value="col-md-6">50%</option>
            <option value="col-md-4">33%</option>
            <option value="col-md-3">25%</option>
        </select>
    </div>
    <!--label-->

    <!--label Input type-->
    <div class="form-group col-md-6" *ngIf="field.input_type !='code_editor'">
        <label>Language</label>
        <select (input)="changeEvent($event,field)"  [(ngModel)]="field['language']" class="form-control">
            <option value="html">HTML</option>
            <option value="css">CSS</option>
            <option value="json">JSON</option>
            <option value="javascript">Javascript</option>
            <option value="sql">SQL</option>

        </select>
    </div>
    <!--label-->


    <!--label Input type-->
    <div class="form-group col-md-6" *ngIf="field.type == 'text'">
        <label>Input type</label>
        <select (input)="changeEvent($event, field)"  [(ngModel)]="field['input_type']" class="form-control">
            <option value="text">Text</option>
            <option value="hidden">Hidden</option>
            <option value="date">Date</option>
            <option value="number">Number</option>
            <option value="email">Email</option>
            <option value="color">Color</option>
            <option value="password">Password</option>
        </select>
    </div>
    <!--label-->

    <!--label default-->
    <div class="form-group col-md-6" *ngIf="field.type == 'text'">
        <label>Default value</label>
        <input type="text" class="form-control" (input)="changeEvent($event,field)"  [(ngModel)]="field['default']">
    </div>
    <!--label-->



    <!--label Html editor options-->
    <div class="form-group col-md-6" *ngIf="field.type == 'htmleditor'">
        <label>Html editor options</label>
        <select (input)="changeEvent($event,field)"  [(ngModel)]="field['htmleditor_options']" class="form-control">
            <option value="standard">Standard</option>
            <option value="minim">Minim</option>
            <option value="full">Full</option>
        </select>
    </div>
    <!--label-->

    <!--label Constant-->
    <div class="form-group col-md-6" *ngIf="field.type == 'select'">
        <label>Constant</label>
        <select (input)="changeEvent($event,field)"  [(ngModel)]="field['constant']" class="form-control">
            <option value="">No constant</option>
            <option value="{{val.value}}" *ngFor="let val of listVars">{{val.text}}</option>
        </select>
    </div>
    <!--label-->

       <!--label Constant-->
    <div class="form-group col-md-6" *ngIf="field.type == 'select'">
        <label>Custom API Values</label>
        <select (input)="changeEvent($event,field)"  [(ngModel)]="field['api']" class="form-control">
            <option value="">No API</option>
            <option value="{{val.name}}" *ngFor="let val of variablesAPI">API : {{val.name}}</option>
        </select>
    </div>
    <!--label-->

    <!--checkbox inline-->
    <div class="form-group col-md-3" *ngIf="field.type == 'radio' || field.type == 'checkbox'">
        <label class="container-checkbox">Inline
            <input type="checkbox" (input)="changeEvent($event,field)"  [(ngModel)]="field['inline']">
            <span class="checkmark"></span>
        </label>
    </div>
    <!--checkbox inline-->


    <!-- if btn -->
    <div class="form-group col-md-6" *ngIf="field.type == 'btn' && field['url']">
        <label for="">URL</label>
        <input type="number" class="form-control" (input)="changeEvent($event,field)"  [(ngModel)]="field['url']">
    </div>
    <!-- if btn -->


    <div class="form-group col-md-12" *ngIf="field.type == 'image'">
        <label class="container-checkbox">Maintain Ratio
            <input type="checkbox" (input)="changeEvent($event,field)"  [(ngModel)]="field['maintainRatio']">
            <span class="checkmark"></span>
        </label>
    </div>

    <!-- cropper width and height-->
    <div class="form-group col-md-6" *ngIf="field.type == 'image' && field['maintainRatio']">
        <label for="">Cropper width</label>
        <input type="number" class="form-control" (input)="changeEvent($event,field)"  [(ngModel)]="field['cropperWidth']">
    </div>


    <div class="form-group col-md-6" *ngIf="field.type == 'image' && field['maintainRatio']">
        <label for="">Cropper height</label>
        <input type="number" class="form-control" (input)="changeEvent($event,field)"  [(ngModel)]="field['cropperHeight']">
    </div>
    <!-- cropper width and height-->
    <!-- cropper-->


    <!--label Input role-->
    <div class="form-group col-md-6" *ngIf="field.input_type !='hidden'">
        <label>Show to (Manage role)</label>
        <select (input)="changeEvent($event,field)"  [(ngModel)]="field['role']" class="form-control">
            <option value="concierge">Concierge</option>
            <option value="administrator">Administrator</option>
            <option value="super-user">Super user</option>
        </select>
    </div>
    <!--label-->
</div>

    <div class="row" *ngIf="field.type == 'select'">
        <div class="col-12">
            <hr>
            <h3>API calls</h3>
            <!--label Constant-->
        </div>
    
        <div class="form-group col-md-6">
            <label>Endpoint URL (Exemple : 'back/hotels')</label>
            <input type="text" class="form-control" (input)="changeEvent($event,field)" [(ngModel)]="field['apiendpoint']">
    
        </div>
        <!--label-->

        <div class="form-group col-md-6">
            <label>Attributs (Exemple : 'id,name')</label>
            <input type="text" class="form-control" (input)="changeEvent($event,field)" [(ngModel)]="field['apiattributs']">
    
        </div>
        <!--label-->
    </div>


 
<div class="row" *ngIf="field.type == 'select' || field.type == 'image'">
    <!--checkbox required-->
    <div class="form-group col-md-3">
        <label class="container-checkbox">Multiple
            <input type="checkbox" (input)="changeEvent($event,field)"  [(ngModel)]="field['multiple']">
            <span class="checkmark"></span>
        </label>
    </div>
    <!--checkbox required-->
</div>
<!--form-->

<field-values [form]="form" [options]="options" [currentSection]="currentSection" [values]="field.values"
    [fieldObject]="field" *ngIf="!!!field['constant']"></field-values>


<footer>
    <div class="row">
        <div class="col-md-6">
            <label class="container-checkbox">Active
                <input type="checkbox" (input)="changeEvent($event,field)"  [(ngModel)]="field['active']">
                <span class="checkmark"></span>
            </label>

        </div>
        <!--checkbox required-->
        <div class="form-group col-md-3"
            *ngIf="field.type == 'text' || field.type == 'textarea' || field.type == 'htmleditor' || field.type =='image'">
            <label class="container-checkbox">Translatable
                <input type="checkbox" (input)="changeEvent($event,field)"  [(ngModel)]="field['translatable']">
                <span class="checkmark"></span>
            </label>
        </div>
        <!--checkbox required-->

        <!--checkbox required-->
        <div class="form-group col-md-3">
            <label class="container-checkbox">Required
                <input type="checkbox" (input)="changeEvent($event,field)"  [(ngModel)]="field['required']">
                <span class="checkmark"></span>
            </label>
        </div>
        <!--checkbox required-->
    </div>
</footer>