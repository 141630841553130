<h2 class="mat-dialog-title">
  {{(cat.id ? 'Edit Category': 'Add Category') | translate}}
</h2>

<mat-dialog-content class="mat-typography">

  <div class="row">
    <div class="form-group col-md-12">
      <label>{{'Title'| translate}} </label>
      <input type="text" class="form-control" [(ngModel)]="cat.title">
      <app-error-input *ngIf="errors" [errors]="errors" name="title"></app-error-input>
    </div>

    <div class="form-group col-md-12">
      <label>{{'Description'| translate}} </label>
      <app-html-editor [model]="cat" attr="description" [config]="'medium'" [mediaManager]="false" [extra]="'picture'">
      </app-html-editor>
      <app-error-input *ngIf="errors" [errors]="errors" name="description"></app-error-input>
    </div>

    <div class="col-lg-12 file form-group">
      <label for="">{{'Image'| translate}}</label>
      <upload-input [model]="cat" [fb]="fb" [attr]="'image'" [media]="MEDIA"></upload-input>
      <app-error-input *ngIf="errors" [errors]="errors" name="image"></app-error-input>
    </div>

  </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'Cancel' | translate}}</button>
  <button mat-button (click)="addCategory(cat)"  class="btn btn-success">{{'Confirm' | translate}}</button>
</mat-dialog-actions>