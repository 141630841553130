import { NgxSpinnerService } from 'ngx-spinner';
import { DeeplTransService } from 'src/app/shared/services/deepl-trans.service';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { WorkflowService } from 'src/app/shared/services/workflow.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-translate-manager',
  templateUrl: './translate-manager.component.html',
  styleUrls: ['./translate-manager.component.scss']
})
export class TranslateManagerComponent implements OnInit {

  constructor(
    private hs: HotelService,
    private router: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private deepl : DeeplTransService,
    private workflowService : WorkflowService
  ) { }

  slug : any;
  hotel : any;
  texts = [{label:''}];
  langs = ['IT', 'ES', 'FR', 'DE'];
  translated :any;

  ngOnInit() {
    this.spinner.show('global');

    this.router.params.subscribe(params => {
      this.slug = params.slug;
      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
        this.spinner.hide('global');

      });
    });
  }

  addNew(){
    this.texts.push({label:''})
  }

  getTexts(){
    let arr = [];
    for(let txt of this.texts){
      arr.push(txt.label);
    }
    return arr
  }

  translate(){
    this.translated =  {'IT':[], 'ES':[], 'FR':[], 'DE':[]};
    this.spinner.show('global');
    for(let lang of this.langs){
      let obj ={
        texts : this.getTexts(),
        lang :lang.toLowerCase(),
        lang_src: 'en'
      }

      this.workflowService.translate(obj).subscribe(res=>{
        this.translated[lang] = res['translations'];
        this.spinner.hide('global');
      })
    }

  }



}
