import * as $ from 'jquery';
import { copyObject } from 'src/app/helpers/commun';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { ConfirmDeleteComponent } from '../../components/modals/confirm-delete/confirm-delete.component';
import { DefaultValueService } from '../../shared/services/default-value.service';
import { HotelService } from '../../shared/services/hotel.service';
import { SignageService } from '../../shared/services/signage.service';
import { _checkbox } from './build/field-constructor/configs/_checkbox';
import { _code_editor, _htmleditor } from './build/field-constructor/configs/_htmleditor';
import { _image } from './build/field-constructor/configs/_image';
import { _boolean, _radio } from './build/field-constructor/configs/_radio';
import { _select, _tags } from './build/field-constructor/configs/_select';
import { _separator } from './build/field-constructor/configs/_sep';
import { _slider } from './build/field-constructor/configs/_slider';
import { _text } from './build/field-constructor/configs/_text';
import { _textarea } from './build/field-constructor/configs/_textarea';
import { fields } from './build/field-constructor/configs/fields';
import { DialogDefaultValuesComponent } from './dialogs/dialog-default-values/dialog-default-values.component';
import { _btn, _title } from './build/field-constructor/configs/_commun';

@Component({
  selector: 'app-form-constructor',
  templateUrl: './form-constructor.component.html',
  styleUrls: ['./form-constructor.component.scss']
})


export class FormConstructorComponent implements OnInit {

  constructor(
    private hs: HotelService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private signage: SignageService,
    private dv: DefaultValueService  ) {
  }

  title = "Form Builder";
  @Input() hotel: any;
  languages: any;
  slug: any;
  @Input() form = { params: { autoTranslate: false }, sections: [{sectionName:"Section 1", role:"concierge", is_array : false, fields:[]}] };
  newForm = false;
  field_type: any;
  fields_config = [];
  fields = fields;
  display_id: any;
  customVars: any;
  display: any;
  activePreview = false;
  topPosition = "0px"
  defaultValues: any;
  deviceParamId = null;
  deviceParam: any;
  _headerId = '#action-collapse-';
  interval: any;
  automaticTrans = false;
  @Input() _breadCrumbs = [];
  @Input() variablesAPI = [];
  debug = false;
  activeSection = 0;
  @Output() saveEvent = new EventEmitter();
  @Output() addFieldEvent = new EventEmitter();
  @Output() deleteFieldEvent = new EventEmitter();
  @Output() changeFieldEvent = new EventEmitter();
  response = {};

  ngOnInit() {
    if(typeof this.form == "undefined"){
      this.form = { params: { autoTranslate: false}, sections: [{sectionName:"Section 1", role:"concierge" , is_array : false, fields:[]}] };
    }
    this.form.sections.map(y=>{
      y.role = y.role == undefined ?  "concierge" : y.role
    })
    this.hs.getLanguages().subscribe((data) => {
      this.languages = data
    })

    this.route.params.subscribe(params => {
      this.slug = params.slug;



      //GET DEFAULT VALUE
      this.dv.getDefaultValue(this.slug, 'default-value').subscribe(res => {
        if (res) {
          this.defaultValues = res;
          this.defaultValues['values'] = JSON.parse(this.defaultValues['values']);
        }
      }, () => {
        this.defaultValues = null;
      });


      //GET ALL VARIABLE
      this.signage.getCustomVars(this.slug).subscribe(res => {
        this.customVars = res;
      });

    });


    //AUTOMATIC UPDATE FORM JSON
    this.interval = setInterval(() => {
      //this.saveForm();
    }, 60 * 1000)




    //DEFAULT JSON BY FIELD
    this.fields_config['checkbox'] = _checkbox;
    this.fields_config['radio'] = _radio;
    this.fields_config['textarea'] = _textarea;
    this.fields_config['text'] = _text;
    this.fields_config['select'] = _select;
    this.fields_config['image'] = _image;
    this.fields_config['htmleditor'] = _htmleditor;
    this.fields_config['separator'] = _separator;
    this.fields_config['slider'] = _slider;
    this.fields_config['boolean'] = _boolean;
    this.fields_config['tags'] = _tags;
    this.fields_config['code_editor'] = _code_editor;
    this.fields_config['btn'] = _btn;
    this.fields_config['title'] = _title;


  }

  /**
   *
   * @param json
   */
  convertJson() {
    // this.form.sections[this.activeSection] = {sectionName:"Section 1", fields:[]};
    let json = this.deviceParamId ? this.deviceParam['INPUTS'] : this.display['INPUTS'];
    let source = { 'imageInputField': 'image', 'CheckBoxField': 'checkbox', 'CharField': 'text', 'EditorHTML': 'htmleditor' }

    for (let item of json.split("\n")) {
      let setting = item.split("|");
      let placeholder = setting[3]
      let label = setting[2].split('_').join(' ');
      label = label.charAt(0).toUpperCase() + label.substring(1);;
      let name = setting[0].trim();
      let type = source[(setting[1]).split(':')[0]];
      if (type) {
        this.addNewField(type, label, name, placeholder)
      } else {
        console.log(type, setting[1])
      }
    }
    // if (this.form.sections[this.activeSection].length == json.split("\n").length) {
    //   alert('conversion done')
    // } else {
    //   alert('conversion not completed')
    // }
  }


  /**
   *
   */
  ngOnDestroy() {
    clearInterval(this.interval)
  }

  /**
   * ACTIVATE/DISABLE DEBUG
   */
  debugForm() {
    this.debug = !this.debug;
  }

  setAutomaticTrans() {
      this.form.params.autoTranslate = !this.form.params.autoTranslate;
  }

  addNewSection() {
    let length = this.form.sections.length
    this.form.sections.push({sectionName:"Section " + (length+1), role:"concierge", is_array : false, fields:[]});
    this.activeSection = length
  }

  /**
   *
   * @param index
   */
  setActiveSection(index){
    this.activeSection = index
  }

  /**
   *
   * @param field
   */
  cloneField(field, section, index) {

    this.form.sections[section].fields.splice(index + 1, 0, {
      ...copyObject(field),
      name: 'copy_' + field.name,
      label: 'copy ' + field.label
    });

  }

  updateForm($event){
    this.form = null;
    setTimeout(()=>{
      this.form = $event
    },10)
  }

  /**
   *
   * @param section
   * @param j
   */
  useSectionAsArray(section){
    section.is_array = section.is_array != undefined ?  !section.is_array : true
  }

  /**
   *
   * @param section
   * @param j
   */
  cloneSelction(section, j){
    this.form.sections.splice(j + 1, 0, {
      ...copyObject(section),
      sectionName: 'Copy ' + section.sectionName,
    });
  }


  /**
   * ADD NEW FIELD IN FORM
   */
  addNewField(type, label = null, name = null, placeholder = null) {
    console.log(this.fields_config[type] ,type)
    let newObj = {
      ...copyObject(this.fields_config[type]),
      label: label ? label : copyObject(this.fields_config[type]).type_name,
      name: name ? name : type + new Date().valueOf(),
      id : 'field_'+new Date().valueOf(),
      placeholder: placeholder ? placeholder : ""
    }
    if (type == "select") {
      newObj = {
        ...copyObject(newObj),
        constant: null,
      }
    }

    this.form.sections[this.activeSection].fields.push(newObj);

    this.headerEvent(newObj.name)

    triggerClick(this._headerId + newObj.name);

    document.body.scrollTop = 10000;

    this.addFieldEvent.emit(newObj)

  }

  /**
   * PREVIEW FORM
   */
  activePreviewForm() {
    if (!this.activePreview) {
      this.topPosition = '0px';
    }
    this.activePreview = !this.activePreview;
  }



  /**
   * DRAGE AND DROP EVENT
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }  }

  /**
   *
   * @param $event
   */
  headerEvent(name) {
    setTimeout(() => {
      this.topPosition = updatePosition(this._headerId + name)
    }, 500)
  }

  /**
   * DELETE FIELD BY INDEX i
   * @param field
   * @param i
   */
  deleteField(field, i, j) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { type: "field", name: field.label, languages: this.languages }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'delete') {
        this.deleteFieldEvent.emit(field);
        this.form.sections[j].fields.splice(i, 1);
      }
    });
  }

  /**
   * SAVE FORM
   */
  saveForm() {
    this.saveEvent.emit(true);
  }

  onChangeFieldEvent(event){
    this.changeFieldEvent.emit(event)
  }

  /**
   * Add default value
   * @param field
   * @param i
   */
  addToDefaultValues(field) {
    // const dialogRef = this.dialog.open(FormProgComponent, {
    //   data: {
    //     form: JSON.stringify([field]),
    //     name: field.label,
    //     languages: this.languages,
    //     hotel: this.hotel,
    //     program: { 'INPUTS_VALUES': '' },
    //     defaultValues: {},
    //     btnDv: false,
    //   }, width: '800px'
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   this.addOrCreateDefaultValue(result)
    // });
  }

  /**
   *
   * @param result
   */
  addOrCreateDefaultValue(result) {
    if (typeof (result) == "object" && !!result) {
      let key = Object.keys(result)[0];
      let value = result[key];
      if (!this.defaultValues) {
        let data = {
          hotel: this.slug,
          name: "default-value",
          values: this.addOrNewValue(key, value),
        }
        this.dv.createDefaultValue(data).subscribe(res => {
          this.defaultValues = res;
          this.defaultValues['values'] = JSON.parse(this.defaultValues['values']);
        })
      } else {
        this.defaultValues['values'] = this.addOrNewValue(key, value);
        this.dv.updateDefaultValue(this.defaultValues).subscribe(res => {
          this.defaultValues = res;
          this.defaultValues['values'] = JSON.parse(this.defaultValues['values']);
        })
      }
    }
  }
  /**
   *
   * @param key
   * @param value
   */
  addOrNewValue(key, value) {
    let temp = {};
    if (this.defaultValues['values']) {
      temp = this.defaultValues['values'];
    }
    temp[key] = value;
    return JSON.stringify(temp);

  }

  /**
   *
   */
  dialogDefaultValues() {
    const dialogRef = this.dialog.open(DialogDefaultValuesComponent, {
      data: this.defaultValues, width: '800px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.addOrCreateDefaultValue(result['values'])
    });
  }

  trim(text) {
    return text.split('.').join('-').trim();
  }


  /**
   *
   * @param section
   * @param i
   */
  deleteSection(section, i){

    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {data:{
      type : '', name : section.sectionName
    }});
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.form.sections.splice(i, 1);
      }
    })
  }



}


/**
 *
 * @param el
 */
function triggerClick(el) {
  setTimeout(() => {
    $(el).trigger('click');
  }, 10)
}

function updatePosition(el) {
  let val = $(el).offset().top - 210;
  return ((val > 0) ? (val + 'px') : "0px")
}
