<div class="row">
  <div class="col-md-8">
    <h2 class="mat-dialog-title">Manage Sections</h2>
  </div>
  <div class="col-md-4 text-right">
    <button class="btn btn-info btn-sm" (click)="addSection()"> Add Section</button>
  </div>
</div>

<mat-dialog-content class="mat-typography">

  <!-- ACCORDION -->
  <div class="accordion" id="accordion" cdkDropList (cdkDropListDropped)="drop($event)">

    <!-- CARD -->
    <div class="card" *ngFor="let tab of data.tabs.configs; let index =index" cdkDrag [cdkDragData]="data.tabs.configs">
      <div class="custom-placeholder" *cdkDragPlaceholder></div>

      <!-- CARD HEADER -->
      <div class="card-header" id="heading-{{index}}">
        <div class="row flex-vertical tab">
          <div class="col-md-4 collapsed action-collapse" [attr.data-toggle]="'collapse'"
            [attr.data-target]="'#collapse-'+index" [attr.aria-expanded]="'false'"
            [attr.aria-controls]="'collapse-'+index">
            <h3>
              <i class="fa fa-plus" aria-hidden="true"></i>
              {{tab.title}}
              <span class="status" [ngClass]="{disabled: !tab.visible }"></span>
            </h3>
          </div>


          <div class="col text-right">
            <mat-slide-toggle [(ngModel)]="tab.active"></mat-slide-toggle>


            <button class="btn" (click)="deleteSection(tab, index)">
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </button>

            <button class="btn" (click)="duplicate(tab)">
              <i class="fa fa-files-o" aria-hidden="true"></i>
            </button>

            <button cdkDragHandle class="btn">
              <i class="fa fa-arrows" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- ./CARD HEADER -->

      <!-- CARD BODY -->
      <div id="collapse-{{index}}" class="collapse" [attr.aria-labelledby]="'heading-'+index" data-parent="#accordion">
        <div class="card-body">

          <div class="row">
            <!-- title -->
            <div class="form-group col-md-6">
              <label>Section title</label>
              <input type="text" [(ngModel)]="tab.title" class="form-control">
            </div>
            <!-- title -->


            <!-- detail dialog  -->
            <div class="form-group col-md-6">
              <label>Detail dialog</label>
              <select class="form-control" [(ngModel)]="tab.detail">
                <option value="">Empty</option>
                <option value="room-details">Dialog property workflow detail</option>
              </select>
            </div>
            <!-- detail dialog  -->

          </div>


          <!-- CUSTOM BUTTON  -->
          <h4>Add custom buttons</h4>
          <p>Manage buttons API to open dialog detail, you can use any attribute listed in API like <span
              class="variable">ID_PROCESS</span> or <span class="variable">hotel</span> variable</p>
         
          <ng-container *ngFor="let btnApi of tab.customBtnsParams; let j = index">
         
          <table class="table table-bordered" >
         
            <tbody>
              <tr *ngIf="btnApi.text">
               <td>General</td>
                <td><input type="text" [(ngModel)]="btnApi.endpoint" class="form-control" placeholder="Text"></td>
                <td><input type="text" [(ngModel)]="btnApi.text" class="form-control" placeholder="Text"></td>
                <td><input type="text" [(ngModel)]="btnApi.icon" class="form-control" placeholder="Icon"></td>                
                <td>
                  <select class="form-control" [(ngModel)]="btnApi.role">
                    <option value="super-user">Super user</option>
                    <option value="adminsitrator">Adminsitrator</option>
                    <option value="concierge">Concierge</option>
                  </select>
                </td>
                <td>
                  <button class="btn btn-sm btn-danger" (click)="delete(tab, j, 'customBtnsParams')">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              <tr *ngFor="let param of btnApi.apiParams; let i = index">
                <td>Param {{i+1}}</td>
                <td colspan="2"><input type="text" [(ngModel)]="param.key" class="form-control" placeholder="Key"></td>
                <td colspan="2"><input type="text" [(ngModel)]="param.value" class="form-control" placeholder="Value"></td>               
                <td>
                  <button class="btn btn-sm btn-danger" (click)="delete(btnApi, i, 'apiParams')">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <button class="btn btn-info btn-sm" (click)="addParamApi(btnApi.apiParams)">Add api param</button>
        </ng-container>

          <button class="btn btn-info btn-sm" (click)="addParamsBtns(tab)">Add new button</button>
          <br>
          <br>
          <!-- ./CUSTOM BUTTON  -->


          <!-- API TABLE -->
          <h4>API parameters </h4>
          <p>You can manage API parameters using <span class="variable">hotel</span> as variable</p>
          <!--body-->
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>#</td>
                <td>Key</td>
                <td>Value</td>
                <td>Delete</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let param of tab.paramsUrl; let i = index">
                <td>Param : {{i+1}}</td>
                <td><input type="text" [(ngModel)]="param.key" class="form-control"></td>
                <td><input type="text" [(ngModel)]="param.value" class="form-control"></td>
                <td><button class="btn btn-sm btn-danger" (click)="delete(tab, i)"><i class="fa fa-trash"></i></button>
                </td>
              </tr>
            </tbody>

          </table>

     
          <button class="btn btn-info btn-sm" (click)="addParam(tab)"> Add param</button>
          <!-- API TABLE -->
      
          <br>
          <br>


          <h4>API filter </h4>
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>#</td>
                <td>Key</td>
                <td>Value</td>
                <td>Delete</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let param of tab.config_filter; let i = index">
                <td>Param : {{i+1}}</td>
                <td><input type="text" [(ngModel)]="param.key" class="form-control"></td>
                <td><input type="text" [(ngModel)]="param.value" class="form-control"></td>
                <td><button class="btn btn-sm btn-danger" (click)="delete(tab, i, 'config_filter')"><i class="fa fa-trash"></i></button>
                </td>
              </tr>
            </tbody>

          </table> 
          <button class="btn btn-sm n btn-info" (click)="addFilter(index)">Add filter</button>




        </div>
      </div>
      <!-- ./CARD BODY -->
    </div>

  </div>



</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close="">{{ 'Cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="data.tabs" class="btn btn-success">Confirm</button>
</mat-dialog-actions>