import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../shared/services/user.service';
import { MEDIA } from '../../shared/settings/global';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.scss']
})
export class HotelsComponent implements OnInit {

  constructor(
    private user : UserService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  hotels : any;
  searchList : any;
  id = localStorage.getItem('id');
  search : any;
  link = '';
  version = environment.version;
  counter(i) {
      return new Array(parseInt(i));
  }
  ngOnInit() {

    // localStorage.setItem('user_role', 'concierge');
      this.spinner.show('global');
      this.user.get(this.id).subscribe((res:any) =>{
        this.spinner.hide('global');

        localStorage.setItem('user_role', res.role);
        if(res.id){
          this.hotels = res.related_hotels;
          this.searchList = res.related_hotels;
          this.hotels.map(e => {e.logo = e.logo.indexOf('media') == -1 ? MEDIA+'/media/'+e.logo : MEDIA+e.logo});
        }else {
          localStorage.clear()
          this.router.navigate(['/login']);
        }
    })

  }

  /**
   * SEARCH
   */
  searchForCustomer(){
    if(!this.search){
      this.searchList = this.hotels;
    }else {
      this.searchList = this.hotels.filter(x=>x.name_hotel.toLowerCase().indexOf(this.search.toLowerCase()) !=-1);
    }
  }

}
