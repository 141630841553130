

export const CATEGORY_TYPE = {
    "RoomService": "Room Service",
    "Activity": "Activity",
    "MapPosition": "Map Position",
    "Transport": "Transport",
    "Upsell": "Upsell",
}

export const EXTRA_OBJECT = {
    "RoomService":"room_service",
    "MapPosition":"map_position",
    "UpsellService":"room_service",
    "Transport":"transport"
}

export const WEEK = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
export const FULLWEEK = ["MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"];
export const MONTHS = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];


export const LANGUAGES = {
    'en': 'English',
    'fr': 'French',
    'fi': 'Finnish',
    'es': 'Spanish',
    'it': 'Italien',
    'de': 'Deutsch',
    'km': 'Khmer',
    'ar':'Arabe',
    'nb':'Norwegian'
}



export const PERIODS = [
    { value: "TODAY", name: 'Today' },
    { value: "YESTERDAY", name: 'Yesterday' },
    { value: "1_HOURS", name: '1 hour' },
    { value: "4_HOURS", name: '4 hours' },
    { value: "8_HOURS", name: '8 hours' },
    { value: "1_DAYS", name: '1 Day' },
    { value: "2_DAYS", name: '2 Days' },
    { value: "3_DAYS", name: '3 Days' },
    { value: "3_DAYS_AGO", name: '3 Days ago' },
    { value: "4_DAYS", name: '4 Days' },
    { value: "4_DAYS_AGO", name: '4 Days ago' },
    { value: "5_DAYS", name: '5 Days' },
    { value: "5_DAYS_AGO", name: '5 Days ago' },
    { value: "6_DAYS", name: '6 Days' },
    { value: "6_DAYS_AGO", name: '6 Days ago' },
    { value: "7_DAYS", name: '7 Days' },
    { value: "7_DAYS_AGO", name: '7 Days ago' },
    { value: "THIS_WEEK", name: 'This week' },
    { value: "LAST_WEEK", name: 'Last week' },
    { value: "THIS_MONTH", name: 'This month' },
    { value: "LAST_MONTH", name: 'Last month' },
    { value: "THIS_YEAR", name: 'This year' },
    { value: "LAST_YEAR", name: 'This year' },
    { value: "ALL", name: 'All' },
  ]


  export const  EXPORT_NOTIF_TYPES = [
    {value: "audio", viewValue: "Audio"},
    {value: "mail", viewValue: "Mail"},
    {value: "text", viewValue: "Text"},
    // {value: 'sms', viewValue: 'SMS'}
  ];