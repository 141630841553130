import { NotifierService } from 'angular-notifier';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { SliderService } from 'src/app/shared/services/slider.service';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { MEDIA } from '../../../../shared/settings/global';
import { EditSliderComponent } from '../edit-slider/edit-slider.component';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SliderComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private sl: SliderService,
    notifierService: NotifierService,
    private translate: TranslateService,
  ) {
    this.notifier = notifierService;
  }

  private readonly notifier: NotifierService;

  sliders: any;
  languages: any;
  edit = true;
  slider = {};
  MEDIA = MEDIA;
  size = "";
  errors = null;




  ngOnInit() {
    this.sliders = this.data.sliders;
    //this.edit = this.data.edit;
    this.languages = this.data.languages;
  }

  /**
   * EDIT SLIDER MODAL
   * @param slider
   */
  editSlider(slider = null) {
    //if new one
    if (!slider) {
      slider = { hotel: this.data.hotel.id, ranked: 0 ,first_image : ''}
    }
    let data = { slider, hotel: this.data.hotel, languages: this.languages, sliders: this.sliders };
    const dialogRefEdit = this.dialog.open(EditSliderComponent, { data: data });
  }

  /**
   * BUILD URL
   * @param src
   */
  buildUrl(src) {
    if (src && src.search('http') === -1) {
      return this.MEDIA + src;
    } else {
      return src;
    }
  }


  /**
   * DELETE MODAL CONFIRM
   * @param slider
   * @param index
   */
  deleteConfirm(slider, index) {
    let type = "Slider";
    let name = slider.first_description
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name, type }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //Request Information
        this.sl.deleteSlider(slider.id).subscribe(res => {
          this.sliders.splice(index, 1);
          this.translate.get('Slider successfully deleted').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
        },
          err => console.log(err))
      }
    });

  }

 /**
 * DRAG AND DROP EVENT
 * @param event
 */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data.sliders, event.previousIndex, event.currentIndex);
    let obj = {
      sliders: this.data.sliders.map(a => a.id),
    }
    this.sl.sortSlider(obj).subscribe(
      res => {
        this.translate.get('Slider order successfully updated').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
      },
      err => {
        this.translate.get('An error has occurred').subscribe(
          value => {
            this.notifier.notify('error', value);
          }
        );
      })

  }

}
