<ng-container *ngIf="value !== ''">
  <!-- BTN DETAIL -->
  <button class="btn btn-default btn-xs"  (click)="openDetail(value)" *ngIf="value.length>60 && !has(attr, '$')">
    <i class="material-icons">assistant</i> {{getValBtn(value)}}...
  </button>
  <!-- BTN DETAIL -->

  
  <!-- ICON TRUE -->
  <div class="text-center" *ngIf="value === true">
    <i class="material-icons" style="color:rgb(40, 161, 40)">check_circle</i>
  </div>
  <!-- ICON TRUE -->


  <!-- ADD COLOR TO SOME VALUES  -->
  <span class="btn btn-xs btn-success" *ngIf="value == 'Confirmed' || value == 'Started'">
    {{value}}
  </span>
  <!-- ADD COLOR TO SOME VALUES  -->

  <!-- DISPLAY FLAGS  -->
  <div class="text-center" *ngIf="value && (attr == 'Country' || attr == 'Nationality') && value.length == 2 && value != 'ND'">
    <img src="https://flagcdn.com/w20/{{value | lowercase}}.png">
  </div>
  
  <div class="text-center" *ngIf="value && (attr == 'Country' || attr == 'Nationality') && value.length > 2 && value != 'ND'">
    {{value}}
  </div>
  <!-- DISPLAY FLAGS  -->

  <!-- OTHER TEXT -->

  <ng-container *ngIf="display()">{{value}}</ng-container>
  <!-- OTHER TEXT -->

  <!-- OPEN DIALOG STEPS -->
  <button class="btn btn-xs btn-default" *ngIf="attr == '_info_steps'" (click)="openTimeLine(value)">
    <i class="material-icons">add_circle</i>{{'See details' | translate}}
  </button>

  <!-- OPEN DIALOG STEPS -->


  <span *ngIf="has(attr, '$')" class="btn-default btn-xs" (click)="openDialogHTML(value)">Preview content</span>

</ng-container>

<!-- ICON FALSE -->
<div class="text-center" *ngIf="value === false">
  <i class="material-icons"  style="color:rgb(155, 42, 42)">block</i>
</div>
<!-- ICON FALSE -->

