<table class="table table-bordered">
  <thead>
    <tr>
      <th>Label</th>
      <th>Field name / Field path</th>
      <th>Active</th>
      <th>Delete</th>
    </tr>
  </thead>
  <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
    <ng-container *ngFor="let field of module.configs.header_table;let i = index">
      <tr cdkDrag>
        <td>
          <ng-container *ngIf="field?.type != 'display'">
            {{field.label}}

          </ng-container>
          <ng-container *ngIf="field?.type == 'display'">
            <input type="text" [(ngModel)]="field.label" class="form-control">
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="field?.type != 'display'">
            {{field.name}}
          </ng-container>
          <ng-container *ngIf="field?.type == 'display'">
            <input type="text" [(ngModel)]="field.name" class="form-control">
          </ng-container>
        </td>
        <td>
          <mat-checkbox [(ngModel)]="field.active">Active</mat-checkbox>
        </td>
        <td>
          <button class="btn btn-sm btn-danger" (click)="delete(i)">Delete</button>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<button class="btn btn-xs btn-info" (click)="addDisplayValue()">Add display value</button>