<h1 class="mat-dialog-title">
    A new version is available now
</h1>
<div mat-dialog-content>

    To have the new version {{data.version}} please refresh the page
<br>
</div>
<div mat-dialog-actions align=end>
    <button class="btn btn-warning" [mat-dialog-close]="">{{'Refresh later' | translate}}</button>
    <a [href]="currentURL" class="btn btn-success">{{'Refresh now' | translate}}</a>
</div>