import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class HotelinformationsService {

	constructor(private http: HttpClient) { }

  	uri = URI;
	/**
	 *
	 * @param id
	 */
  	getInformations(id){
		return this.http.get(`${this.uri}`+"hotel-informations/?hotel="+id);
	}

	/**
	 *
	 * @param id
	 */
	getSocialLinks(id){
		return this.http.get(`${this.uri}`+"socials/?hotel="+id);
	}

	/**
	 *
	 * @param id
	 */
	deleteSocialLink(id){
		return this.http.delete(`${this.uri}`+"socials/"+id+"/");
	}

	/**
	 *
	 * @param id
	 */
	deleteInformation(id){
		return this.http.delete(`${this.uri}`+"hotel-informations/"+id+"/");
	}

	/**
	 *
	 * @param social
	 */
	createSocialLink(social){
		return this.http.post(`${this.uri}`+"socials/", social);
	}

	/**
	 *
	 * @param social
	 */
	updateSocialLink(social){
		return this.http.put(`${this.uri}`+"socials/"+social.id+"/", social)
	}
	/**
	 *
	 * @param information
	 */
	createInformation(information){
		return this.http.post(`${this.uri}`+"hotel-informations/", information);
	}

	/**
	 *
	 * @param id
	 * @param information
	 */
	updateInformation(id, information){
		return this.http.put(`${this.uri}`+"hotel-informations/"+id+"/", information);
	}

	/**
	 *
	 */
	getIcons(){
		return this.http.get(`${this.uri}`+"list-icons/fa");
	}

	/**
	 *
	 * @param icon
	 */
	addIcon(icon){
		return this.http.post(this.uri + "icons/", icon)
	}

	/**
	 *
	 * @param type : svg or fa
	 */
	getImageIcons(type = "svg"){
		return this.http.get(`${this.uri}`+"list-icons/"+type);
	}

}
