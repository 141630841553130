<h1 class="mat-dialog-title">
  {{'Order Header' | translate}}</h1>
<div mat-dialog-content>
  <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of data" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      {{item}}
    </div>
  </div>

</div>
<div mat-dialog-actions align=end>
  <button class="btn btn-success" [mat-dialog-close]="data" >{{'OK' | translate}}</button>
</div>