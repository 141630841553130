
<h1 class="mat-dialog-title">{{'Module to add' | translate}} </h1>
<div mat-dialog-content>
  <ng-container>
    <mat-select [(ngModel)]="module" >
      <ng-container *ngFor="let menu of data.menuItems">
        <mat-option [value]="menu">{{menu.name | translate}}</mat-option>
        <ng-container *ngFor="let submenu of menu.submenu">
          <mat-option [value]="submenu">-- {{submenu.name | translate}}</mat-option>
        </ng-container>
      </ng-container>


      <mat-option [value]="" disabled>Module Manager</mat-option>

      <ng-container *ngFor="let module of data.modules">
          <mat-option [value]="getMenuParamsFromModule(module)">Module Manager : {{module.name | translate}}</mat-option>
      </ng-container>
    </mat-select>
  </ng-container>
</div>
<div mat-dialog-action  align="end">
  <button class="btn btn-default" [mat-dialog-close]="" >{{'No Thanks' | translate}}</button>
  <button class="btn btn-danger" [mat-dialog-close]="module"  class="btn btn-success">{{'Confirm' | translate}}</button>
</div>