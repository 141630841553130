import { copyObject, objectToArray } from 'src/app/helpers/commun';
import { ModuleManagerService } from 'src/app/shared/services/module-manager.service';

import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-supervision-load-api',
  templateUrl: './dialog-supervision-load-api.component.html',
  styleUrls: ['./dialog-supervision-load-api.component.scss']
})
export class DialogSupervisionLoadApiComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSupervisionLoadApiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private moduleService:ModuleManagerService
    )
  {}

  headers = [];
  rows ;
  isLoading = true;
  search = "";
  searchedData;

  ngOnInit() {
    console.log(this.data)
    this.request()
  }


  /**
   *
   */
  request(){
    let httpParams = new HttpParams();
    let paramString = "";
    let params = copyObject(this.data.btn.apiParams);
    for (let param of params){

      param['value'] = param['value'].replace('{{hotel}}', this.data.hotel.id);
      for(let attr in this.data.element){
        param['value'] = param['value'].replace('{{'+attr+'}}', this.data.element[attr])
      }

      httpParams = httpParams.append(param['key'], param['value']);
    }

    paramString = httpParams.toString();
    console.log(paramString)

    this.moduleService.api(this.data.btn.endpoint+'?'+paramString).subscribe(res=>{
      this.rows = objectToArray(res['data']);
      this.searchedData = objectToArray(res['data']);
      this.getHeaders()
      this.isLoading = false
    })
  }


  searchItem(){

    if(this.search.length){
      this.rows = this.rows.filter((obj)=>{
          return JSON.stringify(obj).toLowerCase().indexOf(this.search.toLowerCase()) != -1
      });
    }else {
      this.rows = this.searchedData;

    }
  }

  isobject(obj){
    console.log(typeof(obj))
    return typeof(obj) == 'object';
  }

  /**
   *
   */
  getHeaders(){
    if( this.rows[0] != undefined){
      this.headers = Object.keys(this.rows[0]).filter(x=> x.startsWith('_') == false )
    }
  }

  /**
   *
   * @param attr
   */
  getName(attr){
    return attr.split('json_').join('').split('_').join(' ')
  }


  /**
   *
   * @param word
   * @param key
   */
  startsWith(word, key){
    return word.startsWith(key)
  }





}
