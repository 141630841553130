<div class="alert alert-info">
  You can create varibale fetched from API, you can use  &#123; &#123;hotel&#125; &#125; as variables in your url
</div>
<div class="row" *ngFor="let variable of module.configs.variables; let i = index">
    <div class="form-group col-md-3">
      <label>Variable name</label>
      <input type="text" [(ngModel)]="variable.name" class="form-control">
    </div>
    <div class="form-group col-md-5">
      <label>Get data from</label>
      <input type="text" [(ngModel)]="variable.api" class="form-control">
    </div>
    <div class="form-group col-md-3">
      <label>Variable keys</label>
      <input type="text" [(ngModel)]="variable.keys" class="form-control" placeholder="id, name">
    </div>
    <div class="col-md-1">
      <button class="mrgt-30 btn btn-sm btn-danger" (click)="delete(i)">
        <i class="fa fa-trash"></i>
      </button>
    </div>
</div>
<button class="btn btn-info" (click)="addVariable()">Add variable</button>