import { NgxSpinnerService } from 'ngx-spinner';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DefaultValueService } from '../../shared/services/default-value.service';
import { HotelService } from '../../shared/services/hotel.service';
import { server } from '../../shared/settings/global';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  constructor(
    private hs : HotelService,
    private router : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _router : Router,
    private defaultConfig : DefaultValueService
  ) { }

  url : any;
  slug :  any;
  hotel : any;
  dashboard = [];
  languages : any;

  /**
   *
   */
  ngOnInit() {
    this.getBaseUrl();
    this.spinner.show('global');

    this.router.params.subscribe(params => {
      if(params.slug =="undefined"){
          this._router.navigate(['/hotels'])
      }
      this.slug = params.slug;

      this.hs.getHotel(this.slug).subscribe((data) => {
          this.hotel = data;
      });

      this.hs.getLanguages().subscribe((data) => {
        this.languages = data
      })

  });
  }



  /**
   *
   */
  getBaseUrl(){
    this.url = localStorage.getItem('_baseUrlApi');
    if(!this.url){
      this.url = server;
    }
  }


  /**
   *
   */
  updateUrl(){
    if(this.url){
      localStorage.setItem('_baseUrlApi',this.url);
      window.location.reload();
    }
  }




}


