import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-room-details',
  templateUrl: './dialog-room-details.component.html',
  styleUrls: ['./dialog-room-details.component.scss']
})
export class DialogRoomDetailsComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogRoomDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog)
  {}


  ngOnInit() {
  }

}
