
<!-- HIGHLIGHT -->
<ng-container  *ngIf="template?.display_type == 'highlights' && data">
    <app-stat-highlights [data]="data"  [tile]="tile" [template]="template"></app-stat-highlights>
</ng-container>
<!-- HIGHLIGHT -->

<!-- DONUTS -->
<ng-container *ngIf="template?.display_type == 'donuts' && data" >
    <app-stat-donuts [data]="data" [tile]="tile" [template]="template" (actionEvent)="actions($event)"></app-stat-donuts>
</ng-container>
<!-- DONUTS -->

<!-- CHART -->
<ng-container *ngIf="template?.display_type == 'charts' && data" >
    <stat-chart [data]="data" [tile]="tile" [template]="template" (actionEvent)="actions($event)"></stat-chart>
</ng-container>
<!-- CHART -->

<!-- PMS -->
<ng-container *ngIf="template?.display_type == 'pms' && data">
    <app-stat-pms [template]="template" [data]="data"></app-stat-pms>
</ng-container>
<!-- PMS -->

<!-- MONITORING -->
<ng-container *ngIf="template?.display_type == 'supervision' && data" >
    <app-stat-supervision [tile]="tile" [data]="data" [template]="template" (actionEvent)="actions($event)"></app-stat-supervision>
</ng-container>
<!-- MONITORING -->

<!-- LOADING -->
<app-stat-loading [template]="template" *ngIf="!data"></app-stat-loading>
<!-- LOADING -->
