import * as XLSX from 'xlsx';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  /**
   *
   * @param fileName
   * @param data
   */
  exportExcel(fileName, data){

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };

      /* generate workbook and add the worksheet */
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, fileName+'.xlsx');

  }
}
