import * as $ from 'jquery';

import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-input',
  templateUrl: './error-input.component.html',
  styleUrls: ['./error-input.component.scss']
})
export class ErrorInputComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() errors : any;
  @Input() name : string;

  displayed : any;

  findError(){
    this.displayed = this.name.split('.').reduce((p,c)=>p&&p[c]||null, this.errors);
  }
  ngOnInit() {
    this.findError();
    trigerFindError();
  }

  ngOnChanges(){
    this.findError()
  }

}

function trigerFindError(){
  setTimeout(()=>{
    $('.form-group').removeClass('error-form')

    $('.form-group').each(function(){
      if($(this).find('app-error-input p').length){
          $(this).addClass('error-form')
      }else {
          $(this).removeClass('error-form')
      }
  })
  },10)

}

