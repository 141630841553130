import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { GroupService } from 'src/app/shared/services/group.service';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { HotelService } from '../../shared/services/hotel.service';
import { UserService } from '../../shared/services/user.service';
import { DialogGroupComponent } from '../receptionist/dialogs/dialog-group/dialog-group.component';
import { DialogUserComponent } from './dialog-user/dialog-user.component';
import { GroupsComponent } from './groups/groups.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(
    private userService: UserService,
    private hs: HotelService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public groupService: GroupService,
    notifierService: NotifierService,

  ) {    this.notifier = notifierService;
 }

  @ViewChild(GroupsComponent) child: GroupsComponent;
  private readonly notifier: NotifierService;


  slug: any;
  hotel: any;
  users: any;
  concierges: any;
  // admins: any;
  title = "Users Management"
  id = localStorage.getItem('id');
  // user = localStorage.getItem('user');
  hotelsToManage = [];
  groups: any;
  groupsTh: any;
  indexTab = 0;
  group_administrator_id :any;
  group_concierge_id :any;
  @Input() onglet = false;


  /**
   *
   * @param data
   * @param index
   */
  openGroupDialog(data = {}, index = null) {
    this.setTab(2)
    const dialogRef = this.dialog.open(DialogGroupComponent, {
      width: '950px',
      data: { groups: this.groupsTh, slug: this.slug, users: this.users }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.groupService.list('?hotel=' + this.slug).subscribe(res => {
        this.groupsTh = res;
      })
    });
  }


  ngOnInit() {
    this.spinner.show('global');

    //GET USER CONNECTED
    this.userService.get(this.id).subscribe((res: any) => {
      if (res.id) {
        for (let v of res.related_hotels) {
          this.hotelsToManage.push({ id: v['id'], text: v['name_hotel'] })
        }
      }
    })

    //GET ROUTE
    this.route.params.subscribe(params => {
      this.slug = params.slug

      //GET GROUP TH
      this.groupService.list('?hotel=' + this.slug).subscribe(res => {
        this.groupsTh = res;
      })

      //GET HOTEL
      this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
      });

      //GET DJANGO GROUPS
      this.userService.getGroups().subscribe(res => {
        this.groups = res;
        this.group_administrator_id = this.groups.find(x=>x.name == 'administrator').id;
        this.group_concierge_id  =  this.groups.find(x=>x.name == 'concierge').id;

      })
    })

    //GET ADMINS
    this.init();
  }

  setTab(i){
    this.indexTab=i
  }


  init() {
    this.spinner.show('global');

    this.userService.getAll(this.slug).subscribe(res => {
      this.users = res;
      this.spinner.hide('global');
    })
  }

  /**
 * OPEN DIALOG ADD OR EDIT USER
 * @param type
 * @param user
 */
  openDialogUser(type, user = null, tab = null) {
    if(tab)
      this.setTab(tab)
    let data = {
      hotel: this.hotel,
      hotelsToManage: this.hotelsToManage,
      type: type,
      id: null,
      roles:[],
      groups: [type == 'administrator' ? this.group_administrator_id : this.group_concierge_id],
    };
    const dialogRefInfo = this.dialog.open(DialogUserComponent, { data: data, width: '900px' });

    dialogRefInfo.afterClosed().subscribe(res=>{
      if(res['action'] == "add" ){
        this.init()
      }
    })
  }

}
