<h1 class="mat-dialog-title">Downloading data from server</h1>
<div mat-dialog-content>

    The data is loading from server, please wait a moment ... 
    <br>
    <br>
    <h2>{{current/total * 100 | number: '1.0-0'}} %</h2>
    <mat-progress-bar mode="determinate" [value]="current/total * 100" color="#266dad"></mat-progress-bar>


</div>
<div mat-dialog-actions align=end>
  <button class="btn btn-success" [mat-dialog-close]="" >{{'Cancel' | translate}}</button>
</div>