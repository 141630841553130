import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/shared/services/api.service';

export class ItemLinkPms {
  item_local_id:string
  item_pms_id:string
  customer:string
  dont_show_if_in_basket:boolean
  constructor(slug){
    this.customer = slug
    this.dont_show_if_in_basket = true
  }
}

@Component({
  selector: 'app-dialog-add-edit',
  templateUrl: './dialog-add-edit.component.html',
  styleUrls: ['./dialog-add-edit.component.scss']
})
export class DialogAddEditComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private apiservice:ApiService

  ) {
    this.notifier = notifierService;
  }

  private readonly notifier: NotifierService;
  itemlinkpms:ItemLinkPms;

  ngOnInit(): void {
    if(!this.data.item){
      this.itemlinkpms = new ItemLinkPms(this.data.slug);
    }else {
      this.itemlinkpms = this.data.item
    }

  }

  saveOrEdit(){
    this.spinner.show('global')
    this.apiservice.createOrUpdate('item-link-pms/', this.itemlinkpms).subscribe(res=>{
      console.log(res);
      this.spinner.hide('global');
      this.dialogRef.close(true);
    })
  }

}
