import { NotifierService } from 'angular-notifier';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { HotelinformationsService } from 'src/app/shared/services/hotelinformations.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SocialComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private hs: HotelService,
    private hi: HotelinformationsService,
    private translate: TranslateService,
    notifierService: NotifierService
    ) {
      this.notifier = notifierService;
    }

  private readonly notifier: NotifierService;


  social: any;
  socials : any;
  errors : any;

  ngOnInit() {
    this.socials = this.data.socials
    this.social = this.data.social;
  }

  createOrUpdate(social){

    if (social.id) {
      this.hi.updateSocialLink(social).subscribe(res => {
        social = res;
        this.translate.get('Link successfully updated').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
        this.dialogRef.close(true);
      },
      err => {
        this.errors = err.error;
        console.log(err)
      })
    } else {

      this.hi.createSocialLink(social).subscribe(res => {
        this.translate.get('Link successfully added').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
        this.socials.push(res);
        this.dialogRef.close(true);
      },
      err => {
        this.errors = err.error;
        console.log(err)
      })
    }

  }

}
