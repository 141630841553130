import * as io from 'socket.io-client';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class SocketsService {

  // constructor(private http: HttpClient) { }
  uri = URI;

  socket;
  user = localStorage.getItem('user_id')
  constructor() {   }
  setupSocketConnection() {
    this.socket =  new WebSocket('ws://127.0.0.1:8000/ws/chat/angular'+this.user+'/');

    this.socket.onopen = () => {
      console.log('WebSockets connection created.');
    };
    this.socket.onmessage = (event) => {
      console.log("data from socket:" + event.data);
      // this.num = event.data;
    };

    this.socket.onclose = (event)=>{
      console.log('closed')
      setTimeout(()=>{
      this. setupSocketConnection()
      },5000)
    }

    if (this.socket.readyState == WebSocket.OPEN) {
      this.socket.onopen(null);
    }
  }


}
