import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { ItemService } from 'src/app/shared/services/item.service';
import { Upsell } from 'src/app/shared/services/Upsell';
import { UpsellService } from 'src/app/shared/services/upsell.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.scss']
})
export class UpsellComponent implements OnInit {

  constructor(
    private hs: HotelService,
    private route: ActivatedRoute,
    private ups : UpsellService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private translate: TranslateService,
    notifierService: NotifierService,
    private itemService: ItemService

  ) {
    this.notifier = notifierService;
   }
   private readonly notifier: NotifierService;


  slug : any;
  hotel:any;
  items: any;
  title = "OFFER";
  offer : any;
  data  : any;
  idOffer : any;
  labels : any;
  features=[];
  rooms = [];
  devices = [];
  scenarios = [];
  priceType = [];
  guest = [];
  categories = {};
  today = new Date();
  public startAt = new Date(2018, 3, 10, 10, 30, 30);
  _breadCrumbs = [];

  ngOnInit() {
    this.spinner.show('global');
    /**
     * GET ROUTE PARAMATERS
     */
    this.route.params.subscribe(params => {
      this.slug = params.slug;
      this.idOffer = params.id;
    });

    /**
     * GET THE HOTEL
     */
    this.hs.getHotel(this.slug).subscribe((data) => {
      this.hotel = data;


      /**
       * INIT OFFER IF EXIST
       */
      if(this.idOffer ){
        this.ups.getOffer(this.idOffer).subscribe(res=>{

            setTimeout(()=>{
              this.spinner.hide('global');
            },1000)

            this.buildOffer(res);
            this._breadCrumbs = [
               {href:'/offers-list', label: 'Offer'}, {label: this.offer.NAME}
             ]
        }, ()=>{
          this.spinner.hide('global');
        });
      }else {
        //CREATE NEW OFFER
        this.spinner.hide('global');
        this.offer = {...Upsell};
        this.offer['ORDER'] = 10;
        this.offer['ID_CUSTOMER'] = this.hotel.id;
      }
    });

    /**
     * INIT LABELS
     */
    this.ups.getLabels(this.slug).subscribe(res=>{
      if(res){
        this.features = this.buildObject(res['Features'], 'id', 'name');
        this.rooms    = this.buildObject(res['RoomCategories'], 'id_pms', 'name');
        this.devices  = this.buildObject(res['Devices'], 'UUID_DEVICE', 'NAME');
        this.scenarios  = this.buildObject(res['Scenarios'], 'UUID_WORKFLOW', 'WORKFLOW_NAME');
        this.priceType  = this.buildObject(res['PriceRoomType'], 'id_pms_room_rate', 'label_room_rate');
        this.guest = this.buildObject(res['Guest'], 'id_pms', 'name');
        this.categories = this.buildCategoriesObject(res['categories']);
      }


    })
    this.itemService.getItems("?category__hotel="+this.slug+"&is_up_fixe="+true).subscribe(res => {
      console.log(res)
      this.items = res
    })
  }


  /**
   *
   * @param res
   */
  buildOffer(res){
    this.offer = res;
    this.offer['CONDITIONS'] = JSON.parse(this.offer['CONDITIONS']);
    this.offer['DISPLAY_ITEMS'] = JSON.parse(this.offer['DISPLAY_ITEMS']);
    this.offer['PROMOTIONS'] = JSON.parse(this.offer['PROMOTIONS']);
  }

  /**
   * UPDATE OR CREATE UPSELL / OFFER
   * @param offer
   */
  updateOrCreate(offer, exit = false){
    this.spinner.show('global');
    if(offer.id){
      this.ups.updateOffer(offer).subscribe(res=>{
        this.buildOffer(res)
        this.exit(exit);
        this.translate.get('Offer successfully saved').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
        this.spinner.hide('global');
      }, ()=>{
        this.spinner.hide('global');
        this.translate.get('Offer was not saved').subscribe(
          value => {
            this.notifier.notify('error', value);
          }
        );
      })
    }else {
      this.ups.createOffer(offer).subscribe(res=>{
        this.spinner.hide('global');
        this.buildOffer(res)
        this.exit(exit);
        this.translate.get('Offer successfully saved').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
      }, ()=>{
        this.spinner.hide('global');

        this.translate.get('Offer was not saved').subscribe(
          value => {
            this.notifier.notify('error', value);
          }
        );
      })
    }

  }

  /**
   *
   * @param exit
   */
  exit(exit){
    if(exit){
       this.router.navigate(['/offers-list', this.slug])
    }
  }
  /**
   * BUILD OBJECT IN CAT/ITEMS
   * @param old
   * @param id
   * @param text
   */
  buildObject(old, id, text){
    let i = 0;
    let newObj = [];
    if(old.length){
      for(let v of old){
        newObj[i] =  {id:v[id], text:v[text]? v[text] : "no name"};
        i++
      }
    }
    return newObj;
  }

  /**
   * MAPING OFFER OBJECT BEFORE INT
   * @param old
   */
  buildCategoriesObject(old){
    let i = 0;
    let newObj = [];
    if(old.length){
      for(let v of old){
        newObj[i] =  {id:'>'+v['id'], text:v['name']? 'Category : '+v['name'] : "no name"};
        for(let m of v['items']){
          i++
          newObj[i] =  {id:m['id'], text:m['name']? ' --- Item : '+m['name'] : "Item no name"};
        }
        i++
      }
    }
    return newObj;
  }
}
