import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogCategoryComponent } from '../dialog-category/dialog-category.component';

@Component({
  selector: 'app-dialog-choose',
  templateUrl: './dialog-choose.component.html',
  styleUrls: ['./dialog-choose.component.scss']
})
export class DialogChooseComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  object: any;


  ngOnInit() {
    this.object = this.data;
  }

  /**
   *
   * @param type
   * @param i
   * @param j
   */
  deleteSubElement(type, i, j){
    this.object['subcategories'][i]['items'].splice(j, 1);
  }

  /**
   *
   * @param type
   * @param element
   * @param i
   */
  deleteElement(type, element, i){
    this.object[type].splice(i, 1);
  }
}
