import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoryService } from 'src/app/shared/services/category.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-category',
  templateUrl: './dialog-category.component.html',
  styleUrls: ['./dialog-category.component.scss']
})
export class DialogCategoryComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    notifierService: NotifierService,
    public cats: CategoryService,
    private spinner: NgxSpinnerService,
    public dialog : MatDialog,
    private translate: TranslateService
  ) {
    this.notifier = notifierService;

   }

  private readonly notifier: NotifierService;
  category: any;
  attrs: any;
  selectedFile = null;
  hotel: any;
  superFields: any;
  languages : any;
  category_type:any;
  categories :any;
  errors = null;
  empty :any;

  ngOnInit() {

    this.languages = this.data.languages;
    this.category = this.data.category;
    this.empty = {...this.data.category}
    this.attrs = this.data.attrs;
    this.hotel = this.data.hotel;
    this.superFields = this.data.superFields.super_category_attr;
    this.category_type = this.data.category_type;
    this.categories = this.data.categories
  }


  /**
   * UPDATE CATEGORY
   * @param category
   */
  updateCategory(category){
    this.spinner.show('global');
    if(!category.ranked){
      category.ranked = 0;
    }

    this.cats.editCategory(category).subscribe(
        res => {
            this.spinner.hide('global');
            if(res){
              this.translate.get('Category successfully updated').subscribe(
                value => {
                  this.notifier.notify('success', value);
                }
              );
              category = res;
            }
            //close modal
            this.dialogRef.close({success:true,category});
        },
        err => {
            this.spinner.hide('global');
            this.errors = err.error;
            this.translate.get('An error has occurred').subscribe(
              value => {
                this.notifier.notify('error', value);
              }
            );
        }
    )
  }

  /**
   * CREATE CATEGORY
   * @param category

   */
  createCategory(category, addNew =false){
    this.spinner.show('global');

    this.cats.postCategory(category).subscribe(
        res => {
            if(res){
                this.spinner.hide('global');
                this.updatePositions()
                this.translate.get('Category successfully created').subscribe(
                  value => {
                    this.notifier.notify('success', value);
                  }
                );
                this.categories.unshift(res)
                category = res; //update created category with data from server
                //close modal
                this.dialogRef.close(true);
                if(addNew){
                  this.dialog.open(DialogCategoryComponent, {data:{...this.data, category:this.empty}})
                }
            }
        },
        err =>{
            this.errors = err.error;
            this.spinner.hide('global');
            this.translate.get('An error has occurred').subscribe(
              value => {
                this.notifier.notify('error', value);
              }
            );
        }
    )
  }

  /**
   * CREATE OR UPDATE CATEGORY
   * @param category

   */
  createOrUpdate(category, addNew =false){
    if(category.id){
      this.updateCategory(category);
    }else {
      this.createCategory(category, addNew)
    }
  }


  /**
   *
   */
  updatePositions(){
    let obj = {
        categories: this.categories.map(a => a.id),
        hotel: this.hotel,
        category_type: this.category_type
    }
    this.cats.sortCategories(obj).subscribe(
        () => {
            this.spinner.hide('global');
        },
        ()=>{
          this.translate.get('An error has occurred').subscribe(
            value => {
              this.notifier.notify('error', value);
            }
          );
        }
    )
  }
}
