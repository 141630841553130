<h2 class="mat-dialog-title with-trans">
  {{'Transport arround the property' | translate}}
  <app-select-translate [hotel]="data.hotel" [languages]="data.languages"></app-select-translate>
</h2>
<mat-dialog-content class="mat-typography modal-md">
 
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <label>{{'Category' | translate}}</label>
          <mat-form-field>
            <mat-select [(ngModel)]="transport['category']" name="category">
              <mat-option *ngFor="let type of types" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-error-input *ngIf="errors" [errors]="errors" name="category"></app-error-input>

        </div>
      </div>
      <div class="col-lg-12 form-group" >

        <!--translated input-->
        
        <translated-input [model]="transport" [attr]="'description'" [hotel]="data.hotel" [type]="'textarea'"
          [label]="'Description'" [languages]="data.languages">
        </translated-input>
        <!--translated input--> 
        <app-error-input *ngIf="errors" [errors]="errors" name="description"></app-error-input>

      </div>

    </div>

 
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="createOrUpdate(data['transport'])"  class="btn btn-success">
    {{(transport['id'] ? "Edit transport" : "Add transport") | translate }}
  </button>
</mat-dialog-actions>