import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { TabpayService } from 'src/app/shared/services/tabpay.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-tab-pay',
	templateUrl: './tab-pay.component.html',
	styleUrls: ['./tab-pay.component.scss']
})
export class TabPayComponent implements OnInit {

	constructor(
		private hs: HotelService,
		notifierService: NotifierService,
		private router: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private tabpay: TabpayService,
		private _router: Router,
		public dialog: MatDialog) {
		this.notifier = notifierService;
	}
	private readonly notifier: NotifierService;


	title = "Tab Pay +";
	slug: any;
	hotel: any;
	languages: any;
	transactions = {success:[], failed:[], warning:[]};

	count = {success:0, failed:0, warning:0};


	ngOnInit() {
		this.spinner.show('global');

		this.hs.getLanguages().subscribe((data) => {
			this.languages = data;
		});

		this.router.params.subscribe(params => {
			if (params.slug == "undefined") {
				this._router.navigate(['/hotels'])
			}
			this.slug = params.slug;

			this.hs.getHotel(this.slug).subscribe((data) => {
				this.hotel = data;
			});
		});

	}


	/**
	 *
	 * @param $event
	 * @param attr
	 */
	updateCount($event, attr){
		this.count[attr]=$event
	}



}
