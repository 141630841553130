import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class SignageService {

  constructor(private http: HttpClient) {}
  uri = URI;

  /**
   * GET EVENTS BY DEVICE
   * @param customer_id
   * @param year
   * @param month
   */
  getEventsByDevice(customer_id, year, month, device_id) {
		return this.http.get(`${this.uri}` + "signage/"+customer_id+"/"+year+"-"+month+'/'+device_id);
  }

  /**
   * GET DEVICES WITH EVENTS
   * @param customer_id
   * @param year
   * @param month
   */
  getDevicesWithEvents(customer_id, year, month){
    return this.http.get(`${this.uri}` + "signage/"+customer_id+"/"+year+"-"+month);
  }

  /**
   * DELETE DEVICE
   * @param id
   */
  deleteDevice(id){
    return this.http.delete(this.uri + "devices/"+id+"/");
  }

  /**
  * GET DEVICE DETAIL
  * @param id
  */
  getDevicesDetails(id){
    return this.http.get(`${this.uri}` + "devices/"+id);
  }

  /**
   * GET DISPLAY BY NAME
   * @param name
   */
  getDispayByName(name = "default-values"){
    return this.http.get(`${this.uri}` + "signage/display-by-name/"+name)
  }

  /**
   * GET EVENT DETAIL
   * @param id
   */
  getEventDetail(id){
    return this.http.get(`${this.uri}` + "signage/program/"+id);
  }

  /**
   * CREATE EVENT
   * @param event
   */
  createEvent(event) {
		return this.http.post(`${this.uri}` + "signage/create", event);
  }

  /**
   * UPDATE EVENT
   * @param event
   */
  updateEvent(event) {
		return this.http.patch(`${this.uri}` + "signage/"+event.id+"/update", event);
  }

  /**
   * DELETE EVENT
   * @param event
   */
  deleteEvent(event){
    return this.http.delete(`${this.uri}` + "signage/"+event.id+"/delete");
  }

  /**
   * GET CUSTOM VARIABLE
   */
  getCustomVars(customer_id, variable= "all"){
    return this.http.get(`${this.uri}` + "signage/display-vars/"+variable+"/"+customer_id);
  }

  /**
   * GET DISPLAY LIST
   * @param user_id
   * @param id :id hotel
   */
  getDisplayList(user_id, id, params = false){
    return this.http.get(`${this.uri}` + "signage/"+user_id+"/displays-list/"+id);
  }

  /**
   * GET PARAM DEVICES LIST
   * @param user_id
   * @param id :id hotel
   */
  getDeviceParamsList( customer_id, params = "true"){
    return this.http.get(`${this.uri}` + "device-list/"+customer_id+"?only_params="+params);
  }


   /**
   * GET DEVICE BY USER
   * @param customer_id
   * @param user_id
   * @param id hotel
   */
  getDevicesByUser( customer_id,user_id,  params = "true"){
    return this.http.get(`${this.uri}` + "signage/"+user_id+"/devices/"+customer_id+"?only_params="+params);
  }


  /**
   * UPDATE DEVICE
   * @param device
   */
  updateDevice(device){
    return this.http.put(`${this.uri}` + "devices/"+device.id+"/", device);
  }

  /**
   * GET DISPLAY DETAIL
   * @param id
   */
  getDisplayDetail(id){
    return this.http.get(`${this.uri}` + "signage/display/details/"+id);
  }

  /**
   * UPDATE DISPLAY
   * @param display
   */
  updateDisplay(display){
    return this.http.put(`${this.uri}` + "signage/display/"+display.id+"/update", display);
  }

  /**
   * CREATE DISPLAY
   * @param display
   */
  createDisplay(display){
    return this.http.post(`${this.uri}` + "signage/display/create", display);
  }
}
