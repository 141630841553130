import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DefaultLang, TRANSLATE } from './shared/settings/global';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = "dashboard";

  /**
   *
   * @param translate
   */
  constructor(private translate: TranslateService) {

    let lang = DefaultLang
    if (TRANSLATE) {
      lang = localStorage.getItem('language') ? localStorage.getItem('language') : DefaultLang;
    }else {
      localStorage.setItem('language', "en")
    }


    translate.setDefaultLang("en");


    //CLEAR CACHE SERVICE WORKER
    if ('serviceWorker' in navigator) {
      caches.keys().then(function(cacheNames) {
        cacheNames.forEach(function(cacheName) {
        caches.delete(cacheName);
        });
      });

      navigator.serviceWorker.getRegistrations().then((registrations)=> {
        if(registrations.length){

          setTimeout(()=>{
            window.location.href = "/";
          },1000)
        }

        for(let registration of registrations) {
           registration.unregister()
        }


      })
    }//end clear cache
  }



}
