<h1 class="mat-dialog-title">{{data.hotel.customer_type == 'HOSPITAL' ? 'Appointment' : 'Reservation'}} detail</h1>
<div mat-dialog-content>

  <div class="row">
    <div class="col-6">
      <table class="table  table-striped table-header">
        <tr *ngIf="!data?.detail['Pre checkin online']" class="red">
          <td>{{'Pre checkin' | translate}}</td>
          <td ><i class="material-icons"  style="color:rgb(155, 42, 42)">block</i> </td>
        </tr>
        <tr *ngIf="data?.detail['Pre checkin online']" class="green">
          <td>{{'Pre checkin' | translate}}</td>
          <td><i class="material-icons" style="color:rgb(40, 161, 40)">check_circle</i></td>
        </tr>
        <tr>
          <td style="width: 120px;">{{'Last Name' | lowercase | translate}}</td>
          <td>{{data?.detail['Last Name']}}</td>
        </tr>
        <tr>
          <td>{{'First Name' | lowercase | translate}}</td>
          <td>{{data?.detail['First Name']}}</td>
        </tr>
        <tr>
          <td>{{'Country' | lowercase | translate}}</td>
          <td>{{data?.detail?.Country}}
             <img  src="https://flagcdn.com/w20/{{data?.detail?.Country | lowercase}}.png"  *ngIf="data?.detail?.Country.length == 2 && data?.detail?.Country != 'ND' ">
            </td>
        </tr>
        <tr>
          <td>{{'Nationality' | lowercase | translate}}</td>
          <td>{{data?.detail['Nationality']}} 
            <img src="https://flagcdn.com/w20/{{data?.detail?.Nationality | lowercase}}.png" *ngIf="data?.detail?.Nationality.length == 2 && data?.detail?.Nationality != 'ND'"></td>
        </tr>
        <tr>
          <td>{{'Email' | translate}}</td>
          <td>{{data?.detail['_Email']}}</td>
        </tr>
        <tr>
          <td>{{'Phone' | translate}}</td>
          <td>{{data?.detail['_Phone']}}</td>
        </tr>
        <tr>
          <td>{{'Address' | translate}}</td>
          <td>{{data?.detail?._Address}} {{data?.detail['_Zip_code'] ? ',': ''}} {{data?.detail['_Zip_code']}}  {{data?.detail['_City'] ? "," : ''}} {{data?.detail['_City']}} </td>
        </tr>
        <tr>
          <td>{{'Detail processs' | translate}}</td>
          <td><button class="btn btn-xs btn-default" (click)="openTimeLine(data?.detail['_info_steps'])">See
              details</button></td>
        </tr>



      </table>
    </div>
    <div class="col-md-6">
      <table class="table  table-striped table-header">
        <tr>
          <td style="width: 150px;">{{'Start Date' | translate}}</td>
          <td>{{data?.detail['_start_date']}}</td>
        </tr>
        <tr>
          <td>{{'End Date' | translate}}</td>
          <td>{{data?.detail['_end_date']}}</td>
        </tr>
        <tr>
          <td>{{'Reservation N°' | lowercase | translate}}</td>
          <td>{{data?.detail['Reservation N°']}}</td>
        </tr>
        <tr>
          <td>{{'Number of children' | translate}}</td>
          <td>{{data?.detail['_Number of children']}}</td>
        </tr>
        <tr>
          <td>{{'Number of adults' | translate}}</td>
          <td>{{data?.detail['_Number of adults']}}</td>
        </tr>

        <ng-container *ngIf="data?.detail['_Document type'] == 'P' || data?.detail['_Document type'] == 'ID'">
          <tr>
            <td>{{'Document type' | translate}}</td>
            <td>{{data?.detail['_Document type'] == 'P' ? 'Passport' : 'ID'}}</td>
          </tr>
          <tr>
            <td>{{'Document expire date' | translate}}</td>
            <td>{{data?.detail['_Document expire date']}}</td>
          </tr>
          <tr>
            <td>{{'Document number' | translate}}</td>
            <td>{{data?.detail['_Document number']}}</td>
          </tr>
          <tr>
            <td>{{'Document issuance contry code' | translate}}</td>
            <td>{{data?.detail['_Document issuance contry code']}} <img
                src="https://www.countryflags.io/{{data?.detail['_Document issuance contry code'] | lowercase}}/flat/24.png">
            </td>
          </tr>
        </ng-container>



      </table>
    </div>
  </div>
  <ng-container *ngIf="data?.detail?._Invoice?.reservation_additional_products?.length">
    <h4>{{'Rooms' | translate}}</h4>

    <table class="table table-striped">
      <thead class="thead-dark">
        <tr>
          <th>{{'Floor' | translate}} </th>
          <th>{{'Number' | translate}} </th>
          <th>{{'Number of guests' | translate}} </th>
          <th>{{'Category name' | translate}} </th>
          <th>{{'Is available' | translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let room of data.detail._Rooms">
          <td>{{room.room_floor}}</td>
          <td>{{room.room_number}}</td>
          <td>{{room.number_of_guests}}</td>
          <td>{{room.room_category_name}}</td>
          <td class="text-center"> 
             <i class="material-icons" *ngIf="!room.is_available" style="color:rgb(155, 42, 42)">block</i>
             <i class="material-icons" *ngIf="room.is_available" style="color:rgb(40, 161, 40)">check_circle</i>
            </td>
        </tr>
      </tbody>
    </table>


    <h4>{{'Invoice' | translate}}</h4>
    <table class="table" *ngIf="data?.detail?._Invoice?.reservation_additional_products">
      <thead class="thead-dark">
        <tr>
          <th>{{'Quantity' | translate}}</th>
          <th>{{'Product' | translate}}</th>
          <th>{{'Unit Price' | lowercase | translate}}</th>
          <th>{{'TAX' | lowercase | translate}}</th>
          <th>{{'Total' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let article of data.detail._Invoice.reservation_additional_products">
          <td style="width: 70px; text-align: center;">{{getNumber(article.product_quantity)}}</td>
          <td>
            <i class="fa fa-bed" *ngIf="article.product_type == 'room'"></i>
            <i class="fa fa-shopping-cart" *ngIf="article.product_type != 'room'"></i>
            {{article.product_name}}</td>
          <td>{{article.product_unite_price}}</td>
          <td>{{article.product_tax}} ({{article?.product_tax_rate}}%)</td>
          <td class="text-right">{{article.product_total}} {{ data?.detail?._Invoice?.currency}}</td>
        </tr>
        <tr>
          <td colspan="3" class="no-border"></td>
          <td>{{'Total' | lowercase | translate}}</td>
          <td class="text-right">{{data?.detail?.Total}} {{ data?.detail?._Invoice?.currency}}</td>
        </tr>
        <tr>
          <td colspan="3" class="no-border"></td>
          <td>{{'Paid in kiosk' | translate}}</td>
          <td class="text-right">{{data?.detail?.Paid ? data?.detail?.Paid : 0}} {{ data?.detail?._Invoice?.currency}}
          </td>
        </tr>
        <tr>
          <td colspan="3" class="no-border"></td>
          <td>{{'Balance' | lowercase | translate}}</td>
          <td class="text-right">{{data?.detail?.Balance}} {{ data?.detail?._Invoice?.currency}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
<div mat-dialog-actions align=end>
  <button class="btn btn-success" [mat-dialog-close]="" >{{'Close' | translate}}</button>
</div>