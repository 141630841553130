<div class="file" *ngIf="model[attr]">
  <button class="btn btn-danger" (click)="removeImg(attr)">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
  <build-image [src]="model[attr]" [className]="'img-responsive'"></build-image>

</div>
<div class="input-group" *ngIf="!model[attr]">

  <div class="input-group-prepend">
    <span class="input-group-text" id="file-{{attr}}">{{'Upload' | translate}}</span>
  </div>
  <div class="custom-file">
    <input type="file" class="custom-file-input" id="input-file-{{attr}}" (change)="imageSelected($event, attr)"
      accept="image/*">
    <label class="custom-file-label" for="input-file-{{attr}}">{{'Choose file' | translate}}</label>
  </div>
</div>