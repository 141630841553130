import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { DialogAddEditComponent } from './dialogs/dialog-add-edit/dialog-add-edit.component';

@Component({
  selector: 'app-item-link-pms',
  templateUrl: './item-link-pms.component.html',
  styleUrls: ['./item-link-pms.component.scss']
})
export class ItemLinkPmsComponent implements OnInit {

  constructor(
    private apiservice: ApiService,
    private router: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
  
  }
  items = [];
  products;
  slug

  ngOnInit(): void {
    this.router.params.subscribe(params => {
      this.slug = params.slug;

      this. getData();

      this.apiservice.get('categories/?hotel=' + this.slug).subscribe(res => {
        this.products = res;
      })

    });
  }

  getData(){
    this.apiservice.get('item-link-pms/?customer=' + this.slug+"&category_type=Upsell").subscribe((res: any) => {
      this.items = res;
    });
  }

  getText(text) {
    if(text){
      return text.split(',').join(', <br>')
    }
  }


  /**
   * 
   * @param item 
   */
  openDialog(item = null) {
    const dialogRef = this.dialog.open(DialogAddEditComponent, {
      data:{item, products:this.products, slug:this.slug}, width:'800px'
    });

    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.getData();
      }
    })
  }

  delete(item) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data : {type : "link",name:item.item_source.name}
    });

    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.spinner.show('global');
        this.apiservice.delete('item-link-pms/'+item.id).subscribe(res=>{
          this.getData();
          this.spinner.hide('global');
        })
      }
    })
  }
}
