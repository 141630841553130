import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-update-version',
  templateUrl: './dialog-update-version.component.html',
  styleUrls: ['./dialog-update-version.component.scss']
})
export class DialogUpdateVersionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogUpdateVersionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}

  currentURL = window.location.href; 

  ngOnInit(): void {
  }

}
