import { ChartModule } from 'angular-highcharts';
import { NotifierModule } from 'angular-notifier';
import { QueryBuilderModule } from 'angular2-query-builder';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { enableProdMode, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { TokenInterceptorService } from './auth/token-interceptor.service';
import { FormBuilderComponent } from './components/build-categories/form-builder/form-builder.component';
import { ListInputsComponent } from './components/build-categories/form-builder/inputs/list-inputs/list-inputs.component';
import { ItemComponent } from './components/build-categories/items/items.component';
import { DialogCategoryComponent } from './components/build-categories/modals/dialog-category/dialog-category.component';
import { DialogChooseComponent } from './components/build-categories/modals/dialog-choose/dialog-choose.component';
import { DialogDuplicateComponent } from './components/build-categories/modals/dialog-duplicate/dialog-duplicate.component';
import { DialogGetDetailComponent } from './components/build-categories/modals/dialog-get-detail/dialog-get-detail.component';
import { DialogItemComponent } from './components/build-categories/modals/dialog-item/dialog-item.component';
import { DialogSubcategoryComponent } from './components/build-categories/modals/dialog-subcategory/dialog-subcategory.component';
import { DialogSlideComponent } from './components/build-categories/slides/dialog-slide/dialog-slide.component';
import { SlidesComponent } from './components/build-categories/slides/slides.component';
import { SubcategoriesComponent } from './components/build-categories/subcategories/subcategories.component';
import { BuildCategoriesComponent } from './components/build-categories/this/build-categories.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { ErrorInputComponent } from './components/error-input/error-input.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { ImageComponent } from './components/image/image.component';
import { InputValidatorComponent } from './components/input-validator/input-validator.component';
import { ConfirmDeleteComponent } from './components/modals/confirm-delete/confirm-delete.component';
import { DialogChartConfigComponent } from './components/modals/dialog-chart-config/dialog-chart-config.component';
import { DialogDetailDiaplayComponent } from './components/modals/dialog-detail/dialog-detail.component';
import { DialogErrorComponent } from './components/modals/dialog-error/dialog-error.component';
import { DialogMessageComponent } from './components/modals/dialog-message/dialog-message.component';
import { DialogOrderItemsComponent } from './components/modals/dialog-order-items/dialog-order-items.component';
import { DialogTimelineComponent } from './components/modals/dialog-timeline/dialog-timeline.component';
import { FontIconsComponent } from './components/modals/font-icons/font-icons.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PagePermissionDeniedComponent } from './components/page-permission-denied/page-permission-denied.component';
import { ProgressComponent } from './components/progress/progress.component';
import { DialogIconsComponent } from './components/select-icons/dialog-icons/dialog-icons.component';
import { SelectIconsComponent } from './components/select-icons/select-icons.component';
import { SelectTranslateComponent } from './components/select-translate/select-translate.component';
import { TranslatedInputComponent } from './components/translated-input/translated-input.component';
import { UploadInputComponent } from './components/upload-input/upload-input.component';
import { ViewDetailsComponent } from './components/view-details/view-details.component';
import { ImageCropperModule } from './libs/ngx-image-cropper/src/lib/image-cropper.module';
import { MenuComponent } from './main-template/menu/menu.component';
import { SidebarComponent } from './main-template/sidebar.component';
import { ActivitiesComponent } from './pages/cms/activities/activities.component';
import { AreamapComponent } from './pages/cms/areamap/areamap.component';
import { RoomServiceComponent } from './pages/cms/room-service/room-service.component';
import { TransportComponent } from './pages/cms/transport/transport.component';
import { UpsellserviceComponent } from './pages/cms/upsellservice/upsellservice.component';
import { ConfigComponent } from './pages/config/config.component';
import { ManageCustomerComponent } from './pages/config/manage-customer/manage-customer.component';
import { DialogMenuModuleComponent } from './pages/config/menu-manager/dialog-menu-module/dialog-menu-module.component';
import { MenuManagerComponent } from './pages/config/menu-manager/menu-manager.component';
import { DashboardHardwareComponent } from './pages/dashboard-hardware/dashboard-hardware.component';
import { DialogStatusDetailsComponent } from './pages/dashboard-hardware/dialogs/dialog-status-details/dialog-status-details.component';

import { DialogFaqCategoryComponent } from './pages/faq/dialogs/dialog-faq-category/dialog-faq-category.component';
import { DialogFaqQuestionComponent } from './pages/faq/dialogs/dialog-faq-question/dialog-faq-question.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { FieldConstructorComponent } from './pages/form-constructor/build/field-constructor/field-constructor.component';
import { FieldValuesComponent } from './pages/form-constructor/build/field-constructor/field-values/field-values.component';
import { DebugFormComponent } from './pages/form-constructor/debug-form/debug-form.component';
import { DialogDefaultValuesComponent } from './pages/form-constructor/dialogs/dialog-default-values/dialog-default-values.component';
import { FormConstructorComponent } from './pages/form-constructor/form-constructor.component';
import { FormStepperOrTabsComponent } from './pages/form-constructor/form-stepper-or-tabs/form-stepper-or-tabs.component';
import { FormStepperComponent } from './pages/form-constructor/form-stepper/form-stepper.component';
import { FormTabsComponent } from './pages/form-constructor/form-tabs/form-tabs.component';
import { ImageTranslatableComponent } from './pages/form-constructor/view/image-translatable/image-translatable.component';
import { ViewComponent } from './pages/form-constructor/view/view.component';
import { HotelsComponent } from './pages/hotels/hotels.component';
import { InvitationComponent } from './pages/invitation/invitation.component';
import { LoginComponent } from './pages/login/login.component';
import { MainTemplateComponent } from './pages/main-template/main-template.component';
import { ManageRolesTrComponent } from './pages/manage-roles/manage-roles-tr/manage-roles-tr.component';
import { ManageRolesComponent } from './pages/manage-roles/manage-roles.component';
import { MediaManagerComponent } from './pages/media-manager/media-manager.component';
import { DialogCropperComponent } from './pages/media-manager/modals/dialog-cropper/dialog-cropper.component';
import { DialogFolderComponent } from './pages/media-manager/modals/dialog-folder/dialog-folder.component';
import { DialogMediaComponent } from './pages/media-manager/modals/dialog-media/dialog-media.component';
import { MigrationsComponent } from './pages/migrations/migrations.component';
import { ModuleWorkerDetailComponent } from './pages/module-worker/module-worker-detail/module-worker-detail.component';
import { ModuleWorkerComponent } from './pages/module-worker/module-worker.component';
import { ModuleManagerEditComponent } from './pages/modules-manager/module-manager-edit/module-manager-edit.component';
import { ModuleManagerTabApiComponent } from './pages/modules-manager/module-manager-edit/module-manager-tab-api/module-manager-tab-api.component';
import {
    ModuleManagerTabCustomLinkComponent
} from './pages/modules-manager/module-manager-edit/module-manager-tab-custom-link/module-manager-tab-custom-link.component';
import {
    ModuleManagerTabGeneralComponent
} from './pages/modules-manager/module-manager-edit/module-manager-tab-general/module-manager-tab-general.component';
import {
    ModuleManagerTabTableComponent
} from './pages/modules-manager/module-manager-edit/module-manager-tab-table/module-manager-tab-table.component';
import {
    ModuleManagerTabVariableComponent
} from './pages/modules-manager/module-manager-edit/module-manager-tab-variable/module-manager-tab-variable.component';
import { ModulesManagerComponent } from './pages/modules-manager/modules-manager.component';
import { DialogAmoutComponent } from './pages/payments/dialogs/dialog-amout/dialog-amout.component';
import { DialogCancelPaymentComponent } from './pages/payments/dialogs/dialog-cancel-payment/dialog-cancel-payment.component';
import { DialogDebitComponent } from './pages/payments/dialogs/dialog-debit/dialog-debit.component';
import { TabPayComponent } from './pages/payments/tab-pay/tab-pay.component';
import { TableDetailComponent } from './pages/payments/table-detail/table-detail.component';
import { ManageNotificationsComponent } from './pages/receptionist/components/manage-notifications/manage-notifications.component';
import { ManageTramesComponent } from './pages/receptionist/components/manage-trames/manage-trames.component';
import { ManageTriggersComponent } from './pages/receptionist/components/manage-triggers/manage-triggers.component';
import { DialogExportTriggerComponent } from './pages/receptionist/dialogs/dialog-export-trigger/dialog-export-trigger.component';
import { DialogGroupComponent } from './pages/receptionist/dialogs/dialog-group/dialog-group.component';
import { DialogListNotificationsComponent } from './pages/receptionist/dialogs/dialog-list-notifications/dialog-list-notifications.component';
import { DialogLogComponent } from './pages/receptionist/dialogs/dialog-log/dialog-log.component';
import { DialogNotifSettingComponent } from './pages/receptionist/dialogs/dialog-notif-setting/dialog-notif-setting.component';
import { DialogTrameComponent } from './pages/receptionist/dialogs/dialog-trame/dialog-trame.component';
import { DialogTriggerMaintenanceComponent } from './pages/receptionist/dialogs/dialog-trigger-maintenance/dialog-trigger-maintenance.component';
import { DialogTriggerComponent } from './pages/receptionist/dialogs/dialog-trigger/dialog-trigger.component';
import { RedirectionComponent } from './pages/redirection/redirection.component';
import { RoomCategoryComponent } from './pages/room-category/components/room-category.component';
import { DialogRoomCategoryComponent } from './pages/room-category/dialog/dialog-room-category.component';
import { DialogLanguagesComponent } from './pages/settings/modals/dialoglanguages/dialog-languages.component';
import { EditSliderComponent } from './pages/settings/modals/edit-slider/edit-slider.component';
import { HotelComponent } from './pages/settings/modals/hotel/hotel.component';
import { DialogInformationsComponent } from './pages/settings/modals/informations/informations.component';
import { SliderComponent } from './pages/settings/modals/slider/slider.component';
import { SocialComponent } from './pages/settings/modals/social/social.component';
import { DialogTransportComponent } from './pages/settings/modals/transport/transport.component';
import { InformationsComponent } from './pages/settings/sections/informations/informations.component';
import { LanguagesComponent } from './pages/settings/sections/languages/languages.component';
import { MoreInfosComponent } from './pages/settings/sections/more-infos/more-infos.component';
import { SlidersComponent } from './pages/settings/sections/sliders/sliders.component';
import { SocialLinksComponent } from './pages/settings/sections/social-links/social-links.component';
import { TransportArroundComponent } from './pages/settings/sections/transport-arround/transport-arround.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { StatChartComponent } from './pages/statistics/components/stat-chart/stat-chart.component';
import { StatDonutsComponent } from './pages/statistics/components/stat-donuts/stat-donuts.component';
import { StatHighlightsComponent } from './pages/statistics/components/stat-highlights/stat-highlights.component';
import { StatLoadingComponent } from './pages/statistics/components/stat-loading/stat-loading.component';
import { StatParamComponent } from './pages/statistics/components/stat-param/stat-param.component';
import { StatPmsComponent } from './pages/statistics/components/stat-pms/stat-pms.component';
import { StatSupervisionComponent } from './pages/statistics/components/stat-supervision/stat-supervision.component';
import { DialogStatConfigComponent } from './pages/statistics/dialogs/dialog-stat-config/dialog-stat-config.component';
import { StatDisplayComponent } from './pages/statistics/stat-display/stat-display.component';
import { StatLoadComponent } from './pages/statistics/stat-load/stat-load.component';
import { SupevisionManagerComponent } from './pages/supervision-group/components/supevision-manager/supevision-manager.component';
import {
    DialogAddEditSupervisionComponent
} from './pages/supervision-group/dialogs/dialog-add-edit-supervision/dialog-add-edit-supervision.component';
import { ChartSupervisionComponent } from './pages/supervision/components/chart-supervision/chart-supervision.component';
import { SupervisionChartConfigComponent } from './pages/supervision/components/supervision-chart-config/supervision-chart-config.component';
import { SupervisionTableConfigComponent } from './pages/supervision/components/supervision-table-config/supervision-table-config.component';
import { SupervisonTableColumnsComponent } from './pages/supervision/components/supervison-table-columns/supervison-table-columns.component';
import { TableSupervisonComponent } from './pages/supervision/components/table-supervison/table-supervison.component';
import { TableTdComponent } from './pages/supervision/components/table-supervison/table-td/table-td.component';
import { DialogChartConfComponent } from './pages/supervision/dialogs/dialog-chart-conf/dialog-chart-conf.component';
import { DialogConfigSupervisionComponent } from './pages/supervision/dialogs/dialog-config-supervision/dialog-config-supervision.component';
import { DialogDetailSupervisionComponent } from './pages/supervision/dialogs/dialog-detail-supervision/dialog-detail-supervision.component';
import { DialogHtmlPreviewComponent } from './pages/supervision/dialogs/dialog-html-preview/dialog-html-preview.component';
import { DialogRoomDetailsComponent } from './pages/supervision/dialogs/dialog-room-details/dialog-room-details.component';
import { DialogSupervisionLoadApiComponent } from './pages/supervision/dialogs/dialog-supervision-load-api/dialog-supervision-load-api.component';
import { SupervisionComponent } from './pages/supervision/supervision.component';
import { TranslateManagerComponent } from './pages/translate-manager/translate-manager.component';
import { UpsellListComponent } from './pages/upsell-list/upsell-list.component';
import { UpsellConditionsComponent } from './pages/upsell-list/upsell/conditions/conditions.component';
import { UpsellInformationsComponent } from './pages/upsell-list/upsell/informations/informations.component';
import { UpsellItemsToShowComponent } from './pages/upsell-list/upsell/items-to-show/items-to-show.component';
import { UpsellPromotionsComponent } from './pages/upsell-list/upsell/promotions/promotions.component';
import { UpsellConfigComponent } from './pages/upsell-list/upsell/upsell-config/upsell-config.component';
import { UpsellComponent } from './pages/upsell-list/upsell/upsell.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { DialogInvitationUserComponent } from './pages/users/dialog-invitation-user/dialog-invitation-user.component';
import { DialogUserComponent } from './pages/users/dialog-user/dialog-user.component';
import { GroupsComponent } from './pages/users/groups/groups.component';
import { TableComponent } from './pages/users/table/table.component';
import { UsersComponent } from './pages/users/users.component';
import { WorkflowListComponent } from './pages/workflow/workflow-list/workflow-list.component';
import { StepsTransComponent } from './pages/workflow/workflow-translation/components/steps-trans/steps-trans.component';
import { TransCustomInputComponent } from './pages/workflow/workflow-translation/components/trans-custom-input/trans-custom-input.component';
import { WorkflowTabContentComponent } from './pages/workflow/workflow-translation/components/workflow-tab-content/workflow-tab-content.component';
import { DialogPreviewListComponent } from './pages/workflow/workflow-translation/dialogs/dialog-preview-list/dialog-preview-list.component';
import { DialogPreviewComponent } from './pages/workflow/workflow-translation/dialogs/dialog-preview/dialog-preview.component';
import { DialogTemplateEditorComponent } from './pages/workflow/workflow-translation/dialogs/dialog-template-editor/dialog-template-editor.component';
import { WorkflowContentComponent } from './pages/workflow/workflow-translation/workflow-content.component';
import { SocketsService } from './shared/services/sockets.service';
import { UserService } from './shared/services/user.service';
import { SharedModule } from './shared/shared.module';
import { MatNativeDateModule } from '@angular/material/core';
import { DialogUpdateVersionComponent } from './components/modals/dialog-update-version/dialog-update-version.component';
import { DialogExcelChargingComponent } from './pages/supervision/dialogs/dialog-excel-charging/dialog-excel-charging.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { DialogCheckActiveAccountComponent } from './modules/shared/dialogs/dialog-check-active-account/dialog-check-active-account.component';
import { DeviceParamsComponent } from './pages/digital-signage/device-params/device-params.component';
import { DigitalsignageDevicesComponent } from './pages/digital-signage/devices/devices.component';
import { DigitalSignageComponent } from './pages/digital-signage/digital-signage.component';
import { DisplaysComponent } from './pages/digital-signage/displays/displays.component';
import { DialogDeviceComponent } from './pages/digital-signage/modals/dialog-device/dialog-device.component';
import { DeviceResponseComponent } from './pages/digital-signage/device-params/device-response/device-response.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DeviceFormsComponent } from './pages/digital-signage/device-forms/device-forms.component';
import { OngletContentsComponent } from './pages/onglet-contents/onglet-contents.component';
import { OngletSettingsComponent } from './pages/onglet-settings/onglet-settings.component';
import { ItemLinkPmsComponent } from './pages/item-link-pms/item-link-pms.component';
import { DialogAddEditComponent } from './pages/item-link-pms/dialogs/dialog-add-edit/dialog-add-edit.component';

enableProdMode();

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SettingsComponent,
    HotelsComponent,
    TransportComponent,
    SidebarComponent,
    ConfirmDeleteComponent,
    UpsellComponent,
    BuildCategoriesComponent,
    SubcategoriesComponent,
    ItemComponent,
    UserProfileComponent,
    MainTemplateComponent,
    PageNotFoundComponent,
    SelectTranslateComponent,
    TranslatedInputComponent,
    FontIconsComponent,
    SliderComponent,
    HotelComponent,
    SocialComponent,
    EditSliderComponent,
    DialogTransportComponent,
    DialogInformationsComponent,
    UploadInputComponent,
    InputValidatorComponent,
    RoomServiceComponent,
    DialogSubcategoryComponent,
    FormBuilderComponent,
    ActivitiesComponent,
    AreamapComponent,
    UpsellserviceComponent,
    SelectIconsComponent,
    ListInputsComponent,
    DialogItemComponent,
    DialogCategoryComponent,
    UsersComponent,
    TableComponent,
    DialogUserComponent,
    InformationsComponent,
    LanguagesComponent,
    SlidersComponent,
    MoreInfosComponent,
    SocialLinksComponent,
    TransportArroundComponent,
    DialogLanguagesComponent,
    ImageComponent,
    ViewDetailsComponent,

    UpsellListComponent,
    SlidesComponent,
    DialogSlideComponent,

    UpsellConditionsComponent,
    UpsellPromotionsComponent,
    UpsellItemsToShowComponent,
    UpsellConfigComponent,
    UpsellInformationsComponent,
    FormConstructorComponent,
    FieldConstructorComponent,
    FieldValuesComponent,

    ViewComponent,

    MediaManagerComponent,
    DialogFolderComponent,
    DialogMediaComponent,
    DialogErrorComponent,

    MenuComponent,

    ProgressComponent,
    DialogDefaultValuesComponent,
    DialogGetDetailComponent,
    TabPayComponent,
    DashboardHardwareComponent,
    DialogAmoutComponent,
    DialogStatusDetailsComponent,
    ConfigComponent,
    DebugFormComponent,
    FaqComponent,
    WorkflowContentComponent,
    DialogFaqCategoryComponent,
    DialogFaqQuestionComponent,
    DialogIconsComponent,
    WorkflowListComponent,
    TableDetailComponent,
    SupervisionComponent,
    TableSupervisonComponent,
    MigrationsComponent,
    TableTdComponent,
    DialogDetailDiaplayComponent,
    DialogTimelineComponent,
    DialogOrderItemsComponent,
    DialogChartConfigComponent,
    ChartSupervisionComponent,
    HtmlEditorComponent,
    DialogConfigSupervisionComponent,
    SupervisionChartConfigComponent,
    SupervisionTableConfigComponent,
    DialogDetailSupervisionComponent,
    IframeComponent,
    DialogRoomDetailsComponent,
    DonutChartComponent,
    MenuManagerComponent,
    PagePermissionDeniedComponent,
    RedirectionComponent,
    DialogTemplateEditorComponent,
    DialogChartConfComponent,
    SupervisonTableColumnsComponent,
    DialogPreviewComponent,
    ImageTranslatableComponent,
    StepsTransComponent,
    TransCustomInputComponent,
    ErrorInputComponent,
    ManageTriggersComponent,
    DialogTriggerComponent,
    DialogTrameComponent,
    ManageTramesComponent,
    ManageNotificationsComponent,
    DialogGroupComponent,
    DialogLogComponent,
    DialogListNotificationsComponent,
    DialogExportTriggerComponent,
    RoomCategoryComponent,
    DialogRoomCategoryComponent,
    DialogMenuModuleComponent,
    GroupsComponent,
    StatDonutsComponent,
    StatDisplayComponent,
    StatHighlightsComponent,
    StatLoadComponent,
    DialogStatConfigComponent,
    StatChartComponent,
    StatSupervisionComponent,
    StatLoadingComponent,
    DialogNotifSettingComponent,
    StatParamComponent,
    StatPmsComponent,
    DialogPreviewListComponent,
    DialogDuplicateComponent,
    DialogChooseComponent,
    WorkflowTabContentComponent,
    ManageCustomerComponent,
    DialogCropperComponent,
    ForgotPasswordComponent,
    DialogTriggerMaintenanceComponent,
    InvitationComponent,
    FormStepperComponent,
    DialogInvitationUserComponent,
    ModulesManagerComponent,
    ModuleManagerEditComponent,
    ModuleWorkerComponent,
    ModuleWorkerDetailComponent,
    FormTabsComponent,
    ManageRolesComponent,
    ManageRolesTrComponent,
    FormStepperOrTabsComponent,
    SupevisionManagerComponent,
    DialogAddEditSupervisionComponent,
    DialogHtmlPreviewComponent,
    ModuleManagerTabApiComponent,
    ModuleManagerTabVariableComponent,
    ModuleManagerTabTableComponent,
    ModuleManagerTabGeneralComponent,
    DialogSupervisionLoadApiComponent,
    ModuleManagerTabCustomLinkComponent,
    DialogMessageComponent,
    DialogDebitComponent,
    DialogCancelPaymentComponent,
    TranslateManagerComponent,
    DialogUpdateVersionComponent,
    DialogExcelChargingComponent,
    DeviceParamsComponent,
    DigitalsignageDevicesComponent,
    DigitalSignageComponent,
    DisplaysComponent,
    DialogDeviceComponent,
    DialogCheckActiveAccountComponent,
    DeviceResponseComponent,
    DeviceFormsComponent,
    OngletContentsComponent,
    OngletSettingsComponent,
    ItemLinkPmsComponent,
    DialogAddEditComponent
    
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    DragDropModule,
    MatExpansionModule,
    MatTabsModule,
    ChartModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatChipsModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right'
        }
      }
    }),
    MatDatepickerModule,
    MatStepperModule,
    MatInputModule,
    MatRadioModule,
    NgxJsonViewerModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    QueryBuilderModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatGridListModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ImageCropperModule,
    AngularEditorModule
  ],
  providers: [
    SettingsComponent,
    UserService,
    AuthService,
    AuthGuard,
    SocketsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
