import { map } from 'rxjs/operators';

import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class MigrationsService {


  constructor(private http: HttpClient) {}
  uri = URI;

  /**
   *
   * @param params
   */
	getData(params = "") {
		return this.http.get(this.uri + "migration/"+params);
  }


  migrate(hotel){
		return this.http.get(this.uri + "migration/execute?hotel="+hotel);
  }
  dropTable(){
		return this.http.get(this.uri + "migration/drop-table");
  }

  migration_users(){
    return this.http.get(this.uri + "migration/migration-users");
  }

  postData(params , data){
    return this.http.post(this.uri + "migration/"+params, data , {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event:any) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    )
  }
}
