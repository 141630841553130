import { ActiveSelectLanguageService } from 'src/app/shared/services/active-select-language.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-trans-custom-input',
  templateUrl: './trans-custom-input.component.html',
  styleUrls: ['./trans-custom-input.component.scss']
})
export class TransCustomInputComponent  implements OnInit {
  constructor(
    private selectLang : ActiveSelectLanguageService
  ) { }

  @Input() type;
  @Input() hotel;
  @Input() model;
  @Input() attr = '';
  @Input() default;
  @Input() indication;
  @Input() label;
  @Input() input;
  @Input() languages;
  @Input() defaultLang :any;
  @Input() html = false;
  currentLang = localStorage['hotel_language'];

  @Output() updateInput = new EventEmitter;


  ngOnInit(){
    this.currentLang = this.defaultLang

    this.selectLang.currentLanguage.subscribe(res=>{
      this.currentLang = res;
    });

    this.html = this.isHTML(this.model[this.currentLang])
  }

  isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true;
    }

    return false;
  }




  /**
   *
   * @param id
   */
  getLang(id) {
    return this.languages.find(x => x.id == id);
  }

  /**
   *
   * @param $event
   */
  updateAction($event){
    this.updateInput.emit($event)
  }



}
