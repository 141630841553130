<!-- Page Content -->
<div class="container-fluid">
  <app-fixed-title [title]='title' [hotel]='hotel'></app-fixed-title>

  <div class="white-box content" *ngIf="devicesWithEvents">
    <div class="row ">
      <div class="col-md-4">
          <input type="search" class="form-control" placeholder="{{'Search'| translate}}" (input)="searchDevice($event.target.value)">
      </div>
      <div class="col-md-8 text-right">
        <button class="btn btn-success" (click)="exportexcel()"><i class="fa fa-cloud-download" aria-hidden="true"></i>         Excel Export</button>

        <button class="btn btn-success" (click)="editOrAddDevice()" *appHasRole="'super-user'">
          <i class="fa fa-plus" ></i>
           {{'Add new device' | translate}}</button>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-lg-4 col-md-6 flex col-12" *ngFor="let object of filteredDevices ; let i = index">

        <!--ITEM DISPLAY-->
        <div class="item-display" >
          <!--media-->
          <a class="media" [routerLink]="['/digital-signage/programs/', hotel.id,object.device.id ]">
            <img src="assets/img/icons/svg/monitor.svg" class="mr-3" alt="...">
            <div class="media-body">
              <h5 class="mt-0">{{object.device.NAME}}</h5>
              <span>{{(getActiveEvent(i) ? "See programs": "Create programs") | translate}}<i class="fa fa-chevron-right" ></i></span>
            </div>
            
          </a>
          <div class="content">
               <app-active-program *ngIf="getActiveEvent(i)" [activeProgram]="getActiveEvent(i)">
               </app-active-program>
                <p *ngIf="!getActiveEvent(i)">
                  {{'No active program yet' | translate}}
                </p>
          </div>
          
          
          
          <button class="btn btn-success details" [routerLink]="['/digital-signage/programs/', hotel.id,object.device.id ]" >{{'Programs' | lowercase | translate}}</button>

          <!--menu dropdown-->
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" class="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            
            <mat-menu  #menu="matMenu"  xPosition="before">
              <button mat-menu-item (click)="editOrAddDevice(object.device)">
                <mat-icon>edit</mat-icon>
                <span>{{'Edit device' | translate}}</span>
              </button>
              <button mat-menu-item (click)="deleteDevice(object.device)" *appHasRole="'super-user'">
                  <mat-icon>delete_forever</mat-icon>
                  <span>{{'Delete device' | translate}}</span>
                </button>
            </mat-menu>

          

        </div>
         <!--./ITEM DISPLAY-->
      </div>
    </div>
  </div>
</div>