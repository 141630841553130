import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'upsell-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss']
})
export class UpsellInformationsComponent implements OnInit {

  constructor() { }

  @Input() offer : any;
  today = new Date();

  ngOnInit() {
  }

  updateIsPermanent(event){

    if(event.target.checked){
      this.offer.DATE_START = null
      this.offer.DATE_STOP = null
    }
  }

}
