
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'upload-input',
  templateUrl: './upload-input.component.html',
  styleUrls: ['./upload-input.component.scss']
})
export class UploadInputComponent implements OnInit {

  constructor() { }
  @Input() model;
  @Input() attr;
  @Input() fb;
  @Input() media;


  MEDIA = '';


  imageSelected(event, attr){
    this.fb.append(attr,  event.target.files[0],  event.target.files[0].name);

  }

  removeImg(attr){
    this.model[attr] = null;
  }

  ngOnInit() {
    this.MEDIA = this.media ? this.media : '';
  }




}
