<div class="container-fluid">
  <app-fixed-title [hotel]='hotel'></app-fixed-title>
  <div class="white-box">
    <div class="text-right">
      <button class="btn btn-success" (click)="addNew()">Add new</button>
      <button class="btn btn-success" (click)="translate()">Translate all texts</button>
    </div>

    <br>
    <div class="form-group" *ngFor="let item of texts; let i = index">
      <input type="text" placeholder="Text" class="form-control" [(ngModel)]="item.label">
    </div>


    <ng-container *ngIf="translated">
      <div class="alert alert-primary">
        <div class="row">
          <div class="col-2">
            <h2>EN</h2>
          </div>
          <div class="col-10">
            <ul>
              <li *ngFor="let item of texts; let i = index">
                "{{item.label}}" : "{{item.label}}",
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="alert alert-primary" *ngFor="let lang of translated | keyvalue">
        <div class="row">
          <div class="col-2">
            <h2>{{lang.key}}</h2>
          </div>
          <div class="col-10">
            <ul>
              <li *ngFor="let item of texts; let i = index">
                "{{item.label}}" : "{{lang.value[i]?.translation}}",
              </li>
            </ul>
          </div>
        </div>
      </div>

    </ng-container>


  </div>
</div>