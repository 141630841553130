<div class="container-fluid">
  <app-fixed-title [title]='title + module.name' [hotel]='hotel'></app-fixed-title>

  <div class="white-box">
    <mat-tab-group>
      
      <!-- FORM CONSTRUCTOR -->
      <mat-tab label="Form">

        <app-form-constructor [variablesAPI]="module.configs.variables" [form]="module.form" [hotel]="hotel"
          (addFieldEvent)="onAddFieldEvent($event)" (saveEvent)="saveModule(false)"
          (changeFieldEvent)="changeFieldEvent($event)" (deleteFieldEvent)="onDeleteFieldEvent($event)">
        </app-form-constructor>

      </mat-tab>

      <!-- API links -->
      <mat-tab label="API links">
        <app-module-manager-tab-api [module]="module"></app-module-manager-tab-api>
      </mat-tab>

      <!-- General Configuration -->
      <mat-tab label="General Configuration">
        <app-module-manager-tab-general [module]="module"></app-module-manager-tab-general>
      </mat-tab>
      <!-- Variable Configuration -->
      <mat-tab label="Variable Configuration">
        <app-module-manager-tab-variable [module]="module"></app-module-manager-tab-variable>
      </mat-tab>

      <mat-tab label="Custom Buttons">
        <app-module-manager-tab-custom-link [slug]="slug" [module]="module"></app-module-manager-tab-custom-link>
      </mat-tab>

      <!-- Table Configuration -->
      <mat-tab label="Table Configuration">
        <app-module-manager-tab-table [module]="module"></app-module-manager-tab-table>
      </mat-tab>

    </mat-tab-group>

    <div class="text-right mrgt-20">
      <button (click)="saveModule(false)" class="btn btn-success">Save and stay</button>
      <button (click)="saveModule()" class="btn btn-success">Save and quit</button>
    </div>

  </div>

</div>