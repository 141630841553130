export const _templates =[
  {
      "id": 309,
      "name": "PMS status",
      "template_api": "pms",
      "endpoint": "pms-connected",
      "title": "PMS status",
      "can_change_period": false,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "GET",
      "default_period": "7_DAYS",
      "configs": "",
      "display_type": "pms",
      "ranked": 0
  },
  {
      "id": 310,
      "name": "SMS sent report",
      "template_api": "SMS_REPORT",
      "endpoint": "stats",
      "title": "SMS sent report",
      "can_change_period": true,
      "subtitle": "SMS",
      "text": null,
      "color": "grey",
      "icon": "fa fa-commenting-o",
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "target": "total_sent"
      },
      "display_type": "highlights",
      "ranked": 0
  },
  {
      "id": 311,
      "name": "SMS sent report",
      "template_api": "SMS_REPORT",
      "endpoint": "stats",
      "title": "SMS sent report",
      "can_change_period": true,
      "subtitle": "SMS",
      "text": null,
      "color": "#49a078",
      "icon": "fa fa-commenting-o",
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "x1": "total_delivered",
          "x2": "total_sent",
          "labels": [
              " Delivered sms"
          ],
          "formula": 1
      },
      "display_type": "donuts",
      "ranked": 0
  },
  {
      "id": 287,
      "name": "Email sent report",
      "template_api": "EMAIL_REPORT",
      "endpoint": "stats",
      "title": "Email sent report",
      "can_change_period": true,
      "subtitle": "EMAILS",
      "text": null,
      "color": "grey",
      "icon": "fa fa-envelope",
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "target": "total_sent"
      },
      "display_type": "highlights",
      "ranked": 1
  },
  {
      "id": 297,
      "name": "Total sales amount",
      "template_api": "Sales_Total",
      "endpoint": "stats",
      "title": "Total sales amount",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": "green",
      "icon": "fa fa-eur",
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "target": "total"
      },
      "display_type": "highlights",
      "ranked": 2
  },
  {
      "id": 299,
      "name": "Total sales amount by period",
      "template_api": "Sales_Total_Amount_By_Period",
      "endpoint": "stats",
      "title": "Total sales amount by period",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeX": "date",
              "axeY": "",
              "type": "column",
              "title": "Total Sales",
              "xtype": "datetime"
          }
      },
      "display_type": "charts",
      "ranked": 3
  },
  {
      "id": 303,
      "name": "Upgrade & Upsell & Walkin  amount by period",
      "template_api": "Upgrade_Upsell_Walkin_Amount_By_Period",
      "endpoint": "stats",
      "title": "Upgrade & Upsell & Walkin  amount by period",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeX": "date",
              "axeY": "",
              "type": "column",
              "xtype": "datetime",
              "grouped": true
          }
      },
      "display_type": "charts",
      "ranked": 4
  },
  {
      "id": 295,
      "name": "Upsell total amount",
      "template_api": "Upsell_Total_Amount",
      "endpoint": "stats",
      "title": "Upsell total amount",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": "mauve",
      "icon": "fa fa-eur",
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "target": "total"
      },
      "display_type": "highlights",
      "ranked": 5
  },
  {
      "id": 302,
      "name": "Upsell total amount by period",
      "template_api": "Upsell_Total_Amount_By_Period",
      "endpoint": "stats",
      "title": "Upsell total amount by period",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeX": "date",
              "axeY": "",
              "type": "column",
              "title": "Total upsell amount",
              "xtype": "datetime",
              "grouped": false
          }
      },
      "display_type": "charts",
      "ranked": 6
  },
  {
      "id": 308,
      "name": "Upsell total amount by product",
      "template_api": "upsell",
      "endpoint": "workflow-products-tag",
      "title": "Upsell total amount by product",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "GET",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeX": "date",
              "axeY": "",
              "type": "column",
              "xtype": "datetime",
              "grouped": true
          }
      },
      "display_type": "charts",
      "ranked": 7
  },
  {
      "id": 296,
      "name": "Upgrade total amount",
      "template_api": "Upgrade_Total_Amount",
      "endpoint": "stats",
      "title": "Upgrade total amount",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": "red",
      "icon": "fa fa-eur",
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "target": "total"
      },
      "display_type": "highlights",
      "ranked": 8
  },
  {
      "id": 300,
      "name": "Upgrade total amount by period",
      "template_api": "Upgrade_Total_Amount_By_Period",
      "endpoint": "stats",
      "title": "Upgrade total amount by period",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeX": "date",
              "axeY": "",
              "type": "column",
              "title": "Total Upgrade",
              "xtype": "datetime",
              "grouped": false
          }
      },
      "display_type": "charts",
      "ranked": 9
  },
  {
      "id": 307,
      "name": "Upgrade total amount by room",
      "template_api": "upgrade",
      "endpoint": "workflow-products-tag",
      "title": "Upgrade total amount by room",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "GET",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeX": "",
              "axeY": "",
              "type": "column",
              "xtype": "datetime",
              "grouped": true
          }
      },
      "display_type": "charts",
      "ranked": 10
  },
  {
      "id": 298,
      "name": "Walkin total amount",
      "template_api": "Walkin_Total_Amount",
      "endpoint": "stats",
      "title": "Walkin total amount",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": "green_2",
      "icon": "fa fa-eur",
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "target": "total"
      },
      "display_type": "highlights",
      "ranked": 11
  },
  {
      "id": 301,
      "name": "Walkin total amount by period",
      "template_api": "Walkin_Total_Amount_By_Period",
      "endpoint": "stats",
      "title": "Walkin total amount by period",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeX": "date",
              "axeY": "",
              "type": "column",
              "title": "Total Upgrade",
              "xtype": "datetime",
              "grouped": false
          }
      },
      "display_type": "charts",
      "ranked": 12
  },
  {
      "id": 306,
      "name": "Walkin total amount by room",
      "template_api": "walkin",
      "endpoint": "workflow-products-tag",
      "title": "Walkin total amount by room",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "GET",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeX": "date",
              "axeY": "",
              "type": "column",
              "xtype": "datetime",
              "grouped": true
          }
      },
      "display_type": "charts",
      "ranked": 13
  },
  {
      "id": 305,
      "name": "Procedure by scenario",
      "template_api": "PROCESS_HISTORY",
      "endpoint": "stats",
      "title": "Procedure by scenario",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeX": "_Date_modification",
              "axeY": "",
              "name": "",
              "type": "column",
              "xtype": "datetime",
              "series": "_workflow_name",
              "classname": "col-md-12"
          }
      },
      "display_type": "supervision",
      "ranked": 14
  },
  {
      "id": 304,
      "name": "Procedure by channel",
      "template_api": "PROCESS_HISTORY",
      "endpoint": "stats",
      "title": "Procedure by channel",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeX": "_Date_modification",
              "axeY": "",
              "name": "",
              "type": "column",
              "xtype": "datetime",
              "series": "Hardware",
              "classname": "col-md-12"
          }
      },
      "display_type": "supervision",
      "ranked": 15
  },
  {
      "id": 293,
      "name": "Procedure by scenario (finished/total)",
      "template_api": "PROCESS_BY_WORKFLOW",
      "endpoint": "stats",
      "title": "Procedure by scenario (finished/total)",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": null,
      "icon": null,
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "chart": {
              "axeY": "",
              "type": "column",
              "xtype": "category",
              "labels": [
                  "total",
                  "finished"
              ]
          }
      },
      "display_type": "charts",
      "ranked": 16
  },
  {
      "id": 288,
      "name": "Process History",
      "template_api": "PROCESS_HISTORY",
      "endpoint": "stats",
      "title": "Process History",
      "can_change_period": true,
      "subtitle": "Procedures",
      "text": null,
      "color": "green",
      "icon": "fa fa-list-ul",
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "operation": "length"
      },
      "display_type": "highlights",
      "ranked": 17
  },
  {
      "id": 294,
      "name": "Total transactions",
      "template_api": "TRANSACTION_IN_KIOSK",
      "endpoint": "stats",
      "title": "Total transactions",
      "can_change_period": true,
      "subtitle": null,
      "text": null,
      "color": "blue",
      "icon": "fa fa-money",
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "target": "total"
      },
      "display_type": "highlights",
      "ranked": 18
  },
  {
      "id": 290,
      "name": "Hardwares report",
      "template_api": "HARDWARE_REPORT",
      "endpoint": "stats",
      "title": "Hardwares report",
      "can_change_period": false,
      "subtitle": null,
      "text": null,
      "color": "#2196F3",
      "icon": null,
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "x1": "count",
          "x2": "total",
          "labels": [
              " active hardwares"
          ],
          "formula": 1
      },
      "display_type": "donuts",
      "ranked": 19
  },
  {
      "id": 289,
      "name": "Opened email  report",
      "template_api": "EMAIL_REPORT",
      "endpoint": "stats",
      "title": "Opened email  report",
      "can_change_period": true,
      "subtitle": "EMAILS",
      "text": null,
      "color": "#9c27b0b3",
      "icon": "fa fa-envelope",
      "method": "POST",
      "default_period": "7_DAYS",
      "configs": {
          "x1": "total_opened",
          "x2": "total_sent",
          "labels": [
              " opened emails"
          ],
          "formula": 1
      },
      "display_type": "donuts",
      "ranked": 20
  },
  {
      "id": 291,
      "name": "Reservation departure report",
      "template_api": "RESERVATION_REPORT_DEPARTURES",
      "endpoint": "stats",
      "title": "Reservation departure report",
      "can_change_period": false,
      "subtitle": null,
      "text": null,
      "color": "#00BCD4",
      "icon": null,
      "method": "POST",
      "default_period": "TODAY",
      "configs": {
          "x1": "completed",
          "x2": "remaining",
          "labels": [
              "Completed departures",
              "Remaining departures"
          ],
          "formula": 2,
          "special_text": true
      },
      "display_type": "donuts",
      "ranked": 21
  },
  {
      "id": 292,
      "name": "Reservation arrivals report",
      "template_api": "RESERVATION_REPORT_ARRIVALS",
      "endpoint": "stats",
      "title": "Reservation arrivals report",
      "can_change_period": false,
      "subtitle": null,
      "text": null,
      "color": "#00BCD4",
      "icon": null,
      "method": "POST",
      "default_period": "TODAY",
      "configs": {
          "x1": "completed",
          "x2": "remaining",
          "labels": [
              "Completed arrivals",
              "Remaining arrivals"
          ],
          "formula": 2,
          "special_text": true
      },
      "display_type": "donuts",
      "ranked": 22
  }
]