import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-stat-config',
  templateUrl: './dialog-stat-config.component.html',
  styleUrls: ['./dialog-stat-config.component.scss']
})
export class DialogStatConfigComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogStatConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }


  types = [
    {name:'PMS', value:'pms'},
    {name:'Hightlights', value:'highlights'},
    {name:'Donut', value:'donuts'},
  ]
  extra  = [
    {name:'Chart', value:'charts'},
    {name:'Monitoring', value:'supervision'}
  ]
  display_type : any;
  after = 0;

  ngOnInit() {
    console.log(this.data)
    this.after = this.data.dashboardConfig.configs.length-1;
    this.getType();
  }

  getType(){
    let template = this.data.item.template;
    if(template){
      this.display_type =  this.data.templates.find(x=>x.id == template)['display_type']
    }
  }

  updateHeight(temp = false){
    this.getType();

    this.data.item.title = this.data.templates.find(x=>x.id == this.data.item.template).title;

    if(this.display_type == 'charts' || this.display_type == 'supervision'){
      this.data.item.rows = 3;
      if(temp){
        this.data.item.cols = 6;
      }
    }
    if(this.display_type == 'donuts' && this.data.item.cols == 3){
      this.data.item.rows = 2;
      if(temp){
        this.data.item.cols = 3;
      }
    }
    if(this.display_type == 'donuts' && this.data.item.cols == 4){
      this.data.item.rows = 3;
      if(temp){
        this.data.item.cols = 4;
      }
    }
    if(this.display_type == 'Hightlights'){
      this.data.item.rows = 1;
      if(temp){
        this.data.item.cols = 3;
      }
    }

  }

  updatePosition(positionIndex){
    if(positionIndex !== ""){
      this.array_move(this.data.dashboardConfig.configs, this.data.index, positionIndex);
      this.data.index = positionIndex;


    }
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};



}
