import { Chart } from 'angular-highcharts';

import { Component, Input, OnChanges, OnInit } from '@angular/core';

export interface chartConfig {
  axeX: string,
  xtype: ("category" | "datetime" | "linear" | "logarithmic" | "treegrid"),
  axeY: string,
  type: ('area' | 'pie' | 'column' | 'percent' | 'spline'),
  series: string,
  classname: string,
  name: string
}


@Component({
  selector: 'app-chart-supervision',
  templateUrl: './chart-supervision.component.html',
  styleUrls: ['./chart-supervision.component.scss']
})
export class ChartSupervisionComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() data: any;
  @Input() chartConfig: chartConfig = {
    axeX: '_Date_modification',
    xtype: 'datetime',
    axeY: '',
    type: 'spline',
    series: 'Devices',
    classname: 'col-md-6',
    name: ''
  }
  @Input() dataSeries : any;


  chart: Chart;
  series = [];

  ngOnInit() {
    console.log(this.data,
      this.chartConfig,
      this.dataSeries)
    setTimeout(()=>{
      this.chart.ref$.subscribe();
    },10)

  }

  buildChart() {
    setTimeout(()=>{
      if(!this.dataSeries){

        let axeX = this.chartConfig.axeX;
        let subseries: any;


        if (this.chartConfig.series != "") {
          subseries = this.regroupDataBy(this.data, this.chartConfig.series);
        } else {
          subseries = { 'All': this.data }
        }

        for (let serie in subseries) {
          let convertedData = [];
          let chartData = {}
          for (let item of subseries[serie]) {
            if (item[axeX]) {
              let index = this.chartConfig.xtype == "datetime" ? new Date(item[axeX]).toISOString().slice(0, 10) : item[axeX]
              let attr = chartData[index];
              chartData[index] = (attr && typeof attr != "undefined") ? (attr + 1) : 1;
            }
          }
          for (let i in chartData) {
            convertedData.push([this.findXValue(i), chartData[i]]);
          }
          this.series.push({ name: serie, data: convertedData.sort(Comparator), type: undefined })
        }
        this.chart = this.getChart(this.series);
      }else {
        this.chart = this.getChart([{name : this.dataSeries.name , data : this.dataSeries.values, type:undefined}])
      }
    },5)
  }

  /**
   *
   * @param data
   * @param attr
   */
  regroupDataBy(data, attr) {
    let newData = {}
    for (let item of data) {

      let index = item[attr] ? item[attr] : "no-data";
      if (typeof newData[index] == "undefined") {
        newData[index] = [];
      }
      newData[index].push(item);
    }
    return newData;
  }

  /**
   *
   * @param val
   */
  findXValue(val) {
    if (this.chartConfig.xtype == 'datetime') {
      let date = new Date(val).toISOString().slice(0, 10)
      return Date.parse(date)
    } else {
      return val
    }
  }



  configDisplay(chartConfig) {
  }


  ngOnChanges() {
    this.series = [];
    this.buildChart();
  }

  /**
   *
   * @param data
   */
  getChart(data) {
    return new Chart({
      chart: {
        type: this.chartConfig.type,
      },
      title: {
        text: ''
      },
      credits: {
        enabled: true
      },
      xAxis: {
        type: 'linear',
        visible: true,
      },
      yAxis: {
        min: 0,
        visible: true
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
          }
        }
      },
      series: data
    });
  }
}


function Comparator(a, b) {
  if (a[0] < b[0]) return -1;
  if (a[0] > b[0]) return 1;
  return 0;
}
