import { DialogDetailDiaplayComponent } from 'src/app/components/modals/dialog-detail/dialog-detail.component';
import { DialogTimelineComponent } from 'src/app/components/modals/dialog-timeline/dialog-timeline.component';

import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogHtmlPreviewComponent } from '../../../dialogs/dialog-html-preview/dialog-html-preview.component';

@Component({
  selector: 'app-table-td',
  templateUrl: './table-td.component.html',
  styleUrls: ['./table-td.component.scss']
})
export class TableTdComponent  {

  constructor(
    public dialog: MatDialog,

  ) { }

  @Input() attr : any;
  @Input() value : any;



  getValBtn(val){
    return val.replace(/<[^>]*>?/gm, '').substring(0,15)
  }

  display(){
     let objs = [true, false, 'Started', 'Confirmed'];
     let attrs = ['_info_steps', 'Country', 'Nationality', '$', "@"];
     return (((objs.indexOf(this.value) == -1  && this.value.length<=50 )
     || typeof this.value == 'number') && attrs.indexOf(this.attr) == -1)
  }

  /**
   *
   * @param value
   */
  openDetail(value){
    this.dialog.open(DialogDetailDiaplayComponent, {
      data: { title: "See detail", text: value }, width:'500px'
    });
  }


  /**
   *
   * @param value
   */
  openDialogHTML(value){
    this.dialog.open(DialogHtmlPreviewComponent, {
      data: value, width:'900px', autoFocus:false
    });
  }
  /**
   *
   * @param value
   */
  openTimeLine(value){
    this.dialog.open(DialogTimelineComponent, {
      data: value, width:'700px'
    });
  }

  has(text, code){
    return text.indexOf(code) != -1;
  }

}
