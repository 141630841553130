<h2 class="mat-dialog-title with-trans">
  {{ 'Manage groups' | translate }}
</h2>

<mat-dialog-content class="mat-typography">

  <table class="table">

    <tbody>


      <tr *ngFor="let group of data.groups; let i = index">

        <td style="width: 50%;"><input type="text" [(ngModel)]="group.name" class="form-control">
        </td>
        <td style="width: 50%;">
          <mat-select [(ngModel)]="group.users" name="group.users" multiple>
            <mat-option *ngFor="let user of data.users" [value]="user.id">
              {{user.first_name}} {{user.last_name}}
            </mat-option>
          </mat-select>
        </td>
        <td>

          <button class="btn btn-danger" (click)="deleteField(group, i)">
            <i aria-hidden="true" class="fa fa-trash"></i>
          </button>
        </td>

      </tr>

    </tbody>
  </table>


  <button class="btn btn-default btn-xs" (click)="addField()">+ {{ "Add group" | translate }}</button>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{ "Cancel" | translate }}</button>
  <button mat-button (click)="saveGroups()"  class="btn btn-success">{{ "Save" | translate }}</button>
</mat-dialog-actions>