<!-- Page Content -->
<div class="container-fluid">
    <app-fixed-title [title]='title' *ngIf="!onglet" [hotel]='hotel'></app-fixed-title>
    <div class="row">

        <div class="col-lg-6 col-md-12">
            <!-- HOTEL LANGUAGES -->
            <hotel-languages  [hotel]="hotel"  [languages]="languages"></hotel-languages>
            <!-- Hotel details section -->

            <!-- Social links section -->
            <!-- <hotel-social-links [socials]="socials" [hotel]="hotel" [languages]="languages"></hotel-social-links> -->

            <!-- Transport arround the hotel section -->
            <!-- <hotel-transport-arround [transports]="transports" [hotel]="hotel" [languages]="languages"></hotel-transport-arround> -->


        </div>
        <div class="col-lg-6 col-md-12">
            <hotel-informations [hotel]="hotel"  [languages]="languages"></hotel-informations>

            <!-- More info section -->
            <!-- <hotel-more-infos [informations]="informations" [hotel]="hotel" [languages]="languages" [icons]="icons"></hotel-more-infos> -->

            <!-- Slider section -->
            <!-- <hotel-sliders [sliders]="sliders" [hotel]="hotel" [languages]="languages"></hotel-sliders> -->

        </div>
    </div>
</div>



<!-- /#page-content-wrapper -->