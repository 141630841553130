<mat-sidenav-container class="sidenav-container ">
  <!-- SIDE NAV -->
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)">
    <mat-toolbar><img src="assets/img/logo-tabhotel.svg" class="logo"> <small class="version">{{version}}</small></mat-toolbar>
    <sidebar-menu [user]='user' [slug]="slug" [menu]="menu" class=" box-scroll "></sidebar-menu>
  </mat-sidenav>
  <!-- SIDE NAV -->


  <!-- MAT SIDE NAV -->
  <mat-sidenav-content>
    <!-- TOOLBAR -->
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </button>
      <div class="namehotel">{{hotel?.name_hotel}} </div>

      <span [routerLink]="['/hotels']" class="link"><i class="fa fa-university" aria-hidden="true"></i>
        Properties
      </span>

      <!-- NOTIFICATION -->
      <ng-container *ngIf="activeModuleNotification">

        <mat-slide-toggle [(ngModel)]="notification" (change)="updateNotif()"  class="inline active-notif">
        </mat-slide-toggle>
        <span *ngIf="!notification" style="font-weight: 400; margin-right: 75px;" (click)="openSettingDialog()">{{'Notifications' | translate}} (0)</span>
      
        <app-manage-notifications [slug]="hotel?.id" *ngIf="notification && hotel"></app-manage-notifications>
      </ng-container>
      <!-- NOTIFICATION -->


      <span *ngIf="demonstration_status" class="alert-demo">
        Demo mode is active
      </span>

      <!-- MENU PROFILE -->
      <mat-menu #appMenu="matMenu" id="profile-menu">
        <a mat-menu-item [routerLink]="['/profile', hotel?.id]" *ngIf="hotel" id="profile" >{{'Profile' | translate}}</a>
        <button mat-menu-item (click)="logoutUser()" id="logout">{{'Logout' | translate}}</button>
      </mat-menu>
      <div class="profil-icon" [matMenuTriggerFor]="appMenu">
        <div class="img">
          <img src="{{base_url}}{{user?.of_userth?.image}}" *ngIf="user?.of_userth?.image">
          <img src="https://demo.tabhotel.com/static/default-user.png" *ngIf="!user?.of_userth?.image" alt="">
        </div>
        <span>{{user?.first_name}} {{user?.last_name}} <small>{{user_role}}</small></span>
        <button mat-icon-button>
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </button>
      </div>
      <!-- MENU PROFILE -->


    </mat-toolbar>
    <!-- TOOLBAR -->

    <!-- ROUTER OUTLET -->
    <router-outlet></router-outlet>
    <!-- ROUTER OUTLET -->


  </mat-sidenav-content>
  <!-- MAT SIDE NAV -->

</mat-sidenav-container>


<!-- NOTIFICATIONS -->
<notifier-container></notifier-container>


<!-- LOADING -->
<ngx-spinner bdOpacity=0.7 bdColor="rgb(50 113 192 / 71%)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true"
  [name]="'global'">
  <p style="color: white"> {{'Loading' | translate}}... </p>
</ngx-spinner>