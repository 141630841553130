import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'input-validator',
  templateUrl: './input-validator.component.html',
  styleUrls: ['./input-validator.component.scss']
})
export class InputValidatorComponent implements OnInit {

  constructor() { }
  @Input() errors ;
  ngOnInit() {

  }

}
