<h1 class="mat-dialog-title">Config Chart "{{data.chart.name}}"</h1>
<div mat-dialog-content>


  <div class="form-group">
    <input type="text" class="form-control" [(ngModel)]="data.chart.name">
  </div>
  <input type="hidden" value="_Date_modification" [(ngModel)]="data.axeX">


  <div class="form-group">
    <select class="form-control" [(ngModel)]="data.chart.type">
      <option value="area">{{'Area' | translate}}</option>
      <option value="spline">{{'Spline' | translate}}</option>
      <option value="column">{{'Column' | translate}}</option>
      <!-- <option value="columnpercent">Column Percent by type</option> -->
    </select>
  </div>
  <div class="form-group ">
    <select class="form-control" [(ngModel)]="data.chart.series">
      <option value="">{{'All' | translate}}</option>
      <ng-container *ngFor="let attr of data.headers">
        <option value="{{attr}}" *ngIf="isDate(attr, false)">{{attr}}</option>
      </ng-container>
    </select>
  </div>
  <div class="form-group ">
    <select class="form-control" [(ngModel)]="data.chart.classname">
      <option value="col-md-3">25%</option>
      <option value="col-md-4">33%</option>
      <option value="col-md-6">50%</option>
      <option value="col-md-8">66%</option>
      <option value="col-md-9">75%</option>
      <option value="col-md-12">100%</option>
    </select>

  </div>
</div>
<div mat-dialog-actions align=end>
  <button class="btn btn-default" [mat-dialog-close]="">{{'Cancel' | translate}}</button>
  <button class="btn btn-success" [mat-dialog-close]="data.chart"
    >{{'Confirm' | translate}}</button>
</div>