import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stat-load',
  templateUrl: './stat-load.component.html',
  styleUrls: ['./stat-load.component.scss']
})


export class StatLoadComponent implements OnInit {


  @Input() data;
  @Input() template;
  @Input() text;
  @Input() type;
  @Input() tile;
  @Output() actionEvent = new EventEmitter();

  constructor(

    ) {

  }

  ngOnInit() {

  }
  actions(event){
    this.actionEvent.emit(event);
  }
}
