import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-duplicate',
  templateUrl: './dialog-duplicate.component.html',
  styleUrls: ['./dialog-duplicate.component.scss']
})
export class DialogDuplicateComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {}

  ngOnInit(){}

}
