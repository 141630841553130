import { NgxSpinnerService } from 'ngx-spinner';
import { GroupService } from 'src/app/shared/services/group.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-group',
  templateUrl: './dialog-group.component.html',
  styleUrls: ['./dialog-group.component.scss']
})
export class DialogGroupComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private groupService: GroupService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
  }


  /**
   *
   * @param data
   * @param index
   */
  createOrUpdate(data, index) {
    this.spinner.show('global');
    if (!data['id']) {
      this.groupService.create({ ...data, hotel: this.data.slug }).subscribe(() => {
        if(this.data.groups.length == index){
          this.spinner.hide('global');
          this.dialogRef.close()
        }

      });
    } else {
      this.groupService.update(data).subscribe(() => {
        if(this.data.groups.length == index){
          this.spinner.hide('global');
          this.dialogRef.close()
        }
      });
    }
  }

  addField() {
    let group = {
      name: '',
      users: ''
    }
    this.data.groups.push(group);
  }

  saveGroups() {
    let index = 0;
    for (let group of this.data.groups) {
      index++;
      this.createOrUpdate(group, index);

    }
  }
  /**
   * DELETE VALUE BY INDEX
   * @param i
   */
  deleteField(group, i) {
    this.data.groups.splice(i, 1);
    if(group.id){
      this.groupService.delete(group.id).subscribe(()=>{
      })
    }
  }


}
