import { NgxSpinnerService } from 'ngx-spinner';

import { Component, OnInit } from '@angular/core';

import { MigrationsService } from '../../shared/services/migrations.service';

@Component({
  selector: 'app-migrations',
  templateUrl: './migrations.component.html',
  styleUrls: ['./migrations.component.scss']
})
export class MigrationsComponent implements OnInit {

  constructor(
    private service : MigrationsService,
    private spinner: NgxSpinnerService,

  ) { }

  categories : any;
  hotel_id : any;
  hotels : any;
  newCategories  : any;

  ngOnInit() {
    //this.spinner.show('global')
    this.service.getData("hotels").subscribe((res:string[])=>{
      this.hotels = res.sort((a, b) => (a['id'] > b['id']) ? 1 : -1);
      //this.spinner.hide('global')
    })
    this.findCategories(4)
  }

  /**
   *
   * @param event
   */
  changeHotel(event){
    this.findCategories(event.value)
  }

    /**
   *
   * @param event
   */
  dropTable(){
    this.service.dropTable().subscribe(res=>{
      console.log(res)
    });
  }

  migration_users(){
    this.service.migration_users().subscribe(res=>{
      console.log(res)
    });
  }




  /**
   *
   * @param hotel_id
   */
  findCategories(hotel_id){
    this.service.getData("categories?hotel="+hotel_id).subscribe(res=>{
      this.categories = res;
      // this.categoryConvertion()
    });
  }

  migrateCategories(hotel){
    this.service.migrate(hotel).subscribe(res=>{
      this.newCategories = res;
    })
  }

  /**
   *
   * @param categories
   */
  categoryConvertion(){
    this.newCategories = []
    for(let cat of this.categories){
      let newCategory = {}
      //create new category without items
      for(let attr in cat){
        if(attr != "items" && attr != "id"){
          newCategory[attr] = cat[attr];
        }
      }

      //create items in subcategory if has subcategory
      for(let item of cat.items){
        if(item.sub_category){
         let sub = newCategory['sub_categories'].find(x=>x.id == item.sub_category);
        if(!sub.items){
          sub.items = []
        }
         sub.items.push({...item, category : null}) ;
        }else {
          newCategory['items'] ? ! newCategory['items'].push({...item, category : cat.id, sub_category:null}) : []
        }
      }
      this.newCategories.push(newCategory)
    }
  }

  /**
   *
   * @param json
   * @param attrs
   */
  customView(json, attrs = ['id', 'name']){
    let newArray = [];
    if(json){
      for(let item of json){
        let newItem = {}
        for(let attr in item){
          if(attrs.includes(attr) && typeof(item[attr]) != "object"){
            newItem[attr] = item[attr]
          }else if(typeof(item[attr]) == "object") {
            newItem[attr] = this.customView(item[attr], attrs)
          }
        }
        newArray.push(newItem)
      }
      return newArray;
    }

  }

}
