import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { CategoryService } from '../../../shared/services/category.service';
import { HotelService } from '../../../shared/services/hotel.service';
import { ItemService } from '../../../shared/services/item.service';
import { SubcategoryService } from '../../../shared/services/subcategory.service';
import { presentation, super_category_attr, super_item_attr, super_subcat_attr } from './fields';

@Component({
  selector: 'app-room-service',
  templateUrl: './room-service.component.html',
  styleUrls: ['./room-service.component.scss']
})
export class RoomServiceComponent implements OnInit {

  constructor(
    private hs: HotelService,
    public cats: CategoryService,
    public dialog: MatDialog,
    public item: ItemService,
    public subcat: SubcategoryService,
    private route: ActivatedRoute,
  ) { }

  slug: any;
  title = "Room Services";
  label = "Room service";
  category_type = "RoomService";
  hotel: any;
  id: any;
  categories: any;
  copy: any;
  searchResults = [];
  filteredCategories = [];
  see_details = {};
  presentation  = presentation;

  superFields = {
    super_category_attr,
    super_item_attr,
    super_subcat_attr
  };


  ngOnInit() {

  }



}
