import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URI } from '../shared/settings/global';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
  
  loginUrl = URI+'rest-auth/login/';
  isLoggedIn = false;
  constructor(
    private router: Router,
    private http : HttpClient
  ) {}

  registerUser(user){

  }



  /**
   * 
   * @param user 
   */
  loginUser(user){
    return this.http.post<any>(this.loginUrl, user)
  }


  loggedIn(){
    return !!localStorage.getItem('_token');
  }
  getToken(){
    let _token = localStorage.getItem('_token');
    if(_token && _token != "undefined" && typeof(_token) != "undefined"){
      return _token;
    }
    return false
  }
}