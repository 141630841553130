import { NgxSpinnerService } from 'ngx-spinner';

import { Component, Input, OnInit } from '@angular/core';

import { _PERIODS } from '../../constants';

@Component({
  selector: 'app-stat-highlights',
  templateUrl: './stat-highlights.component.html',
  styleUrls: ['./stat-highlights.component.scss']
})
export class StatHighlightsComponent implements OnInit {


  @Input() data :any;
  @Input() title :any;
  @Input() template:any;
  @Input() tile;

  constructor(
    private spinner: NgxSpinnerService,
  ) { }


  getCount(){
    let count = 1;
    for(let i in this.data){
      count++;
    }
    return count
  }

  ngOnInit() {
  }

  getPeriod(){
    return _PERIODS[this.template.default_period]
  }

}
