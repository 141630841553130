import { NgxSpinnerService } from 'ngx-spinner';
import { TriggersService } from 'src/app/shared/services/triggers.service';
import { EXPORT_NOTIF_TYPES } from 'src/app/shared/settings/_const';

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-trigger',
  templateUrl: './dialog-trigger.component.html',
  styleUrls: ['./dialog-trigger.component.scss']
})
export class DialogTriggerComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogTriggerComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private triggerService: TriggersService,
    private spinner: NgxSpinnerService,


  ) { }

  admin_id =  localStorage.getItem('admin_id');

  export_types = EXPORT_NOTIF_TYPES


  errors : any;
  /**
   *
   * @param data
   */
  create(data){
    this.triggerService.create({...data.data, customer: this.data.slug, created_by: this.admin_id}).subscribe(res => {
      this.spinner.hide('global');
      this.dialogRef.close(res);
    }, err=>{
      this.errors = err.error
      this.spinner.hide('global');

    });
  }
  /**
   *
   * @param data
   */
  update(data){
    this.triggerService.update({...data.data}).subscribe(res => {
      this.spinner.hide('global');
      this.dialogRef.close(res);
    }, err=>{
      this.errors = err.error
      this.spinner.hide('global');

    });
  }

  /**
   *
   * @param data
   */
  createOrUpdate(data){
    this.spinner.show('global');
    if (!data['id']) {
      this.create(this.data);
    } else {
      this.update(this.data);
    }
  }



}
