import { NgxSpinnerService } from 'ngx-spinner';
import { FaqService } from 'src/app/shared/services/faq.service';
import { MEDIA } from 'src/app/shared/settings/global';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-faq-question',
  templateUrl: './dialog-faq-question.component.html',
  styleUrls: ['./dialog-faq-question.component.scss']
})
export class DialogFaqQuestionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogFaqQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public it: FaqService,
    private spinner: NgxSpinnerService,

  ) { }
  fb = new FormData();
  quest: any;
  MEDIA = MEDIA;
  errors = null;

  ngOnInit() {
    this.quest = this.data.quest;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  changed(event) {
    let target = event.target.value;
    target = event.target.innerHTML;
  }

  /**
   *
   * @param quest
   */
  addOrEditQuestion(quest) {
    this.spinner.show('global');

    for (let v in quest) {
      if (v != "image" && quest[v]) {
        this.fb.append(v, quest[v]);
      }
    }
    if (quest.id) {
      this.it.editFaqItem(this.fb,quest.id).subscribe(res => {
        this.spinner.hide('global');
        for(let attr in res){
          this.quest[attr] = res[attr]
        }
        this.dialogRef.close({res, action : 'edit'});
      })
    }
    else {
      this.it.addFaqItem(this.fb).subscribe(res => {
        this.spinner.hide('global');
        this.quest = res;
        this.dialogRef.close({res, action : 'add'});
      })
    }
  }
}
