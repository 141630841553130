<div class="white-box nopadd">
  <span class="title gradient"></span>
  <span class="text gradient"></span>
  <span class="cercle gradient" *ngIf="template?.display_type == 'donuts'"></span>
  <ul *ngIf="template?.display_type == 'charts' || template?.display_type == 'supervision'">
    <li class="gradient"></li>
    <li class="gradient"></li>
    <li class="gradient"></li>
    <li class="gradient"></li>
    <li class="gradient"></li>
    <li class="gradient"></li>
    <li class="gradient"></li>
    <li class="gradient"></li>
    <li class="gradient"></li>
    <li class="gradient"></li>
  </ul>
</div>