import { MEDIA } from 'src/app/shared/settings/global';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss']
})
export class ViewDetailsComponent implements OnInit {

  constructor( 
  ) { }

  @Input() model : any;
  @Input() attrs : any;
  @Input() label : any;
  MEDIA = MEDIA;

  private _album = [];

  /**
   * BUILD URL FOR IMAGES
   * @param src
   */
  buildUrl(src){
    if(src && src.search('http') === -1){
      return this.MEDIA + src;
    }else {
      return src;
    }
  }

  ngOnInit(){
    if(this.model.slides){
      for (let v of this.model.slides) {
        const src = this.buildUrl(v.image);
        const album = {
           src: src,
           caption: this.model.name,
        };
        this._album.push(album);
      }
    }

  }
  open(index: number): void {
    // open lightbox
 
  }

  openImage(img, name){
 
  }
  close(): void {
    // close lightbox programmatically
 
  }
}
