import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceService } from 'src/app/shared/services/device.service';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { SignageService } from 'src/app/shared/services/signage.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-device-response',
	templateUrl: './device-response.component.html',
	styleUrls: ['./device-response.component.scss']
})
export class DeviceResponseComponent implements OnInit {

	constructor(
		private deviceService: DeviceService,
		private hs: HotelService,
		private router: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private _router: Router,
		public dialog: MatDialog) { }

	title = "Devices form";
	device: any;
	slug: any;
	hotel: any;
	form: any;
	languages: any;
	response: any;
	_breadCrumbs = [];

	ngOnInit() {
		this.spinner.show('global');

		this.hs.getLanguages().subscribe((data) => {
			this.languages = data;
		});


		this.router.params.subscribe(params => {
			if (params.slug == "undefined") {
				this._router.navigate(['/hotels'])
			}
			this.slug = params.slug;

			this.hs.getHotel(this.slug).subscribe((data) => {
				this.hotel = data;
			});


			this.deviceService.getDeviceDetail(params.device).subscribe(res => {
				this.device = res;
				this.form = JSON.parse(this.device['FORM_JSON']);
				// console.log(this.form)
				this.response = this.device['INPUTS_VALUES'] ? JSON.parse(this.device['INPUTS_VALUES']) : [];
				this.title = "Fill the form for device " + this.device.NAME;
				this.spinner.hide('global');
				this._breadCrumbs = [
					{
						"href": '/digital-signage/params',
						"label": "Params Devices"
					}, {
						"label": this.device.NAME
					}
				]
			});

		});
	}

	/**
	*
	*
	*/
	saveValue() {
		this.spinner.show('global');
		this.device['INPUTS_VALUES'] = JSON.stringify(this.response);
		this.deviceService.updateDevice(this.device).subscribe(res => {
			this.device = res;
			this.spinner.hide('global');
			// this.redirect.navigate(['/digital-signage/params', this.slug]);
		})
	}

}
