<h2 mat-dialog-title>{{(item.id ? "Edit Item" : "Add Item") | translate}}
  <app-select-translate [hotel]="hotel" [languages]="languages"></app-select-translate>
</h2>
<!--content-->
<mat-dialog-content class="mat-typography">

  <app-form-builder [attrs]="attrs" [hotel]="hotel" [model]="item" [superFields]="superFields" type="item"
    [extraObject]="extraObject" [languages]="languages" [errors]="errors"
    ></app-form-builder>

</mat-dialog-content>
<!--content-->

<mat-dialog-actions align="end">
  <button mat-dialog-close class="btn btn-warning">{{'Cancel' | translate}}</button>

  <button (click)="createOrUpdate(item, true)"  class="btn btn-success" *ngIf="!item.id">
    <i class="fa fa-floppy-o" aria-hidden="true"></i> {{'save and add another' | translate}}
  </button>
  <button (click)="createOrUpdate(item)"  class="btn btn-success">
    <i class="fa fa-floppy-o" aria-hidden="true"></i> {{"Save" | translate}}
  </button>
</mat-dialog-actions>