import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { server, URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class ModuleManagerService {


  constructor(private http: HttpClient) { }

  uri = URI + "module-manager/";


  /**
   *
   * @param url
   */
  api(path){
    return this.http.get(server+"/api/v1/"+path);
  }


  /**
   *
   * @param params
   */
  list(params = null) {
    return this.http.get(this.uri + params);
  }

  /**
   *
   * @param id
   */
  get(id) {
    return this.http.get(this.uri + id);
  }


  /**
   *
   * @param object
   */
  update(object) {
    return this.http.put(this.uri + object.id + '/', object);
  }

  /**
   *
   * @param object
   */
  create(object) {
    return this.http.post(this.uri, object);
  }


  /**
   *
   * @param id
   */
  delete(id) {
    return this.http.delete(this.uri + id);
  }

}
