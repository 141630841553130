import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'upsell-items-to-show',
  templateUrl: './items-to-show.component.html',
  styleUrls: ['./items-to-show.component.scss']
})
export class UpsellItemsToShowComponent implements OnInit {

  constructor() { }

  @Input() offer: any;
  @Input() labels : any;
  @Input() features=[];
  @Input() rooms = [];
  @Input() devices = [];
  @Input() scenarios = [];
  @Input() priceType = [];
  @Input() guest = [];
  @Input() categories = {};

    /**
   * SET DATA IN ARRAY
   * @param path
   * @param obj
   * @param value
   */
  resolve(path, obj, value) {
    let l = path.split('.').length;
    let i = 0;
    return path.split('.').reduce(function(prev, curr) {
        i++;
        if(i == l){
          prev[curr] = value
        }
        return prev ? prev[curr] : null
    }, obj || self)
  }

  /**
   * CHANGED SELECT2 EVENT
   * @param event
   * @param model
   */
  changed(event, model) {
      this.resolve(model, this.offer, event.value)
  }

  ngOnInit() {
  }

}
