import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { SignageService } from 'src/app/shared/services/signage.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-displays',
  templateUrl: './displays.component.html',
  styleUrls: ['./displays.component.scss']
})
export class DisplaysComponent implements OnInit {

  /**
   * CONSTRUCTEUR
   */
  constructor(
    private signage : SignageService,
    private hs: HotelService,
    private router : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _router : Router,
    public dialog: MatDialog,


  ) {

  }

  title="Displays"
  displays: any;
  languages : any;
  slug : any;
  hotel:any;
  displayedColumns: string[] = ['ID_DISPLAY', 'NAME', 'FORM_JSON', 'FOR_WORKFLOW','action'];
  sortedData : any;
  // MatPaginator Inputs
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  user_id =  localStorage.getItem('user_id');



  // MatPaginator Output
  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  ngOnInit() {
    this.spinner.show('global');
    this.router.params.subscribe(params => {

      if(params.slug =="undefined"){
          this._router.navigate(['/hotels'])
      }
      this.slug = params.slug
      this.hs.getHotel(this.slug).subscribe((data) => {
          this.hotel = data;
      });

      this.hs.getLanguages().subscribe((data) => {
        this.languages = data
      });
    });

    this.signage.getDisplayList(this.user_id, this.slug).subscribe(res=>{
      this.displays = res['displays'];
      this.sortedData = this.displays.slice();
      this.spinner.hide('global');
    })
  }


  openDialogDisplay(){
  }

  /**
   * SORT DATA TABLE
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.displays.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'NAME': return compare(a.name, b.name, isAsc);
        case 'FORM_JSON': return compare(a.calories, b.calories, isAsc);
        case 'FOR_WORKFLOW': return compare(a.fat, b.fat, isAsc);
        default: return 0;
      }
    });
  }

  /**
   *
   * @param element
   */
//   setDefaultValues(element){

//     let data = {
//       form:element.FORM_JSON,
//       languages : this.languages,
//       hotel:this.hotel,
//       program : {CONTENT:element.INPUTS_VALUES}
//     }

//     const dialogRef = this.dialog.open(FormProgComponent, {data, width:'1200px'});
//     dialogRef.afterClosed().subscribe(res => {
//       element.INPUTS_VALUES = JSON.stringify(res);
//       if(res){
//         this.signage.updateDisplay(element).subscribe(res=>{
//           element = res;
//         })
//       }


//     });
//   }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
