import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class DefaultValueService {

  constructor(private http: HttpClient) {}
  uri = URI;

  /**
   * GET DEFAULT VALUE BY HOTEL
   * @param id
   */
  getDefaultValue(customer_id, name) {
		return this.http.get(`${this.uri}`+"default-value/"+customer_id+"/"+name);
  }

  /**
   * CREATE DEFAULT VALUE
   * @param id
   */
  createDefaultValue(defaultValue) {
		return this.http.post(`${this.uri}`+"default-values/", defaultValue);
  }

  /**
   * UPDATE DEFAULT VALUE
   * @param id
   */
  updateDefaultValue(defaultValue) {
		return this.http.put(`${this.uri}`+"default-values/"+defaultValue.id+"/", defaultValue);
  }



  /**
   * UPDATE DEFAULT VALUE
   * @param id
   */
  delete(defaultValue) {
		return this.http.delete(`${this.uri}`+"default-values/"+defaultValue.id+"/");
  }


  /**
   * 
   * @param data 
   * @param slug 
   */
  updateOrCreate(data, slug){
    if(data.hotel == slug && data.id){
      return this.updateDefaultValue(data);
    }else if(!data.id){
      return this.createDefaultValue(data);
    }
  }

 



}
