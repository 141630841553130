<form [formGroup]="formgroup" (ngSubmit)="sendCode()" *ngIf="!uid">
  <div class="limiter">
    <div class="container-login100 ">
      <div class="wrap-login100 fadeInUp">
        <div class="login100-form validate-form">

          <img src="assets/img/logo-tabhotel.svg">
          <br>
          <br>
          <br>
          <div class="login100-form-title p-b-34 p-t-27">
            Forgot password
          </div>
          <br>
          <div class="error" *ngIf="message">
            {{message}}
          </div>
          <br>
          <br>
          <div *ngIf="success" class="success">
            You have received an email to reset your password, please check.
          </div>
         
          
          <br>
          <div class="wrap-input100 validate-input" data-validate="Enter email" *ngIf="!success" >
            <label>Email</label>
            <input autocomplete="disabled" required class="input100" type="email" [(ngModel)]="user['email']"
              formControlName="email" placeholder="{{'Email' | translate}}">
            <span class="icon"><img src="/assets/img/icons/svg/user.svg" alt=""></span>
            <!-- validator -->
            <div *ngIf="submitted && formgroup.controls.email.errors" class="text-error">
              <div *ngIf="formgroup.controls.email.errors.required">{{'Email is required' | translate}}
              </div>
              <div *ngIf="formgroup.controls.email.errors.email">
                {{'Email must be a valid email address' | translate}}</div>
            </div>
            <!-- validator -->
          </div>
          <br>
          <br>
          <div class="container-login100-form-btn"  *ngIf="!success">
            <button type="submit" class="login100-form-btn" *ngIf="!loading">
              Send code via email
            </button>
            <div class="lds-ellipsis" *ngIf="loading">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</form>

<form [formGroup]="formgroupReset" autocomplete="off" (ngSubmit)="resetPassword()" *ngIf="uid">
  <div class="limiter">
    <div class="container-login100 ">
      <div class="wrap-login100 fadeInUp">
        <div class="login100-form validate-form">

          <img src="assets/img/logo-tabhotel.svg">
          <br>
          <br>
          <br>
          <div class="login100-form-title p-b-34 p-t-27">
            Reset password
          </div>
          <br>
          <div class="error" *ngIf="message2">
            {{message2}}
          </div>
          <br>
          <br>
          <br>


          <div class="wrap-input100 validate-input" data-validate="Enter new password">
            <label>Enter new password</label>
            <input autocomplete="disabled" required class="input100" type="password" [(ngModel)]="reset['password']"
              formControlName="password" placeholder="{{'New password' | translate}}">
            <span class="icon"><img src="/assets/img/icons/svg/password.svg" alt=""></span>
            <!-- validator -->
            <div *ngIf="formgroupReset.errors?.notSame" class="text-error">{{'Passwords are not the same' | translate}}
            </div>

            <div *ngIf="submittedReset && formgroupReset.controls.password.errors" class="text-error">
              <div *ngIf="formgroupReset.controls.password.errors.required">{{'Password is required' | translate}}</div>
              <div *ngIf="formgroupReset.controls.password.errors.minlength">{{'Password must be more than 4 digits' |
                translate}}</div>
            </div>
            <!-- validator -->
          </div>

          <div class="wrap-input100 validate-input" data-validate="Confirm password">
            <label>Confirm password</label>
            <input autocomplete="disabled" required class="input100" type="password"
              [(ngModel)]="reset['confirm_password']" formControlName="confirm_password"
              placeholder="{{'Confirm password' | translate}}">
            <span class="icon"><img src="/assets/img/icons/svg/password.svg" alt=""></span>
            <!-- validator -->
            <div *ngIf="formgroupReset.errors?.notSame" class="text-error">{{'Passwords are not the same' | translate}}
            </div>

            <div *ngIf="submittedReset && formgroupReset.controls.password.errors" class="text-error">

              <div *ngIf="formgroupReset.controls.confirm_password.errors.required">{{'Password is required' |
                translate}}</div>
              <div *ngIf="formgroupReset.controls.confirm_password.errors.minlength">{{'Password must be more than 4
                digits' | translate}}</div>
            </div>
            <!-- validator -->
          </div>

          <div class="container-login100-form-btn">
            <button type="submit" class="login100-form-btn" *ngIf="!loading">
              Reset password
            </button>
            <div class="lds-ellipsis" *ngIf="loading">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</form>