 <!-- whitebox-->
 <div class="white-box">
    <h2 class="title">{{'Slider' | translate}}</h2>
    <p *ngIf="sliders?.length == 0" (click)="openDialogSlider(true)" class="link-first">
        {{'Be the first to add slider' | translate}}</p> 
    <span (click)="openDialogSlider()" class="open-modal">
        <i class="fa fa-pencil-square-o"></i>
    </span>
    <div id="carousel" class="carousel slide" data-ride="carousel" *ngIf="sliders?.length">
        <ol class="carousel-indicators">

            <li data-target="#carousel" *ngFor="let slider of sliders; let index = index"
                [class.active]="index == 0" [attr.data-slide-to]="index"></li>
        </ol>
        <div class="carousel-inner">
               
            <div class="carousel-item " *ngFor="let slider of sliders; let index = index"
                [class.active]="index == 0">
                <img src="{{buildUrl(slider.first_image)}}" class="d-block w-100">
            </div>

        </div>
        <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
            <span class="sr-only">{{'Previous' | translate}}</span>
        </a>
        <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon"></span>
            <span class="sr-only">{{'Next' | translate}}</span>
        </a>
    </div>
</div>
<!-- whitebox-->