<!-- Page Content -->


<div class="container-fluid">

  <app-fixed-title [title]='title' [hotel]='hotel'></app-fixed-title>


  <div class="white-box content" *ngIf="customer">
    <div class="alert alert-danger" *ngIf="errorsMessage">
      {{errorsMessage}}
    </div>

    <div class="alert alert-success" *ngIf="successMessage">
      Customer <strong>"{{customer.clone.new_customer.name_hotel}}"</strong> created with ID
      <strong>"{{successMessage}}"</strong>, <a href="/configuration/{{successMessage}}">click here configure to its
        backoffice.</a>
    </div>

    <h4>Clone from</h4>
    <div class="row">

      <!-- SOURCE ID -->
      <div class="col-md-4">
        <div class="form-group number">
          <label>Source customer ID</label>
          <select class="form-control" [(ngModel)]="customer.clone.id_customer_source"
            (change)="getDevices($event.target.value);onChange();">
            <option [value]="hotel.id" *ngFor="let hotel of hotels">{{hotel.name_hotel}}</option>
          </select>
        </div>
      </div>
      <!-- SOURCE ID -->

      <!-- NUMBER OF DEVICE TO CREATE -->
      <div class="col-md-4">
        <div class="form-group number">
          <label>Number devices to create</label>
          <input class="form-control " type="number" min="1" max="10" (change)="onChange();"
            [(ngModel)]="customer.clone.number_devices_to_create">
        </div>
      </div>
      <!-- NUMBER OF DEVICE TO CREATE -->



      <!-- DEVICE TO CLONE -->
      <div class="col-md-4">
        <div class="form-group text">
          <label>Device model to clone</label>
          <select class="form-control" [(ngModel)]="customer.clone.device_model_to_clone" (change)="onChange();">
            <option [value]="device.UUID_DEVICE" *ngFor="let device of devices">{{device.UUID_DEVICE}} - {{device.NAME}}
            </option>
          </select>
        </div>
      </div>
      <!-- DEVICE TO CLONE -->


      <!-- CUSTOMER SLUG -->
      <div class="col-md-4">
        <div class="form-group text">
          <label>New customer slug </label>
          <input class="form-control" (input)="updateSlug($event.target.value)" (change)="$event.target.value=corrigerCaracteres($event.target.value);onChange();"
            [(ngModel)]="customer.clone.new_customer.name_hotel_slug" type="text">
        </div>
      </div>
      <!-- CUSTOMER SLUG -->



      <!-- Customer NAME  -->
      <div class="col-md-4">
        <div class="form-group text">
          <label>Customer name </label>
          <input class="form-control" [(ngModel)]="customer.clone.new_customer.name_hotel" (change)="onChange();" type="text">
        </div>
      </div>
      <!-- Customer NAME  -->



      <!-- PMS -->
      <div class="col-md-4">
        <div class="form-group text">
          <label>PMS ID</label>
          <select class="form-control" [(ngModel)]="customer.Duplicate.Pms.id" (change)="onChange();">
            <option [value]="pms.id" *ngFor="let pms of pmses">{{pms.id}} - {{pms.pms_name}}</option>
          </select>
        </div>
      </div>
      <!-- PMS -->


      <!-- languages -->
      <div class="col-md-4">
        <div class="form-group text">
          <mat-form-field>
            <mat-label>Languages</mat-label>
            <mat-select multiple [(ngModel)]="customer.Get['LanguageHotel$1']" (change)="onChange();">
              <mat-option *ngFor="let lang of languages" [value]="{lang_code: lang.lang_code}">{{lang.lang_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- languages -->

      <!-- default language -->
      <div class="col-md-4 form-group text" *ngIf="customer.Get['LanguageHotel$1'].length">
        <mat-form-field>
          <mat-label>Default language</mat-label>
          <mat-select [(ngModel)]="customer.Get['LanguageHotel$2']" (selectionChange)="onChange();">
            <mat-option *ngFor="let lang of customer.Get['LanguageHotel$1']; let i = index"
              [value]="{lang_code: lang.lang_code}">{{lang.lang_code}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- default language -->


    </div>
    <div class="row">
      <div class="form-group col-md-4">
        <mat-checkbox [(ngModel)]="customer.clone.create_dns_ovh">Create dns ovh</mat-checkbox>
      </div>

      <div class="form-group col-md-4">
        <mat-checkbox [(ngModel)]="customer.clone.clone_concierge" style="display:none">Clone concierge</mat-checkbox>
      </div>
      <div class="form-group col-md-4">
        <mat-checkbox [(ngModel)]="customer.clone.clone_offers" style="display:none">Clone offers</mat-checkbox>
      </div>
      <div class="form-group col-md-4">
        <mat-checkbox [(ngModel)]="customer.clone.clone_notification">Clone notifications</mat-checkbox>
      </div>
      
    </div>
    <hr style="display:none">

    <h4 class="mat-dialog-title with-trans" style="display:none">
      Device Params

    </h4>



    <div class="row" >
      <div style="display:none">
      <div class="col-md-3">
        <div class="form-group">
          <label>Main color 1</label><br>
          <input class="form-control"
            [(ngModel)]="customer.data_to_update.DEVICES.params_techniques.INPUTS_VALUES.main_color_1" type="color">

        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label>Main color 2</label><br>
          <input class="form-control"
            [(ngModel)]="customer.data_to_update.DEVICES.params_techniques.INPUTS_VALUES.main_color_2" type="color">

        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label>Main color 3</label><br>
          <input class="form-control"
            [(ngModel)]="customer.data_to_update.DEVICES.params_techniques.INPUTS_VALUES.main_color_3" type="color">

        </div>
      </div>
      <div class="col-md-3">

        <ng-container *ngIf="customer.Get['LanguageHotel$1'].length">
          <ng-container *ngFor="let lang of customer.Get['LanguageHotel$1']; let i = index">

            <div class="form-group form-translate-{{lang.lang_code}} translated" [ngClass]="{'hidden': i != 0}">
              <label for="">Customer name ({{lang.lang_code}})</label>
              <input type="text" class="form-control"
                [(ngModel)]="customer.data_to_update.DEVICES.params_generaux.INPUTS_VALUES['hotel_name_'+lang.lang_code]">
            </div>
          </ng-container>

        </ng-container>

      </div>



      <div class="col-md-4">
        <form-select-icons label="Logo" [attr]="{name:'logo'}"
          [model]="customer.data_to_update.DEVICES.params_generaux.INPUTS_VALUES" [hotel]="hotel" size='big'
          [tab_icon]="false"></form-select-icons>
      </div>
      </div>
      <div class="col-md-12 text-right">
        <button class="btn btn-success" (click)="createCustomer()">Add new customer</button>  
      </div>
      <div class="col-md-12">
        <debug *ngIf="!edit" [obj]="{customer:customer}"></debug>
      </div>
  

    </div>
  </div>
</div>