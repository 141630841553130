import { ActiveSelectLanguageService } from 'src/app/shared/services/active-select-language.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-translatable',
  templateUrl: './image-translatable.component.html',
  styleUrls: ['./image-translatable.component.scss']
})
export class ImageTranslatableComponent  implements OnInit{

  constructor(
    private selectLang : ActiveSelectLanguageService
  ) { }

  @Input() field;
  @Input() response;
  @Input() serverApi;
  @Input() languages:any;
  @Input() hotel: any;
  @Output() openMedia= new EventEmitter();
  fieldsTrans = {}
  currentLang = "en";
  changeEvent;

  ngOnInit(){
    this.currentLang = this.getLang(this.hotel.default_lang).lang_code

    this.selectLang.currentLanguage.subscribe(res=>{
      this.currentLang = res;
      this.changeEvent = true
      console.log(res, "---lang")
    })

    for(let lang of this.hotel.languages_param){
      this.fieldsTrans[this.getLang(lang).lang_code] = {...this.field, name : this.field.name +'_' + this.getLang(lang).lang_code }
    }
  }

  /**
   *
   * @param field
   */
  openMediaManager(field){
    this.openMedia.emit(field)
  }

  /**
   * remove image by index
   * @param field
   * @param i
   */
  removeImg(field, i = false) {
    if (field.multiple) {
      this.response[field.name].splice(i, 1);
    } else {
      this.response[field.name] = "";
    }
  }


  /**
   *
   * @param id
   */
  getLang(id) {
    return this.languages.find(x => x.id == id);
  }


  /**
   *
   * @param field
   * @param lang
   */
  getName(field, lang){
    return field  + '_' +this.getLang(lang).lang_code
  }
}
