import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { CategoryService } from '../../../shared/services/category.service';
import { HotelService } from '../../../shared/services/hotel.service';
import { ItemService } from '../../../shared/services/item.service';
import { SubcategoryService } from '../../../shared/services/subcategory.service';
import { presentation, super_category_attr, super_extra_attr, super_item_attr, super_subcat_attr } from './fields';

@Component({
  selector: 'app-areamap',
  templateUrl: './areamap.component.html',
  styleUrls: ['./areamap.component.scss']
})
export class AreamapComponent implements OnInit {

  constructor(
    private hs: HotelService,
    public cats: CategoryService,
    public dialog: MatDialog,
    public item: ItemService,
    public subcat: SubcategoryService,
    private route: ActivatedRoute,
  ) { }

  slug: any;
  title = "Area Map";
  label = "Area Map";
  category_type = "MapPosition";
  hotel: any;
  id: any;
  categories: any;
  copy: any;
  searchResults = [];
  filteredCategories = [];
  see_details = {};
  presentation  = presentation;

  //Override inputs
  superFields = {
    super_category_attr,
    super_item_attr,
    super_subcat_attr,
    super_extra_attr
  }


  ngOnInit() {

  }



}
