<h2 class="mat-dialog-title with-trans">
  {{(data.room.id ? 'Edit' : 'Add') | translate }} {{'Room Category' | translate }}
  <app-select-translate [hotel]="hotel"></app-select-translate>

</h2>
<input-validator *ngIf="errors" [errors]="errors"></input-validator>

<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-4">
      <div class="form-group">
        <translated-input [model]="data.room" attr="name" [hotel]="hotel" type="input" label="{{'Name' | translate}}">
        </translated-input>
        <app-error-input *ngIf="errors" [errors]="errors" name="name"></app-error-input>

      </div>
    </div>
    <div class="col-lg-4" *appHasRole="'super-user'">
      <div class="form-group">
        <label>{{'ID PMS' | translate}}</label>
        <input type="text" [(ngModel)]="data.room.id_pms" class="form-control">
        <app-error-input *ngIf="errors" [errors]="errors" name="id_pms"></app-error-input>

      </div>
    </div>

    <div class="col-lg-4 form-group">
      <label>{{'Max Guests' | translate}}</label>
      <input type="number" [(ngModel)]="data.room.max_guests" class="form-control">
      <app-error-input *ngIf="errors" [errors]="errors" name="max_guests"></app-error-input>

    </div>

    <div class="col-lg-12">
      <div class="form-group">
        <translated-input [model]="data.room" attr="short_description" [hotel]="hotel" [type]="'htmleditor'"
          label="{{'Short description' | translate}}"></translated-input>
        <app-error-input *ngIf="errors" [errors]="errors" name="short_description"></app-error-input>

      </div>
    </div>

    <div class="col-lg-12">
      <div class="form-group">
        <translated-input [model]="data.room" attr="long_description" [hotel]="hotel" [type]="'htmleditor'"
          label="{{'Long description' | translate}}"></translated-input>
        <app-error-input *ngIf="errors" [errors]="errors" name="long_description"></app-error-input>

      </div>
    </div>

    <!--slider field-->
    <div class="form-group col-lg-6">

      <form-select-icons [attr]="{name: 'image_square'}" [model]="data.room" [hotel]="hotel" [tab_icon]="false"
        [size]="'big'" label="{{'First Image' | translate}}"></form-select-icons>
      <app-error-input *ngIf="errors" [errors]="errors" name="image_square"></app-error-input>

    </div>
    <!--slider field-->

    <!--slider field-->
    <div class="form-group  col-lg-6">
      <form-select-icons [attr]="{name: 'image_rectangular'}" [model]="data.room" [hotel]="hotel" [tab_icon]="false"
        [size]="'big'" label="{{'Second Image' | translate}}"></form-select-icons>
      <app-error-input *ngIf="errors" [errors]="errors" name="image_rectangular"></app-error-input>

    </div>
    <!--slider field-->


    <div class="col-lg-6">
      <div class="form-group">
        <mat-checkbox [(ngModel)]="data.room.active">{{'Active' | translate}}</mat-checkbox>

      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{'Cancel' | translate}}</button>
  <button mat-button (click)="createOrUpdate(data.room)" class="btn btn-success">{{'Save' | translate}}</button>
</mat-dialog-actions>