import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {

  constructor(
    private vcRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver) {}

  @ViewChild('iframe') iframe: ElementRef;
  doc: any;
  compRef: any;


  createComponent() {
    const compFactory = this.resolver.resolveComponentFactory(IframeComponent);
    this.compRef = this.vcRef.createComponent(compFactory);

    this.doc.body.appendChild(this.compRef.location.nativeElement);
  }
  //https://stackblitz.com/edit/angular-component-iframe?file=src%2Fapp%2Fapp.module.ts

  onLoad() {
    this.doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
  }

  ngAfterViewInit() {
    this.onLoad();
  }

  ngOnDestroy() {
    if(this.compRef) {
      this.compRef.destroy();
    }
  }
  ngOnInit() {
  }

}
