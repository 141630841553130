import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/shared/services/user.service';
import { LANGUAGES } from 'src/app/shared/settings/_const';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-user',
  templateUrl: './dialog-user.component.html',
  styleUrls: ['./dialog-user.component.scss']
})
export class DialogUserComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private us: UserService,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,

  ) {
    this.notifier = notifierService;

  }



  private readonly notifier: NotifierService;

  fb = new FormData();
  IMGS = [
    'image'
  ];
  user: any;
  type: any;
  hotel: any;
  hotelsToManage: any;
  users: any;
  errors: any;
  confirm_password: any;
  error_password: any;
  id: any;
  value: any;
  errorPassword = true;
  errorRole = false;
  langs = LANGUAGES
  lengthPasswordErr = true;

  ngOnInit() {
    console.log(this.data)
    this.id = this.data.id;
    this.type = this.data.type;
    this.hotel = this.data.hotel;
    this.hotelsToManage = this.data.hotelsToManage;
    this.data.password1 = "";
    this.data.is_tabhotel_member = this.data.id ? this.data.is_tabhotel_member : false;
    this.data.compte_test = this.data.id ? this.data.compte_test : false;
    this.data.send_notification_email = this.data.id ? this.data.send_notification_email : false;
  }

  addRole(){
    if(this.data.roles == undefined){
      this.data.roles = [];
    };
    this.data.roles.push({
      id:'',
      role:'',
    });
  }

  verifyRoles(event) {
    let value = event.value;
    let element = this.data.roles.filter(x => x['id'] == value);
    if (element.length>1){
      this.errorRole = true;
    } else {
      this.errorRole = false;
    }
  }

  deleteRole(i) {
    this.data.roles.splice(i, 1);
  }


  /**
   * VERIFY PASSWORD VALIDATOR
   * @param $event
   */
  verifyPassword(event) {
    let value = event.target.value;
    this.errorPassword = (value != this.data.password1)
  }

  /**
   * TEST LEGTH PASSWORD < 4
   */
  lengthPassword() {
    if (this.data.password1 && this.data.password1.length < 6) {
      this.lengthPasswordErr = true;
      return 'error';
    } else if (this.data.password1 && this.data.password1.length >= 6) {
      this.lengthPasswordErr = false;
      return 'was-validated';
    }

  }

  /**
   *
   * @param user
   */
  addOrUpdateUser(user) {
    if (this.confirmPassword() && !this.errorRole) {
      this.spinner.show('global');

      if (user.id) {
        this.updateUser(user);
      } else {
        this.createUser(user);
      }
    }

  }


  confirmPassword(){
      return (!this.errorPassword && this.data.password1 != "" && !this.lengthPasswordErr) || (this.data.password1 == "")
  }

  /**
   *
   * @param data
   * @param user
   */
  updateUser(data){
    this.us.update(data, this.id).subscribe(() => {
      this.dialogRef.close({ success: true, action : "update" });
      this.translate.get('User successfully updated').subscribe(
        value => {
          this.notifier.notify('success', value);
        }
      );
      this.spinner.hide('global');
    }, err => {
      this.spinner.hide('global');
      this.errors = err.error;
    });

  }
  /**
   *
   * @param data
   * @param user
   */
  createUser(data){
    this.us.create(data).subscribe(() => {
      this.dialogRef.close({ success: true, action : "add" });
      this.translate.get('User successfully created').subscribe(
        value => {
          this.notifier.notify('success', value);
        }
      );
      this.spinner.hide('global');
    }, err => {
      this.spinner.hide('global');
      this.errors = err.error;
    })
  }

  getHotel(id){
    return this.hotelsToManage.find(x=>x.id == id);
  }
  updateRoles(){
    console.log(this.data)
    for(let hotel of this.data.hotels){
      console.log(hotel, this.data.roles.findIndex(x=> parseInt(x.id) == parseInt(hotel)), this.data.roles, "dd5d5d5d")
      if(this.data.roles.findIndex(x=>parseInt(x.id) == parseInt(hotel)) == -1){
        this.data.roles.push({
          id : parseInt(hotel),
          role :this.data.type
        })
      }
    }
    setTimeout(()=>{
      for(let role_hotel of this.data.roles){
        console.log(role_hotel,this.data.hotels.indexOf(role_hotel['id']), "----")
        if(this.data.hotels.indexOf(role_hotel['id']) == -1){
          let index = this.data.roles.findIndex(x=>x.id == role_hotel.id);
          this.data.roles.splice(index, 1)
        }
      }
    },10)

  }

  getError(name) {
    if (this.errors) {
      if (name.split('.').reduce((p, c) => p && p[c] || null, this.errors) != "undefined") {
        return 'error';
      }
    }
  }

  hideError(path){
  }


}
