 <!-- whitebox-->
 <div class="white-box content">
    <span (click)="openDialogSocial()" class="open-modal" *ngIf="socials?.length<=6"> 
        <i class="fa fa-plus-circle" aria-hidden="true"></i>
    </span>
    
    <h2 class="title">{{ 'Social media and other links' | translate }}</h2>
    <p *ngIf="socials?.length == 0" (click)="openDialogSocial()" class="link-first">
      {{'Be the first to add social link' | translate }}</p> 
    <div class="box-scroll h-150">

        <table class="info">
            <tr *ngFor="let social of socials; let i = index ">
                <td class="icon">
                    <img src="/assets/img/icons/svg/{{social?.name_social}}.svg" style="height: 25px">
                </td>
                <td>{{social?.link}}</td>
                <td class="text-right">
                    <button class="btn dis-onhover edit" (click)="openDialogSocial(social)"><i class="fa fa-pencil"
                            aria-hidden="true"></i></button>
                    <button class="btn dis-onhover delete"
                        (click)="deleteConfirm(social, social.name_social,'Link',i)"><i
                            class="fa fa-trash-o" aria-hidden="true"></i></button>
                </td>

            </tr>

        </table>
    </div>
</div>
<!-- whitebox-->