import { NotifierService } from 'angular-notifier';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { GroupService } from 'src/app/shared/services/group.service';
import { HardwareService } from 'src/app/shared/services/hardware.service';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { TriggerTypeService } from 'src/app/shared/services/trigger-type.service';
import { TriggersService } from 'src/app/shared/services/triggers.service';
import { UserService } from 'src/app/shared/services/user.service';
import { WorkflowService } from 'src/app/shared/services/workflow.service';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DialogExportTriggerComponent } from '../../dialogs/dialog-export-trigger/dialog-export-trigger.component';
import { DialogGroupComponent } from '../../dialogs/dialog-group/dialog-group.component';
import { DialogLogComponent } from '../../dialogs/dialog-log/dialog-log.component';
import { DialogTrameComponent } from '../../dialogs/dialog-trame/dialog-trame.component';
import { DialogTriggerComponent } from '../../dialogs/dialog-trigger/dialog-trigger.component';
import { ApiService } from 'src/app/shared/services/api.service';
@Component({
  selector: 'app-manage-triggers',
  templateUrl: './manage-triggers.component.html',
  styleUrls: ['./manage-triggers.component.scss']
})
export class ManageTriggersComponent implements OnInit {

  constructor(
    private triggerService: TriggersService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    notifierService: NotifierService,
    private groupService: GroupService,
    private userService: UserService,
    private workflowService: WorkflowService,
    private hardwareService: HardwareService,
    private hotelService : HotelService,
    private translate: TranslateService,
    private triggerTypeService: TriggerTypeService,
    private apiservice : ApiService

  ) {
    this.notifier = notifierService;
  }

  private readonly notifier: NotifierService;
  triggers: any;
  triggersMaintenance: any;
  triggersM :any
  workflows: any;
  slug: any;
  title = 'Manage Triggers';
  hotel : any;
  displayedColumns: string[] = ['id', 'name', 'category', 'scenario_name', 'export_type', 'active', 'groups', 'actions'];
  groups: any;
  users: any;
  categories: any;
  @Input() hardwares:any;
  notifications_keys:any;
  @ViewChild('table') table: MatTable<string[]>;


  ngOnInit() {
    this.apiservice.get('notification-key/').subscribe(res => {
      this.notifications_keys = res
    })  

    this.route.params.subscribe(params => {
      this.slug = params.slug

      this.hotelService.getHotel(this.slug).subscribe(res=>{
        this.hotel = res;
      })
      this.triggerService.list('?customer=' + this.slug+"&is_maintenance=false").subscribe(res => {
        this.triggers = res;
      });
      this.triggerService.list('?customer=' + this.slug+"&is_maintenance=true").subscribe(res => {
        this.triggersMaintenance = this.getTramsofMaintenance(res);
        this.triggersM = res;
      });

      this.workflowService.getWorkflowsByCustomer(this.slug).subscribe(res => {
        this.workflows = res;
      })
      this.groupService.list('?hotel=' + this.slug).subscribe(res => {
        this.groups = res;
      })
      this.userService.getAll(this.slug).subscribe(res => {
        this.users = res;
      })
      this.hardwareService.getHardwares(this.slug).subscribe(res => {
        this.hardwares = res;
      })
      this.triggerTypeService.list().subscribe(res => {
        this.categories = res;
      })

    })
  }




  /**
   *
   * @param data
   */
  openDialog(data ={},index=null): void {

    const dialogRef = this.dialog.open(DialogTriggerComponent, {
      width: '650px',
      data : {data:{...data}, slug: this.slug, groups: this.groups, workflows: this.workflows, categories: this.categories}
    });

    dialogRef.afterClosed().subscribe(res => {
      if(res && index != null){
        this.triggers[index] = res;
      } else if (res && index === null) {
        this.triggers.unshift(res);
      }
    });
  }

  /**
   *
   * @param data
   * @param index
   */
  openGroupDialog() {

    const dialogRef = this.dialog.open(DialogGroupComponent, {
      width: '950px',
      // data : {data:{...data}, slug: this.slug, users: this.users}
      data: {groups: this.groups, slug: this.slug, users: this.users}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.groupService.list('?hotel=' + this.slug).subscribe(res => {
        this.groups = res;
      })
    });
  }


  /**
   * DELETE TRIGGER
   * @param trigger
   * @param index
   */
  deleteConfirm(trigger, index){
    let type = "trigger";
    let name = trigger.name;
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: { name, type }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //Request Information
        this.triggerService.delete(trigger.id).subscribe(() => {
          this.triggers.splice(index, 1);
          this.translate.get('trigger successfully deleted').subscribe(
            value => {
              this.notifier.notify('success', value);
            }
          );
        },
          err => console.log(err))
      }
    });

  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.triggers, event.previousIndex, event.currentIndex);
    let obj = {
      triggers: this.triggers.map(a => a.id),
    }

    this.triggerService.sort(obj).subscribe(
      () => {
        this.translate.get('trigger priority successfully updated').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
      },
      () => {
        this.translate.get('An error has occurred').subscribe(
          value => {
            this.notifier.notify('error', value);
          }
        );
      })
  }


  /**
   *
   * @param trigger_id
   * @param data
   * @param index
   */
  trameDialog(trigger_id, data = {}, index = null): void {
    let dialogRef = this.dialog.open(DialogTrameComponent, {
      width: '850px',
      data: {
          data: { ...data },
          slug: this.slug,
          trigger: trigger_id,
          groups: this.groups,
          hardwares: this.hardwares,
          workflows : this.workflows,
          hotel : this.hotel,
          notifications_keys : this.notifications_keys
        }
    });

    if(!data['is_maintenance']){
      let indexTr = this.triggers.findIndex(x=>x.id == trigger_id)
      dialogRef.afterClosed().subscribe(res => {
        if (res && index != null) {
          this.triggers[indexTr].trames[index] = res;
        } else if (res && index === null) {
          this.triggers[indexTr].trames.unshift(res);
        }
      });
    }else  {
      dialogRef.afterClosed().subscribe(res => {
        let tr = res;
        tr.is_maintenance = true;
        this.triggersMaintenance[index] = tr
      })
    }

  }

  /**
   *
   * @param $event
   */
  openTramDialog($event){
    this.trameDialog($event.trigger_id, $event.obj , $event.i )
  }


  /**
   *
   * @param trigger
   * @param i
   */
  duplicate(trigger){
      let newTrigger = {...trigger, name : 'Copy '+trigger.name};
      delete newTrigger.id;
      this.openDialog(newTrigger);
  }

  /**
   *
   * @param trigger
   */
  getExportType(trigger){
    let types = [];
    for(let tr of trigger.trames){
      for(let ex of tr.export_type){
        if(types.indexOf(ex) == -1){
          types.push(ex)
        }
      }
    }
    return types;
  }

  /**
   *
   */
  logDialoag() {
    this.dialog.open(DialogLogComponent, {  width: '1250px' });
  }

  /**
   *
   * @param val
   */
  getCategoryName(val){
    if(this.categories && this.categories.find(x=>x.key == val)){
      return this.categories.find(x=>x.key == val).name
    }
  }

  getTramsofMaintenance(triggers){
    let array = []
    for(let trigger of triggers){
      for(let trame of trigger.trames){
        let tr = trame;
        tr.is_maintenance = true;
      array.push(trame);
    }
  }
    return array;
  }

  exportTriggers(){
    this.dialog.open(DialogExportTriggerComponent, {
      width: '950px',
      data : {maintenance : JSON.stringify(this.triggersM) , triggers : JSON.stringify(this.triggers)}
    });

    console.log(this.triggers);
  }

}
