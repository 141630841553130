import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
	uri = URI

	constructor(private http: HttpClient) { }

	/**
	 *
	 * @param id
	 */
	getWorkflowDetail(id){
		return this.http.get(`${this.uri}` +"workflow-with-steps/"+id);
	}


	/**
	 *
	 * @param id
	 */
	getWorkflowStep(id){
		return this.http.get(this.uri +"workflow-description/"+id);
	}

	/**
	 *
	 * @param url
	 */
	getContent(url){
		return this.http.get(url);
	}


	getPreview(url){
		return this.http.get('https://www.googleapis.com/pagespeedonline/v1/runPagespeed?url=' +encodeURIComponent(url) + '&screenshot=true')
	}
	/**
	 *
	 * @param step
	 */
	updateWorkflowStep(step){
		return this.http.put(`${this.uri}` +"workflow-description/"+step.id, step);
	}

	/**
	 *
	 * @param obj
	 */
	translate(obj){
		return this.http.post(`${this.uri}`+"translate-ibm/", obj);
	}


	/**
	 *
	 * @param id
	 */
	getWorkflowsByCustomer(id){
		return this.http.get(`${this.uri}` +"workflow/?FOR_PARAMAS=true&CUSTOMER="+id);
	}


	/**
	 *
	 * @param uid
	 * @param customer
	 * @param group
	 */
	getWorkflowTranslation(uid, customer,group = 'user'){
		if(group == 'user')
			return this.http.get(`${this.uri}` +"getWorkflowTranslation/"+uid+"?version=v2&customer="+customer);
		else
			return this.http.get(`${this.uri}` +"getGeneralWorkflowTranslation/"+uid+"?version=v2&customer="+customer);
	}

	/**
	 * SAVE WORKFLOW TRANS CONTENT
	 * @param body
	 * @param group
	 */
	setWorkflowTranslation(body , group = 'user'){
		if(group == 'user')
			return this.http.post(`${this.uri}` +"setTranslation/", body);
		else
			return this.http.post(`${this.uri}` +"setAdminTranslation/", body);

	}

	/**
	 *
	 * @param id
	 */
	getTemplateContent(id){
		return this.http.get(`${this.uri}` +"templates/"+id);
	}

	/**
	 *
	 * @param template
	 */
	updateTemplateContent(template){
		return this.http.put(`${this.uri}` +"templates/"+template.id+"/", template);
	}


}

