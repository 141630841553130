<app-build-categories
[categoryId]="4"
[title]="title"
[id]="id"
[filteredCategories]="filteredCategories"
[label]="label"
[category_type]="category_type"
[superFields]="superFields"
[displaySubcategory]="false"
[presentation]="presentation"
[hideprice]="false"
[onglet]="onglet"
></app-build-categories>