import { MEDIA } from 'src/app/shared/settings/global';

import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SliderComponent } from '../../modals/slider/slider.component';

@Component({
  selector: 'hotel-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.scss']
})
export class SlidersComponent {

  constructor(
    public dialog: MatDialog,
  ) { }
  MEDIA = MEDIA;
  @Input() sliders= [];
  @Input() hotel:any;
  @Input() languages:any;


  /**
   *
   * @param src
   */
  buildUrl(src){
    if(src && src.search('http') === -1){
      return this.MEDIA + src;
    }else {
      return src;
    }
  }

  /**
   *
   * @param edit
   */
  openDialogSlider(edit = false){

    let data = {sliders : this.sliders, edit, languages: this.languages, hotel:this.hotel };
    const dialogRef = this.dialog.open(SliderComponent, {data:data});
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
       // this.sliders = res;
      }
      let temp = JSON.parse(JSON.stringify(this.sliders));
      this.sliders = [];
      this.sliders = temp;

      console.log(res)
    })
  }


}
