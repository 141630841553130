import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-manager-tab-api',
  templateUrl: './module-manager-tab-api.component.html',
  styleUrls: ['./module-manager-tab-api.component.scss']
})
export class ModuleManagerTabApiComponent implements OnInit {

  constructor() { }

  @Input() module;

  ngOnInit() {
  }

}
