import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-template',
  templateUrl: './main-template.component.html',
  styleUrls: ['./main-template.component.scss']
})
export class MainTemplateComponent implements OnInit {

  constructor(
    private router: Router,

  ) { }

  ngOnInit() {
    if(localStorage['roles'] == undefined){
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

}
