import { NgxSpinnerService } from 'ngx-spinner';
import { TriggersService } from 'src/app/shared/services/triggers.service';

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-dialog-log',
  templateUrl: './dialog-log.component.html',
  styleUrls: ['./dialog-log.component.scss']
})
export class DialogLogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private triggerService: TriggersService,
    private spinner: NgxSpinnerService,
  ) { }
  @ViewChild(MatPaginator, {read:false}) paginator: MatPaginator;

  logs : any;
  user_id = localStorage.getItem('admin_id')

  displayedColumns = ["trame_text",'condition', 'trigger_type','notification','is_read','hardware','created_at'];

  ngOnInit() {
    this.spinner.show('global');
    this.triggerService.getLog(this.user_id).subscribe(res=>{
      this.logs = res['results'];
      this.spinner.hide('global');

    })
  }

}
