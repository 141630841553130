import { NotifierService } from 'angular-notifier';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmDeleteComponent } from '../../modals/confirm-delete/confirm-delete.component';
import { category_attr } from '../fields/category-fields';
import { BuildCategoriesComponent } from '../this/build-categories.component';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.scss']
})
export class SubcategoriesComponent implements OnInit {

	private readonly notifier: NotifierService;

	constructor(public dialog: MatDialog,
		public BuildCategoriesComponent : BuildCategoriesComponent,
		private translate: TranslateService,
		notifierService: NotifierService
	) {
		this.notifier = notifierService;
	}

	@Input() category:any;
	@Input() see_details:false;
	@Input() hotel:any;
	@Input() category_type : "RoomService";
	@Input() superFields : any;
	@Input() btnNewItem :any;
	@Input() languages:any;
	@Input() hideprice : any;

	category_attr = category_attr;
	activeIndex = 0;

	ngOnInit() {
	}



	/**
	 * CONFIRM DELETE CATEGORY
	 * @param category
	 * @param index
	 */
	openDialog(category, index): void {
		const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
			width: '390px',
			data: { category, laguages: this.languages }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == 'delete') {
				this.category.subcategories.splice(index, 1);
				this.activeIndex = this.category.subcategories.length - 1;
				this.translate.get('Subcategory successfully deleted').subscribe(
					value => {
					  this.notifier.notify('success', value);
					}
				  );
			}
		});
	}

	/**
	 * TAB CLICK EVENT
	 * @param tab
	 * @param number
	 * @param subcategories
	 */
	tabClick(number) {

		this.BuildCategoriesComponent.addSubCategory(this.category)
		setTimeout(()=>{this.activeIndex = number },1);

	}

}
