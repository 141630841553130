import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogMessageComponent } from 'src/app/components/modals/dialog-message/dialog-message.component';
import { ActiveSelectLanguageService } from 'src/app/shared/services/active-select-language.service';
import { WorkflowService } from 'src/app/shared/services/workflow.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DialogPreviewComponent } from '../../dialogs/dialog-preview/dialog-preview.component';
import { DialogTemplateEditorComponent } from '../../dialogs/dialog-template-editor/dialog-template-editor.component';
import { convertAfter, convertBefore } from './utilities';

@Component({
  selector: 'app-steps-trans',
  templateUrl: './steps-trans.component.html',
  styleUrls: ['./steps-trans.component.scss']
})
export class StepsTransComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private workflowService: WorkflowService,
    notifierService: NotifierService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private selectLang : ActiveSelectLanguageService


  ) {
    this.notifier = notifierService;
  }
  private readonly notifier: NotifierService;

  @Input() workflow: any;
  @Input() slug: any;
  @Input() hotel: any;
  @Input() languages: any;
  @Input() defaultLang: any;
  @Input() workflowContent: any;
  @Input() group: any;
  @Output() getList = new EventEmitter()
  displayLang : any;
  userRole = localStorage.getItem('user_role');
  currentLang :any;

  ngOnInit() {
    this.currentLang = this.defaultLang;
    this.getTranslation();

    this.selectLang.currentLanguage.subscribe(res=>{
      this.currentLang = res;
    });


  }


  displayLongText(message){
    (message.length) && this.dialog.open(DialogMessageComponent, {
      data:{title:"Preview text", message}, width:"700px"
    })
  }
  /**
   *
   * @param hideSpinner
   */
  getTranslation(hideSpinner=false){
    //GET WORKFLOW TEXT CONTENT
    this.workflowService.getWorkflowTranslation(this.workflow.UUID_WORKFLOW, this.slug, this.group).subscribe((res:any) => {
      //CONVERT DATA BECAUSE ITS NOT OPTIMIZED FOR ANGULAR
      let list =  res.data.sort((a, b) => (a.order > b.order) ? 1 : -1)
      this.workflowContent = convertBefore({data:list});
      this.getList.emit(this.workflowContent);
      this.displayLang = Array(this.workflowContent.data.length).fill(this.defaultLang, 0, this.workflowContent.data.length - 1, )
      hideSpinner && this.spinner.hide('global')
    }, err=>{
      this.spinner.hide('global');
    });
  }


  /**
  * SAVE CONTENT
  */
  saveContent(template = null, spinner = true) {
    //
    if (spinner)
      this.spinner.show('global');
    let saveContent = convertAfter(this.workflowContent.data);

    let fb = new FormData();
    fb.append('format', 'v2');
    fb.append('translation', JSON.stringify(saveContent));
    fb.append('customer', this.slug);

    this.workflowService.setWorkflowTranslation(fb, this.group).subscribe(res => {
      this.spinner.hide('global');
      if (res['success']) {
        this.translate.get('Texts are saved').subscribe(
          value => {
            this.notifier.notify('success', value);
          }
        );
      } else {
        this.translate.get(res['message']).subscribe(
          value => {
            this.notifier.notify('error', value);
          }
        );
      }
    })
  }



  /**
   * DIALOG PREVIEW
   * @param template
   */
  dialogPreview(template, i) {
    this.dialog.open(DialogPreviewComponent, {
      data: {url : template.url_preview, currentLang: this.currentLang,languages : this.languages, hotel:this.hotel,defaultLang:this.displayLang[i] }
    });

  }


  /**
   * DIALOG TEMPLATE EDIT
   * @param template
   */
  dialogTemplate(template) {
    const dialogTemplate = this.dialog.open(DialogTemplateEditorComponent, {
      data: { template }, width: '1100px'
    });

    dialogTemplate.afterClosed().subscribe(result => {
      if(result){
        this.spinner.show('global');
        this.getTranslation(true);
      }
    });
  }


  isTemplate(template){
    // console.log(template, "ddd")
    return template.template_id != -1;
  }

  /**
   * DISPLAT GOOD TEXT
   * @param text
   */
  formatText(text) {
    return text.split('_').join(' ')
  }

  /**
 * I DONT KNOW WHATS IS THIS
 */
  getDefaultText(text) {
    return text[this.defaultLang]
  }

  /**
   *
   * @param template
   */
  displayCardCondition(template){
    return this.userRole == 'super-user' || template.translations.length
  }

  /**
   *
   * @param $event
   * @param i
   */
  getLang($event, i){
    this.displayLang[i] = $event;
  }


  clearData(){
    this.workflowContent.data.map(x=>{
      x.translations.map(y=>{
        
        y.content[this.currentLang] = ""
      })
    })
  }
}
