<h1 class="mat-dialog-title">{{'Room detail' | translate}}</h1>
<div mat-dialog-content>
  
  <table class="table table-striped">
    <thead class="thead-dark">
      <tr>
        <th>{{'Room floor' | translate}} </th>
        <th>{{'Room number' | translate}} </th>
        <th>{{'Number of guests' | translate}} </th>
        <th>{{'Room category name' | translate}} </th>
        <th>{{'Is available' | translate}} </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let room of data">
        <td>{{room.room_floor}}</td>
        <td>{{room.room_number}}</td>
        <td>{{room.number_of_guests}}</td>
        <td>{{room.room_category_name}}</td>
        <td>{{room.is_available}}</td>
      </tr>
    </tbody>
  </table>

</div>
<div mat-dialog-actions align=end>
  <button class="btn btn-success" [mat-dialog-close]="" >{{'Close' | translate}}</button>
</div>