import { ActiveSelectLanguageService } from 'src/app/shared/services/active-select-language.service';
import { LANGUAGES } from 'src/app/shared/settings/_const';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-translate',
  templateUrl: './select-translate.component.html',
  styleUrls: ['./select-translate.component.scss']
})
export class SelectTranslateComponent implements OnInit {

  constructor(
    private selectLang : ActiveSelectLanguageService
  ) { }

  @Input() hotel;
  @Input() languages;
  @Input() fixed = true;
  @Input() parent : any;
  @Input() data_id : any;
  @Input() model : any;
  @Input() default ;
  hotel_lang = localStorage['hotel_language'];
  @Output() changeEvent = new EventEmitter<string>();
  active = {lang : 'fr'};
  langList = LANGUAGES;

  getLanguage(id){
    return  this.hotel.languages.find(x=>x.id == id);
  }

  activeLang(langId){
    return false;
  }

  ngOnInit() {

    this.model &&  (this.hotel_lang = this.model);

    if(this.hotel){
      this.active.lang = this.getLanguage(this.hotel.default_lang).lang_code
    }
  }



  /**
   *
   * @param val
   */
  updateModel(val){
    this.model = val;
    this.selectLang.setLanguage(val);
    this.changeEvent.emit(val);
  }

}
