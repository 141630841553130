<!-- Page Content -->
<div class="container-fluid">


  <app-fixed-title [title]='title' *ngIf="!onglet" [hotel]='hotel' [action]="true" (clickEvent)="init()"></app-fixed-title>

  <h4 class="mt-3" *ngIf="onglet">{{title}}</h4>

  <div class="row">
    <div class="col-md-4 col-6">
      <div class="white-box triggers" [ngClass]="{'active':indexTab == 0 }">
        <div class="row" (click)="setTab(0)">

          <div class="col-8">
            <h1>{{ 'Administrators' | translate }} </h1>
            <p>{{ 'Manage administrator users' | translate }}</p>
          </div>
          <div class="col-4">
            <img src="/assets/img/icons/svg/008-man.svg" class="img-responsive" alt="">
          </div>
        </div>
        <div class="footer-white-box" (click)="openDialogUser('administrator',null, 0)">
          {{ 'Add new administrator' | translate }}
          <i class="fa fa-plus-circle" aria-hidden="true"></i>

        </div>
      </div>
    </div>
    <div class="col-md-4 col-6">
      <div class="white-box triggers"  [ngClass]="{'active':indexTab == 1 }">
        <div class="row" (click)="setTab(1)">

          <div class="col-8">
            <h1>{{ 'Users' | translate }} </h1>
            <p>{{ 'Manage simple users' | translate }}</p>
          </div>
          <div class="col-4">
            <img src="/assets/img/icons/svg/010-concierge-1.svg" class="img-responsive" alt="">
          </div>
        </div>
        <div class="footer-white-box" (click)="openDialogUser('concierge', null, 1)">
          {{ 'Add new user' | translate }}
          <i class="fa fa-plus-circle" aria-hidden="true"></i>

        </div>
      </div>
    </div>

    <div class="col-md-4 col-6">
      <div class="white-box triggers"  [ngClass]="{'active':indexTab == 2 }">
        <div class="row"  (click)="setTab(2)">

          <div class="col-8">
            <h1>{{ 'Groups' | translate }} </h1>
            <p>{{ 'Manage groups' | translate }} </p>
          </div>
          <div class="col-4">
            <img src="/assets/img/icons/svg/team.svg" class="img-responsive" alt="">
          </div>
        </div>
        <div class="footer-white-box" (click)="openGroupDialog()">
          {{ 'Add group' | translate }}
          <i class="fa fa-plus-circle" aria-hidden="true"></i>

        </div>
      </div>
    </div>
  </div>
 


  <div class="row">
    <div class="col-md-12">
      <div class="white-box content without-header">
        <mat-tab-group [(selectedIndex)]="indexTab">
          <mat-tab>

            <app-table-users  [groups]="groups" [users]="users" [hotelsToManage]="hotelsToManage" [id]="id" [type]="'administrator'" [group_id]="group_administrator_id" [hotel]="hotel"></app-table-users>

          </mat-tab>
          <mat-tab>
              <app-table-users  [groups]="groups" [users]="users" [hotelsToManage]="hotelsToManage" [id]="id" [type]="'concierge'" [group_id]="group_concierge_id" [hotel]="hotel"></app-table-users>
          </mat-tab>

          <mat-tab>
              <app-groups [groups]="groupsTh"  [slug]="slug" [users]="users"></app-groups>
          </mat-tab>
        </mat-tab-group>

      </div>
    </div>
  </div>
</div>