<!-- Page Content -->
<div class="row searchbar">
  <div class="col-md-8">
    <img src="assets/img/logo-tabhotel.svg" class="logo"> <small class="version">{{version}}</small>
  </div>
  <div class="col-md-4">
    <input type="search" placeholder="Search" [(ngModel)]="search" class="form-control" (input)="searchForCustomer()">
  </div>
</div>
<div class="container-fluid">
  <div class="row justify-content-md-center" *ngIf="hotels">

    <div class="col-md-6 col-12 flex" [ngClass]="{'col-lg-4': hotels.length<4, 'col-lg-2': hotels.length>=4}"
      *ngFor="let hotel of searchList; let i = index">
      <a class="white-box hotel-content" [ngClass]="{'template-hotel': hotel.is_template, 'deactivate-hotel': !hotel.is_active, 'test-mode-hotel': hotel.is_in_test_mode, 'not-monitored-hotel': !hotel.is_monitored, 'demo-hotel': hotel.is_demo_customer}" [routerLink]="['/redirection', hotel.id]" id="client-{{hotel.id}}">
        <div class="img-c">
          <img src="{{hotel.logo}}" alt="" class="img-responsive">
        </div>
        <h4>{{hotel.name_hotel}}</h4>
      </a>
    </div>
  </div>
</div>


<ngx-spinner bdOpacity=0.7 bdColor="#3271c0" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true"
  [name]="'global'">
  <p style="color: white"> {{'Loading' | translate}}... </p>
</ngx-spinner>