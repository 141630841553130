<!-- Page Content -->
<div class="container-fluid">
  <app-fixed-title [customtitle]='title' [hotel]='hotel' [links]="_breadCrumbs"
    [icon]="'/assets/img/icons/svg/workflow-icon.svg'" *ngIf="!workflow_id"></app-fixed-title>


  <div class="white-box content" id="translate_generic">
    <div class="select-lang absolute" *appHasRole="'super-user'">
      {{'Language' | translate}}
      <app-select-translate [hotel]="hotel" data_id="translate_generic" (changeEvent)="automaticLang = $event"
        [languages]="languages" [fixed]="false">
      </app-select-translate>
    </div>

    <div class="select-lang" *appHasntRole="'super-user'">
      {{'Language' | translate}}
      <app-select-translate [hotel]="hotel" data_id="translate_generic" (changeEvent)="automaticLang = $event"
        [languages]="languages" [fixed]="false">
      </app-select-translate>
    </div>

    
    <!-- ONLY FOR SUPER USER -->
    <mat-tab-group *appHasRole="'administrator'" >

      <!--ADMIN TRANSLATE-->
      <mat-tab [label]="'Translation for admin' | translate" *ngIf="workflow">
        
        <workflow-tab-content
        [automaticLang]="automaticLang"
        [hotel]="hotel"
          [slug]="slug"
          [defaultLang]="defaultLang"
          [languages]="languages"
          [workflow]="workflow"
          group="admin"
        ></workflow-tab-content>
      

      </mat-tab>
      <!--ADMIN TRANSLATE-->

      <mat-tab [label]="'Translation for user' | translate" *ngIf="workflow">

        <workflow-tab-content
        [automaticLang]="automaticLang"
        [hotel]="hotel"
        [slug]="slug"
        [defaultLang]="defaultLang"
        [languages]="languages"
        [workflow]="workflow"
        group="user"
        
      ></workflow-tab-content>
         
      </mat-tab>
    </mat-tab-group>
    <!-- ONLY FOR SUPER USER -->


    <!-- if is not super User -->
    <ng-container *appHasntRole="'super-user'">
      <ng-container *ngIf="workflow">
        <workflow-tab-content

        [hotel]="hotel"
        [slug]="slug"
        [defaultLang]="defaultLang"
        [languages]="languages"
        [workflow]="workflow"
        group="user"
      ></workflow-tab-content>
      </ng-container>
    </ng-container>
    <!-- if is not super User -->



  </div>
</div>