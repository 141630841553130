import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-supervision-table-config',
  templateUrl: './supervision-table-config.component.html',
  styleUrls: ['./supervision-table-config.component.scss']
})
export class SupervisionTableConfigComponent implements OnInit {

  constructor() { }

  @Input() displayedColumns : any;
  @Input() headers : any;
  @Input() model : any;
  @Input() options : any;
  @Input() displayedFilter : any;
  ngOnInit() {
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  inArray(array, item){
    return array.includes(item)
  }

}
