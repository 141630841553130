import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-onglet-settings',
  templateUrl: './onglet-settings.component.html',
  styleUrls: ['./onglet-settings.component.scss']
})
export class OngletSettingsComponent implements OnInit {

  constructor(
    private apiservice:ApiService,
    private router: ActivatedRoute,

  ) { }

  modules;
  slug;
  module_journey;
  

  ngOnInit(): void {
 
      this.apiservice.get('module-manager/?&visible=true').subscribe(res=>{
        this.modules = [];
        for(let r in res){
          if(res[r]['name'] == "Journeys"){
            this.module_journey = res[r];
          }else{
            this.modules.push(res[r]);
          }
        }
      });

  }

}
