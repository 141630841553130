import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { DialogErrorComponent } from '../components/modals/dialog-error/dialog-error.component';
import { DialogNotifSettingComponent } from '../pages/receptionist/dialogs/dialog-notif-setting/dialog-notif-setting.component';
import { DefaultValueService } from '../shared/services/default-value.service';
import { HotelService } from '../shared/services/hotel.service';
import { UserService } from '../shared/services/user.service';
import { BASE_URL } from '../shared/settings/global';
import { ApiService } from '../shared/services/api.service';
import { DialogUpdateVersionComponent } from '../components/modals/dialog-update-version/dialog-update-version.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class SidebarComponent implements OnInit  {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private us: UserService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private hs: HotelService,
    private dialog : MatDialog,
    private defaultConfig : DefaultValueService,
    private apiService : ApiService

  ) { }


  version = environment.version
  base_url = BASE_URL
  user: any;
  id = localStorage.getItem('id');
  logged = true;
  slug : any;
  hotel: any;
  menu : any;
  activeModuleNotification = false;
  user_role = localStorage.getItem('user_role');
  notification = localStorage.getItem('notif_'+this.id) ? (localStorage.getItem('notif_'+this.id) == 'true') :  false
  demonstration_status = false;

  loginChangedHandler(logged:boolean) {
    this.logged = logged;
  }

  updateNotif(){
    localStorage.setItem('notif_'+this.id, this.notification.toString())
  }

  /**
   * LOGOUT USER
   */
  logoutUser(){
    localStorage.clear()
    this.router.navigate(['/login']);
  }

  ngOnInit() {

    this.checkVersion();



    if(!localStorage.getItem('user_role') || !localStorage.getItem('roles')){
      this.logoutUser()
    }
    this.route.firstChild.params.subscribe(params => {
      this.slug = params.slug

      this.apiService.get('for_demonstration/'+this.slug).subscribe(res=>{
        this.demonstration_status = res['success']
      })

      //FIND USER
      this.us.get(this.id).subscribe(res=>{
        this.user = res;

        localStorage.setItem('user_obj', JSON.stringify(res))
        localStorage.setItem('language', res['language'])
        if(this.user.related_hotels.findIndex(x=>x.id == this.slug) != -1){
          //GET DETAIL HOTEL IF PERMISSION IS TRUE
          this.findHotelDetail();
        }else {
          //PERMISSION DENIED REDIRECT
          this.router.navigate(['/403']);
        }
      },
      ()=>{
        this.displayError()
      });

      //--- GET LIST MENU
      this.defaultConfig.getDefaultValue(this.slug, 'main-menu').subscribe(res=>{
        this.menu = JSON.parse(res['values']);
        if(this.menu.find(x=> x.id == 'notification' &&  x.active == true )){
          this.activeModuleNotification = true
        }
      },()=>{

        this.defaultConfig.getDefaultValue('29', 'main-menu').subscribe(res=>{
          this.menu = JSON.parse(res['values']);
          if(this.menu.find(x=> x.id == 'notification' &&  x.active == true )){
            this.activeModuleNotification = true
          }

        })

      })

    });
  }

  /**
   * GET HOTEL DETAIL
   */
  findHotelDetail(){
    this.hs.getHotel(this.slug).subscribe((data)=>{
      this.hotel = data;
      localStorage.setItem('hotel_language', this.hotel.languages.find(x=>x.id ==this.hotel.default_lang).lang_code)
    },
    ()=>{
      this.displayError()
    });
  }

  /**
   * DISPLAY ERROR IF CONNECTION
   */
  displayError(){
    setTimeout(()=>{
      this.dialog.open(DialogErrorComponent,{panelClass:'error-dialog'});
      this.spinner.hide('global');
    },2000)
  }

  /**
   * 
   */
  openSettingDialog(){
    this.dialog.open(DialogNotifSettingComponent, {
      width : '500px'
    })
  }

  /**
   * CHECK VERSION
   */
  checkVersion(){
    this.apiService.get('updates-news/?project=backoffice_V2&page_size=1').subscribe((res:any)=>{

      if(res && res['results'].length){
        let current_version = parseInt(environment.version.split('.').join(''));
        let last_version = parseInt(res.results[0].version.split('.').join(''));
  
        if(last_version>current_version){
          this.dialog.open(DialogUpdateVersionComponent, {
            data:res.results[0],
            width:"400px",
            position:{bottom:"30px", right:"30px"},
            disableClose:true
          })
        }
      }
     

      setTimeout(()=>{
        this.checkVersion();
      },1000*60*30)
    })
  }


}
