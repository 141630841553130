import { NgxSpinnerService } from 'ngx-spinner';
import { TramesService } from 'src/app/shared/services/trames.service';
import { TriggersService } from 'src/app/shared/services/triggers.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-export-trigger',
  templateUrl: './dialog-export-trigger.component.html',
  styleUrls: ['./dialog-export-trigger.component.scss']
})
export class DialogExportTriggerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogExportTriggerComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private triggerService: TriggersService,
    private trameService: TramesService,
    private spinner: NgxSpinnerService,
  ) { }

  customer : any;
  ngOnInit() {
  }


  import(){
    for (let trigger of JSON.parse(this.data.triggers)) {
      this.createTrigger(trigger)
    }
    for(let trigger of JSON.parse(this.data.maintenance)){
      this.createTrigger(trigger)
    }
  }

  /**
   *
   * @param trigger
   * @param createTrame
   */
  createTrigger(trigger){
    this.triggerService.create({...trigger, id : null, customer:this.customer, groups:''}).subscribe(res => {

        for(let trame of trigger.trames){
          this.trameService.create({...trame, id : null, trigger : res['id'], groups : ''}).subscribe(response=>{
              console.log(response, 'trame created')
          })
        }

    })
  }

}
