<h1 class="mat-dialog-title error">{{'Duplicate'| translate}} {{data.name}} </h1>
<div mat-dialog-content>

  <p>{{'Do you want to duplicate with all sub-categories and items ?' | translate}}</p>
  
</div>
<div mat-dialog-actions align="end">  
  <button class="btn btn-danger" [mat-dialog-close]="" >{{'Cancel' | translate}}</button>
  <button class="btn btn-success" [mat-dialog-close]="'no'" >{{'No, Just the category' | translate}}</button>
  <button class="btn btn-success" [mat-dialog-close]="'yes'" >{{'Yes' | translate}}</button>

</div>