import { map } from 'rxjs/operators';

import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class MediaService {


  constructor(private http: HttpClient) {}
  uri = URI;

  /**
   * GET MEDIA BY HOTEL/CLIENT
   * @param id
   */
  getMediaByHotel(id){
    return this.http.get(`${this.uri}` + "folders/"+id);
  }

  /**
   * GET MEDIA BY FOLDER
   * @param path
   */
  getMediaByFolder(path){
    return this.http.post(`${this.uri}` + "folders-files", {path});
  }

  /**
   * CREATE FOLDER BY PATH
   * @param path
   */
  createFolder(path){
    return this.http.post(`${this.uri}` + "create-folder", {path});
  }

  /**
   * DELETE DIRECTORY BY PATH
   * @param path
   */
  deleteDirectory(path){
    return this.http.post(`${this.uri}` + "remove-item", {"items":[path] });
  }

  /**
   * RENAME FOLDER OR FILE
   * @param path
   */
  renameDirectory(path){
    return this.http.post(`${this.uri}` + "rename-item",path);
  }

  /**
   * UPLOAD FILE WITH PROGRESS
   * @param file
   */
  uploadMedia(file){
    return this.http.post(`${this.uri}` + "upload-file", file, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event:any) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

}
