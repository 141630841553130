//-------------------------------------------
//--------------- BASE URL ----------------

import { environment } from "src/environments/environment";

//-------------------------------------------
export const defaultEnv = environment.url;
//export const defaultEnv = "http://localhost:8000";



//-------------------------------------------
//---------- LOCALSTORAGE URL -------------
//-------------------------------------------
export const localconfig = localStorage.getItem('_baseUrlApi');


//-------------------------------------------
//-- CONFIG : LOCALSTORAGE OR BASE URL ----
//-------------------------------------------
export const server = localconfig ? localconfig : defaultEnv;


//-------------------------------------------
//-------------- API URL ------------------
//-------------------------------------------
export const URI = server+'/api/v1/back/';


//-------------------------------------------
//-------------- BASE URL -----------------
//-------------------------------------------
export const BASE_URL = server;



//-------------------------------------------
//-------------- MEDIA URL ----------------
//-------------------------------------------
export const MEDIA = server;


//-------------------------------------------
//-------------- DEFAULT LANG ---------------
//-------------------------------------------
export const DefaultLang = "en";


//-------------------------------------------
//-------------- TRANSLATE  -----------------
//-------------------------------------------
export const TRANSLATE = false;

//-------------------------------------------
//-------------- WS SERVICE  -----------------
//-------------------------------------------
export const APIWS = "wss://api.tabhotel.com"
