

<h2 class="mat-dialog-title with-trans">
  {{(data.data.id ? 'Edit Trigger' : 'Add Trigger') | translate }}
</h2>

<mat-dialog-content class="mat-typography">
  <div class="col-lg-12">
    <div class="form-group">
      <label>{{ "Name" | translate }}</label>
      <input type="text" [(ngModel)]="data.data.name" class="form-control">
      <app-error-input *ngIf="errors" [errors]="errors" name="name"></app-error-input>

    </div>
  </div>
  <div class="col-lg-12">
    <div class="form-group">
      <mat-form-field>
        <mat-label>{{ "Category" | translate }}</mat-label>
        <mat-select  [(ngModel)]="data.data.category" name="category">
          <mat-option *ngFor="let category of data.categories" [value]="category.key">
            
            {{category.name}}
          </mat-option>
        </mat-select >
      </mat-form-field>
      <app-error-input *ngIf="errors" [errors]="errors" name="categories"></app-error-input>

    </div>
  </div>
 


  <div class="col-lg-6">
    <div class="form-group">
      <mat-checkbox [(ngModel)]="data.data.active">{{ "Active" | translate }}</mat-checkbox>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-warning">{{ "Cancel" | translate }}</button>
  <button mat-button (click)="createOrUpdate(data.data)"  class="btn btn-success">{{ "Save" | translate }}</button>
</mat-dialog-actions>