<div class="container-fluid">
  <app-fixed-title *ngIf="!is_child" [title]='module?.name' [hotel]='hotel'></app-fixed-title>

  <div [ngClass]="{'white-box':!is_child}">
    <!--  -->
    <div class="row">
      <div class="form-group col-md-3">
        <input type="search" placeholder="Search" (input)="searchItem()" class="form-control" [(ngModel)]="search">
      </div>
      <div class="form-group col-md-9 text-right">
        <button class="btn btn-success" [routerLink]="['/module-worker-detail', slug, module?.id, 'new']">Add
          new</button>
      </div>
    </div>
    <!--  -->

    <table class="table table-bordered" *ngIf="module">
      <thead>
        <tr>
          <ng-container *ngFor="let head of module?.configs?.header_table">
            <th *ngIf="head.active"> {{head.label}} </th>
          </ng-container>
          <th style="text-align: center; min-width: 250px;">Actions</th>
        </tr>

      </thead>
      <tbody>
        <tr *ngFor="let row of data; let i = index">

          <ng-container *ngFor="let head of module?.configs?.header_table">
            <td *ngIf="head.active">{{getDataValue(row, head.name)}}</td>
          </ng-container>
          <td class="text-center">
            <!-- {{module?.configs?.go_to_btns | json}} -->
            <ng-container *ngIf="module?.configs?.go_to_btns?.length">
              <button class="btn btn-xs btn-success" [matMenuTriggerFor]="menu"><i class="fa fa-caret-down" aria-hidden="true"></i> Links</button>
              <mat-menu #menu="matMenu" >
                <ng-container *ngFor="let link of module.configs.go_to_btns">
                  <a [href]="link.link" target="_blank" mat-menu-item *ngIf="link.type == 'extern_link'">{{link.button_text}}</a>
                  <a [routerLink]="getLink(link, row)" mat-menu-item  *ngIf="link.type != 'extern_link'">{{link.button_text}}</a>
  
                </ng-container>
              </mat-menu>
             
            </ng-container>
            <button class="btn btn-xs btn-info" [routerLink]="['/module-worker-detail', slug, module?.id, row.id ]"><i
                class="fa fa-pencil" aria-hidden="true"></i> Edit </button>

            <button class="btn btn-xs btn-danger" (click)="deleteItem(row, i)" *ngIf="module.configs?.can_delete"><i
                class="fa fa-trash" aria-hidden="true"></i> Delete</button>
          </td>
        </tr>
      </tbody>


    </table>

    <div class="mat-paginator-outer-container"  *ngIf="module?.configs?.pagination_serverside">

       <p>
        {{paramsPagination.current}} - {{paramsPagination.total_pages}} of {{paramsPagination.total_pages}}
       </p> 
         
       <button class="mat-icon-button mat-button-base" [attr.disable]="paramsPagination.previous === null" (click)="getDataFromEndPoint(paramsPagination.previous)"><span class="material-icons">arrow_back_ios</span> Previous</button>
      <button class="mat-icon-button mat-button-base" (click)="getDataFromEndPoint(paramsPagination.next)"> Next<span class="material-icons">arrow_forward_ios</span></button>
 
    </div>
 

  </div>
</div>