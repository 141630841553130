import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
	providedIn: 'root'
})
export class SlideService {

	constructor(private http: HttpClient) { }
	uri = URI;


	getSlides(id) {//by item id
		return this.http.get(`${this.uri}` + "slides/"+id);
	}

	getSlide(id){
		return this.http.get(`${this.uri}` + "slides/"+id);
	}

	updateSlide(slide, id){
		return this.http.put(`${this.uri}` + "slides/"+id+"/", slide);
	}

	createSlide(slide) {
		return this.http.post(`${this.uri}` + "slides/", slide);
	}

	deleteSlide(id){
		return this.http.delete(`${this.uri}` + "slides/"+id+"/");
	}

	sortSlides(slides){
		return this.http.post(`${this.uri}` + "slides/sort", slides);
	}
}
