import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BASE_URL, URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }



  /**
   * 
   * @param params 
   * @returns 
   */
	list(params = null){
		return this.http.get(URI+params);
	}

  /**
   * 
   * @param params 
   * @returns 
   */
  get(params){
		return this.http.get(URI+params);
	}


  /**
   * 
   * @param endpoint 
   * @param params 
   * @returns 
   */
  getWithParam(endpoint, params){
		return this.http.get(URI+endpoint,{params});
	}


  /**
   * 
   * @param params 
   * @returns 
   */
  getByUrl(params){
		return this.http.get(environment.url+"/api/v1/"+params);
  }


  /**
   * 
   * @param params 
   * @param object 
   * @returns 
   */
	update(params,object){
		return this.http.patch(URI+params+object.id+'/', object);
	}


  /**
   * 
   * @param params 
   * @returns 
   */
  delete(params){
    return this.http.delete(URI+params)
  }


  /**
   * 
   * @param params 
   * @param object 
   * @returns 
   */
	create(params,object){
		return this.http.post(URI+params, object);
	}


  /**
   * 
   * @param params 
   * @param object 
   * @returns 
   */
  post(params,object){
		return this.http.post(URI+params, object);
	}

  postbase(params,object){
		return this.http.post(BASE_URL+params, object);
  }


  /**
   * 
   * @param endpoint 
   * @param object 
   * @returns 
   */
  createOrUpdate(endpoint, object){
    if(object.id){
      return this.update(endpoint, object);
    }else {
      return this.post(endpoint, object);
    }
  }
}
