import { NgxSpinnerService } from 'ngx-spinner';
import { HotelService } from 'src/app/shared/services/hotel.service';
import { SignageService } from 'src/app/shared/services/signage.service';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-device-params',
  templateUrl: './device-params.component.html',
  styleUrls: ['./device-params.component.scss']
})
export class DeviceParamsComponent implements OnInit {

  constructor(
    private signage : SignageService,
    private hs: HotelService,
    private router : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _router : Router,
    public dialog: MatDialog) { }

	title = "Devices List for params"
	devices : any;
	slug: any;
	hotel : any;
	user_id = localStorage.getItem('user_id');
	languages : any;
	displayedColumns: string[] = ['ID_DEVICE', 'NAME','action'];
	sortedData : any;
	paramTypes = [{name:'Genral paramters', 'key':""} ];
	// MatPaginator Inputs
	length = 100;
	pageSize = 10;
	pageSizeOptions: number[] = [5, 10, 25, 100];
  @Input() onglet = false;



  ngOnInit() {
	    this.spinner.show('global');

	    this.hs.getLanguages().subscribe((data) => {
	      this.languages = data
	    });

	    this.router.params.subscribe(params => {

	      if(params.slug =="undefined"){
	          this._router.navigate(['/hotels'])
	      }
	      this.slug = params.slug;


	      this.hs.getHotel(this.slug).subscribe((data) => {
            this.hotel = data;
            this.spinner.hide('global');

	      });

	      this.signage.getDevicesByUser(this.slug, this.user_id).subscribe(res=>{
          this.spinner.hide('global');
          this.devices = res['devices'];
          this.findParams(this.devices)
            // this.sortedData = this.devices['devices'].slice();
          })

	    });
	}
// MatPaginator Output
  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }


  /**
   *
   * @param name
   */
  getParamsByType(name){
  	return this.devices.filter(x=>x.NAME_SCREEN_FORMAT == name).length
  }

  /**
   *
   * @param devices
   */
  findParams(devices){
	  for(let device of devices){
		  this.paramTypes.findIndex(x=>x.key == device.NAME_SCREEN_FORMAT) == -1 && this.paramTypes.push({key:device.NAME_SCREEN_FORMAT, name:device.NAME_SCREEN_FORMAT.split('_').join(' ')})
	  }
  }
	/**
	* SORT DATA TABLE
	* @param sort
	*/
  sortData(sort: Sort) {
    const data = this.devices.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'NAME': return compare(a.name, b.name, isAsc);
        default: return 0;
      }
    });
  }

}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
