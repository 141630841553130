import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URI } from '../settings/global';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  	uri = URI;

	constructor(private http: HttpClient) {
	}

	//GET CATEGORIES
	getCategories() {
		return this.http.get(`${this.uri}`+"items-all");
	}

	getCategoriesByType(hotel_id, id) {
		let categories = [
			'RoomService',
            'Activity',
            'MapPosition',
            'Transport',
            'Upsell'
		]
		return this.http.get(`${this.uri}`+"categories/?hotel="+hotel_id+"&category_type="+categories[id]);
	}

	//ADD CATEGORY
	postCategory(category){
		return this.http.post(`${this.uri}`+"categories/", category)
	}

	//EDIT CATEGORY
	editCategory(category){
		return this.http.put(`${this.uri}`+"categories/"+category.id+"/", category)
	}

	//SORT CATEGORIES
	//obj : {categories , hotel, category_id}
	sortCategories(obj){
		return this.http.get(`${this.uri}`+"categories/", {params: obj})
	}

	//DELETE CATAGORY
	deteleCategory(id){
		return this.http.delete(`${this.uri}`+"categories/"+id+"/")
	}
}
