<p>{{'Choose table columns to display' | translate}}</p>
<div cdkDropList class="example-list">
  <div class="example-box" *ngFor="let item of columns ; let i = index">
    <div class="form-group">
      <label class="container-checkbox"> {{i+1}} - {{item | lowercase | translate}}
        <input type="checkbox" [(ngModel)]="activeColumns[item]" (change)="updateDisplayedColumns($event)">
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</div>