
/**
 * GET NESTED OBJECT VALUE
 * @param nestedObj 
 * @param pathArr 
 */
export const getNestedObject = (nestedObj, pathArr:string[]) => {
    return pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
}

/**
 * SET NESTED OBJECT VALUE
 * @param obj 
 * @param path 
 * @param value 
 */
export const setNestedKey = (obj, path, value) => {
    if (path.length === 1) {
      obj[path] = value
      return
    }
    return setNestedKey(obj[path[0]], path.slice(1), value)
}


/**
 * GET END POINT
 * @param action 
 * @param slug 
 * @param module 
 */
export function getEndPoint(action, slug, module) {
  if (action == 'list') {
    return module.configs['api_' + action] ? module.configs['api_' + action].replace('{{hotel}}', slug) : module.endpoint.replace('{{hotel}}', slug);
  }
  else {
    return module.configs['api_' + action] ? module.configs['api_' + action].split('?')[0].replace('{{hotel}}', slug) : module.endpoint.split('?')[0].replace('{{hotel}}', slug);
  }
}
