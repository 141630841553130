
<mat-tab-group *ngIf="needsTab(attrs)">

  <ng-container *ngFor="let group of attrs ; let i = index">
   
      <mat-tab label="{{group.label}}">
        <form-list-inputs
          [group]="group"
          [hotel]="hotel"
          [model]="model"
          [superFields]="superFields"
          [type]="type"
          [languages]="languages" 
          [fb]="fb"
          [errors]="errors">
        </form-list-inputs>
      </mat-tab>
  </ng-container>

</mat-tab-group>

<ng-container *ngIf="!needsTab(attrs)">
  <ng-container *ngFor="let group of attrs ; let i = index">
      <form-list-inputs
          [group]="group"
          [hotel]="hotel"
          [model]="model"
          [superFields]="superFields"
          [type]="type"
          [languages]="languages"
          [fb]="fb"
          [errors]="errors">
        </form-list-inputs>
  </ng-container>
</ng-container>