<div class="container-fluid">
  <app-fixed-title *ngIf="hotel && !onglet" [title]='title | translate' [hotel]='hotel'></app-fixed-title>

  <h4 class="mt-3" *ngIf="onglet">{{title}}</h4>
  <div class="content">


    <div class="white-box">
      <div class="row">
        <div class="col-md-3">
          <input type="search" placeholder="Search" class="form-control" (input)="searchMenu($event.target.value)">
        </div>

        <div class="col text-right">
          <app-select-translate [hotel]="hotel" *ngIf="hotel" [fixed]="false"></app-select-translate>

          <button class="btn btn-success" (click)="updateMenu()">Save changes</button>
        </div>
      </div>


      <div class="group-menu" [cdkDropListData]="activeMenus" cdkDropList (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let menu of activeMenus ; let i = index">

          <div class="" cdkDrag>
          <div class="item" *appHasRole="menu.role" >
            <div class="custom-placeholder" *cdkDragPlaceholder></div>

            <div class="row">
              <div class="left-side col-md-6">
                <button class="btn move" cdkDragHandle><span class="material-icons">drag_indicator</span></button>
                <button class="btn edit" (click)="edit(menu)"><span class="material-icons">create</span></button>

                <ng-container *ngIf="!menu.edit">{{menu.name}}</ng-container>
                <ng-container  *ngIf="menu.edit">
                  <span class="default">{{menu.name}}</span> <translated-input [getDefault]="true" [model]="menu" attr="name" [hotel]="hotel" type="text"
                  [translate]="false"></translated-input>
                  <button class="btn btn-save btn-sm btn-success" (click)="updateMenu(menu)">Confirm</button>
                 </ng-container>
              </div>
              <div class="right-side col-md-6 text-right">
                <label>Display for : </label>
                <select class="form-control" [(ngModel)]="menu.role">
                  <option value="concierge">{{'Concierge' | translate}}</option>
                  <option value="administrator">{{'Admin' | translate}}</option>
                  <option value="super-user" *appHasRole="'super-user'">{{'Super admin' | translate}}</option>
                </select>
              </div>
            </div>

          </div>

          <div class="group-submenu" *ngIf="menu?.submenu?.length" [cdkDropListData]="menu.submenu" cdkDropList (cdkDropListDropped)="drop($event)">
            <ng-container *ngFor="let submenu of menu.submenu; let j = index">
              <!-- SUBMENU -->
              <div class="submenu" *appHasRole="submenu.role" >
                <!-- ITEM -->
                <div class="item" cdkDrag>
                  <div class="custom-placeholder" *cdkDragPlaceholder></div>

                  <div class="row">
                    <div class="left-side col-md-6">
                      <button class="btn move" cdkDragHandle><span class="material-icons">drag_indicator</span></button>
                      <button class="btn edit" (click)="edit(submenu)"><span class="material-icons">create</span></button>
                      <ng-container *ngIf="!submenu.edit">{{submenu.name}}</ng-container>
                      <ng-container  *ngIf="submenu.edit">
                        <span class="default">{{submenu.name}} </span>
                        <translated-input [getDefault]="true" [model]="submenu" attr="name" [hotel]="hotel" type="text"
                        [translate]="false"></translated-input>
                        <button class="btn btn-save btn-sm btn-success" (click)="updateMenu(submenu)">Confirm</button>
                       </ng-container>
                      
                    </div>
                    <div class="right-side col-md-6 text-right">
                      <label>Display for : </label>
                      <select class="form-control" [(ngModel)]="submenu.role">
                        <option value="concierge">{{'Concierge' | translate}}</option>
                        <option value="administrator">{{'Admin' | translate}}</option>
                        <option value="super-user" *appHasRole="'super-user'">{{'Super admin' | translate}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- ITEM -->
              </div>
              <!-- SUBMENU -->
            </ng-container>
          </div>
        </div> 
        </ng-container>
     
      </div>
    </div>
  </div>