import { QueryBuilderConfig } from 'angular2-query-builder';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignageService } from 'src/app/shared/services/signage.service';
import { TramesService } from 'src/app/shared/services/trames.service';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-trame',
  templateUrl: './dialog-trame.component.html',
  styleUrls: ['./dialog-trame.component.scss']
})
export class DialogTrameComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogTrameComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private trameService: TramesService,
    private signageService: SignageService,
    private spinner: NgxSpinnerService,
  ) { }

  trame: any;
  errors

  countries: any;
  slug: any;
  config: QueryBuilderConfig;
  hotel = this.data.hotel;
  export_types = [
    {value: "audio", viewValue: "Audio"},
    {value: "mail", viewValue: "Mail"},
    {value: "text", viewValue: "Text"},
    // {value: 'sms', viewValue: 'SMS'}
  ];




  /**
   *
   * @param data
   * @param val
   * @param id
   */
  transformData(data, val = 'name', id = 'id'){
    let array = [];

    try {
      for(let obj of data){
        array.push({name : obj[val] , value : obj[id]})
      }
    } catch (error) {
      
    }

    return array;


  }


  ngOnInit() {
    this.trame = this.data.data;
    if (!this.trame.conditions) {
      this.trame.conditions = {
        condition: 'and',
        rules: []
      }
    }

    this.signageService.getCustomVars(3, 'COUNTRIES').subscribe(res => {
      this.countries = this.transformData(res["data"], 'label', 'label' );
      this.config = {
        fields: {
          Comments:{
            name:'Comments',
            type : 'category',
            options : [{name:'Empty', value:''}]
          },
          Alerts:{
            name:'Alerts',
            type : 'category',
            options : [{name:'Empty', value:''}]
          },
          Member:{
            name:'Member',
            type : 'category',
            options : [{name:'Empty', value:''}]
          },
          Scenario :{
            name: 'Scenario',
            type: 'category',
            options: this.transformData(this.data.workflows, 'WORKFLOW_NAME', 'WORKFLOW_NAME')
          },
          Borne: {
            name: 'Borne name',
            type: 'category',
            options: this.transformData(this.data.hardwares, 'Name', 'Name')
          },
          Total: { name: 'Total', type: 'number' },
          Nationality :{
            name: 'Nationality',
            type: 'category',
            options: this.countries
          }
        }
      }
    })

  }

  /**
   *
   * @param data
   */
  createOrUpdate(data) {
    this.spinner.show('global');
    if (!data['id']) {
      this.trameService.create({ ...data, trigger: this.data.trigger , priority:1}).subscribe(res => {
        this.spinner.hide('global');
        this.dialogRef.close(res);
      }, err=>{
        this.errors = err.error
        this.spinner.hide('global');

      });
    } else {
      this.trameService.update(data).subscribe(res => {
        this.spinner.hide('global');
        this.dialogRef.close(res);
      }, err=>{
        this.errors = err.error
        this.spinner.hide('global');

      });
    }
  }


}
