import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {

  constructor() { }

  @Input() minValue = 0; //time 00
  @Input() maxValue = 1439; //time 23:59 to minutes 

  @Input() start = this.minValue;
  @Input() end   = this.maxValue;

  

  ngOnInit() {
  
  }

  /**
   * 
   */
  getStyle(){
    let width = (((this.end - this.start)*100) / (this.maxValue  - this.minValue))+"%"
    let left = ((this.start*100) / (this.maxValue  - this.minValue)) + "%";
    if(this.end == this.start){
      width = "100%";
      left = "0";
    }
    return {width, left}
  }

  /**
   * 
   */
  getStyleStart(){
    let left = ((this.start*100) / (this.maxValue  - this.minValue)) -5 ;
    if(left >= 0){
      return {left : left + "%"}
    }else {
      return {left:0}
    }
    
  }

  /**
   * 
   */
  getStyleEnd(){
    let left = ((this.end*100) / (this.maxValue  - this.minValue))-2 + "%";
    return {left}
  }

  /**
   * 
   * @param time 
   */
  getTime(time){
    let h = (time - (time % 60)) / 60 + '';
    let m = (time % 60) + '';
    (h.length == 1) ? h = '0' + h : h = h;
    (m.length == 1) ? m = '0' + m : m = m;

    return h + ':' + m ;
  }
}
