import { NgxSpinnerService } from 'ngx-spinner';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { HotelService } from '../../shared/services/hotel.service';
import { HotelinformationsService } from '../../shared/services/hotelinformations.service';
import { ItemService } from '../../shared/services/item.service';
import { SliderService } from '../../shared/services/slider.service';
import { TransportService } from '../../shared/services/transport.service';
import { MEDIA } from '../../shared/settings/global';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {



  constructor(
    private hs: HotelService,
    private it: ItemService,
    private hi: HotelinformationsService,
    private trans: TransportService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private slr : SliderService,
    private spinner: NgxSpinnerService,
  ) { }


  title = "Property settings";
  @Input() onglet = false;
  icons:any;
  hotel: any;
  hotels: any;
  informations : any;
  languages: any;
  socials: any;
  sliders : any;
  slider: any;
  items: any;
  transports : any;
  transport: any;
  slug: any;
  id: any;
  MEDIA = MEDIA;
  subscriber = [];


  ngOnInit() {
    this.spinner.show('global');

    this.route.params.subscribe(params => {
      this.slug = params.slug

      this.subscriber.push(this.hs.getLanguages().subscribe((data) => {
        this.languages = data
      }))

      this.subscriber.push(this.hs.getHotel(this.slug).subscribe((data) => {
        this.hotel = data;
        this.id = this.hotel.id;
        this.spinner.hide('global');
      }));
      this.subscriber.push(this.hi.getSocialLinks(this.slug).subscribe((data) => {
        this.socials = data
      }));
      this.subscriber.push(this.hi.getInformations(this.slug).subscribe((data) => {
        this.informations = data;
      }));
    });
    this.subscriber.push(this.slr.getSliders(this.slug).subscribe((data) => {
      this.sliders = data
    }));
    this.subscriber.push(this.trans.getTransports(this.slug).subscribe((data) => {
      this.transports = data
    }));

    this.subscriber.push(this.it.getItems().subscribe((data) => {
      this.items = data
    }));

    this.subscriber.push(this.hi.getIcons().subscribe(data=>{
      this.icons = data;
    }))

  }


  ngOnDestroy() {
    for(let sub of this.subscriber){
        sub.unsubscribe();
    }
  }



}
